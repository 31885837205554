import React from "react";
import styled from "styled-components";

const StyledNotFoundPage = styled.div`
    text-align: center;
    padding: 20px;
`;

const NotFoundPage = () => (
    <StyledNotFoundPage>
        <h1>Nothing found here</h1>
    </StyledNotFoundPage>
);

export default NotFoundPage;
