import { ResizeSensor2 } from "@blueprintjs/popover2";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import styled from "styled-components";

import { useScrollbarWidth } from "../../common/hooks";

/**
 * Container with sticky footer and header
 * THIS COMPONENT MUST HAVE AN height SET TO WORK !!!
 */
const StyledStickyContainer = styled.div`
    position: relative;
    .sticky-container-children {
        position: absolute;
        overflow: auto;
        left: 0;
        right: 0;
        top: ${({ $headerHeight }) => $headerHeight}px;
        bottom: ${({ $footerHeight }) => $footerHeight}px;
    }
    .sticky-container-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: ${({ $headerHeight }) => $headerHeight}px;
        overflow: hidden;
        box-sizing: border-box;
        .sticky-container-sensor {
            overflow: hidden;
        }
    }

    .sticky-container-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: ${({ $footerHeight }) => $footerHeight}px;
        overflow: hidden visible;
        box-sizing: border-box;
        .sticky-container-sensor {
            overflow: hidden;
        }
    }
`;

const StickyContainer = ({ children, header, footer, className }) => {
    const [footerHeight, setFooterHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const scrollBarSize = useScrollbarWidth();

    const handleResizeFooter = useCallback((entries) => {
        setFooterHeight(entries[0].contentRect.height);
    }, []);

    const handleResizeHeader = useCallback((entries) => {
        setHeaderHeight(entries[0].contentRect.height);
    }, []);

    return (
        <StyledStickyContainer
            className={className}
            $footerHeight={footerHeight}
            $headerHeight={headerHeight}
            $scrollBarSize={scrollBarSize}
        >
            <div className="sticky-container-header">
                <ResizeSensor2 onResize={handleResizeHeader}>
                    <div className="sticky-container-sensor">{header}</div>
                </ResizeSensor2>
            </div>

            <div className="sticky-container-children">{children}</div>

            <div className="sticky-container-footer">
                <ResizeSensor2 onResize={handleResizeFooter}>
                    <div className="sticky-container-sensor">{footer}</div>
                </ResizeSensor2>
            </div>
        </StyledStickyContainer>
    );
};

StickyContainer.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node,
    footer: PropTypes.node,
    className: PropTypes.string,
};

StickyContainer.defaultProps = {
    header: null,
    footer: null,
    className: null,
};

export default StickyContainer;
