import { Button, Classes, FormGroup, InputGroup, Intent, Switch } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { peek } from "../../common/utils";
import { useMutationCreateUserAgency } from "../agency/gql-agency";
import ErrorCallout from "../common/error-callout";
import SimpleSelect from "../common/simple-select";

export const ALL_USER_TYPE = [
    { value: "customer", label: "Customer", icon: "user" },
    { value: "team", label: "Team", icon: "high-priority" },
];
export const ALL_TICKET_TYPE = [
    { value: "bug", label: "Bug", icon: "warning-sign" },
    { value: "premium", label: "Premium", icon: "dollar" },
    { value: "marketing", label: "Marketing", icon: "shopping-cart" },
    { value: "sale", label: "Sale", icon: "chart" },
    { value: "platform", label: "Platform", icon: "social-media" },
    { value: "design", label: "Design", icon: "page-layout" },
    { value: "ai", label: "AI", icon: "heart" },
];
export const FieldGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`;
const CreateUserAgencyDialog = ({ hideDialog, isDialogProcessing }) => {
    const [createUserAgency, { loading: saving, error }] = useMutationCreateUserAgency();
    const [data, setData] = useState({});
    const [userPassword, setUserPassword] = useState(null);

    const onChangeValue = useCallback((k, v) => {
        setData((oldValues) => ({
            ...oldValues,
            [k]: v,
        }));
    }, []);
    const handleCreate = useCallback(
        async (values) => {
            const response = await createUserAgency({
                userAgency: {
                    ...peek(values, ["type", "email", "allowEmail", "allowLogin"]),
                },
            });
            toast.success("Successfully user created");
            setUserPassword(response.data?.createUserAgency?.password);
        },
        [createUserAgency]
    );
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while creating user" error={error} />
                <br />
                {userPassword ? (
                    <>
                        <h4>Password :</h4> <h3>{userPassword}</h3>
                        <h4 style={{ color: "red" }}>
                            Please remember to copy your password for safekeeping, or the user will have to use the
                            Forgot Password option
                        </h4>
                        <Button text="OK" onClick={() => hideDialog(0)} />
                    </>
                ) : (
                    <>
                        <FieldGroup>
                            <FormGroup
                                label="Type"
                                helperText="Select an appropriate user type for your agency"
                                intent={Intent.WARNING}
                            >
                                <SimpleSelect
                                    outlined
                                    options={ALL_USER_TYPE}
                                    onSelect={(v) => onChangeValue("type", v)}
                                    selected={data.type}
                                />
                            </FormGroup>
                        </FieldGroup>

                        {data?.type === "customer" && (
                            <>
                                <FormGroup label="Email">
                                    <InputGroup
                                        required
                                        aria-label="email"
                                        value={data?.email}
                                        onChange={(e) => onChangeValue("email", e.target.value)}
                                        maxLength={80}
                                    />
                                </FormGroup>
                                <FormGroup label="Permissions">
                                    <Switch
                                        large
                                        id="targeting-input-validated"
                                        checked={data?.allowLogin}
                                        onChange={(e) => onChangeValue("allowLogin", e.currentTarget.checked)}
                                        labelElement={<strong>Allow Login</strong>}
                                    />
                                    <Switch
                                        large
                                        id="targeting-input-validated"
                                        checked={data?.allowEmail}
                                        onChange={(e) => onChangeValue("allowEmail", e.currentTarget.checked)}
                                        labelElement={<strong>Allow Email</strong>}
                                    />
                                </FormGroup>
                            </>
                        )}

                        {data?.type === "team" && (
                            <FormGroup label="Email">
                                <InputGroup
                                    required
                                    aria-label="email"
                                    value={data?.email}
                                    onChange={(e) => onChangeValue("email", e.target.value)}
                                    maxLength={80}
                                />
                            </FormGroup>
                        )}
                    </>
                )}
            </div>
            {!userPassword && (
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button disabled={isDialogProcessing} text="Cancel" onClick={() => hideDialog(0)} />
                        <Button
                            disabled={!data?.email}
                            loading={saving || isDialogProcessing}
                            intent={Intent.SUCCESS}
                            text="Create User"
                            onClick={() => handleCreate(data)}
                            rightIcon="plus"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

CreateUserAgencyDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

export default {
    title: "Create User Agency",
    icon: "user",
    component: CreateUserAgencyDialog,
};
