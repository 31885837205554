import { Button, Classes, ControlGroup, Dialog, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import Box from "../common/box";
import ErrorCallout from "../common/error-callout";
import SimpleTable from "../common/simple-table";
import Search from "../search";
import { useQueryMapboxAddress } from "./qgl-mapbox";

const MapboxTable = ({ features, onSelect, selected }) => {
    return (
        <SimpleTable
            items={features}
            columns={[
                { title: "Id", cell: (item) => item.id, icon: null, width: 200 },
                { title: "Name", cell: (item) => item.placeName, icon: null, width: 300, wrapText: true },
                { title: "Type", cell: (item) => item.placeType, icon: null },
            ]}
            onSelect={onSelect}
            selected={selected}
        />
    );
};

MapboxTable.propTypes = {
    features: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            placeName: PropTypes.string,
            placeType: PropTypes.string,
        })
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.string,
};

MapboxTable.defaultProps = {
    selected: null,
};

const MapboxFeatureChooser = ({ onChange, onClose, value }) => {
    const [selection, setSelection] = useState(null);
    const [searchValue, setSearchValue] = useState(value);
    const [loadMapboxAddresses, { loading, addresses: results, error }] = useQueryMapboxAddress();
    const doSearch = useCallback(async () => {
        if (searchValue) {
            await loadMapboxAddresses({ q: searchValue });
        }
    }, [loadMapboxAddresses, searchValue]);

    return (
        <div>
            <div className={Classes.DIALOG_BODY}>
                <Search value={searchValue} setValue={setSearchValue} onSearch={doSearch} />
                <br />
                <div style={{ height: "240px" }}>
                    {loading ? (
                        <div>Loading please wait...</div>
                    ) : (
                        results && (
                            <MapboxTable
                                features={results}
                                selected={results.find((r) => r.id === selection)}
                                onSelect={(item) => setSelection(item.id)}
                            />
                        )
                    )}
                    {error && <ErrorCallout title="Unable to get data" error={error} />}
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Cancel" onClick={onClose} />
                    <Button
                        disabled={!selection}
                        text="Select"
                        intent={Intent.PRIMARY}
                        onClick={() => onChange(selection)}
                    />
                </div>
            </div>
        </div>
    );
};

MapboxFeatureChooser.propTypes = {
    value: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.string,
};

MapboxFeatureChooser.defaultProps = {
    value: null,
    selected: null,
};

const StyledMapboxIdField = styled.div``;

const MapboxIdField = ({ value, onChange, searchValue }) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [selecting, setSelecting] = useState(false);
    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleChange = useCallback(
        (val) => {
            setSelecting(false);
            onChange(val);
        },
        [onChange]
    );

    return (
        <StyledMapboxIdField>
            <Dialog
                onClose={() => setSelecting(false)}
                onEsc={() => setSelecting(false)}
                isOpen={selecting}
                title="Select a mapbox id"
                style={{ height: "450px", width: "800px" }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <MapboxFeatureChooser
                        onClose={() => setSelecting(false)}
                        onChange={handleChange}
                        value={searchValue}
                    />
                </div>
            </Dialog>
            <Box direction="row" gap="small">
                <ControlGroup className="content-value" fill>
                    {currentValue ? (
                        <Button
                            fill
                            minimal
                            outlined
                            text={currentValue.toString()}
                            alignText="left"
                            onClick={() => setSelecting(true)}
                        />
                    ) : (
                        <Button
                            fill
                            minimal
                            outlined
                            text="Not set"
                            className={Classes.DISABLED}
                            alignText="left"
                            onClick={() => setSelecting(true)}
                        />
                    )}
                    <Button onClick={() => setSelecting(true)} intent={Intent.PRIMARY}>
                        <span style={{ margin: "0 5px" }}>{currentValue ? " Change" : " Set "}</span>
                    </Button>
                    {currentValue && <Button onClick={() => onChange(null)} icon="cross" />}
                </ControlGroup>
            </Box>
        </StyledMapboxIdField>
    );
};

MapboxIdField.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    searchValue: PropTypes.string,
};

MapboxIdField.defaultProps = {
    className: null,
    value: null,
    onChange: null,
    searchValue: "",
};

export default MapboxIdField;
