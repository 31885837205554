import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { parseQueryString } from "../../common/utils";
import Box from "../common/box";
import Search from "../search";
import TransactionsList from "./transactions-list";

const StyledTargetingPage = styled.div`
    height: 100vh;
    width: 100%;
    display: grid;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    .targeting-list {
        position: relative;
        align-items: stretch;
        .amp-table-container {
            position: absolute;
            bottom: 10px;
            top: 0;
            right: 10px;
            left: 10px;
        }
    }
`;

const TransactionsPage = ({ history, location }) => {
    const { search } = location;
    const query = parseQueryString(search);
    const [inputValue, setInputValue] = useState(query.q);
    const [currentSearch, setCurrentSearch] = useState(query.q);

    const pushHistoryQuery = useCallback(
        (newQuery, replace) => {
            const { search: s } = location;
            const q = parseQueryString(s || "");
            const newParams = replace
                ? newQuery
                : {
                      ...q,
                      ...newQuery,
                  };
            const params = new URLSearchParams(
                Object.entries(newParams).reduce((acc, [k, v]) => {
                    if (v !== null) {
                        acc[k] = v;
                    }
                    return acc;
                }, {})
            ).toString();
            history.push({
                search: `?${params}`,
            });
        },
        [history, location]
    );

    const handleSearch = useCallback(
        () => {
            setCurrentSearch(inputValue);
            pushHistoryQuery({
                q: inputValue,
            });
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [history, inputValue]
    );

    return (
        <StyledTargetingPage>
            <Box direction="row" pad="small" gap="small">
                <Search
                    setValue={setInputValue}
                    value={inputValue}
                    onSearch={handleSearch}
                    onReset={() => setInputValue("")}
                    placeholder="Search transaction with email user"
                />
            </Box>
            <Box pad="small" className="targeting-list">
                <TransactionsList search={currentSearch} />
            </Box>
        </StyledTargetingPage>
    );
};

TransactionsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
};

export default TransactionsPage;
