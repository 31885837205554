import { RangeSlider } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledFilterPerfInput = styled.div`
    padding: 0 10px;
`;

const FilterPerfInput = ({ id, value, onChange, disabled }) => {
    const [val, setVal] = useState(value);
    useEffect(() => {
        setVal(value);
    }, [value]);
    const renderLabel = (v, { isHandleTooltip }) => {
        const color = (() => {
            if (isHandleTooltip) {
                return "white";
            }
            if (v <= 0.25) {
                return "red";
            }
            if (v <= 0.5) {
                return "orange";
            }
            return "green";
        })();
        return <span style={{ color }}>{(v * 100.0).toFixed(0)}</span>;
    };
    return (
        <StyledFilterPerfInput>
            <RangeSlider
                id={id}
                min={0.0}
                max={1.0}
                stepSize={0.05}
                onChange={setVal}
                onRelease={onChange}
                value={val}
                labelStepSize={0.1}
                labelRenderer={renderLabel}
                className="inner-slider"
                disabled={disabled}
            />
        </StyledFilterPerfInput>
    );
};

FilterPerfInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

FilterPerfInput.defaultProps = {
    id: null,
    disabled: false,
};

export default FilterPerfInput;
