import { gql, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

export const GQL_FRAGMENT_GEO_TARGETING = gql`
    fragment targetingLinks on Targeting {
        id
        name
        localeName
        countryLocaleName
        subtype
        platforms
        isoCode
    }
`;

export const GQL_QUERY_GEO_TARGETING = gql`
    query QueryLocation($q: String!, $country: String) {
        searchGeoTargeting(q: $q, country: $country) {
            ...targetingLinks
            platforms
        }
    }

    ${GQL_FRAGMENT_GEO_TARGETING}
`;

export const useQueryGeoTargeting = () => {
    const [caller, { data: { searchGeoTargeting } = {}, ...rest }] = useLazyQuery(GQL_QUERY_GEO_TARGETING, {
        notifyOnNetworkStatusChange: true,
    });
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { results: searchGeoTargeting, ...rest }];
};
