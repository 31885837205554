import { Callout, Intent, Spinner, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import NavBreadcrumb from "../../common/nav-breadcrumb";
import { useQueryMessageLogs } from "../gql-messages";
import NavigationMenu from "../message-menu";
import { getMessageBackerLog } from "./log-helpers";
import LogView from "./log-view";

const MessageLogs = ({ message }) => {
    const allLogs = useMemo(() => {
        let result = (message ? message.logs : []).map((l) => ({ ...l, at: new Date(l.insertedAt) }));
        if (message && message.ticketLogs) {
            result = result.concat(message.ticketLogs.map((tl) => ({ ...tl, at: new Date(tl.insertedAt) })));
        }

        (message ? message.backers : []).forEach((b) => {
            result.push(getMessageBackerLog(b, message.currency));
        });
        (message ? message.revisions : []).forEach((r) => {
            result.push({
                text: r.changes.length ? (
                    <span>
                        New revision with {r.changes.length} changes:{" "}
                        {r.changes.map((c) => (
                            <Tag minimal style={{ marginRight: 5 }}>
                                {c.key}
                            </Tag>
                        ))}
                    </span>
                ) : (
                    "Initial revision"
                ),
                level: "INFO",
                icon: "git-new-branch",
                at: new Date(r.lastStateChangedAt),
            });
        });
        result.sort((a, b) => b.at - a.at);
        return result;
    }, [message]);
    return (
        <div>
            <NavBreadcrumb
                items={[
                    { icon: "home", to: "/" },
                    { text: "Messages", icon: <RiAdvertisementLine />, to: "/messages" },
                    {
                        text: message.id,
                    },
                    {
                        text: "Logs",
                        icon: "properties",
                        current: true,
                    },
                ]}
                currentBreadcrumbRenderer={({ text, icon }) => (
                    <NavigationMenu message={message} text={text} icon={icon} />
                )}
            />
            <LogView logs={allLogs} />
        </div>
    );
};

MessageLogs.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        logs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        backers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        revisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        ticketLogs: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

const StyledMessageLogPage = styled.div`
    padding: 10px;
`;

const MessageLogPage = () => {
    const { id } = useParams();
    const { message, loading, error } = useQueryMessageLogs(id);

    return (
        <StyledMessageLogPage>
            {loading && (
                <Callout title="Loading message, please wait...">
                    <Spinner />
                </Callout>
            )}
            {error && (
                <Callout intent={Intent.DANGER} title="Error while loading message">
                    {error.message}
                </Callout>
            )}

            {message ? (
                <MessageLogs message={message} />
            ) : (
                !loading &&
                !error && (
                    <Callout intent={Intent.DANGER} title="Nothing found here">
                        {id}
                    </Callout>
                )
            )}
        </StyledMessageLogPage>
    );
};

export default MessageLogPage;
