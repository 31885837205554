import { useLazyQuery } from "@apollo/client";
import { Button, Intent } from "@blueprintjs/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useIdentity } from "../../common/identity-context";
import { DialogContext } from "../bp-dialog-context";
import ErrorCallout from "../common/error-callout";
import FilterOnOff from "../common/filter-onoff";
import FilterOptionsSelect from "../common/filter-options-select";
import FilterTextSearch from "../common/filter-text-search";
import Pagination from "../common/pagination";
import CreateTicket, { ALL_TICKET_PRIORITY, ALL_TICKET_TYPE } from "../dialogs/create-ticket";
import { Label, StyledFiltersZone } from "../messges/message-filter-pane";
import { GQL_QUERY_TICKETS } from "./gql-tickets";
import TicketsTable from "./tickets-table";

const LIMIT = 10;

const HeaderInfo = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
    .fill {
        flex-grow: 1;
    }
`;
const getAllStatus = (closed) => {
    const stautsTab = [
        {
            value: "open",
            label: "Open",
        },
        {
            value: "in progress",
            label: "In Progress",
            color: "blue",
        },
        {
            value: "resolved",
            label: "Resolved",
            color: "green",
        },
    ];
    if (closed) {
        stautsTab.push({
            value: "closed",
            label: "Closed",
            color: "green",
        });
    }
    return stautsTab;
};

const TicketsPage = () => {
    const { showDialog } = useContext(DialogContext);
    const [, identity, , hasPermission] = useIdentity();
    const [filters, setFilters] = useState({
        assignedMember: identity?.username,
    });
    const offset = filters?.offset || 0;
    const [loadTickets, { loading, error, data }] = useLazyQuery(GQL_QUERY_TICKETS, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
    });
    const count = data?.getAllTickets?.count;
    const nbPages = Math.ceil(count / LIMIT);
    const currentPage = Math.floor(offset / LIMIT) + 1;
    const indices = [offset + 1, Math.min(offset + LIMIT, count)];
    const history = useHistory();

    useEffect(
        () =>
            (async () => {
                await loadTickets({
                    variables: {
                        ...Object.fromEntries(Object.entries(filters).filter(([key, value]) => value !== "")),
                        limit: LIMIT,
                    },
                });
            })(),
        [filters, history, loadTickets]
    );

    const handleChangePage = async ({ startIndex, endIndex }) => {
        setFilters({ ...filters, offset: startIndex });
    };

    const onChangeFilter = useCallback((k, v) => {
        setFilters((oldValues) => ({
            ...oldValues,
            [k]: v,
            offset: 0,
        }));
    }, []);

    const createTicket = useCallback(async () => {
        await showDialog(CreateTicket);
    }, [showDialog]);

    return (
        <div style={{ padding: "20px" }}>
            <h1>Staff Tickets</h1>
            <StyledFiltersZone>
                <Label>Status</Label>
                <FilterOptionsSelect
                    options={getAllStatus(filters?.closed)}
                    value={filters?.status}
                    onChange={(value) => {
                        onChangeFilter("status", value);
                    }}
                    emptyText="Any status"
                />
                <Label>Type</Label>
                <FilterOptionsSelect
                    options={ALL_TICKET_TYPE}
                    value={filters?.ticketType}
                    onChange={(value) => {
                        onChangeFilter("ticketType", value);
                    }}
                    emptyText="Any type"
                />
                <Label>AssignedMember</Label>
                <FilterTextSearch
                    value={filters?.assignedMember}
                    onChange={(value) => onChangeFilter("assignedMember", value)}
                    emptyText="Search a username"
                />
                <Label>Message Id</Label>
                <FilterTextSearch
                    value={filters?.messageId}
                    onChange={(value) => onChangeFilter("messageId", value)}
                    emptyText="Search message id"
                />
                <Label>Priority</Label>
                <FilterOptionsSelect
                    options={ALL_TICKET_PRIORITY}
                    value={filters?.priority}
                    onChange={(value) => {
                        onChangeFilter("priority", value);
                    }}
                    emptyText="Any Priority"
                />
                <Label>Ticket Id</Label>
                <FilterTextSearch
                    value={filters?.ticketId}
                    onChange={(value) => onChangeFilter("ticketId", value)}
                    emptyText="Search ticket id"
                />
                <Label>Closed Tickets</Label>
                <FilterOnOff
                    value={!!filters.closed}
                    onChange={(value) => onChangeFilter("closed", value)}
                    onText={<span>Display closed Tickets&nbsp;</span>}
                />
            </StyledFiltersZone>
            <div style={{ padding: "10px" }}>
                <Button
                    intent={Intent.PRIMARY}
                    disabled={!hasPermission && !hasPermission("staff", "add_ticket")}
                    onClick={createTicket}
                    text="Create Ticket"
                    icon="add"
                />
            </div>
            <HeaderInfo>
                {(() => {
                    if (loading) {
                        return "Loading, please wait...";
                    }
                    if (indices[1] === 0) {
                        return "No ticket to display";
                    }
                    return `See tickets from ${indices[0]} to ${indices[1]} on a total of ${count}`;
                })()}
            </HeaderInfo>

            <ErrorCallout title="Error while loading tickets" error={error} />
            <TicketsTable
                tickets={data?.getAllTickets.results || []}
                loading={loading}
                ordering={filters.order}
                hasPermission={hasPermission}
                identity={identity}
            />

            <HeaderInfo>
                {!loading && <div className="fill">{count} tickets</div>}
                <div>
                    {nbPages > 1 ? (
                        <Pagination
                            step={LIMIT}
                            numberItems={count}
                            page={currentPage}
                            numberEdgePages={2}
                            onChange={handleChangePage}
                        />
                    ) : (
                        <div />
                    )}
                </div>
            </HeaderInfo>
        </div>
    );
};

export default TicketsPage;
