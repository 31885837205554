import { MenuItem } from "@blueprintjs/core";
import { Omnibar } from "@blueprintjs/select";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import { useQueryAllUsers } from "../users/gql-users";
import UserBadge from "./user-badge";

const getUserLabel = (user) => (
    <div>
        {user.firstName} {user.lastName} <small>{user.email}</small>
    </div>
);

const UserChooser = ({ selected, onSelect, isOpen, onClose, onlyStaff }) => {
    const [q, setQ] = useState("");
    const [loadUsers, { users, loading, updateQuery }] = useQueryAllUsers();
    const [preloading, setPreloading] = useState(false);

    useEffect(() => {
        if (isOpen === false) {
            setQ("");
        }
    }, [updateQuery, isOpen]);

    const queryDebounced = useMemo(() => {
        const _query = async (newQ) => {
            let queryObject = { q: { search: newQ } };
            if (onlyStaff)
                queryObject.isStaff = {
                    is: true,
                };
            await loadUsers(queryObject);
            setPreloading(false);
        };
        const debounced = debounce(_query, 500);
        return (_queryPre) => {
            if (_queryPre.length > 2) {
                setPreloading(true);
                debounced(_queryPre);
            }
        };
    }, [loadUsers, onlyStaff]);

    useEffect(() => {
        queryDebounced(q);
    }, [queryDebounced, q]);

    const itemRenderer = (user, { modifiers, handleClick }) => {
        return (
            <MenuItem
                text={getUserLabel(user)}
                onClick={handleClick}
                key={user.id}
                active={modifiers.active}
                icon={selected?.id === user.id ? "tick" : "blank"}
                label={<UserBadge user={user} size={24} />}
            />
        );
    };

    const loadingIndicator = loading || preloading;
    return (
        <Omnibar
            isOpen={isOpen}
            items={loadingIndicator ? [] : users || []}
            itemRenderer={itemRenderer}
            onItemSelect={(user) => {
                onSelect && onSelect(user);
            }}
            query={q}
            onQueryChange={setQ}
            noResults={<MenuItem disabled={true} text={loadingIndicator ? "loading..." : "No user found."} />}
            onClose={onClose}
            itemPredicate={(query, opt, _index, exactMatch) => {
                const normalizedEmail = opt?.email.toLowerCase();
                const normalizedFirstName = opt?.email.toLowerCase();
                const normalizedLastName = opt?.email.toLowerCase();
                const normalizedQuery = query.toLowerCase();
                return (
                    normalizedEmail.indexOf(normalizedQuery) >= 0 ||
                    normalizedFirstName.indexOf(normalizedQuery) >= 0 ||
                    normalizedLastName.indexOf(normalizedQuery) >= 0
                );
            }}
        />
    );
};

UserChooser.propTypes = {
    onSelect: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selected: PropTypes.shape({ id: PropTypes.string }),
    onlyStaff: PropTypes.bool,
};

UserChooser.defaultProps = {
    selected: null,
    onlyStaff: false,
};

export default UserChooser;
