import { RangeSlider } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const AGE_MAP = {
    0: "13y",
    1: "20y",
    2: "30y",
    3: "50y",
    4: "60y",
    5: "60+",
};

export function ageRangeToString(min, max) {
    if (max > 4) {
        // from x to oo
        return `${AGE_MAP[min]} years old and more`;
    }
    // from x to y
    return `From ${AGE_MAP[min]} to ${AGE_MAP[max]} years old`;
}

const StyledFieldAge = styled.div`
    padding: 10px 20px;
`;

const FieldAge = ({ value, onChange, disabled }) => {
    const realValues = [value[0] || 0, value[1] || 5];
    return (
        <StyledFieldAge>
            <RangeSlider
                disabled={disabled}
                min={0}
                max={5}
                labelRenderer={(v, locale) => AGE_MAP[v]}
                onChange={onChange}
                value={realValues}
                labelStepSize={1}
            />
        </StyledFieldAge>
    );
};

FieldAge.propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FieldAge.defaultProps = {
    value: [0, 5],
    emptyValue: null,
    onChange: null,
    disabled: false,
};

export default FieldAge;
