import { Button, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationUpdateStatusTicket } from "../tickets/gql-tickets";
import { FieldGroup } from "./create-ticket";

const DialogStatusTicket = ({ hideDialog, isDialogProcessing, ticket }) => {
    const [statusTicket, { error }] = useMutationUpdateStatusTicket();
    let newStatus = "in progress";
    if (ticket.status === "in progress") {
        newStatus = "resolved";
    } else if (ticket.status === "resolved") {
        newStatus = "open";
    }

    const handleChangeStatus = useCallback(async () => {
        await statusTicket({ id: ticket.id, status: newStatus });
        toast.success("Successfully changed ticket status");
        hideDialog(1, {});
    }, [hideDialog, newStatus, statusTicket, ticket.id]);

    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while changing ticket status" error={error} />
                <br />
                <>
                    <FieldGroup>
                        <p>
                            <strong>Current Status Ticket : {ticket.status.toUpperCase()}</strong>
                        </p>
                    </FieldGroup>
                </>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={false}
                        loading={isDialogProcessing}
                        intent={Intent.SUCCESS}
                        text={`Change Status to ${newStatus.toUpperCase()}`}
                        onClick={() => handleChangeStatus()}
                        rightIcon="user"
                    />
                </div>
            </div>
        </>
    );
};

DialogStatusTicket.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    ticket: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
    }).isRequired,
};

export default {
    title: "Status Ticket",
    icon: "label",
    component: DialogStatusTicket,
};
