import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { GQL_ALL_USERS_QUERY } from "../users/gql-users";

export const GQL_QUERY_USER_AGENCY = gql`
    query getUserAgency($username: String) {
        getUserAgency(username: $username) {
            logoUrl
            name
        }
    }
`;

export const GQL_CREATE_USER_AGENCY_MUTATION = gql`
    mutation CreateUserAgency($userAgency: UserAgencyCreate!) {
        createUserAgency(userAgency: $userAgency) {
            user {
                id
            }
            password
        }
    }
`;

export const useQueryUserAgency = () => {
    const [caller, { data: { getUserAgency } = {}, ...rest }] = useLazyQuery(GQL_QUERY_USER_AGENCY);
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { agency: getUserAgency, ...rest }];
};

export const useMutationCreateUserAgency = () => {
    const [actionPromise, rest] = useMutation(GQL_CREATE_USER_AGENCY_MUTATION, {
        refetchQueries: [GQL_ALL_USERS_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
