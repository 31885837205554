import { AnchorButton, Button, Callout, ControlGroup, FormGroup, Intent, Spinner } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { DialogContext } from "../bp-dialog-context";
import NavBreadcrumb from "../common/nav-breadcrumb";
import DialogAccept from "./dialog-accept";
import DialogReject from "./dialog.reject";
import { useQueryMessageReview } from "./gql-validation";
import MessageChangesView from "./message-changes-view";

const StyledRow = styled.div`
    margin: 10px 0;
`;

const MessageInfo = ({ message }) => {
    const history = useHistory();
    const { showDialog } = useContext(DialogContext);
    const handleDialog = async (dialog) => {
        const { code } = await showDialog(dialog, { message });
        if (code === 1) {
            history.replace("/validation");
        }
    };
    return (
        <div>
            <NavBreadcrumb
                items={[
                    { text: "Validations", icon: "inbox", to: "/validation" },
                    {
                        text: message.isNew ? "Review new ad" : "Review ad changes",
                        icon: "eye-open",
                    },
                ]}
            />

            <h2>{message.isNew ? "Review new ad" : "Review ad changes"}</h2>
            <div>
                <StyledRow>
                    <strong>Title:</strong>&nbsp;{message.texts?.find((val) => val.type === "primary_text")?.value}
                </StyledRow>
                <StyledRow>
                    <strong>Review ID:</strong>&nbsp;{message.id}
                </StyledRow>
                {(message.landing || {}).slug ? (
                    <StyledRow>
                        <strong>Ad destination:</strong>&nbsp;
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`${window.LANDING_SERVER_URL}/l/${message.landing.slug}`}
                        >
                            Conversion page
                        </a>
                    </StyledRow>
                ) : (
                    <StyledRow>
                        <strong>Ad destination:</strong>&nbsp;
                        {message.adLink}&nbsp;
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={message.adLink}
                            icon="document-open"
                        />
                    </StyledRow>
                )}
                <StyledRow>
                    <FormGroup label={<strong>Actions</strong>} inline>
                        <ControlGroup>
                            <Button intent={Intent.SUCCESS} onClick={() => handleDialog(DialogAccept)}>
                                Accept ad
                            </Button>
                            <Button intent={Intent.DANGER} onClick={() => handleDialog(DialogReject)}>
                                Reject ad
                            </Button>
                        </ControlGroup>
                    </FormGroup>
                </StyledRow>
            </div>

            <h2>{message.isNew ? "Attributes" : "Changes"}</h2>
            <MessageChangesView changes={message.changes} hasOldValues={!message.isNew} />
        </div>
    );
};

MessageInfo.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
        changes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        isNew: PropTypes.bool.isRequired,
        adLink: PropTypes.string,
        landing: PropTypes.shape({
            slug: PropTypes.string,
        }),
    }).isRequired,
};

const StyledMessageReviewPage = styled.div`
    padding: 10px;
`;

const MessageReviewPage = () => {
    const { id } = useParams();

    const { message, loading, notFound, error } = useQueryMessageReview(id);
    return (
        <StyledMessageReviewPage>
            {loading && (
                <Callout title="Loading message to review, please wait...">
                    <Spinner />
                </Callout>
            )}
            {error && (
                <Callout intent={Intent.DANGER} title="Error while loading review">
                    {error.message}
                </Callout>
            )}
            {notFound && (
                <Callout intent={Intent.DANGER} title="Nothing found here">
                    {id}
                </Callout>
            )}
            {message && <MessageInfo message={message} />}
        </StyledMessageReviewPage>
    );
};

export default MessageReviewPage;
