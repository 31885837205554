import { Button, Classes, FormGroup, InputGroup, Intent, TextArea } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useMutationUpdateBusiness } from "../../common/gql-business";
import ErrorCallout from "../common/error-callout";

const StyledModalContent = styled.div``;

const ModalContent = ({ hideDialog, setIsDialogProcessing, isDialogProcessing, user }) => {
    const business = useMemo(() => user.business || { name: "", shortName: "" }, [user]);
    const [name, setName] = useState(business.name);
    const [shortName, setShortName] = useState(business.shortName);
    const [saveBusiness, { loading: saving, error }] = useMutationUpdateBusiness();
    useEffect(() => {
        setName(business.name);
        setShortName(business.shortName);
    }, [business]);

    const handleSave = async () => {
        setIsDialogProcessing(true);
        try {
            await saveBusiness({ userId: user.id, changes: { name, shortName } });
            toast.success("Business updated successfully");
            hideDialog(1, {});
        } finally {
            setIsDialogProcessing(false);
        }
    };

    return (
        <StyledModalContent>
            <div className={Classes.DIALOG_BODY}>
                <h3>Change business info</h3>
                <ErrorCallout title="Error while saving business" error={error} />
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup label="Name" labelFor="business-name" helperText={`${name.length} / 100 characters`}>
                        <TextArea
                            fill
                            id="comments-input"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            growVertically={false}
                            maxLength={100}
                            rows={3}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Short name"
                        labelFor="business-shortname"
                        helperText={`${shortName.length} / 15 characters`}
                    >
                        <InputGroup value={shortName} onChange={(e) => setShortName(e.target.value)} maxLength={15} />
                    </FormGroup>
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || saving} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        loading={isDialogProcessing || saving}
                        intent={Intent.PRIMARY}
                        text="Save"
                        onClick={handleSave}
                    />
                </div>
            </div>
        </StyledModalContent>
    );
};

ModalContent.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        business: PropTypes.shape({
            name: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

ModalContent.defaultProps = {};

export default {
    title: "Edit business",
    icon: "edit",
    component: ModalContent,
};
