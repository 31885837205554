import { createGraphQLPromise } from "../common/graphql";

export const getGlobalStats = createGraphQLPromise({
    query: `
        query GetGlobalStats {
            getGlobalStats {
                needReviewCount,
                wizardEntriesCount
            }
        }
    `,
    resolveSuccess: (data) => data.getGlobalStats,
});
