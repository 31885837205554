import { ControlGroup, FormGroup, InputGroup, Intent, Tag } from "@blueprintjs/core";
import { isNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { formatCurrency } from "../../../common/i18n";
import SimpleSelect from "../../common/simple-select";

const OPTIONS = [
    { label: "Custom", value: 1 },
    { label: "Automatic", value: 0 },
];

const StyledFieldCpcMax = styled.div``;

const getInternalValue = (v) => {
    if (isNumber(v)) {
        return (v / 100.0).toString();
    }
    return "";
};

const FieldCpcMax = ({ value, onChange, disabled, currency, max }) => {
    const [internalValue, setInternalValue] = useState(getInternalValue(value));
    useEffect(() => {
        if (isNumber(value)) {
            setInternalValue((currentInternalValue) => {
                const newV = value / 100.0;
                if (newV !== parseFloat(currentInternalValue.toString())) {
                    return newV;
                }
                return currentInternalValue;
            });
        } else {
            setInternalValue("");
        }
    }, [value]);

    useEffect(() => {
        const ret = parseFloat(internalValue.toString());
        if (!isNaN(ret)) {
            let v = Math.floor(ret * 100.0);
            if (max && v > max) {
                return;
            }
            onChange(v);
        }
    }, [internalValue, onChange, max]);

    const fieldError = useMemo(() => {
        const ret = parseFloat(internalValue.toString());
        if (!isNaN(ret)) {
            let v = Math.round(ret * 100.0);
            if (max && v > max) {
                return `Maximum value is ${formatCurrency(max, currency)}`;
            }
            return null;
        }
        if (value === null) {
            return null;
        }
        return "Not a valid value";
    }, [internalValue, max, currency, value]);

    return (
        <StyledFieldCpcMax>
            <FormGroup intent={fieldError ? Intent.DANGER : Intent.NONE} helperText={fieldError}>
                <ControlGroup fill={false} vertical={false}>
                    <SimpleSelect
                        disabled={disabled}
                        options={OPTIONS}
                        onSelect={(opt) => onChange(opt === 1 ? 200 : null)}
                        selected={value !== null ? 1 : 0}
                    />
                    <InputGroup
                        disabled={disabled || value === null}
                        placeholder={null}
                        onChange={(e) => setInternalValue(e.target.value)}
                        value={internalValue}
                        rightElement={value ? <Tag minimal>{currency}</Tag> : null}
                        intent={fieldError ? Intent.DANGER : Intent.NONE}
                    />
                </ControlGroup>
            </FormGroup>
        </StyledFieldCpcMax>
    );
};

FieldCpcMax.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    currency: PropTypes.string.isRequired,
    max: PropTypes.number,
};

FieldCpcMax.defaultProps = {
    value: null,
    onChange: null,
    disabled: false,
    max: null,
};

export default FieldCpcMax;
