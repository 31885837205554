import { Button, Callout, Classes, FormGroup, InputGroup, Intent, NumericInput, Switch } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";

import { getRootUserAgencyPromise } from "../../common/gql-promises";
import { peek } from "../../common/utils";
import SimpleSelect from "../common/simple-select";
import MapboxIdField from "./mapbox-id-field";
import TargetingField from "./targeting-field";

const DEFAULT = {
    name: "",
    nameFr: "",
    nameEn: "",
    nameDe: "",
    validated: false,
    isoCode: "",
    population: 0,
};

const SUBTYPES = [
    { value: "city", label: "City" },
    { value: "region", label: "Region" },
    { value: "country", label: "Country" },
];

function computeSaveValue(original) {
    return peek(original, [
        "name",
        "validated",
        "nameFr",
        "nameEn",
        "nameDe",
        "subtype",
        "isoCode",
        "population",
        "mapboxId",
        ["parent", "parentId", (p) => (p ? p.id : null)],
    ]);
}

function computeChanges(valA, valB) {
    const peekA = computeSaveValue(valA);
    const peekB = computeSaveValue(valB);

    return Object.entries(peekA).reduce((acc, [key, vA]) => {
        const vB = peekB[key];
        if (vB !== vA) {
            acc.push(key);
        }
        return acc;
    }, []);
}

const StyledForm = styled.div`
    max-width: 640px;
    h2 {
        text-align: center;
    }
    .bp4-form-group {
        &.bp4-inline {
            align-items: center;
        }
        & > .bp4-label {
            font-size: 14px;
        }
    }
    .form-content {
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        padding: 2px;
        margin: 0;
    }
`;

const TargetingForm = ({ actionLabel, onCancel, defaultValue, onSave, canReset, autoSave, loading }) => {
    const [originalValue, setOriginalValue] = React.useState(defaultValue || DEFAULT);
    const [value, setValue] = React.useState(defaultValue || DEFAULT);
    const [agencyTeam, setAgencyTeam] = React.useState(null);

    useEffect(() => {
        const load = async () => {
            const rootUser = await getRootUserAgencyPromise();
            setAgencyTeam(!!rootUser);
        };
        load();
    }, []);

    useEffect(() => {
        setValue(defaultValue || DEFAULT);
        setOriginalValue(defaultValue || DEFAULT);
    }, [defaultValue]);

    const hasNoChanges = useMemo(
        () => Object.keys(value).reduce((acc, k) => acc && value[k] === originalValue[k], true),
        [value, originalValue]
    );

    const errors = useMemo(() => {
        const errs = [];
        if (!value) {
            return [""];
        }
        if (!value.name) {
            errs.push("Missing name");
        }
        if (!value.isoCode || value.isoCode.length !== 2) {
            errs.push("Country ISO code must be 2 character length");
        }
        if (!value.subtype) {
            errs.push("Missing location type");
        }
        if (value.subtype !== "country" && value.parent === null) {
            errs.push("Missing parent for non-country location");
        }
        if (value.population < 0) {
            errs.push("Population cannot be negative");
        }
        return errs;
    }, [value]);

    const setData = useCallback(
        (key, v) => {
            setValue((prevValue) => {
                const newValue = {
                    ...prevValue,
                    [key]: v,
                };
                const changedKeys = computeChanges(prevValue, newValue);
                if (autoSave) {
                    if (changedKeys.indexOf("mapboxId") !== -1 || changedKeys.indexOf("parentId") !== -1) {
                        onSave(computeSaveValue(newValue));
                    }
                }
                return newValue;
            });
        },
        [autoSave, onSave]
    );
    return (
        <StyledForm>
            <div className="form-content">
                <FormGroup label="Type" labelFor="targeting-input-subtype">
                    <SimpleSelect
                        fill
                        outlined
                        id="targeting-input-subtype"
                        options={SUBTYPES}
                        name="subtype"
                        selected={value.subtype}
                        onSelect={(v) => setData("subtype", v)}
                    />
                </FormGroup>

                <FormGroup label="Name" labelFor="text-input-name">
                    <InputGroup
                        id="text-input-name"
                        onChange={(e) => setData("name", e.currentTarget.value)}
                        value={value.name}
                    />
                </FormGroup>

                {!agencyTeam && (
                    <FormGroup label="Validated" labelFor="targeting-input-validated">
                        <Switch
                            large
                            id="targeting-input-validated"
                            checked={value.validated}
                            onChange={(e) => setData("validated", e.currentTarget.checked)}
                            innerLabel={value.validated ? "valid" : "invalid"}
                        />
                    </FormGroup>
                )}

                <FormGroup label="Population" labelFor="text-input-population">
                    <NumericInput
                        fill
                        id="text-input-population"
                        onValueChange={(v) => setData("population", v)}
                        value={value.population}
                    />
                </FormGroup>

                <h2>Localizations</h2>

                <FormGroup label="Name [Français]" labelFor="text-input-name_fr">
                    <InputGroup
                        id="text-input-name_fr"
                        onChange={(e) => setData("nameFr", e.currentTarget.value)}
                        value={value.nameFr}
                    />
                </FormGroup>

                <FormGroup label="Name [English]" labelFor="text-input-name_en">
                    <InputGroup
                        id="text-input-name_en"
                        onChange={(e) => setData("nameEn", e.currentTarget.value)}
                        value={value.nameEn}
                    />
                </FormGroup>

                <FormGroup label="Name [Deutsch]" labelFor="text-input-name_de">
                    <InputGroup
                        id="text-input-name_de"
                        onChange={(e) => setData("nameDe", e.currentTarget.value)}
                        value={value.nameDe}
                    />
                </FormGroup>

                <h2>Dependencies</h2>

                <FormGroup label="Country ISO Code ( 2 letters )" labelFor="text-input-iso_code" labelInfo="(required)">
                    <InputGroup
                        id="text-input-iso_code"
                        onChange={(e) => setData("isoCode", e.currentTarget.value)}
                        value={value.isoCode}
                        maxLength={2}
                        minLength={2}
                        style={{ width: "50px" }}
                    />
                </FormGroup>

                <FormGroup label="Parent">
                    <TargetingField onChange={(v) => setData("parent", v)} value={value.parent} />
                </FormGroup>

                <FormGroup label="Mapbox© ID">
                    <MapboxIdField
                        name="mapboxId"
                        onChange={(v) => setData("mapboxId", v)}
                        value={value.mapboxId}
                        searchValue={value.name}
                    />
                </FormGroup>
            </div>
            {errors && errors.length > 0 && (
                <Callout intent={Intent.DANGER} title="Correct the following errors">
                    <ul>
                        {errors.map((e) => (
                            <li key={e}>{e}</li>
                        ))}
                    </ul>
                </Callout>
            )}
            <br />
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                {onCancel && <Button type="button" text="Cancel" onClick={onCancel} loading={loading} />}
                {canReset && (
                    <Button
                        disabled={hasNoChanges}
                        type="reset"
                        label="Reset"
                        onClick={() => setValue({})}
                        loading={loading}
                    />
                )}
                <Button
                    intent={Intent.PRIMARY}
                    disabled={hasNoChanges || errors.length > 0}
                    type="submit"
                    text={actionLabel}
                    loading={loading}
                    onClick={() => onSave(computeSaveValue(value))}
                />
            </div>
        </StyledForm>
    );
};

TargetingForm.propTypes = {
    actionLabel: PropTypes.string.isRequired,
    defaultValue: PropTypes.shape({}),
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    canReset: PropTypes.bool,
    autoSave: PropTypes.bool,
    loading: PropTypes.bool,
    height: PropTypes.string,
};

TargetingForm.defaultProps = {
    defaultValue: null,
    onSave: null,
    onCancel: null,
    canReset: false,
    autoSave: false,
    height: null,
    loading: false,
};

export default TargetingForm;
