import { Button, Dialog, Intent } from "@blueprintjs/core";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import logger from "loglevel";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import { getIdentity, logoutPromise } from "./gql-actions";
import { getUserPaymentProfile } from "./gql-promises";

export const IdentityContext = React.createContext({
    identity: null,
    paymentProfile: null,
    loaded: false,
    hasPermission: (service, permission) => {},
    setIdentity: () => {},
    refreshIdentity: async () => {},
    setPaymentProfile: async () => {},
    refreshPaymentProfile: async () => {},
});

export const IdentityConsumer = IdentityContext.Consumer;

const StyledRequireLogin = styled.div``;

export const RequireLogin = ({ children, next }) => {
    const [logoutOpen, setLogoutOpen] = useState(false);

    const handleLogout = async () => {
        await logoutPromise({});
        window.location = "/";
    };

    const doCloseLogout = () => {
        setLogoutOpen(false);
    };

    return (
        <IdentityConsumer>
            {({ loaded, identity }) => {
                if (loaded) {
                    if (!identity) {
                        const nextUrl = next || window.location;
                        const to = `/login?next=${nextUrl}`;
                        return <Redirect to={to || "/"} />;
                    } else if (!identity.isStaff) {
                        return (
                            <StyledRequireLogin>
                                <h3>Your are not part of Amplify Staff</h3>
                                <p>
                                    <Button
                                        intend={Intent.PRIMARY}
                                        onClick={() => setLogoutOpen(true)}
                                        text="Logout"
                                        icon="log-out"
                                    />
                                </p>
                                <Dialog isOpen={logoutOpen}>
                                    <h3>Do you want to logout</h3>
                                    <p>
                                        <Button onClick={doCloseLogout} text="Cancel" />
                                        <Button intent={Intent.PRIMARY} onClick={handleLogout} text="Logout" />
                                    </p>
                                </Dialog>
                            </StyledRequireLogin>
                        );
                    }
                }
                return identity ? children : null;
            }}
        </IdentityConsumer>
    );
};

RequireLogin.propTypes = {
    children: PropTypes.node,
    next: PropTypes.string,
    staffOnly: PropTypes.bool,
};

RequireLogin.defaultProps = {
    children: null,
    next: null,
    staffOnly: false,
};

export class IdentityManager extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    static defaultProps = {
        children: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            identity: null,
            paymentProfile: null,
            loaded: false,
        };

        // TODO do we still use Usersnap. And if yes, is it the place where to initialize it?
        /**
         * To understand this code, check the /server/core/templates/core/usersnap.html
         */
        if (window.Usersnap) {
            // window.Usersnap is create in the template
            this.registerUsersnap(window.Usersnap);
        } else {
            const oldOnLoad = window.onUsersnapLoad;
            window.onUsersnapLoad = (api) => {
                this.registerUsersnap(api);
                oldOnLoad(api);
            };
        }
    }

    componentDidMount() {
        const load = async () => {
            try {
                await this.loadIdentity();
                await this.loadPaymentProfile();
            } catch (e) {
                logger.error("identity manager failed to initialize", e);
            } finally {
                this.setState({
                    loaded: true,
                });
            }
        };
        load();
    }

    setIdentity = (identity) => {
        this.setState({
            identity,
        });
    };

    setPermissions = (permissions) => {
        this.setState({
            permissions,
        });
    };

    hasPermission = (service, permission) => {
        const { loaded, permissions } = this.state;
        return loaded && (permissions?.[service]?.perm || []).includes(permission);
    };

    setPaymentProfile = (paymentProfile) => {
        this.setState({
            paymentProfile,
        });
    };

    registerUsersnap = (api) => {
        api.on("open", (event) => {
            const { identity } = this.state;
            if (identity) {
                event.api.setValue("email", identity.email);
            }
        });
    };

    loadIdentity = async () => {
        const identity = await getIdentity();
        this.setIdentity(identity);
        const jwtToken = Cookies.get("jwttoken");
        try {
            this.setPermissions(jwt_decode(jwtToken)?.auth || {});
        } catch (InvalidTokenError) {
            // We are already logged. This exception should raise only during tests.
            this.setPermissions({});
        }
    };

    loadPaymentProfile = async () => {
        const paymentProfile = await getUserPaymentProfile();
        this.setPaymentProfile(paymentProfile);
    };

    render() {
        const { children } = this.props;
        const { identity, paymentProfile, loaded } = this.state;
        return (
            <IdentityContext.Provider
                value={{
                    identity,
                    paymentProfile,
                    loaded,
                    hasPermission: this.hasPermission,
                    setIdentity: this.setIdentity,
                    setPaymentProfile: this.setPaymentProfile,
                    refreshIdentity: this.loadIdentity,
                    refreshPaymentProfile: this.loadPaymentProfile,
                }}
            >
                {children}
            </IdentityContext.Provider>
        );
    }
}

export function useIdentity() {
    const { loaded, identity, setIdentity, hasPermission } = useContext(IdentityContext);
    return [loaded, identity, setIdentity, hasPermission];
}
