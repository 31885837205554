const Theme = {
    headerHeight: "80px",
    headerHeightMobile: "40px",
    backgroundColor: "#f2f2f2",
    placeholderColor: "#dddddd",
    overgroundColor: "white",
    mainColor: "#057CE5",
    altColor: "#057CE5",
    greenColor: "#30E9A2",
    altHighColor: "#75a8cd",
    altLightColor: "#a8c8e6",
    altLighterColor: "#d8ecff",
    altBorder: "#04406c",
    grayColor: "#aaa",
    staffColor: "#d9ac09",
    staffBackgroundColor: "#fff9f0",
    lightGrayColor: "#ddd",
    contentWidth: "950px",
    responsiveWidth: "768px",
    mobileWidth: "450px",
    responsiveWidthFirst: "1024px",
    editorWidth: "950px",
    ssoWidth: "480px",
    editorBackgroundColor: "#f2f2f2",
    mediaWidth: 256,
    mediaHeight: 256 / (16 / 9),
    lightErrorColor: "#f8c9c9",
    darkErrorColor: "#ef6666",
    lightWarningColor: "#f8f2c9",
    darkWarningColor: "#efd866",
    lightInfoColor: "#d2f8c9",
    darkInfoColor: "#66ef68",
};

export default Theme;
