import { ControlGroup, InputGroup } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";

import SimpleSelect from "../common/simple-select";

const StyledFormStep = styled.div`
    text-align: center;
    h2 {
        margin: 1em 0;
    }
    .other-field {
        textarea,
        input {
            margin: 10px 0;
            border-radius: 5px;
            border: 1px solid #eee;
            width: 100%;
            box-sizing: border-box;
            resize: vertical;
            padding: 9px 10px;
        }
    }
    margin: 20px 0;
`;

const StepPosition = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    color: ${({ theme }) => theme.mainColor};
    display: inline-block;
    line-height: 30px;
    text-align: center;
    margin-top: 20px;
`;

const SurveyStep = ({ step, position, value, onChangeValue, inputValue, onChangeInputValue, disabled }) => {
    const selectedOption = useMemo(() => step.options.find((opt) => opt.value === value), [step.options, value]);
    return (
        <StyledFormStep>
            <StepPosition>{position}</StepPosition>
            <h2>{step.title}</h2>
            <ControlGroup fill={true} vertical={true}>
                <SimpleSelect options={step.options} selected={value} onSelect={onChangeValue} disabled={disabled} />
                {selectedOption?.inputType === "string" && (
                    <>
                        <br />
                        <InputGroup
                            placeholder={selectedOption.inputPlaceHolder}
                            value={inputValue || ""}
                            onChange={(e) => onChangeInputValue(e.currentTarget.value)}
                            disabled={disabled}
                        />
                    </>
                )}
            </ControlGroup>
        </StyledFormStep>
    );
};

export const surveyStepPropTypes = PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        })
    ),
});

SurveyStep.propTypes = {
    step: surveyStepPropTypes.isRequired,
    position: PropTypes.number.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChangeValue: PropTypes.func.isRequired,
    inputValue: PropTypes.string,
    onChangeInputValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

SurveyStep.defaultProps = {
    value: null,
    inputValue: null,
    disabled: false,
};

export default SurveyStep;
