import { Button, FormGroup, Intent, TextArea } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import styled from "styled-components";

import { ALL_CURRENCIES } from "../../../currency";
import ErrorCallout from "../../common/error-callout";
import SimpleSelect from "../../common/simple-select";
import StickyContainer from "../../common/sticky-container";
import { ALL_FORMATS } from "../../formats";
import { useQueryTopics } from "../../topics";
import FieldImage from "../edit/field-image";
import FieldUrl from "../edit/field-url";
import FieldVideo from "../edit/field-video";
import { getUrlValidationError } from "../edit/url-validator";
import MessageField from "../message-field";

export const DEFAULT_DATA = {
    currency: window.DEFAULT_CURRENCY,
};

const StyledCreateForm = styled.div`
    .bp4-form-group {
        label {
            font-weight: bold;

            small {
                font-weight: lighter;
                opacity: 0.75;
            }
        }
    }
    .bp4-form-content {
        textarea {
            resize: vertical;
        }
    }
    .sticky-container {
        height: 100vh;
    }
`;

const StyledCreateFormFooter = styled.div`
    background-color: #fafafa;
    padding: 15px;
    .footer-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .footer-alert {
        margin-bottom: 10px;
    }
`;

const StyledInnerForm = styled.div`
    padding: 10px;
`;

const CreateForm = ({ onNewMessage, loading, disablePrompt, header }) => {
    const history = useHistory();
    const [values, setValues] = useState(DEFAULT_DATA);
    const { topics } = useQueryTopics();

    const onSubmit = useCallback(() => {
        onNewMessage(values);
    }, [values, onNewMessage]);

    const onCancel = () => {
        setValues(DEFAULT_DATA);
        history.push("/messages/");
    };

    const hasChanges = values !== DEFAULT_DATA;

    const onChangeValue = useCallback((k, v) => {
        setValues((oldValues) => ({
            ...oldValues,
            [k]: v,
        }));
    }, []);
    const onChangeUrl = useCallback((k, v) => {
        setValues((oldValues) => ({
            ...oldValues,
            [k]: v.replace(/ /g, ""),
        }));
    }, []);

    const errors = useMemo(() => {
        const errors = {};
        if (!values?.topic) {
            errors["topic"] = "Ad topic must be set";
        }
        if (!values?.format) {
            errors["format"] = "Ad format must be set";
        }
        if ((values?.title?.length || 0) < 10) {
            errors["title"] = "Message title should be at less 10 characters";
        }
        const adLinkError = getUrlValidationError(values?.adLink);
        if (adLinkError) {
            errors["adLink"] = adLinkError;
        }
        return errors;
    }, [values]);

    const hasError = Object.keys(errors).length > 0;

    return (
        <StyledCreateForm>
            <StickyContainer
                className="sticky-container"
                header={header}
                footer={
                    <StyledCreateFormFooter>
                        <ErrorCallout
                            className="footer-alert"
                            title="Cannot save, there is errors in your inputs"
                            message={
                                hasError && (
                                    <ul>
                                        {Object.entries(errors).map(([k, v]) => (
                                            <li key={k}>{v}</li>
                                        ))}
                                    </ul>
                                )
                            }
                        />
                        <div className="footer-buttons">
                            <Button
                                intent={Intent.NONE}
                                onClick={onCancel}
                                disabled={loading}
                                text="Cancel"
                                icon="cross"
                            />
                            &nbsp;
                            <Button
                                intent={Intent.SUCCESS}
                                onClick={onSubmit}
                                disabled={loading || hasError}
                                text="Create"
                                rightIcon="arrow-right"
                            />
                        </div>
                    </StyledCreateFormFooter>
                }
            >
                <StyledInnerForm>
                    <Prompt
                        when={hasChanges && !disablePrompt}
                        message={() => "You have unsaved changes, do you really want to leave this page?"}
                    />
                    <FormGroup label="Topic">
                        <SimpleSelect
                            fill
                            outlined
                            options={topics}
                            onSelect={(v) => onChangeValue("topic", v)}
                            selected={values.topic}
                        />
                    </FormGroup>
                    <FormGroup label="Currency">
                        <SimpleSelect
                            fill
                            outlined
                            options={ALL_CURRENCIES}
                            onSelect={(v) => onChangeValue("currency", v)}
                            selected={values.currency}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Title"
                        helperText="Type the message in the correct language as the system will be getting the language from this title"
                        intent={Intent.WARNING}
                    >
                        <TextArea
                            value={values.title}
                            maxLength={255}
                            onChange={(e) => onChangeValue("title", e.currentTarget.value)}
                            fill
                        />
                    </FormGroup>
                    <FormGroup
                        label="Ad format"
                        helperText="This cannot be changed after the message creation"
                        intent={Intent.WARNING}
                    >
                        <SimpleSelect
                            fill
                            outlined
                            options={ALL_FORMATS}
                            onSelect={(v) => onChangeValue("format", v)}
                            selected={values.format}
                        />
                    </FormGroup>
                    {values.format === "image" && (
                        <MessageField
                            label="Image"
                            name="image"
                            values={values}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldImage value={value} onChange={setValue} disabled={disabled} />
                            )}
                        />
                    )}
                    {values.format === "video" && (
                        <MessageField
                            label="Video"
                            name="movie"
                            values={values}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldVideo value={value} onChange={setValue} disabled={disabled} />
                            )}
                        />
                    )}
                    <MessageField
                        label="Destination URL"
                        name="adLink"
                        values={values}
                        onChangeValue={onChangeUrl}
                        errors={errors}
                        renderComponent={({ value, setValue, disabled }) => (
                            <FieldUrl value={value} onChange={setValue} disabled={disabled} />
                        )}
                    />
                </StyledInnerForm>
            </StickyContainer>
        </StyledCreateForm>
    );
};

CreateForm.propTypes = {
    onNewMessage: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disablePrompt: PropTypes.bool,
    header: PropTypes.node,
};

CreateForm.defaultProps = {
    loading: false,
    disablePrompt: false,
    header: null,
};

export default CreateForm;
