import { USER_FRAGMENT } from "../common/gql-actions";
import { createGraphQLPromise } from "../common/graphql";

export const loginPromise = createGraphQLPromise({
    query: `
        mutation Login($email: String!, $password: String!) {
            login(username: $email, password: $password) {
                identity {
                ...user
                }
            }
        }
        ${USER_FRAGMENT}
    `,
    resolveSuccess: (data) => {
        return data.login;
    },
});
