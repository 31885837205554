import { Alignment, Button, Classes, Icon, Intent, Navbar, Tab, Tabs } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import Box from "../common/box";
import { getPlatformIcon } from "../platforms";
import InterestItem from "./interest-item";
import InterestsChooser from "./interests-chooser";
import { formatItemForPlatform } from "./intersets-format";

const extractInterestsByPlatform = (items) =>
    items.reduce((acc, item, idx) => {
        if (item.type === "interest") {
            (acc[item.platform] = acc[item.platform] || []).push({
                idx,
                data: item.data,
            });
        }
        return acc;
    }, {});

const StyledTab = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const StyledInterestsPlatform = styled.div`
    .existing {
        background-color: white;
        border: 1px solid lightgray;
    }
`;

const InterestsPlatform = ({ platform, items, onAddItem, onRemove }) => {
    const [adding, setAdding] = useState(false);
    const existingItems = useMemo(() => items.map((i) => i.data), [items]);
    return (
        <StyledInterestsPlatform>
            <div className="existing">
                {items.map((item) => (
                    <InterestItem
                        {...formatItemForPlatform(item.data, platform)}
                        item={item.data}
                        key={item.idx}
                        onActionIcon="trash"
                        onAction={onRemove ? () => onRemove(item.idx) : null}
                        actionIntent={Intent.DANGER}
                    />
                ))}
            </div>
            <br />
            {onAddItem && (
                <div>
                    {adding ? (
                        <InterestsChooser
                            onCancel={() => setAdding(false)}
                            platform={platform}
                            onSelect={(item) => onAddItem(item)}
                            existingItems={existingItems}
                        />
                    ) : (
                        <div>
                            <Button
                                fill
                                intent={Intent.PRIMARY}
                                text={`Add ${platform} interests`}
                                onClick={() => setAdding(true)}
                            />
                        </div>
                    )}
                </div>
            )}
        </StyledInterestsPlatform>
    );
};

InterestsPlatform.propTypes = {
    platform: PropTypes.string.isRequired,
    onAddItem: PropTypes.func,
    onRemove: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({})),
};

InterestsPlatform.defaultProps = {
    onAddItem: null,
    onRemove: null,
    items: [],
};

function InterestsInput({ disabled, selectedPlatforms, onChange, value }) {
    const interestsByPlatform = extractInterestsByPlatform(value || []);
    const [selectedTab, setSelectedTab] = useState(
        selectedPlatforms && selectedPlatforms.length > 0 && selectedPlatforms[0]
    );

    useEffect(() => {
        setSelectedTab((old) => {
            if (selectedPlatforms && selectedPlatforms.indexOf(old) === -1) {
                return selectedPlatforms.length > 0 && selectedPlatforms[0];
            }
            return old;
        });
    }, [selectedPlatforms]);

    const handleAddItem = useCallback(
        (platform, item) => {
            onChange([
                ...value,
                {
                    platform,
                    data: item,
                    type: "interest",
                },
            ]);
        },
        [onChange, value]
    );

    const handleRemoveItem = useCallback(
        (idx) => {
            const newValue = [...value];
            newValue.splice(idx, 1);
            onChange(newValue);
        },
        [onChange, value]
    );

    return (
        <Box fill="horizontal">
            <Navbar>
                <Navbar.Group>
                    <Navbar.Heading>For platform : </Navbar.Heading>
                </Navbar.Group>
                <Navbar.Group align={Alignment.CENTER}>
                    <Tabs large onChange={setSelectedTab} selectedTabId={selectedTab}>
                        {selectedPlatforms.map((platform) => (
                            <Tab
                                title2={<div className={Classes.TAB_PANEL}>{platform}</div>}
                                title={
                                    <StyledTab>
                                        <Icon icon={getPlatformIcon(platform)} />
                                        <span weight="bold">{platform}</span>
                                        <small>{(interestsByPlatform[platform] || []).length}</small>
                                    </StyledTab>
                                }
                                key={`tab}-${platform}`}
                                id={platform}
                            ></Tab>
                        ))}
                    </Tabs>
                </Navbar.Group>
            </Navbar>
            <div>
                {selectedTab && (
                    <InterestsPlatform
                        platform={selectedTab}
                        items={interestsByPlatform[selectedTab] || []}
                        onAddItem={!disabled ? (item) => handleAddItem(selectedTab, item) : null}
                        onRemove={!disabled ? (idx) => handleRemoveItem(idx) : null}
                        actionIntent={Intent.DANGER}
                    />
                )}
            </div>
        </Box>
    );
}

InterestsInput.propTypes = {
    disabled: PropTypes.bool,
    selectedPlatforms: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
};

InterestsInput.defaultProps = {
    disabled: false,
    selectedPlatforms: [],
    onChange: () => {},
    value: [],
};
export default InterestsInput;
