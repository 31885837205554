import { gql, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

const GQL_QUERY_TARGETING_PROFILES = gql`
    query QueryTargetingProfiles($q: String!, $offset: Int) {
        searchTargetingProfiles(q: $q, offset: $offset) {
            count
            nextOffset
            result {
                id
                title
                platforms
            }
        }
    }
`;

export const useQueryTargetingProfiles = () => {
    const [caller, { data: { searchTargetingProfiles } = {}, ...rest }] = useLazyQuery(GQL_QUERY_TARGETING_PROFILES);
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { targetingProfiles: searchTargetingProfiles, ...rest }];
};
