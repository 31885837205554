import { Button, Icon, Intent, MenuItem } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledMultiSelectTag = styled.div`
    display: flex;
    align-items: center;
`;

const SimpleMultiSelect = ({ id, options, value, onChange, emptyText, disabled, noResultText, large }) => {
    const findOption = (v) => options.find((opt) => opt.value === v);
    const selectedOptions = value.map(findOption);

    const selectOptionValue = (val) => onChange([...value, val]);
    const deselectOptionValue = (val) => onChange(value.filter((k) => k !== val));

    const handleItemSelect = (opt) => {
        if (value.includes(opt.value)) {
            deselectOptionValue(opt.value);
        } else {
            selectOptionValue(opt.value);
        }
    };

    const handleClear = () => {
        onChange([]);
    };

    const clearButton =
        value.length > 0 && !disabled ? <Button icon="cross" minimal={true} onClick={handleClear} /> : undefined;

    const renderOption = (option, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                icon={value.includes(option.value) ? "tick" : "blank"}
                key={option.value}
                onClick={handleClick}
                text={option.label}
                shouldDismissPopover={false}
                label={option.icon ? <Icon icon={option.icon} /> : null}
            />
        );
    };

    const areOptionEqual = (a, b) => {
        return a.value === b.value;
    };

    const handleTagRemove = (_tag, index) => {
        const newValue = value.filter((_opt, i) => i !== index);
        onChange(newValue);
    };

    return (
        <MultiSelect
            id={id}
            fill
            resetOnSelect
            items={options}
            selectedItems={selectedOptions}
            onItemSelect={handleItemSelect}
            itemRenderer={renderOption}
            placeholder={emptyText}
            itemPredicate={(query, opt, _index, exactMatch) => {
                const normalizedLabel = opt.label.toLowerCase();
                const normalizedQuery = query.toLowerCase();
                if (exactMatch) {
                    return normalizedLabel === normalizedQuery;
                } else {
                    return normalizedLabel.indexOf(normalizedQuery) >= 0;
                }
            }}
            itemsEqual={areOptionEqual}
            noResults={<MenuItem disabled={true} text={noResultText || "No results."} />}
            popoverProps={{ minimal: true, canEscapeKeyClose: true, disabled }}
            tagRenderer={(opt) => (
                <StyledMultiSelectTag>
                    {opt.icon ? (
                        <>
                            <Icon icon={opt.icon} size={14} />
                            &nbsp;
                        </>
                    ) : null}
                    {opt?.label}
                </StyledMultiSelectTag>
            )}
            tagInputProps={{
                disabled,
                onRemove: handleTagRemove,
                rightElement: clearButton,
                large: large,
                tagProps: {
                    intent: disabled ? Intent.NONE : Intent.PRIMARY,
                    minimal: true,
                    large: large,
                },
            }}
        />
    );
};

SimpleMultiSelect.propTypes = {
    id: PropTypes.string,
    emptyText: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.node,
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ).isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    allowEmpty: PropTypes.bool,
    disabled: PropTypes.bool,
    noResultText: PropTypes.string,
    large: PropTypes.bool,
};

SimpleMultiSelect.defaultProps = {
    id: null,
    emptyText: null,
    value: [],
    allowEmpty: false,
    disabled: false,
    noResultText: null,
    large: false,
};

export default SimpleMultiSelect;
