import { Button, Intent, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Cell, Column, RenderMode, Table2, TableLoadingOption } from "@blueprintjs/table";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const handleEditInStaffMessage = (history, message) => {
    history.push(`/messages/${message.id}/edit/`);
};

const targets = {
    "": "privates individuals & companies",
    b2b: "companies",
    b2c: "privates individuals",
};

const modes = {
    amplify: "Amplify creates the ad",
    diy: "Customer creates the ad (image/video + title)",
};

const renderCellMenu = (message, history) => (
    <Cell style={{ padding: 5 }}>
        <Popover2
            content={
                <Menu>
                    <MenuDivider title="Actions" />
                    <MenuItem
                        icon="document-open"
                        onClick={() => handleEditInStaffMessage(history, message)}
                        text="Edit in staff"
                    />
                </Menu>
            }
        >
            <Button large minimal icon="menu" />
        </Popover2>
    </Cell>
);

const StyledTitle = styled.div`
    font-size: 11px;
    line-height: 12px !important;
    display: inline-flex;
`;

const renderCellUser = (message) => (
    <Cell wrapText>
        <StyledTitle>{message.owner.username}</StyledTitle>
    </Cell>
);

const renderCellTarget = (message) => (
    <Cell wrapText>
        <StyledTitle>{targets[JSON.parse(message.wizardMetas)?.audienceTarget]}</StyledTitle>
    </Cell>
);

const renderCellMode = (message) => (
    <Cell wrapText>
        <StyledTitle>{modes[JSON.parse(message.wizardMetas)?.messageEditor]}</StyledTitle>
    </Cell>
);

const renderCellTitle = (message) => (
    <Cell wrapText>
        <StyledTitle>{message.texts?.find((val) => val.type === "primary_text")?.value}</StyledTitle>
    </Cell>
);

const renderCellDescription = (message) => (
    <Cell wrapText>
        <StyledTitle>{JSON.parse(message.wizardMetas)?.descriptionText}</StyledTitle>
    </Cell>
);

const renderCellPhone = (message) => (
    <Cell wrapText>
        <StyledTitle>{JSON.parse(message.wizardMetas)?.phone}</StyledTitle>
    </Cell>
);

const renderCellEdit = (message, history) => (
    <Cell>
        <Button
            small
            intent={Intent.PRIMARY}
            outlined
            onClick={() => handleEditInStaffMessage(history, message)}
            text="Edit"
            rightIcon="arrow-right"
        />
    </Cell>
);

const StyledMessageDraftsTable = styled.div`
    width: 100%;
    position: relative;
    .centered-table-cell,
    .bp4-table-cell {
        display: flex;
        align-items: center;
    }
`;

const WizardCompletionTable = ({ messages, loading }) => {
    const history = useHistory();

    const withMessage =
        (func, ...args) =>
        (index) =>
            func(messages[index], ...args);
    const [columnWidths, setColumnWidths] = useState([50, 150, 300, 150, 150, 300, 150, 300]);

    return (
        <StyledMessageDraftsTable>
            <Table2
                numRows={messages.length}
                enableRowHeader={false}
                numFrozenColumns={1}
                fill
                defaultRowHeight={55}
                columnWidths={columnWidths}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
                loadingOptions={loading ? [TableLoadingOption.COLUMN_HEADERS, TableLoadingOption.CELLS] : []}
                renderMode={RenderMode.NONE} // https://github.com/palantir/blueprint/issues/4094#issuecomment-689190282
            >
                <Column name="" cellRenderer={withMessage(renderCellMenu, history)} />
                <Column name="User" cellRenderer={withMessage(renderCellUser)} />
                <Column name="Title" cellRenderer={withMessage(renderCellTitle)} />
                <Column name="Target" cellRenderer={withMessage(renderCellTarget)} />
                <Column name="Mode" cellRenderer={withMessage(renderCellMode)} />
                <Column name="Wizard description" cellRenderer={withMessage(renderCellDescription)} />
                <Column name="Wizard phone" cellRenderer={withMessage(renderCellPhone)} />
                <Column name="Action" cellRenderer={withMessage(renderCellEdit, history)} />
            </Table2>
        </StyledMessageDraftsTable>
    );
};

WizardCompletionTable.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            texts: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string,
                    type: PropTypes.string,
                })
            ),
        })
    ).isRequired,
    loading: PropTypes.bool,
};

WizardCompletionTable.defaultProps = {
    loading: false,
};

export default WizardCompletionTable;
