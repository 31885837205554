import { Button, Callout, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationCancelActiveServiceSubscription } from "../users/gql-users";

/**
 * Cancel the active subscription of a user if this subscription has a gateway type of "amplify"
 */
const CancelServiceSubscription = ({ hideDialog, isDialogProcessing, user }) => {
    const [cancelServiceSubscription, { loading, error }] = useMutationCancelActiveServiceSubscription();
    const handleCancelActiveSubscription = useCallback(async () => {
        await cancelServiceSubscription({
            userId: user.id,
        });
        toast.success("Subscription canceled successfully");
        hideDialog(1, {});
    }, [user, hideDialog, cancelServiceSubscription]);

    return user.serviceSubscription != null ? (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while canceling subscription" error={error} />
                <p>
                    <strong>{user.serviceSubscription.product.name}</strong>
                </p>
                <p>Do you really want to cancel the subscription of this user?</p>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="No" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={loading}
                        loading={loading || isDialogProcessing}
                        intent={Intent.WARNING}
                        text="YES! Cancel subscription!"
                        onClick={handleCancelActiveSubscription}
                        rightIcon="arrow-right"
                    />
                </div>
            </div>
        </>
    ) : (
        <>
            <div className={Classes.DIALOG_BODY}>
                <Callout
                    intent={Intent.WARNING}
                    icon="warning-sign"
                    title="This user does not have an active subscription"
                />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="Ok" onClick={() => hideDialog(0)} />
                </div>
            </div>
        </>
    );
};

CancelServiceSubscription.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        serviceSubscription: PropTypes.shape({
            product: PropTypes.shape({
                name: PropTypes.string,
            }),
        }),
    }).isRequired,
};

export default {
    title: "Cancel active subscription",
    icon: "dollar",
    component: CancelServiceSubscription,
};
