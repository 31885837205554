import { Button, Intent } from "@blueprintjs/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { getRootUserAgencyPromise } from "../../common/gql-promises";
import { useIdentity } from "../../common/identity-context";
import { DialogContext } from "../bp-dialog-context";
import { ORDER_BY_SPECS } from "../common/bp-table-helper";
import ErrorCallout from "../common/error-callout";
import { buildQueryFilters, useUrlFilters } from "../common/filter-utils";
import Pagination from "../common/pagination";
import CreateUserAgency from "../dialogs/create-user-agency";
import { useQueryAllUsers } from "./gql-users";
import UserFilterPane from "./user-filter-pane";
import UsersTable from "./users-table";

const LIMIT = 20;

const FILTER_SPECS = {
    q: {
        type: "search",
    },
    agency: {
        type: "search",
    },
    product: {
        type: "in",
        buildKey: "subscriptionProduct",
    },
    lang: {
        type: "in",
    },
    isActive: {
        type: "bool",
        parse: (v) => (v === "t" ? true : v === "f" ? false : null),
        format: (v) => (v === true ? "t" : v === false ? "f" : null),
    },
    verified: {
        type: "bool",
        parse: (v) => (v === "t" ? true : v === "f" ? false : null),
        format: (v) => (v === true ? "t" : v === false ? "f" : null),
    },
    offset: {
        parse: (v) => parseInt(v || "0", 10),
        format: (v) => v,
    },
    order: ORDER_BY_SPECS,
};

const HeaderInfo = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
    .fill {
        flex-grow: 1;
    }
`;

const UserListPage = () => {
    const [filters, setFilters] = useUrlFilters(FILTER_SPECS);
    const offset = filters.offset || 0;
    const [rootUserAgency, setRootUserAgency] = useState(null);
    const [loadUserRoot, setLoadUserRoot] = useState(false);
    const [, identity, ,] = useIdentity();
    const { showDialog } = useContext(DialogContext);
    const [loadUsers, { loading, users, count, error }] = useQueryAllUsers();

    const nbPages = Math.ceil(count / LIMIT);
    const currentPage = Math.floor(offset / LIMIT) + 1;
    const indices = [offset + 1, Math.min(offset + LIMIT, count)];
    const onChangeSort = (v) => {
        setFilters((old) => ({
            ...old,
            order: v,
        }));
    };

    useEffect(() => {
        loadUsers({
            limit: LIMIT,
            ...buildQueryFilters(filters, FILTER_SPECS),
        });
    }, [loadUsers, offset, filters]);

    useEffect(() => {
        const getUserAgency = async () => {
            const rootUser = await getRootUserAgencyPromise();
            setRootUserAgency(rootUser);
            setLoadUserRoot(true);
        };
        getUserAgency();
    }, []);

    const handleChangePage = async ({ startIndex, endIndex }) => {
        setFilters({ ...filters, offset: startIndex });
    };

    const handleChangeFilters = (newFilters) => {
        setFilters({ ...newFilters, offset: 0 });
    };

    const createUserAgency = useCallback(async () => {
        await showDialog(CreateUserAgency);
    }, [showDialog]);
    return (
        <div>
            <UserFilterPane filters={filters} setFilters={handleChangeFilters} isAgency={!!rootUserAgency} />
            {identity?.username === rootUserAgency?.username && (
                <div style={{ padding: "10px" }}>
                    <Button intent={Intent.SUCCESS} text="Create User" onClick={createUserAgency} icon="add" />
                </div>
            )}
            <HeaderInfo>
                {(() => {
                    if (loading) {
                        return "Loading, please wait...";
                    }
                    if (indices[1] === 0) {
                        return "No user to display";
                    }
                    return `See users from ${indices[0]} to ${indices[1]} on a total of ${count}`;
                })()}
            </HeaderInfo>
            <ErrorCallout title="Error while loading users" error={error} />

            {users && loadUserRoot ? (
                <UsersTable
                    users={users}
                    loading={loading}
                    ordering={filters.order}
                    onChangeSort={onChangeSort}
                    rootUserAgency={rootUserAgency}
                />
            ) : null}

            <HeaderInfo>
                <div className="fill">{loading ? "Loading..." : `${count} messages`}</div>
                <div>
                    {nbPages > 1 ? (
                        <Pagination
                            step={LIMIT}
                            numberItems={count}
                            page={currentPage}
                            numberEdgePages={2}
                            onChange={handleChangePage}
                        />
                    ) : (
                        <div />
                    )}
                </div>
            </HeaderInfo>
        </div>
    );
};

export default UserListPage;
