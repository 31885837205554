import { Button, Intent, Menu, MenuDivider, MenuItem, Tag } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { Cell, Column, RenderMode, Table2, TableLoadingOption } from "@blueprintjs/table";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { buildAppUrl } from "../../common/utils";
import UserBadge from "../common/user-badge";

const handleReviewMessage = (history, message) => {
    history.push(`/validation/${message.id}`);
};

const handleShowMessage = (message) => {
    window.open(buildAppUrl(`/edit/ads/${message.messageId}`), message.messageId);
};

const renderCellMenu = (message, history) => (
    <Cell style={{ padding: 5 }}>
        <Popover2
            content={
                <Menu>
                    <MenuDivider title="Actions" />
                    <MenuItem icon="document-open" onClick={() => handleShowMessage(message)} text="Show ad" />
                    <MenuDivider />
                    <MenuItem icon="eye-open" text="Review" onClick={() => handleReviewMessage(history, message)} />
                </Menu>
            }
        >
            <Button large minimal icon="menu" />
        </Popover2>
    </Cell>
);

const StyledImage = styled.img`
    object-fit: scale-down;
    height: 55px;
    width: 55px;
`;

const renderCellImage = (message) => (
    <Cell>
        <StyledImage src={message?.imageUrl} alt={message.texts?.find((val) => val.type === "primary_text")?.value} />
    </Cell>
);

const StyledTitle = styled.div`
    font-size: 11px;
    line-height: 12px !important;
    display: inline-flex;
`;

const renderCellTitle = (message) => (
    <Cell wrapText>
        <StyledTitle>{message.title}</StyledTitle>
    </Cell>
);

const renderCellStatus = (message) => (
    <Cell>
        {message.isNew ? (
            <Tag intent={Intent.WARNING}>New</Tag>
        ) : (
            <Tag minimal intent={Intent.NONE}>
                Changes
            </Tag>
        )}
    </Cell>
);

const renderCellChanges = (message) => <Cell>{message.changes.length}</Cell>;

const renderCellDate = (message) => (
    <Cell>
        <Tooltip2 content={moment(message.createdAt).format("LLLL")}>
            <span>{moment(message.createdAt).fromNow()}</span>
        </Tooltip2>
    </Cell>
);

const renderCellReview = (message, history) => (
    <Cell>
        <Button
            small
            intent={Intent.PRIMARY}
            outlined
            onClick={() => handleReviewMessage(history, message)}
            text="Review"
            rightIcon="arrow-right"
        />
    </Cell>
);

const renderCellOwner = (message) => (
    <Cell>
        <UserBadge user={message.owner} size={30} showEmail />
    </Cell>
);

const StyledMessageReviewTable = styled.div`
    width: 100%;
    position: relative;
    .centered-table-cell,
    .bp4-table-cell {
        display: flex;
        align-items: center;
    }
`;

const MessageReviewTable = ({ messages, loading }) => {
    const history = useHistory();

    const withMessage =
        (func, ...args) =>
        (index) =>
            func(messages[index], ...args);
    const [columnWidths, setColumnWidths] = useState([50, 100, 300, 100, 80, 200, 150, 105]);

    return (
        <StyledMessageReviewTable>
            <Table2
                numRows={messages.length}
                enableRowHeader={false}
                numFrozenColumns={1}
                fill
                defaultRowHeight={55}
                columnWidths={columnWidths}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
                loadingOptions={loading ? [TableLoadingOption.COLUMN_HEADERS, TableLoadingOption.CELLS] : []}
                renderMode={RenderMode.NONE} // https://github.com/palantir/blueprint/issues/4094#issuecomment-689190282
            >
                <Column name="" cellRenderer={withMessage(renderCellMenu, history)} />
                <Column name="" cellRenderer={withMessage(renderCellImage)} />
                <Column name="Title" cellRenderer={withMessage(renderCellTitle)} />
                <Column name="New" cellRenderer={withMessage(renderCellStatus)} />
                <Column name="Changes" cellRenderer={withMessage(renderCellChanges)} />
                <Column name="owner" cellRenderer={withMessage(renderCellOwner)} />
                <Column name="Requested" cellRenderer={withMessage(renderCellDate)} />
                <Column name="" cellRenderer={withMessage(renderCellReview, history)} />
            </Table2>
        </StyledMessageReviewTable>
    );
};

MessageReviewTable.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    loading: PropTypes.bool,
};

MessageReviewTable.defaultProps = {
    loading: false,
};

export default MessageReviewTable;
