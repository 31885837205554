import { Button, Card, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import MapboxView from "../../common/mapbox-view";
import Box from "../common/box";
import Search from "../search";
import { useQueryMapboxGeometry } from "./qgl-mapbox";

const TargetingGeojsonCell = ({ geometry, onSelect }) => (
    <Card>
        <h5>{geometry?.display_name}</h5>
        {geometry && geometry.geojson && <MapboxView geometries={[geometry.geojson]} />}
        <br />
        <Button
            text="select this one"
            intent={Intent.PRIMARY}
            plain={false}
            size="small"
            icon="paperclip"
            onClick={onSelect}
            alignText="left"
            rightIcon="arrow-right"
        />
    </Card>
);

TargetingGeojsonCell.propTypes = {
    geometry: PropTypes.shape({
        display_name: PropTypes.string,
        geojson: PropTypes.any,
    }),
    onSelect: PropTypes.func,
};

TargetingGeojsonCell.defaultProps = {
    geometry: null,
    onSelect: null,
};

const TargetingGeojson = ({ onSelect, searchValue }) => {
    const [search, setSearch] = useState(searchValue || "");

    const [loadGeometries, { loading, geometries: results, updateQuery }] = useQueryMapboxGeometry();

    const doSearch = useCallback(async () => {
        if (search) {
            await loadGeometries({
                q: search,
            });
        }
    }, [loadGeometries, search]);

    const clearResults = useCallback(() => {
        updateQuery(() => ({ searchMapGeometries: null }));
    }, [updateQuery]);

    useEffect(() => {
        setSearch(searchValue || "");
    }, [searchValue]);

    return (
        <Box>
            <h2>Set or replace geometry</h2>
            <Box pad="small">
                <Search setValue={setSearch} value={search} onSearch={doSearch} loading={loading} icon="geosearch" />
            </Box>

            {loading && <div>Loading...</div>}
            {results && results.length > 0 && (
                <div>
                    {results.map((g, idx) => (
                        <TargetingGeojsonCell
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={idx}
                            geometry={g}
                            onSelect={() => {
                                onSelect(g.geojson);
                                clearResults();
                            }}
                        />
                    ))}
                </div>
            )}
            {results && results.length === 0 && <Box pad="medium">Nothing found</Box>}
        </Box>
    );
};

TargetingGeojson.propTypes = {
    onSelect: PropTypes.func,
    searchValue: PropTypes.string,
};

TargetingGeojson.defaultProps = {
    onSelect: null,
    searchValue: null,
};

export default TargetingGeojson;
