import { Button, Callout, Classes, FormGroup, Intent, NumericInput, Tag, TextArea } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { formatCurrency } from "../../common/i18n";
import { ALL_CURRENCIES } from "../../currency";
import ErrorCallout from "../common/error-callout";
import SimpleSelect from "../common/simple-select";
import { useMutationRegisterFieldSales } from "../gql-wallet";

const RegisterFieldSalesDialog = ({ hideDialog, setIsDialogProcessing, isDialogProcessing, user }) => {
    const [registerFieldSale, { loading, error }] = useMutationRegisterFieldSales();
    const [currency, setCurrency] = useState("CHF");
    const [amount, setAmount] = useState(0);
    const [adAmount, setAdAmount] = useState(0);
    const [comment, setComment] = useState("");
    const amplifyAmount = amount - adAmount;

    const isValid = amount > 0 && adAmount > 0 && adAmount <= amount;

    const handleRegisterFieldSales = async () => {
        await registerFieldSale({
            userId: user.id,
            amount: amount * 100,
            adAmount: adAmount * 100,
            currency,
            comment,
        });
        toast.success("Field sales registration success!");
        hideDialog(1, {});
    };

    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while registering field sales" error={error} />
                <Callout intent={Intent.PRIMARY} icon={null}>
                    <p>
                        This will register a yearly premium subscription to <strong>{user.email}</strong>
                    </p>
                </Callout>
                <br />
                <>
                    <FormGroup label="Currency" labelFor="currency-input" fill>
                        <SimpleSelect
                            id="currency-input"
                            selected={currency}
                            options={ALL_CURRENCIES}
                            onSelect={(v) => setCurrency(v)}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Received amount"
                        labelFor="amount-input"
                        labelInfo={currency}
                        intent={amount > 0 ? Intent.NONE : Intent.DANGER}
                        helperText={amount > 0 ? "Total amount received" : "Amount must be greater than 0"}
                    >
                        <NumericInput
                            id="amount-input"
                            onValueChange={(v) => setAmount(v)}
                            min={0}
                            stepSize={1}
                            minorStepSize={null} // Disable specific value for alt+increment
                            rightElement={<Tag minimal>{currency}</Tag>}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Ad amount"
                        labelFor="ad-amount-input"
                        labelInfo={currency}
                        helperText="Amount credited to the wallet ( ad buying budget )"
                    >
                        <NumericInput
                            id="ad-amount-input"
                            onValueChange={(v) => setAdAmount(v)}
                            min={0}
                            stepSize={1}
                            minorStepSize={null} // Disable specific value for alt+increment
                            max={amount > 0 ? amount : 0}
                            rightElement={<Tag minimal>{currency}</Tag>}
                        />
                    </FormGroup>
                    <FormGroup label="Amplify" helperText="Amount earned by amplify">
                        <strong>{formatCurrency(amplifyAmount * 100.0, currency)}</strong>
                    </FormGroup>
                    <FormGroup label="Comments" labelFor="comments-input" helperText="Optional comments">
                        <TextArea
                            fill
                            id="comments-input"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            growVertically={false}
                        />
                    </FormGroup>
                </>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={!isValid || loading}
                        loading={isDialogProcessing || loading}
                        intent={Intent.SUCCESS}
                        text="Register"
                        onClick={handleRegisterFieldSales}
                        rightIcon="arrow-right"
                    />
                </div>
            </div>
        </>
    );
};

RegisterFieldSalesDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
};

export default {
    title: "Register field sales",
    icon: "trending-up",
    component: RegisterFieldSalesDialog,
};
