import { Cell, Column, SelectionModes, Table2 } from "@blueprintjs/table";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const SimpleTable = ({ items, onSelect, selected, columns }) => {
    const [columnWidths, setColumnWidths] = useState(columns.map((c) => c.width));
    const selectedIndex = items.findIndex((v) => v === selected);

    const cellRenderer = (col, item) => {
        return <Cell wrapText={col.wrapText}>{col.cell(item)}</Cell>;
    };

    useEffect(() => {
        setColumnWidths(columns.map((c) => c.width));
    }, [columns]);

    return (
        <Table2
            enableRowHeader={false}
            defaultRowHeight={40}
            fill
            numRows={items.length}
            columnWidths={columnWidths}
            onColumnWidthChanged={(idx, val) => {
                const newWidths = [...columnWidths];
                newWidths[idx] = val;
                setColumnWidths(newWidths);
            }}
            selectionModes={SelectionModes.ROWS_AND_CELLS}
            selectedRegionTransform={(e) => {
                if (!e.rows) {
                    return e;
                }
                return {
                    rows: [e.rows[0], e.rows[0]],
                };
            }}
            onSelection={(e) => {
                if (e.length > 0 && e[0].rows) {
                    onSelect(items[e[0].rows[0]]);
                } else {
                    onSelect(null);
                }
            }}
            selectedRegions={
                selectedIndex !== null
                    ? [
                          {
                              rows: [selectedIndex, selectedIndex],
                          },
                      ]
                    : []
            }
        >
            {columns.map((col) => (
                <Column name={col.title} cellRenderer={(idx) => cellRenderer(col, items[idx])} />
            ))}
        </Table2>
    );
};

SimpleTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            cell: PropTypes.func.isRequired,
            icon: PropTypes.string,
            wrapText: PropTypes.bool,
            width: PropTypes.number,
        })
    ).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selected: PropTypes.shape({}),
    onSelect: PropTypes.func.isRequired,
};

SimpleTable.defaultProps = {
    selected: null,
};
export default SimpleTable;
