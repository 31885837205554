import { useQuery } from "@apollo/client";
import { Callout, Intent } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import NavBreadcrumb from "../common/nav-breadcrumb";
import { GQL_QUERY_STAFF_FOR_COMPLETION_MESSAGES } from "./gql-wizard-completion";
import WizardCompletionTable from "./wizard-completion-table";

const StyledListPage = styled.div`
    padding: 10px;
`;

const WizardCompletionListPage = () => {
    const { data, loading, error } = useQuery(GQL_QUERY_STAFF_FOR_COMPLETION_MESSAGES, {
        fetchPolicy: "cache-and-network",
    });

    return (
        <StyledListPage>
            <NavBreadcrumb items={[{ text: "New wizard entries", icon: "inbox" }]} />
            {error && (
                <Callout title="Error while loading messages" intent={Intent.DANGER}>
                    {error.message}
                </Callout>
            )}
            <WizardCompletionTable messages={data?.getStaffForCompletionMessagesFromWizard || []} loading={loading} />
        </StyledListPage>
    );
};

export default WizardCompletionListPage;
