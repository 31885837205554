import mapboxgl, { Map } from "mapbox-gl";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

mapboxgl.accessToken = window.MAPBOX_ACCESS_TOKEN;

const MAPBOX_STYLE = "mapbox://styles/devamplify/ckfqfxosb05ra19t8o1jj52p4";
const MAPBOX_GEOMETRIES = {
    backgroundColor: "#003288",
    borderColor: "#333c55",
    opacity: 0.3,
};

export const WORLD_BOUNDS = [
    [180.0, 90.0],
    [-180.0, -90.0],
];
export const EUROPA_BOUNDS = [
    [-35.887527, 28.553465],
    [47.828293, 58.641402],
];
export const SWISS_BOUNDS = [
    [14, 48],
    [3, 45],
];
const POINT_RADIUS = 0.01; // in deg arc

/* Utility to draw a circle as polygons */

function toRadians(angleInDegrees) {
    return (angleInDegrees * Math.PI) / 180;
}

function toDegrees(angleInRadians) {
    return (angleInRadians * 180) / Math.PI;
}

function offset(c1, radius, bearing) {
    const lat1 = toRadians(c1[1]);
    const lon1 = toRadians(c1[0]);
    // const dByR = distance / 6378137; // distance divided by 6378137 (radius of the earth) wgs84
    const dByR = radius / 100;
    const lat = Math.asin(Math.sin(lat1) * Math.cos(dByR) + Math.cos(lat1) * Math.sin(dByR) * Math.cos(bearing));
    const lon =
        lon1 +
        Math.atan2(
            Math.sin(bearing) * Math.sin(dByR) * Math.cos(lat1),
            Math.cos(dByR) - Math.sin(lat1) * Math.sin(lat)
        );
    return [toDegrees(lon), toDegrees(lat)];
}

function circleToPolygon(center, radius, nbOfEdges = 32) {
    const coordinates = [];
    for (let i = 0; i < nbOfEdges; i += 1) {
        coordinates.push(offset(center, radius, (2 * Math.PI * -i) / nbOfEdges));
    }
    coordinates.push(coordinates[0]);

    return {
        type: "Polygon",
        coordinates: [coordinates],
    };
}

/**
 * found bounds to transition map
 * [sw, ne] order
 * @param data
 * @returns [[lng, lat], [lng, lat]]
 */
const boundsForPolygons = (data) => {
    if (data) {
        return data.reduce((bounds, datum) => {
            if (!datum) {
                return bounds;
            }
            if (datum.coordinates) {
                let allCoords = [];
                if (datum.type === "MultiPolygon") {
                    allCoords = datum.coordinates.reduce((acc, cds) => acc.concat(cds[0]), []);
                } else if (datum.type === "Polygon") {
                    [allCoords] = datum.coordinates;
                } else if (datum.type === "Point") {
                    const [lng, lat] = datum.coordinates;
                    allCoords = [
                        [lng - POINT_RADIUS, lat - POINT_RADIUS],
                        [lng - POINT_RADIUS, lat + POINT_RADIUS],
                        [lng + POINT_RADIUS, lat - POINT_RADIUS],
                        [lng + POINT_RADIUS, lat + POINT_RADIUS],
                    ];
                }

                return allCoords.reduce(([[swLng, swLat], [neLng, neLat]], [lng, lat]) => {
                    const newSwLng = Math.min(swLng, lng);
                    const newSwLat = Math.min(swLat, lat);
                    const newNeLng = Math.max(neLng, lng);
                    const newNeLat = Math.max(neLat, lat);
                    return [
                        [newSwLng, newSwLat],
                        [newNeLng, newNeLat],
                    ];
                }, bounds);
            }
            return bounds;
        }, SWISS_BOUNDS);
    }
    return null;
};

const ensurePolygon = (data) => {
    if (!data) {
        return null;
    }
    if ((data.type && data.type === "Polygon") || (data.type && data.type === "MultiPolygon")) {
        return data;
    }
    if (data.type && data.type === "Point") {
        return circleToPolygon(data.coordinates, POINT_RADIUS);
    }
    return {
        type: "Polygon",
        coordinates: [[]],
    };
};

const ensureCoordinates = (data) => {
    if (!data || !data.coordinates) {
        return null;
    }
    return data;
};

export default styled(({ className, geometries }) => {
    const [mapValue, setMapValue] = useState(null);

    const containerRef = useRef(null);
    useEffect(
        () => {
            if (containerRef.current) {
                let bounds = null;
                if (geometries) {
                    const sanitizedGeometries = geometries.map(ensureCoordinates);
                    bounds = boundsForPolygons(sanitizedGeometries);
                }
                const map = new Map({
                    container: containerRef.current,
                    style: MAPBOX_STYLE,
                    bounds,
                    fitBoundsOptions: { padding: 20, duration: 3000, essential: true },
                    scrollZoom: false.zoom,
                });

                // empty source
                map.on("load", () => {
                    setMapValue(map);
                });

                return () => {
                    map.remove();
                };
            }
            return null;
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [containerRef]
    );

    useLayoutEffect(() => {
        if (mapValue) {
            mapValue.resize();
        }
    });

    useEffect(() => {
        if (mapValue) {
            // setup polygons
            const polygonSource = mapValue.getSource("polygonSource");
            const sanitizedPolygons = geometries.map(ensurePolygon);
            const polygonData = {
                type: "FeatureCollection",
                features: sanitizedPolygons.map((g) => ({
                    type: "Feature",
                    properties: {},
                    geometry: g,
                })),
            };
            if (polygonSource) {
                polygonSource.setData(polygonData);
            } else {
                mapValue.addSource("polygonSource", { type: "geojson", data: polygonData });
                mapValue.addLayer({
                    id: "geometries-fill",
                    type: "fill",
                    source: "polygonSource",
                    layout: {},
                    paint: {
                        "fill-color": MAPBOX_GEOMETRIES.backgroundColor,
                        "fill-opacity": MAPBOX_GEOMETRIES.opacity,
                    },
                });
                mapValue.addLayer({
                    id: "geometries-line",
                    type: "line",
                    source: "polygonSource",
                    layout: {},
                    paint: {
                        "line-color": MAPBOX_GEOMETRIES.borderColor,
                    },
                });
            }

            const boundsGeometries = geometries.map(ensureCoordinates);
            const bounds = boundsForPolygons(boundsGeometries);
            if (bounds) {
                mapValue.fitBounds(bounds, {
                    padding: 40,
                    duration: 100.0,
                    essential: true,
                    animate: true,
                });
            }
        }
    }, [geometries, mapValue]);

    return <div className={className} ref={containerRef} />;
})`
    position: relative;
    width: auto;
    height: 320px;
    .mapboxgl-control-container {
        display: none;
    }
`;
