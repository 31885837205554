import { Button, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import FieldOwner from "../messges/edit/field-owner";
import MessageField from "../messges/message-field";
import { useMutationAssignTicket } from "../tickets/gql-tickets";
import { FieldGroup } from "./create-ticket";

const DialogAssignedTicket = ({ hideDialog, isDialogProcessing, ticket }) => {
    const [assignTicket, { error }] = useMutationAssignTicket();
    const [data, setData] = useState({});

    const onChangeValue = useCallback((k, v) => {
        setData((oldValues) => ({
            ...oldValues,
            [k]: v,
        }));
    }, []);
    const handleAssignTicket = useCallback(async () => {
        await assignTicket({
            id: ticket.id,
            memberId: data.assignedMember.id,
        });
        toast.success("Successfully assigned ticket");
        hideDialog(1, {});
    }, [assignTicket, data?.assignedMember?.id, hideDialog, ticket?.id]);

    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while assigned ticket" error={error} />
                <br />
                <>
                    <FieldGroup>
                        <MessageField
                            label="Assigned To"
                            name="assignedMember"
                            values={data}
                            onChangeValue={onChangeValue}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldOwner value={value} onChange={setValue} disabled={disabled} onlyStaff />
                            )}
                        />
                    </FieldGroup>
                </>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={false}
                        loading={isDialogProcessing}
                        intent={Intent.SUCCESS}
                        text="Assign"
                        onClick={() => handleAssignTicket()}
                        rightIcon="user"
                    />
                </div>
            </div>
        </>
    );
};

DialogAssignedTicket.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    ticket: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

export default {
    title: "Assigned Ticket to ",
    icon: "label",
    component: DialogAssignedTicket,
};
