import { Alignment, Breadcrumb, Breadcrumbs, Navbar } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

const NavBreadcrumb = ({ items, currentBreadcrumbRenderer }) => {
    const history = useHistory();
    const renderer = (props) => <Breadcrumb {...props} />;

    const realItems = useMemo(() => {
        return items
            .map((i) => {
                if (!i) {
                    return null;
                }
                let { to, ...item } = i;
                if (to) {
                    if (typeof to === "string") {
                        to = {
                            ...history.location,
                            pathname: to,
                        };
                    } else {
                        to = { ...history.location, ...to };
                    }
                    item.onClick = () => history.push(to);
                }
                return item;
            })
            .filter((n) => n);
    }, [items, history]);
    return (
        <Navbar style={{ backgroundColor: "#fafafa" }}>
            <Navbar.Group align={Alignment.LEFT}>
                <Breadcrumbs
                    breadcrumbRenderer={renderer}
                    items={realItems}
                    currentBreadcrumbRenderer={currentBreadcrumbRenderer}
                />
            </Navbar.Group>
        </Navbar>
    );
};

NavBreadcrumb.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                text: PropTypes.string,
                icon: PropTypes.node,
                intent: PropTypes.string,
                href: PropTypes.string,
                to: PropTypes.string,
            }),
            PropTypes.bool,
        ])
    ).isRequired,
    currentBreadcrumbRenderer: PropTypes.func,
};

NavBreadcrumb.defaultProps = {
    currentBreadcrumbRenderer: null,
};

export default NavBreadcrumb;
