import { Button, Classes, FormGroup, Intent, NumericInput, Tag, TextArea } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationAddMoneyToWalletFromAgency } from "../gql-wallet";

const AddMoneyToWalletFromAgencyDialog = ({ hideDialog, isDialogProcessing, user, rootUserAgency }) => {
    const agencyWallet = rootUserAgency?.wallets[0];
    const [addMoneyToWallet, { loading, error }] = useMutationAddMoneyToWalletFromAgency();
    const currency = agencyWallet?.currency || "CHF";
    const [amount, setAmount] = useState(0);
    const [comment, setComment] = useState("");

    const isValid = amount > 0 && amount <= agencyWallet.balance / 100;
    const handleAddMoneyToWallet = useCallback(async () => {
        await addMoneyToWallet({
            userId: user.id,
            agencyUserId: rootUserAgency.id,
            currency,
            amount: amount * 100,
            comment,
        });
        toast.success("Successfully added money to wallet");
        hideDialog(1, {});
    }, [addMoneyToWallet, user.id, rootUserAgency.id, currency, amount, comment, hideDialog]);

    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while adding money" error={error} />
                <br />
                <>
                    <FormGroup label="Balance Agency" labelFor="currency-input" fill>
                        <Tag large>
                            {agencyWallet?.balance / 100} {currency}
                        </Tag>
                    </FormGroup>
                    <FormGroup
                        label="Received amount"
                        labelFor="amount-input"
                        labelInfo={currency}
                        intent={isValid ? Intent.NONE : Intent.DANGER}
                        helperText={isValid ? "Total amount received" : "Amount must be greater than 0"}
                    >
                        <NumericInput
                            id="amount-input"
                            onValueChange={(v) => setAmount(v)}
                            min={0}
                            stepSize={1}
                            minorStepSize={null} // Disable specific value for alt+increment
                            rightElement={<Tag minimal>{currency}</Tag>}
                        />
                    </FormGroup>
                    <FormGroup label="Comments" labelFor="comments-input" helperText="Optional comments">
                        <TextArea
                            fill
                            id="comments-input"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            growVertically={false}
                        />
                    </FormGroup>
                </>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={!isValid || loading}
                        loading={isDialogProcessing || loading}
                        intent={Intent.SUCCESS}
                        text="Add money"
                        onClick={handleAddMoneyToWallet}
                        rightIcon="bank-account"
                    />
                </div>
            </div>
        </>
    );
};

AddMoneyToWalletFromAgencyDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    rootUserAgency: PropTypes.shape({
        id: PropTypes.string,
        wallets: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

export default {
    title: "Add money to wallet from Agency wallet",
    icon: "office",
    component: AddMoneyToWalletFromAgencyDialog,
};
