import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import theme from "./theme";
import { peek } from "./utils";

// eslint-disable-next-line import/prefer-default-export
export function useAsyncHook(asyncPromise, defaultResult, transformFun = (x) => x) {
    const [result, setResult] = React.useState(defaultResult);
    const [error, setError] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);

    React.useEffect(
        () => {
            async function executePromise() {
                try {
                    const response = await asyncPromise();
                    // console.log(json);
                    setResult(transformFun(response));
                    setCompleted(true);
                } catch (err) {
                    setError(err);
                    setCompleted(true);
                }
            }
            if (asyncPromise) {
                executePromise();
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [asyncPromise]
    );

    return [result, completed, error];
}

/**
 *
 * const queries = {
 *      xs: '(max-width: 320px)',
 *      md: '(max-width: 720px)',
 *      lg: '(max-width: 1024px)',
 * }
 *
 * const matchPoints = useBreakpoint(queries);
 *
 *
 * @param queries
 * @returns list of matched queries
 */

export const RESPONSIVE_QUERIES = {
    mobile: `(max-width: ${theme.responsiveWidth})`,
};

export const useBreakpoint = (queries) => {
    const [queryMatch, setQueryMatch] = useState({});

    useEffect(() => {
        const mediaQueryLists = {};
        const keys = Object.keys(queries);

        // To check whether event listener is attached or not
        let isAttached = false;

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce((acc, media) => {
                acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
                return acc;
            }, {});
            // Setting state to the updated matches
            // when document either starts or stops matching a query
            setQueryMatch(updatedMatches);
        };

        if (window && window.matchMedia) {
            const matches = {};
            keys.forEach((media) => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media] = window.matchMedia(queries[media]);
                    matches[media] = mediaQueryLists[media].matches;
                } else {
                    matches[media] = false;
                }
            });
            // Setting state to initial matching queries
            setQueryMatch(matches);
            isAttached = true;
            keys.forEach((media) => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media].addListener(handleQueryListener);
                }
            });
        }

        return () => {
            // If event listener is attached then remove it when deps change
            if (isAttached) {
                keys.forEach((media) => {
                    if (typeof queries[media] === "string") {
                        mediaQueryLists[media].removeListener(handleQueryListener);
                    }
                });
            }
        };
    }, [queries]);

    return queryMatch;
};

export const useIsMobile = () => !!useBreakpoint(RESPONSIVE_QUERIES).mobile;

export const usePreventWindowUnload = (preventDefault) => {
    useEffect(() => {
        if (!preventDefault) return;
        const handleBeforeUnload = (event) => {
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = "";
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        // eslint-disable-next-line consistent-return
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [preventDefault]);
};

function getSize(el) {
    if (!el) {
        return {
            width: 0,
            height: 0,
        };
    }

    return {
        width: el.offsetWidth,
        height: el.offsetHeight,
    };
}

export const useComponentSize = (ref) => {
    const [componentSize, setComponentSize] = useState(getSize(ref ? ref.current : {}));
    const handleResize = useCallback(() => {
        if (ref.current) {
            setComponentSize(getSize(ref.current));
        }
    }, [ref]);
    useLayoutEffect(
        () => {
            if (!ref.current) {
                return null;
            }
            const currentRef = ref.current;

            handleResize();

            if (typeof window.ResizeObserver === "function") {
                let resizeObserver = new window.ResizeObserver(() => {
                    handleResize();
                });
                resizeObserver.observe(ref.current);

                return () => {
                    resizeObserver.disconnect(currentRef);
                    resizeObserver = null;
                };
            }
            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [ref.current]
    );
    return componentSize;
};

const INITIAL_STATE = { status: 0, checking: true };

const checkUrlStatus = (url) => {
    // console.log(`Checking URL - ${url}`);
    try {
        const http = new XMLHttpRequest();
        http.open("HEAD", url, false);
        http.send();
        return http.status === 200;
    } catch (ex) {
        // nothing to do here
    }
    return false;
};

const reducer = (state, action) => {
    let result = null;
    switch (action.type) {
        case "timeout":
            // return error if still checking
            return { status: state.checking ? -1 : state.status, checking: false };
        case "check":
            if (state.checking) {
                result = checkUrlStatus(action.url);
                if (result) {
                    return { status: 1, checking: false };
                }
            }
            break;
        default:
            return state;
    }
    return state;
};

export const useUrlCheck = (url, { timeout = 10000, retry = 1000 }) => {
    const initState = url ? INITIAL_STATE : { status: 1, checking: false };
    const [state, dispatch] = useReducer(reducer, initState);
    useEffect(() => {
        const intervalHandler = setInterval(() => {
            dispatch({ type: "check", url });
        }, retry);
        dispatch({ type: "check", url });

        const timeoutHandler = setTimeout(() => {
            dispatch({ type: "timeout" });
            clearTimeout(timeoutHandler);
            clearInterval(intervalHandler);
        }, timeout);
        return () => {
            clearTimeout(timeoutHandler);
            clearInterval(intervalHandler);
        };
    }, [timeout, retry, url]);
    return state.status;
};

function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tuple
        result[key] = value;
    }
    return result;
}

export const parseQuery = (search) => paramsToObject(new URLSearchParams(search).entries());

export const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => parseQuery(search), [search]);
};

export const useUrlSearch = (keys) => {
    const search = useQuery();
    const history = useHistory();
    const setSearch = useCallback(
        (newParams) => {
            const searchParams = new URLSearchParams(search);
            for (const k of keys) {
                if (newParams[k] === null) {
                    searchParams.delete(k);
                } else {
                    searchParams.set(k, newParams[k]);
                }
            }
            history.push({
                pathname: history.location.pathname,
                search: searchParams.toString(),
            });
        },
        [keys, search, history]
    );
    return [peek(search, keys), setSearch];
};

export const useScrollbarWidth = () => {
    // from https://github.com/shawnmcknight/react-scrollbar-size
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
    const element = useRef(null);

    // initialize resize event handler and state when mounted
    useEffect(() => {
        const getElement = () => {
            if (element.current == null) {
                // element was not created yet -- initialize
                element.current = document.createElement("div");
                element.current.style.width = "99px";
                element.current.style.height = "99px";
                element.current.style.overflow = "scroll";
                element.current.style.position = "absolute";
                element.current.style.top = "-9999px";
                element.current.setAttribute("aria-hidden", "true");
                element.current.setAttribute("role", "presentation");
            }
            return element.current;
        };

        const updateState = () => {
            const { offsetHeight, clientHeight, offsetWidth, clientWidth } = getElement();
            const scrollbarHeight = offsetHeight - clientHeight;
            const scrollbarWidth = offsetWidth - clientWidth;

            setDimensions((currentDimensions) => {
                const { height, width } = currentDimensions;
                return height !== scrollbarHeight || width !== scrollbarWidth
                    ? { height: scrollbarHeight, width: scrollbarWidth }
                    : currentDimensions;
            });
        };

        const handleResize = debounce(updateState, 100);

        // initialize
        window.addEventListener("resize", handleResize);
        document.body.appendChild(getElement());
        updateState();

        const elementToRemove = getElement();
        // cleanup
        return () => {
            handleResize.cancel();
            window.removeEventListener("resize", handleResize);
            document.body.removeChild(elementToRemove);
        };
    }, []);

    return dimensions;
};
