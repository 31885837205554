import { Button, Callout, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationCancelPublishing } from "../messges/gql-messages";

/**
 * Cancel publishing is the action to stop a message that is only in review and was NEVER synced with platforms
 */
const CancelPublishingDialog = ({ hideDialog, isDialogProcessing, message }) => {
    const [cancelPublishing, { loading, error }] = useMutationCancelPublishing();
    const handleCancelPublishing = useCallback(async () => {
        await cancelPublishing({
            id: message.id,
        });
        toast.success("Publishing canceled successfully");
        hideDialog(1, {});
    }, [message, hideDialog, cancelPublishing]);

    return message.state === "review" || message.state === "completion" ? (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while canceling publishing" error={error} />
                <p>
                    <strong>{message.texts?.find((val) => val.type === "primary_text")?.value}</strong>
                </p>
                <p>Do you really want to cancel the publishing of this message?</p>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="No" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={loading}
                        loading={loading || isDialogProcessing}
                        intent={Intent.WARNING}
                        text="YES! Cancel publishing!"
                        onClick={handleCancelPublishing}
                        rightIcon="arrow-right"
                    />
                </div>
            </div>
        </>
    ) : (
        <>
            <div className={Classes.DIALOG_BODY}>
                <Callout
                    intent={Intent.WARNING}
                    icon="warning-sign"
                    title="You can only cancel message that are in 'review'"
                />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="Ok" onClick={() => hideDialog(0)} />
                </div>
            </div>
        </>
    );
};

CancelPublishingDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
    }).isRequired,
};

export default {
    title: "Cancel publishing",
    icon: "undo",
    component: CancelPublishingDialog,
};
