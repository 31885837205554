import { AnchorButton, Button, Colors, Divider, Intent, Tag, Text } from "@blueprintjs/core";
import { formatPercentage } from "@blueprintjs/core/lib/esnext/components/slider/sliderUtils";
import { Popover2 } from "@blueprintjs/popover2";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { formatCurrency, formatDateString, formatLargeNumber } from "../../common/i18n";
import { useIdentity } from "../../common/identity-context";
import PropertiesGrid from "../layout/properties-grid";
import { getPlatformIcon } from "../platforms";

const StyledPlatformInfoBadge = styled.div`
    display: flex;
    align-items: center;
    & > .platform-name {
        margin-left: 0.5em;
    }
`;

const StyledPlatformInfoBadgeTooltip = styled.div`
    padding: 10px;
    h2 {
        text-align: center;
    }
    code {
        font-size: 8px;
    }
`;

const INTENT_STATE_MAP = {
    valid: Intent.SUCCESS,
    running: Intent.SUCCESS,
    pending_validation: Intent.PRIMARY,
    learning_fail: Intent.WARNING,
    invalid: Intent.DANGER,
    learning: Intent.PRIMARY,
};

const OUTLINED_STATE_MAP = {
    init: true,
    pending_validation: true,
};

const getIntentForPlatformState = (state) => {
    return INTENT_STATE_MAP[state];
};

const getIntentForPlatform = (platform) => {
    if (platform.syncError) {
        return Intent.DANGER;
    }
    return getIntentForPlatformState(platform.state);
};

const getOutlinedForPlatformState = (state) => {
    return OUTLINED_STATE_MAP[state];
};

const StyledSyncError = styled(Text)`
    max-width: 400px;
    font-size: 0.7em;
    line-height: 20px;
    white-space: pre-wrap;
    color: ${Colors.RED1};
`;

const StyledBusiness = styled(Text)`
    font-size: 0.7em;
    line-height: 20px;
`;

export const PlatformProperties = ({ platform, currency, isAdmin, messageBusinessUuid }) => {
    return (
        <PropertiesGrid
            left="1fr"
            values={[
                {
                    label: "State",
                    value: (
                        <Tag intent={getIntentForPlatformState(platform.state)} minimal>
                            {platform.state}
                        </Tag>
                    ),
                },
                { label: "Budget", value: formatCurrency(platform.budget, currency) },
                { label: "Spent", value: formatCurrency(platform.spent, currency) },
                {
                    label: "% Spent",
                    value: platform.budget ? formatPercentage(platform.spent / platform.budget) : "",
                },
                {
                    label: "Last spent at",
                    value: platform.lastSpentAt ? formatDateString(platform.lastSpentAt, "short") : "never",
                },
                { value: <Divider /> },
                { label: "Unique click", value: formatLargeNumber(platform.uniqueClickCount) },
                { label: "Reach", value: formatLargeNumber(platform.reach) },
                {
                    label: "Perf. Index",
                    value: platform.perfIndex ? platform.perfIndex.toFixed(2) : "",
                },
                {
                    label: "CTR",
                    value: platform.reach ? `${((platform.uniqueClickCount / platform.reach) * 100).toFixed(2)}%` : "",
                },
                {
                    label: "CPC",
                    value: platform.uniqueClickCount
                        ? formatCurrency(platform.spent / platform.uniqueClickCount, currency)
                        : "",
                },

                { value: <Divider /> },
                {
                    label: "Sync error",
                    value: <StyledSyncError ellipsize>{platform.syncError}</StyledSyncError>,
                },
                {
                    label: "Full state",
                    value: <code>{platform.fullState}</code>,
                },
                {
                    label: "Invalid reason",
                    value: <StyledSyncError ellipsize>{platform.invalidReason}</StyledSyncError>,
                },
                {
                    label: "Business",
                    value: <StyledBusiness>{messageBusinessUuid}</StyledBusiness>,
                },
                {
                    label: "Urls",
                    value: (
                        <>
                            {platform.adManagerUrl && (
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    minimal
                                    small
                                    text="Ads Manager"
                                    rightIcon="share"
                                    onClick={() => {
                                        window.open(platform.adManagerUrl, `adsManager_${platform.name}`);
                                    }}
                                />
                            )}
                            {isAdmin && platform.adAdminUrl && (
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    minimal
                                    small
                                    text="Admin"
                                    rightIcon="share"
                                    onClick={() => {
                                        window.open(platform.adAdminUrl, `admin_${platform.name}`);
                                    }}
                                />
                            )}
                        </>
                    ),
                },
            ]}
        />
    );
};

const PlatformPropsTypes = PropTypes.shape({
    name: PropTypes.string.isRequired,
    state: PropTypes.string,
    budget: PropTypes.number,
    spent: PropTypes.number,
    lastSpentAt: PropTypes.string,
    reach: PropTypes.number,
    perfIndex: PropTypes.number,
    uniqueClickCount: PropTypes.number,
    syncError: PropTypes.string,
    fullState: PropTypes.string,
    invalidReason: PropTypes.string,
    adManagerUrl: PropTypes.string,
    adAdminUrl: PropTypes.string,
});

PlatformProperties.propTypes = {
    platform: PlatformPropsTypes.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    messageBusinessUuid: PropTypes.string.isRequired,
};

const PlatformInfoBadge = ({ platform, showName, currency, messageBusinessUuid }) => {
    const [, , , hasPermission] = useIdentity();
    if (!platform) {
        return null;
    }
    const disabled = !platform.state;

    const popoverContent = (
        <StyledPlatformInfoBadgeTooltip>
            <h2>{platform.name}</h2>
            <PlatformProperties
                platform={platform}
                currency={currency}
                messageBusinessUuid={messageBusinessUuid}
                isAdmin={hasPermission("admin", "use_admin")}
            />
        </StyledPlatformInfoBadgeTooltip>
    );

    return (
        <Popover2 content={!disabled ? popoverContent : null}>
            <StyledPlatformInfoBadge tabIndex={0}>
                <Button
                    disabled={disabled}
                    small
                    outlined={getOutlinedForPlatformState(platform.state)}
                    intent={getIntentForPlatform(platform)}
                    icon={getPlatformIcon(platform.name)}
                />
                {showName && <span className="platform-name">{platform.name}</span>}
            </StyledPlatformInfoBadge>
        </Popover2>
    );
};

PlatformInfoBadge.propTypes = {
    platform: PlatformPropsTypes.isRequired,
    showName: PropTypes.bool,
    currency: PropTypes.string.isRequired,
    messageBusinessUuid: PropTypes.string.isRequired,
};

PlatformInfoBadge.defaultProps = {
    size: "50px",
    showName: false,
};
export default PlatformInfoBadge;
