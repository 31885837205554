import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback } from "react";

const COUPON_FRAGMENT = `
    fragment coupon on Coupon {
        id
        amount
        validUntil
        insertedAt
        usedAt
        usedBy {
            id
            title
            editUrl
        }
        code
        prefix
    }
`;

export const GQL_GET_COUPONS_QUERY = gql`
    query searchCoupons($offset: Int = 0, $limit: Int, $code: String = "") {
        searchCoupons(code: $code, offset: $offset, limit: $limit) {
            results {
                ...coupon
            }
            count
            nextOffset
        }
    }

    ${COUPON_FRAGMENT}
`;

export const GQL_CREATE_COUPON = gql`
    mutation createCoupon($amount: Int!, $prefix: String!, $validUntil: DateTime!, $couponType: CouponType) {
        createCoupon(amount: $amount, prefix: $prefix, validUntil: $validUntil, couponType: $couponType) {
            coupon {
                ...coupon
            }
        }
    }

    ${COUPON_FRAGMENT}
`;

export const useQueryCoupons = () => {
    const [caller, { data: { searchCoupons } = {}, ...rest }] = useLazyQuery(GQL_GET_COUPONS_QUERY, {
        fetchPolicy: "cache-and-network",
    });
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { coupons: searchCoupons?.results, count: searchCoupons?.count, ...rest }];
};

export const useMutationUpdateGeoTargeting = () => {
    const [caller, { data: { createCoupon: createdCoupon } = {}, ...rest }] = useMutation(GQL_CREATE_COUPON);
    const mutateFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [mutateFunction, { createdCoupon, ...rest }];
};
