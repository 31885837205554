import { Classes, Colors, Icon } from "@blueprintjs/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import styled from "styled-components";

const StyledFileButton = styled.label`
    position: relative;
    font-weight: normal !important;
    display: inline-flex;
    input {
        width: 0px !important;
        height: 0px !important;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .progress-uploading {
        position: absolute;
        left: 2px;
        bottom: 2px;
        right: 2px;
        width: unset;
        background: rgba(255, 255, 255, 0.3);
        height: 4px;
        margin: 0 !important;
        .progress-meter {
            width: ${({ $percent = 0 }) => Math.round($percent * 100.0)}%;
        }
    }
    .file-button.uploading > * {
        transform: translateY(-2px);
    }
`;

const FileButton = ({ inputId, onFileUpload, disabled, text, icon, rightIcon, title, progress }) => {
    let buttonClasses = ["file-button", Classes.BUTTON, Classes.INTENT_PRIMARY];
    if (disabled) {
        buttonClasses.push(Classes.DISABLED);
    }
    const uploading = progress !== null && progress !== undefined;
    if (uploading) {
        buttonClasses = ["file-button", "uploading", Classes.BUTTON, Classes.INTENT_PRIMARY, Classes.DISABLED];
    }
    const progressClasses = ["progress-uploading", Classes.PROGRESS_BAR, Classes.INTENT_PRIMARY];
    if (progress > 0.0) {
        progressClasses.push(Classes.PROGRESS_NO_ANIMATION);
        progressClasses.push(Classes.PROGRESS_NO_STRIPES);
    }
    const onChange = useCallback(
        async (e) => {
            const file = e.target?.files[0];
            await onFileUpload(file);
        },
        [onFileUpload]
    );

    return (
        <StyledFileButton htmlFor={inputId} title={title} $percent={progress} $progressColor={Colors.BLUE5}>
            <input
                id={inputId}
                type="file"
                name="image"
                onChange={onChange}
                value=""
                disabled={disabled || uploading}
            />
            <div>
                <div className={classNames(buttonClasses)}>
                    {icon ? <Icon icon={icon} /> : null}
                    {text ? <div>{text}</div> : null}
                    {rightIcon ? <Icon icon={rightIcon} /> : null}
                </div>
                {uploading && (
                    <div className={classNames(progressClasses)}>
                        <div className={classNames("progress-meter", Classes.PROGRESS_METER)} />
                    </div>
                )}
            </div>
        </StyledFileButton>
    );
};

FileButton.propTypes = {
    inputId: PropTypes.string.isRequired,
    onFileUpload: PropTypes.func.isRequired,
    text: PropTypes.string,
    icon: PropTypes.node,
    rightIcon: PropTypes.node,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    progress: PropTypes.number,
};

FileButton.defaultProps = {
    text: null,
    icon: null,
    rightIcon: null,
    title: null,
    disabled: null,
    progress: null,
};

export default FileButton;
