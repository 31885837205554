import { Button, Classes, FormGroup, Intent, TextArea } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationAddCommentTicket } from "../tickets/gql-tickets";

const AddCommentDialog = ({ hideDialog, isDialogProcessing, ticket }) => {
    const [commentTicket, { error }] = useMutationAddCommentTicket();
    const [newComment, setNewComment] = useState("");

    const handleComment = useCallback(async () => {
        await commentTicket({ id: ticket.id, comment: newComment });
        toast.success("Successfully ticket commented");
        hideDialog(1, {});
    }, [commentTicket, ticket.id, newComment, hideDialog]);
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while commenting ticket" error={error} />
                <br />
                <>
                    <FormGroup label="Comment" labelFor="comments-input" helperText="comment on the ticket">
                        <TextArea
                            fill
                            id="comments-input"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            growVertically={false}
                            maxLength={4095}
                        />
                    </FormGroup>
                </>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={false}
                        loading={isDialogProcessing}
                        intent={Intent.SUCCESS}
                        text="Confirm "
                        onClick={() => handleComment()}
                        rightIcon="endorsed"
                    />
                </div>
            </div>
        </>
    );
};

AddCommentDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    ticket: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

export default {
    title: "Add Comment",
    icon: "comment",
    component: AddCommentDialog,
};
