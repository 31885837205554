import React, { useCallback, useState } from "react";
import styled from "styled-components";

import Box from "../common/box";
import Search from "../search";
import CouponTable from "./coupon-table";
import { useQueryCoupons } from "./gql-coupons";

const StyledCouponSearch = styled.div`
    margin: 20px 0px;
`;
const CouponSearch = () => {
    const [couponToCheck, setCouponToCheck] = useState("");
    const [searchCoupons, { loading, coupons }] = useQueryCoupons();
    const refreshCouponsList = useCallback(
        async (couponCode) => {
            searchCoupons({ code: couponCode });
        },
        [searchCoupons]
    );

    return (
        <StyledCouponSearch>
            <Box direction="row" gap="small">
                <Search
                    fill
                    value={couponToCheck}
                    setValue={setCouponToCheck}
                    onSearch={() => {
                        refreshCouponsList(couponToCheck);
                    }}
                ></Search>
            </Box>
            {loading && (
                <Box pad="small">
                    <p>Loading ...</p>
                </Box>
            )}
            {coupons && <CouponTable coupons={coupons} />}
        </StyledCouponSearch>
    );
};

export default CouponSearch;
