import { Button, Icon, Intent } from "@blueprintjs/core";
import { Cell, Column, SelectionModes, Table2, TableLoadingOption } from "@blueprintjs/table";
import PropTypes from "prop-types";
import { useState } from "react";
import styled from "styled-components";

import { getPlatformIcon } from "../platforms";

const StyledTargetingTable = styled.div`
    .bp4-table-cell {
        align-items: center;
        display: flex;
        .striked {
            text-decoration: line-through;
        }
    }
    small {
        font-size: 10px;
    }
`;

const TargetingTable = ({ targetings, loading, onSelect, selected, height, onInspect, rootUserAgency }) => {
    const [columnWidths, setColumnWidths] = useState([200, 200, 80, 200, 40, 40, 100]);
    const selectedIndex = targetings.findIndex((v) => v.id === selected?.id);
    return (
        <StyledTargetingTable style={{ height: height || "notset" }} className="amp-table-container">
            <Table2
                enableGhostCells
                numRows={targetings.length}
                enableRowHeader={false}
                defaultRowHeight={40}
                fill
                columnWidths={columnWidths}
                loadingOptions={loading ? [TableLoadingOption.CELLS] : []}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
                selectionModes={SelectionModes.ROWS_AND_CELLS}
                selectedRegionTransform={(e) => {
                    if (!e.rows) {
                        return e;
                    }
                    return {
                        rows: [e.rows[0], e.rows[0]],
                    };
                }}
                onSelection={(e) => {
                    if (e.length > 0) {
                        onSelect(targetings[e[0].rows[0]]);
                    } else {
                        onSelect(null);
                    }
                }}
                selectedRegions={
                    selectedIndex !== null
                        ? [
                              {
                                  rows: [selectedIndex, selectedIndex],
                              },
                          ]
                        : []
                }
            >
                <Column
                    name="Name"
                    cellRenderer={(index) => (
                        <Cell>
                            <span className={targetings[index].validated ? null : "striked"}>
                                {targetings[index].name}
                            </span>
                        </Cell>
                    )}
                />
                <Column name="Country" cellRenderer={(index) => <Cell>{targetings[index].countryLocaleName}</Cell>} />
                <Column name="Type" cellRenderer={(index) => <Cell>{targetings[index].subtype}</Cell>} />
                <Column
                    name="Platforms"
                    cellRenderer={(index) => (
                        <Cell>
                            {(targetings[index].platformData || []).map((pd) => (
                                <span key={pd.platform}>
                                    <span>{getPlatformIcon(pd.platform, { key: pd.platform, size: "14px" })}</span>
                                    &nbsp;
                                </span>
                            ))}
                        </Cell>
                    )}
                />
                <Column
                    name=""
                    nameRenderer={() => <small>geo</small>}
                    cellRenderer={(index) => (
                        <Cell>{targetings[index].geojson && <Icon icon="globe" intent={Intent.SUCCESS} />}</Cell>
                    )}
                />
                <Column
                    name=""
                    nameRenderer={() => <small>valid</small>}
                    cellRenderer={(index) => (
                        <Cell>{targetings[index].validated && <Icon icon="tick" intent={Intent.SUCCESS} />}</Cell>
                    )}
                />

                <Column
                    name=""
                    cellRenderer={(index) => (
                        <Cell>
                            {onInspect && (
                                <Button
                                    disabled={
                                        rootUserAgency !== null && rootUserAgency?.id !== targetings[index]?.owner?.id
                                    }
                                    outlined
                                    minimal
                                    small
                                    intent={Intent.PRIMARY}
                                    text="edit"
                                    icon="edit"
                                    onClick={() => onInspect(targetings[index])}
                                />
                            )}
                        </Cell>
                    )}
                />
            </Table2>
        </StyledTargetingTable>
    );
};

TargetingTable.propTypes = {
    targetings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            countryLocaleName: PropTypes.string.isRequired,
            subtype: PropTypes.string.isRequired,
            validated: PropTypes.bool,
            geojson: PropTypes.any,
            platformData: PropTypes.arrayOf(
                PropTypes.shape({
                    platform: PropTypes.string,
                })
            ),
            owner: PropTypes.shape({
                id: PropTypes.number,
            }),
        })
    ).isRequired,
    selected: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    loading: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    height: PropTypes.string,
    onInspect: PropTypes.func,
    rootUserAgency: PropTypes.shape({
        id: PropTypes.string,
    }),
};

TargetingTable.defaultProps = {
    loading: false,
    selected: null,
    height: null,
    onInspect: false,
    rootUserAgency: null,
};
export default TargetingTable;
