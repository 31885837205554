import { Button, ControlGroup, InputGroup, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { ALL_LANG } from "../lang";
import SimpleSelect from "./common/simple-select";

const Search = ({
    value,
    setValue,
    lang,
    setLang,
    onSearch,
    placeholder,
    options,
    setOptionValue,
    optionValue,
    allowEmptySearch,
    autoFocus,
    loading,
    icon,
}) => {
    const handleKeyUp = useCallback(
        (e) => {
            if (onSearch) {
                if (e.code === "Enter" && !e.isPropagationStopped()) {
                    e.preventDefault();
                    e.stopPropagation();
                    onSearch(value);
                }
            }
        },
        [onSearch, value]
    );
    return (
        <div style={{ flexGrow: 1 }} tabIndex={0} onKeyUp={handleKeyUp}>
            <ControlGroup fill>
                <InputGroup
                    fill
                    className="text-field"
                    value={value || ""}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    disabled={loading}
                />
                {setLang && (
                    <SimpleSelect
                        options={ALL_LANG}
                        selected={lang || "en"}
                        onSelect={(option) => setLang(option)}
                        disabled={loading}
                    />
                )}
                {options && (
                    <SimpleSelect
                        options={options}
                        selected={optionValue}
                        onSelect={(v) => setOptionValue(v)}
                        disabled={loading}
                    />
                )}
                <Button
                    className="button-end"
                    type="button"
                    intent={Intent.PRIMARY}
                    text="Search"
                    disabled={loading || (!value && !allowEmptySearch)}
                    loading={loading}
                    onClick={onSearch}
                    icon={icon}
                />
            </ControlGroup>
        </div>
    );
};

Search.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    lang: PropTypes.string,
    setLang: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.shape({}),
    optionValue: PropTypes.string,
    setOptionValue: PropTypes.func,
    allowEmptySearch: PropTypes.bool,
    autoFocus: PropTypes.bool,
    loading: PropTypes.bool,
    icon: PropTypes.string,
};

Search.defaultProps = {
    value: null,
    lang: null,
    setLang: null,
    placeholder: null,
    options: null,
    optionValue: null,
    setOptionValue: null,
    allowEmptySearch: false,
    autoFocus: false,
    loading: false,
    icon: null,
};

export default Search;
