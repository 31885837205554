import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

function formatMessage(m) {
    return m;
}

class ErrorPane extends React.Component {
    static propTypes = {
        error: PropTypes.shape({}),
        className: PropTypes.string.isRequired,
    };

    static defaultProps = {
        error: null,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { className, error } = this.props;
        if (!error) {
            return null;
        }

        let errorNode = formatMessage(error.toString());
        if (error.message) {
            errorNode = formatMessage(error.message);
        }
        if (error.errors) {
            errorNode = (
                <ul>
                    {error.errors.map((e) => (
                        <li key={e.message}>{formatMessage(e.message)}</li>
                    ))}
                </ul>
            );
        }
        return <div className={className}>{errorNode}</div>;
    }
}

ErrorPane.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
        errors: PropTypes.arrayOf(PropTypes.string),
    }),
    className: PropTypes.string.isRequired,
};

ErrorPane.defaultProps = {
    error: null,
};

export default styled(ErrorPane)`
    background-color: red;
    color: white;
    padding: 10px;
`;
