import { Alignment, Icon, Navbar, Tab, Tabs } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";

import { useUrlSearch } from "../../../common/hooks";
import { getPlatformIcon } from "../../platforms";
import PlatformSection from "./platform-section";

const StyledPlatformInspector = styled.div`
    padding: 0 10px;
`;

const StyledTabTitle = styled.span`
    display: flex;
    align-items: center;
    & > *:first-child {
        margin-right: 0.25em;
    }
`;

const StyledPlatformSection = styled.div``;
const PlatformsSection = ({ messageId, platforms, disabled, currency }) => {
    const [{ platform }, setUrlQuery] = useUrlSearch(["platform"]);

    const currentPlatform = useMemo(() => {
        return platforms.find((p) => p.name === platform);
    }, [platform, platforms]);

    useEffect(() => {
        if (!platform && platforms) {
            setUrlQuery({ platform: platforms[0].name });
        }
    }, [platform, platforms, setUrlQuery]);

    const setTab = useCallback(
        (p) => {
            setUrlQuery({ platform: p });
        },
        [setUrlQuery]
    );
    return (
        <StyledPlatformSection>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    {/* controlled mode & no panels (see h1 below): */}
                    <Tabs animate id="navbar" large={true} onChange={setTab} selectedTabId={platform}>
                        {(platforms || []).map((p) => (
                            <Tab
                                key={p.name}
                                id={p.name}
                                title={
                                    <StyledTabTitle>
                                        <Icon icon={getPlatformIcon(p.name)} /> {p.name}
                                    </StyledTabTitle>
                                }
                            />
                        ))}
                    </Tabs>
                </Navbar.Group>
            </Navbar>

            <StyledPlatformInspector>
                {currentPlatform && (
                    <PlatformSection
                        messageId={messageId}
                        platform={currentPlatform}
                        currency={currency}
                        disabled={disabled}
                    />
                )}
            </StyledPlatformInspector>
        </StyledPlatformSection>
    );
};

PlatformsSection.propTypes = {
    messageId: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            cpcMax: PropTypes.number,
        })
    ).isRequired,
    disabled: PropTypes.bool,
    currency: PropTypes.string.isRequired,
};

PlatformsSection.defaultProps = {
    disabled: false,
};

export default PlatformsSection;
