import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { GQL_ALL_USERS_QUERY } from "../users/gql-users";

const GQL_MUTATION_SET_BUSINESS_SURVEY_DATA = gql`
    mutation SetBusinessSurveyData($surveyData: SurveyDataInput!, $impersonateUserId: Int!) {
        setBusinessSurveyData(surveyData: $surveyData, impersonateUserId: $impersonateUserId) {
            business {
                id
            }
        }
    }
`;

export const useMutationSetBusinessSurveyData = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_SET_BUSINESS_SURVEY_DATA, {
        refetchQueries: [GQL_ALL_USERS_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
