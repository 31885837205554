import { Button, Callout, Classes, FormGroup, Intent, NumericInput, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { formatCurrency } from "../../common/i18n";
import ErrorCallout from "../common/error-callout";
import { useMutationAddMoneyFromWallet } from "../gql-wallet";

const AddMoneyFromWalletDialog = ({ hideDialog, isDialogProcessing, message }) => {
    const [addMoneyFromWallet, { loading, error }] = useMutationAddMoneyFromWallet();
    const [amount, setAmount] = useState(0);

    const handleAddMoney = useCallback(async () => {
        await addMoneyFromWallet({
            messageId: message.id,
            amount: amount * 100,
        });
        toast.success("Successfully added money on message");
        hideDialog(1, {});
    }, [message, hideDialog, addMoneyFromWallet, amount]);

    if (message.availableWallet) {
        const maxAmount = message.availableWallet.balance / 100;
        const isValid = amount > 0 && amount <= maxAmount;
        return (
            <>
                <div className={Classes.DIALOG_BODY}>
                    <ErrorCallout title="Error while adding money to wallet" error={error} />
                    <p>
                        <strong>{message.texts?.find((val) => val.type === "primary_text")?.value}</strong>
                    </p>
                    <p>
                        Wallet amount left:&nbsp;
                        <strong>{formatCurrency(message.availableWallet.balance, message.currency)}</strong>
                    </p>
                    <div>
                        <FormGroup inline label="Amount" labelFor="amount-input">
                            <NumericInput
                                id="amount-input"
                                onValueChange={(v) => setAmount(v)}
                                min={0}
                                stepSize={1}
                                minorStepSize={null} // Disable specific value for alt+increment
                                max={maxAmount}
                                rightElement={<Tag minimal>{message.currency}</Tag>}
                            />
                        </FormGroup>
                    </div>
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button disabled={isDialogProcessing || loading} text="Cancel" onClick={() => hideDialog(0)} />
                        <Button
                            disabled={!isValid || loading}
                            loading={isDialogProcessing || loading}
                            intent={Intent.SUCCESS}
                            text="Add money"
                            onClick={handleAddMoney}
                            rightIcon="bank-account"
                        />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className={Classes.DIALOG_BODY}>
                    <Callout intent={Intent.DANGER} icon="warning-sign" title="No wallet available" />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button disabled={isDialogProcessing || loading} text="Ok" onClick={() => hideDialog(0)} />
                    </div>
                </div>
            </>
        );
    }
};

AddMoneyFromWalletDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
        currency: PropTypes.string.isRequired,
        availableWallet: PropTypes.shape({
            balance: PropTypes.number.isRequired,
        }).isRequired,
        owner: PropTypes.shape({
            email: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default {
    title: "Add money from wallet",
    icon: "bank-account",
    component: AddMoneyFromWalletDialog,
};
