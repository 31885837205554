import log from "loglevel";

const GQL_ACTION_REGEXP = /^GQL.(.*?).(REQUEST|SUCCESS|FAILURE)$/;

const TEST_RESULTS = [];

let GQL_ENABLED = true;

const REGISTRATION_MAP = {};

export class GraphQLError extends Error {
    constructor(status, errors = []) {
        super(`GraphQLError (${status})`);
        this.response = {
            errors,
            status,
        };
        this.errors = errors;
        this.status = status;
    }
}

class GraphQLClient {
    constructor(root, options) {
        this.rootPath = root;
        this.options = options || {};
    }

    request = async (query, params) => {
        const args = {
            ...{
                mode: this.options.cors, // no-cors, cors, *same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, same-origin, *omit
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    ...(this.options.headers || {}),
                    // "Content-Type": "application/x-www-form-urlencoded",
                },
                redirect: "follow", // manual, *follow, error
                referrer: "no-referrer", // no-referrer, *client
            },
            ...this.options,
            method: "POST",
            body: JSON.stringify({
                query,
                variables: params,
            }),
        };
        const response = await fetch(this.rootPath, args);
        if (response.status === 400) {
            const { errors } = await response.json();
            log.error(`GraphQL 400 error: ${JSON.stringify(errors)}`);
            throw new GraphQLError(response.status, errors);
        } else if (response.status === 200 || response.status === 201) {
            const result = await response.json();
            if (result.errors) {
                log.error(`GraphQL 200 error: ${JSON.stringify(result.errors)}`);
                throw new GraphQLError(response.status, result.errors);
            } else {
                return result.data;
            }
        } else {
            throw new GraphQLError(response.status);
        }
    };
}

const amplifyClient = new GraphQLClient(`${window.APP_SERVER_URL}/graphql`, {
    credentials: "include",
    mode: "cors",
});

const datoClient = new GraphQLClient(window.DATO_GRAPHQL_URL, {
    credentials: "include",
    mode: "cors",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.DATO_API_TOKEN}`,
    },
});

async function normalGQLFetch(query, params, endpoint) {
    let client = amplifyClient;
    if (endpoint === "dato") {
        client = datoClient;
    }
    return client.request(query, params);
}

async function testGQLFetch(query, params, endpoint) {
    if (!GQL_ENABLED) {
        return null;
    }
    if (TEST_RESULTS.length === 0) {
        log.error(`GraphQL query call without TEST response set! query=\n ${JSON.stringify(query, null, 4)}`);
        throw new Error(
            "Test result for GraphQL is empty, you forgot to call setupTestResults or done an unexpected call"
        );
    }
    const ret = TEST_RESULTS.shift();
    if (typeof ret === "function") {
        return ret.call(null, query, params, endpoint);
    }
    return ret;
}

// export function to be able to fill test results

export const setupTestResults = (data) => {
    if (process.env.NODE_ENV !== "test") {
        throw new Error("setupTestResults has no effects, it should never be called outside tests");
    }
    GQL_ENABLED = true;
    if (data === null || data === undefined) {
        TEST_RESULTS.length = 0;
    } else if (data === false) {
        GQL_ENABLED = false;
        TEST_RESULTS.length = 0;
    } else if (Array.isArray(data)) {
        TEST_RESULTS.length = 0;
        data.forEach((x) => TEST_RESULTS.push(x));
    } else {
        throw new Error("setupTestResults parameter should by an array");
    }
};

// setup graphql fetch function, test or normal
const graphQLFetch = process.env.NODE_ENV === "test" ? testGQLFetch : normalGQLFetch;

const getErrorMessage = (status) => {
    switch (status) {
        case 400:
            return `Bad request`;
        case 401:
            return `Missing or wrong authorization`;
        case 403:
            return `Access denied`;
        case 404:
            return `Not found`;
        case 500:
            return `Internal error`;
        case 503:
            return `Service unavailable```;
        default:
            return `Error status ${status}`;
    }
};

/**
 * Create a GraphQL action bound to the redux store 'graphql'
 * @param name Unique name for this action
 * @param query Full GraphQL query 7 mutation string
 * @param resolveSuccess function(gql result) => payload data
 * @param successReducer (optional) (state, action) => state
 * @param failureReducer (optional) (state, action) => state
 * @returns {function(*=): Function}
 */
export const createGraphQLAction = ({
    name,
    query,
    resolveSuccess = (x) => x,
    successReducer = null,
    failureReducer = null,
}) => {
    if (!name) {
        throw Error("Missing 'name' in options");
    }
    if (REGISTRATION_MAP[name]) {
        throw Error(`GraphQL action with name '${name}' already registered`);
    }
    if (!query) {
        throw Error("Missing 'query' in options");
    }

    REGISTRATION_MAP[name] = {
        successReducer,
        failureReducer,
    };

    const func = (params) => async (dispatch) => {
        if (!GQL_ENABLED) {
            log.debug("GraphQL is disabled");
            return;
        }
        try {
            dispatch({
                type: `GQL.${name}.REQUEST`,
            });
            const data = await graphQLFetch(query, params);
            dispatch({
                type: `GQL.${name}.SUCCESS`,
                // we need to resolve success data to avoid parsing
                // the GraphQL query to get the key of the data
                // second argument is the parameters sent
                // to the graphQL query / mutation
                payload: resolveSuccess(data, params),
            });
        } catch (ex) {
            log.error(`Unable to get GraphQL data, reason: ${ex}`, ex);
            const status = ex.response ? ex.response.status || 0 : -1;
            const errors = ex.response ? ex.response.errors : [];
            const message = getErrorMessage(status);
            dispatch({
                type: `GQL.${name}.FAILURE`,
                error: {
                    message,
                    errors,
                },
            });
        }
    };
    return func;
};

/**
 * Create a simple reducer action on graphQL store without any graphQL call
 * @param name
 * @param reducer
 * @returns {function(*=): Function}
 */
export const createGraphQLReducer = ({ name, reducer }) => {
    if (!name) {
        throw Error("Missing 'name' in options");
    }
    if (REGISTRATION_MAP[name]) {
        throw Error(`GraphQL action with name '${name}' already registered`);
    }
    REGISTRATION_MAP[name] = {
        successReducer: reducer,
    };

    return (params) => async (dispatch) => {
        dispatch({
            type: `GQL.${name}.SUCCESS`,
            payload: params,
        });
    };
};

export const createGraphQLPromise = ({ query, resolveSuccess = (x) => x, endpoint = "amplify" }) => {
    const func = async (params) => {
        if (!GQL_ENABLED) {
            log.debug("GraphQL is disabled");
            return null;
        }
        const data = await graphQLFetch(query, params, endpoint);
        return resolveSuccess(data);
    };
    return func;
};

export const graphQLReducers = (state, action) => {
    if (action.type.startsWith("GQL.")) {
        let reduxState = state;
        const match = GQL_ACTION_REGEXP.exec(action.type);
        if (match !== null) {
            const name = match[1];
            let result = {};
            switch (match[2]) {
                case "REQUEST":
                    result = {
                        [name]: {
                            data: null,
                            loading: true,
                            error: null,
                        },
                    };
                    break;
                case "SUCCESS":
                    result = {
                        [name]: {
                            data: action.payload,
                            loading: false,
                            error: null,
                        },
                    };
                    if (REGISTRATION_MAP[name].successReducer) {
                        reduxState = REGISTRATION_MAP[name].successReducer(state, action);
                    }
                    break;
                case "FAILURE":
                    result = {
                        [name]: {
                            data: null,
                            loading: false,
                            error: action.error,
                        },
                    };
                    if (REGISTRATION_MAP[name].failureReducer) {
                        reduxState = REGISTRATION_MAP[name].failureReducer(state, action);
                    }
                    break;
                default:
            }
            return { ...reduxState, ...result };
        }
        throw Error(`GQL action bad format '${action.type}'`);
    }
    return state;
};
