import { useLazyQuery } from "@apollo/client";
import { AnchorButton, Button, Callout, Intent, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { getRootUserAgencyPromise } from "../common/gql-promises";
import { useIdentity } from "../common/identity-context";
import { GQL_QUERY_TICKETS } from "./tickets/gql-tickets";
import { getIntentForState } from "./tickets/tickets-table";

const StyledMainStaffPage = styled.div`
    padding: 29px;
    h1 {
        font-size: 1.5em;
        margin: 20px 0;
    }
    ul {
        display: block;
        li {
            padding: 10px;
            display: inline-flex;
            flex-grow: 1;
            a {
                padding: 10px;
                font-size: 20px;
                text-decoration: none;
                border: 1px solid #ddd;
                border-radius: 5px;
                background-color: white;
                .svg-icon {
                    font-size: 30px;
                }
                small {
                    margin: 5px;
                    padding: 2px 6px;
                    background-color: red;
                    font-size: 0.75em;
                    vertical-align: middle;
                    color: white;
                    border-radius: 5px;
                    min-width: 2em;
                    display: inline;
                }
            }
        }
    }
`;

const StyledTicketsContainer = styled.div`
    .tickets {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
    }

    .ticket {
        background: white;
        padding: 20px 30px;
        font-size: 1em;
        font-weight: bold;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        width: 200px;
        text-align: center;
    }
`;

const MainStaffPage = () => {
    const history = useHistory();
    const [loaded, identity] = useIdentity();
    const [loadTickets, { data }] = useLazyQuery(GQL_QUERY_TICKETS, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
    });
    const [rootAgency, setRootAgency] = useState(null);
    const crash = undefined;

    useEffect(() => {
        const load = async () => {
            const rootUser = await getRootUserAgencyPromise();
            setRootAgency(rootUser);
            await loadTickets({
                variables: {
                    assignedMember: identity?.username,
                    limit: 20,
                },
            });
        };
        if (loaded) load();
    }, [identity?.username, loadTickets, loaded]);
    const tickets = data?.getAllTickets?.results.filter((elt) => elt.status === "open" || elt.status === "in progress");
    if (!loaded) return null;
    return (
        <StyledMainStaffPage>
            <h1>Welcome back {identity.firstName || identity.email}</h1>
            {!rootAgency && (
                <>
                    <Callout title="Reports" icon="timeline-area-chart" intent={Intent.PRIMARY}>
                        <p>Download all messages Excel report</p>
                        <AnchorButton
                            large
                            href={`${window.APP_SERVER_URL}/exports/message-ads-xls/`}
                            target="_blank"
                            icon="download"
                            text="Download"
                        />
                    </Callout>
                    <br />
                    <Callout title="Error simulator" icon="warning-sign" intent={Intent.DANGER}>
                        <p>This will generate a Rollbar error</p>
                        {/* eslint-disable-next-line no-undef */}
                        <Button
                            intent={Intent.DANGER}
                            onClick={() => crash()}
                            icon="warning-sign"
                            text="DO NOT CLICK"
                        />
                    </Callout>
                </>
            )}
            {tickets && (
                <StyledTicketsContainer>
                    <h1>Your Tickets TO-DO ({tickets.length})</h1>
                    <div className="tickets">
                        {tickets.map((ticket) => {
                            return (
                                <div className="ticket" key={ticket.id}>
                                    {ticket.title.substring(0, 30) + "..."}
                                    <Tag minimal intent={getIntentForState(ticket.status)}>
                                        {ticket.status}
                                    </Tag>
                                    <Button
                                        intent={Intent.PRIMARY}
                                        onClick={() =>
                                            history.push({
                                                pathname: `/tickets/${ticket.id}/edit/`,
                                                search: history.location.search,
                                            })
                                        }
                                        text="Open"
                                        icon="share"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </StyledTicketsContainer>
            )}
        </StyledMainStaffPage>
    );
};

export default MainStaffPage;
