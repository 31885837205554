import { AnchorButton, Button, Icon, Intent, Tag } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { Cell, Column, Table2 } from "@blueprintjs/table";
import union from "lodash/union";
import moment from "moment";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { DialogContext } from "../bp-dialog-context";

const ROW_HEIGHT = 30;

const AGE_MAP = {
    0: "13",
    1: "20",
    2: "30",
    3: "50",
    4: "60",
    5: "60+",
};

const NotSet = styled.span`
    opacity: 0.25;
    font-size: 0.75em;
`;
const StyledMessageChangesView = styled.div`
    .key-cell {
        background-color: #ddd;
        font-weight: bold;
        line-height: ${ROW_HEIGHT}px;
    }
    .key-value {
        display: flex;
        align-items: center;
    }
`;

const ageRenderer = (value) => {
    if (value === null) {
        return <NotSet>Not set</NotSet>;
    }
    const age = AGE_MAP[value];
    return `${age} years old`;
};

const platformFormatsRenderer = (value) => {
    if (value === null) {
        return <NotSet>Not set</NotSet>;
    }
    return <Tag minimal>{value.format}</Tag>;
};

const textAssetsRenderer = (value) => {
    if (value === null) {
        return <NotSet>Not set</NotSet>;
    }
    if (value === false) {
        return (
            <Tag minimal intent={Intent.DANGER}>
                <Icon icon="remove" /> Removed
            </Tag>
        );
    }
    return (
        <Tooltip2 content={value.value && value.value.toString()}>{value.value ? value.value.toString() : ""}</Tooltip2>
    );
};

const StyledImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const StyledVideo = styled.video`
    max-width: 100%;
    max-height: 100%;
`;

const imageRenderer = (value, { showDialog }) => {
    if (value === null) {
        return <NotSet>Not set</NotSet>;
    }
    return (
        <div>
            <Button
                small
                intent={Intent.NONE}
                text="Show image"
                icon="media"
                tabIndex={0}
                onClick={async () => {
                    await showDialog({
                        title: "Ad image",
                        icon: "media",
                        component: () => <StyledImage src={value} />,
                    });
                }}
            />
        </div>
    );
};

const videoRenderer = (value, { showDialog }) => {
    if (value === null) {
        return <NotSet>Not set</NotSet>;
    }
    return (
        <div>
            <Button
                small
                intent={Intent.NONE}
                text="Show video"
                icon="media"
                tabIndex={0}
                onClick={async () => {
                    await showDialog({
                        title: "Ad video",
                        icon: "media",
                        component: () => <StyledVideo src={value} controls autoplay />,
                    });
                }}
            />
        </div>
    );
};

const imageAssetRenderer = (value, { showDialog }) => {
    if (value === null) {
        return <NotSet>Not set</NotSet>;
    }
    if (value === false) {
        return (
            <Tag minimal intent={Intent.DANGER}>
                <Icon icon="remove" /> Removed
            </Tag>
        );
    }
    return (
        <div>
            <Button
                small
                intent={Intent.NONE}
                text={"Show " + value.type + "/" + value.role}
                icon="media"
                tabIndex={0}
                onClick={async () => {
                    await showDialog({
                        title: "Ad image " + value.type + "/" + value.role,
                        icon: "media",
                        component: () => <StyledImage src={value.image_url} />,
                    });
                }}
            />
        </div>
    );
};

const videoAssetRenderer = (value, { showDialog }) => {
    if (value === null) {
        return <NotSet>Not set</NotSet>;
    }
    if (value === false) {
        return (
            <Tag minimal intent={Intent.DANGER}>
                <Icon icon="remove" /> Removed
            </Tag>
        );
    }
    return (
        <div>
            <Button
                small
                intent={Intent.NONE}
                text={"Show " + value.type + "/" + value.role}
                icon="media"
                tabIndex={0}
                onClick={async () => {
                    await showDialog({
                        title: "Ad video " + value.type + "/" + value.role,
                        icon: "media",
                        component: () => <StyledVideo src={value.video_url} controls autoplay />,
                    });
                }}
            />
        </div>
    );
};

const KEYS_DEF = {
    ad_link: {
        renderer: (value) => {
            if (value === null) {
                return <NotSet>Not set</NotSet>;
            }
            return (
                <AnchorButton small href={value} target="_blank" rightIcon="document-open">
                    {value}
                </AnchorButton>
            );
        },
    },
    targeting_min_age: {
        renderer: ageRenderer,
    },
    targeting_max_age: {
        renderer: ageRenderer,
    },
    image_url: {
        renderer: imageRenderer,
    },
    video_url: {
        renderer: videoRenderer,
    },
    image_assets_entry: {
        renderer: imageAssetRenderer,
    },
    movie_assets_entry: {
        renderer: videoAssetRenderer,
    },
    text_assets_entry: {
        renderer: textAssetsRenderer,
        wrapText: true,
        height: 60,
    },
    platform_formats_entry: {
        renderer: platformFormatsRenderer,
    },
    title: {
        renderer: (v) => {
            if (v === null) {
                return <NotSet>Not set</NotSet>;
            }
            return v;
        },
        wrapText: true,
        height: 80,
    },
    ad_goal: {
        renderer: (v) => {
            if (!v) {
                return <Tag minimal>AUTOMATIC</Tag>;
            }
            return v;
        },
    },
    live_end_date: {
        renderer: (v) => {
            if (!v) {
                return <NotSet>Not set</NotSet>;
            }
            return moment(v).format("LLLL");
        },
    },
    _default: {
        renderer: (value) => {
            if (typeof value === "boolean") {
                if (value) {
                    return (
                        <Tag minimal intent={Intent.SUCCESS}>
                            <Icon icon="add" /> Added
                        </Tag>
                    );
                } else {
                    return (
                        <Tag minimal intent={Intent.DANGER}>
                            <Icon icon="remove" /> Removed
                        </Tag>
                    );
                }
            }
            if (value === "false") {
                return (
                    <Tag minimal intent={Intent.NONE}>
                        <Icon icon="cross" /> NO
                    </Tag>
                );
            }
            if (value === "true") {
                return (
                    <Tag minimal intent={Intent.NONE}>
                        <Icon icon="tick" /> YES
                    </Tag>
                );
            }
            if (value === null) {
                return <NotSet>Not set</NotSet>;
            }
            return <Tooltip2 content={value && value.toString()}>{value ? value.toString() : ""}</Tooltip2>;
        },
    },
};

const cellRenderer = (row, keyDisplay, options) => {
    const definition = KEYS_DEF[row.key?.split(".")?.pop()] || KEYS_DEF[row.key] || KEYS_DEF._default;
    return (
        <Cell className="key-value" wrapText={definition.wrapText}>
            {definition.renderer(row[keyDisplay], options)}
        </Cell>
    );
};

const ARRAY_KEYS = [
    "active_platforms",
    "image_assets",
    "movie_assets",
    "text_assets",
    "platform_formats",
    "targeting_list",
    "google_ads_search_standard.keywords",
];

const maybeJsonArray = (v) => {
    if (Array.isArray(v)) {
        return v;
    }
    return JSON.parse(v);
};
const computeArrayChanges = (change) => {
    const oldArray = (change?.old && maybeJsonArray(change.old)) || [];
    const newArray = (change?.new && maybeJsonArray(change.new)) || [];
    const unionArray = union(oldArray, newArray);
    return unionArray.map((v) => {
        let keys, key, label;
        if (typeof v === "object" && v !== null) {
            keys = [...change.keys, v.id];
            key = `${change.key}_entry`;
            label = v.platform ? v.platform : v.type + "/" + v.role + " (" + v.id + ")";
            return {
                keys: keys,
                key: key,
                label: label,
                old: oldArray.includes(v) ? v : null,
                new: newArray.includes(v) ? v : false,
                offset: change.offset + 1,
            };
        } else {
            keys = [...change.keys, v];
            key = `${change.key}.${v}`;
            label = v;
            return {
                keys: keys,
                key: key,
                label: label,
                old: "",
                new: newArray.includes(v),
                offset: change.offset + 1,
            };
        }
    });
};

const computeRowChanges = (changes) => {
    let result = [];
    const firstPass = [...changes]
        .sort((a, b) => a.key.localeCompare(b.key))
        .map((c) => {
            const keys = c.key.split(".");
            return { ...c, keys, offset: keys.length - 1, label: keys[keys.length - 1] };
        });

    let oldHeader = null;

    firstPass.forEach((c) => {
        const newHeader = c.keys.slice(0, c.keys.length - 1).join("/");
        if (newHeader && newHeader !== oldHeader) {
            oldHeader = newHeader;
            result.push({
                offset: c.offset - 1,
                label: newHeader,
                old: "",
                new: "",
            });
        }
        if (ARRAY_KEYS.includes(c.key)) {
            result = [...result, { ...c, label: c.label, old: "", new: "" }, ...computeArrayChanges(c)];
        } else {
            result.push(c);
        }
    });
    return result;
};

const computeRowHeight = (row) => {
    const definition = KEYS_DEF[row.key] || KEYS_DEF._default;
    return definition.height || ROW_HEIGHT;
};

const initialColumnWidth = (hasOldValues) => {
    if (hasOldValues) {
        return [300, 400, 400];
    }
    return [300, 400];
};

const MessageChangesView = ({ changes, hasOldValues }) => {
    const [columnWidths, setColumnWidths] = useState(initialColumnWidth(hasOldValues));
    const { showDialog } = useContext(DialogContext);

    const rows = useMemo(() => {
        return computeRowChanges(changes);
    }, [changes]);

    useEffect(() => setColumnWidths(initialColumnWidth(hasOldValues)), [hasOldValues]);

    const columns = [];
    columns.push(
        <Column
            key="key"
            name=""
            cellRenderer={(index) => {
                const row = rows[index];
                const padding = 10 + row.offset * ROW_HEIGHT;
                return (
                    <Cell className="key-cell" style={{ paddingLeft: `${padding}px` }}>
                        {row.label}
                    </Cell>
                );
            }}
        />
    );
    if (hasOldValues) {
        columns.push(
            <Column
                key="old"
                name="Previous value"
                cellRenderer={(index) => {
                    return cellRenderer(rows[index], "old", { showDialog });
                }}
            />
        );
    }
    columns.push(
        <Column
            key="new"
            name="Current value"
            cellRenderer={(index) => {
                return cellRenderer(rows[index], "new", { showDialog });
            }}
        />
    );
    return (
        <StyledMessageChangesView>
            <Table2
                numRows={rows.length}
                enableRowHeader={false}
                rowHeights={rows.map(computeRowHeight)}
                numFrozenColumns={1}
                fill
                defaultRowHeight={ROW_HEIGHT}
                columnWidths={columnWidths}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
            >
                {columns}
            </Table2>
        </StyledMessageChangesView>
    );
};

MessageChangesView.propTypes = {
    changes: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            old: PropTypes.string,
            new: PropTypes.string,
        })
    ).isRequired,
    hasOldValues: PropTypes.bool.isRequired,
};

export default MessageChangesView;
