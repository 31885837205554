import { Cell, Column, Table2 } from "@blueprintjs/table";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import styled from "styled-components";

import Box from "../common/box";

function truncateString(str, num) {
    if (str.length <= num) {
        return str;
    }
    return `${str.slice(0, num)}...`;
}

const StyledCouponTable = styled.div`
    width: 100%;
    padding: 10px 0px;
    position: relative;
    .centered-table-cell,
    .bp4-table-cell {
        display: flex;
        align-items: center;
    }
`;

const textAttributeCell = (attribute) => (coupon) => {
    return <Cell style={{ padding: 5 }}>{coupon[attribute]}</Cell>;
};

const relativeDateAttributeCell = (attribute) => (coupon) => {
    if (!coupon[attribute]) {
        return null;
    }
    return <Cell>{moment(coupon[attribute]).fromNow()}</Cell>;
};

const amountAttributeCell = (attribute) => (coupon) => {
    if (!coupon[attribute]) {
        return null;
    }
    return <Cell>{parseInt(coupon[attribute], 10) / 100}</Cell>;
};

const statusAttributeCell = () => (coupon) => {
    if (!coupon.insertedAt) {
        return null;
    }
    if (coupon.usedAt) {
        const usedAt = moment(coupon.usedAt).fromNow();
        return (
            <Cell wrapText>
                Used in{" "}
                {coupon.usedBy ? (
                    <a target="_blank" rel="noreferrer" href={`/messages?id=${coupon.usedBy.id}`}>
                        {truncateString(coupon.usedBy.title, 15)}
                    </a>
                ) : (
                    "Unknown"
                )}{" "}
                {usedAt}
            </Cell>
        );
    }
    const formattedValidUntil = moment(coupon.validUntil).format("lll");
    return <Cell wrapText>{`Valid until ${formattedValidUntil}`}</Cell>;
};

export default function CouponTable({ coupons }) {
    const withCoupon = useCallback(
        (func, ...args) =>
            (index) =>
                func(coupons[index], ...args),
        [coupons]
    );

    return coupons.length > 0 ? (
        <StyledCouponTable>
            <Table2
                columnWidths={[50, 80, 150, 150, 500]}
                numRows={coupons.length}
                enableRowHeader={false}
                fill
                defaultRowHeight={40}
                enableGhostCells
            >
                <Column name="id" cellRenderer={withCoupon(textAttributeCell("id"))} />
                <Column name="prefix" cellRenderer={withCoupon(textAttributeCell("prefix"))} />
                <Column name="insertedAt" cellRenderer={withCoupon(relativeDateAttributeCell("insertedAt"))} />
                <Column name="amount" cellRenderer={withCoupon(amountAttributeCell("amount"))} />
                <Column name="status" cellRenderer={withCoupon(statusAttributeCell())} />
            </Table2>
        </StyledCouponTable>
    ) : (
        <Box>
            <p>Nothing found</p>
        </Box>
    );
}

CouponTable.propTypes = {
    coupons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
