import { Button, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

const getMessageNavigationMenuItems = ({ message, history }) => {
    return [
        <MenuItem
            key="show-insight"
            icon="timeline-bar-chart"
            onClick={() =>
                history.push({
                    pathname: `/messages/${message.id}/insights`,
                    search: history.location.search,
                })
            }
            text="Show insights"
        />,
        <MenuItem
            key="show-logs"
            icon="properties"
            onClick={() =>
                history.push({
                    pathname: `/messages/${message.id}/logs`,
                    search: history.location.search,
                })
            }
            text="Show logs"
        />,
        <MenuItem
            key="show-platforms"
            icon="search-around"
            onClick={() =>
                history.push({
                    pathname: `/messages/${message.id}/platforms`,
                    search: history.location.search,
                })
            }
            text="Platforms info"
        />,
        <MenuItem
            key="edit"
            icon={<Icon icon="edit" />}
            onClick={() =>
                history.push({
                    pathname: `/messages/${message.id}/edit/`,
                    search: history.location.search,
                })
            }
            text="Edit"
        />,
    ];
};

const NavigationMenu = ({ message, text, icon }) => {
    const history = useHistory();
    return (
        <Popover2 content={<Menu>{getMessageNavigationMenuItems({ message, history })}</Menu>}>
            <Button large minimal icon={icon} text={text} rightIcon="caret-down" />
        </Popover2>
    );
};

NavigationMenu.propTypes = {
    message: PropTypes.shape({}).isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
};

NavigationMenu.defaultProps = {
    icon: null,
};

export default NavigationMenu;
