import { Callout, Intent, Spinner } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import NavBreadcrumb from "../common/nav-breadcrumb";
import LogView from "../messges/logs/log-view";
import NavigationMenu from "../messges/message-menu";
import { useQueryTicketLogs } from "./gql-tickets";

const TicketLogs = ({ ticket }) => {
    const allLogs = useMemo(() => {
        const result = (ticket ? ticket.logs : []).map((l) => ({ ...l, at: new Date(l.insertedAt) }));

        result.sort((a, b) => b.at - a.at);
        return result;
    }, [ticket]);
    return (
        <div>
            <NavBreadcrumb
                items={[
                    { icon: "home", to: "/" },
                    { text: "Tickets", icon: <RiAdvertisementLine />, to: "/tickets" },
                    {
                        text: ticket.id,
                    },
                    {
                        text: "Logs",
                        icon: "properties",
                        current: true,
                    },
                ]}
                currentBreadcrumbRenderer={({ text, icon }) => (
                    <NavigationMenu message={ticket} text={text} icon={icon} />
                )}
            />
            <LogView logs={allLogs} />
        </div>
    );
};

TicketLogs.propTypes = {
    ticket: PropTypes.shape({
        id: PropTypes.string.isRequired,
        logs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
};

const StyledMessageLogPage = styled.div`
    padding: 10px;
`;

const TicketLogPage = () => {
    const { id } = useParams();
    const { ticket, loading, error } = useQueryTicketLogs(id);

    return (
        <StyledMessageLogPage>
            {loading && (
                <Callout title="Loading ticket, please wait...">
                    <Spinner />
                </Callout>
            )}
            {error && (
                <Callout intent={Intent.DANGER} title="Error while loading ticket">
                    {error.message}
                </Callout>
            )}

            {ticket ? (
                <TicketLogs ticket={ticket} />
            ) : (
                !loading &&
                !error && (
                    <Callout intent={Intent.DANGER} title="Nothing found here">
                        {id}
                    </Callout>
                )
            )}
        </StyledMessageLogPage>
    );
};

export default TicketLogPage;
