import { Callout, Intent, Spinner } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import NavBreadcrumb from "../common/nav-breadcrumb";
import { useQueryMessage } from "./gql-messages";

const MessageDetails = ({ message }) => {
    return (
        <div>
            <NavBreadcrumb
                items={[
                    { icon: "home", to: "/" },
                    { text: "Messages", icon: <RiAdvertisementLine />, to: "/messages" },
                    {
                        text: message.id,
                    },
                    {
                        text: "General",
                    },
                ]}
            />
            <h1>Title</h1>
            <p>{message.texts?.find((val) => val.type === "primary_text")?.value}</p>
        </div>
    );
};

MessageDetails.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
    }).isRequired,
};

const StyledMessageDetailsPage = styled.div`
    padding: 10px;
`;

const MessageDetailsGeneral = () => {
    const { id } = useParams();
    const { message, loading, error } = useQueryMessage(id);

    return (
        <StyledMessageDetailsPage>
            {loading && (
                <Callout title="Loading message, please wait...">
                    <Spinner />
                </Callout>
            )}
            {error && (
                <Callout intent={Intent.DANGER} title="Error while loading message">
                    {error.message}
                </Callout>
            )}

            {message ? (
                <MessageDetails message={message} />
            ) : (
                !loading &&
                !error && (
                    <Callout intent={Intent.DANGER} title="Nothing found here">
                        {id}
                    </Callout>
                )
            )}
        </StyledMessageDetailsPage>
    );
};

export default MessageDetailsGeneral;
