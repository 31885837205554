import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

export const GQL_QUERY_TICKETS = gql`
    query getAllTickets(
        $limit: Int
        $offset: Int
        $assignedMember: String
        $status: [String]
        $ticketType: [String]
        $priority: [String]
        $messageId: UUID
        $ticketId: UUID
        $closed: Boolean
    ) {
        getAllTickets(
            limit: $limit
            offset: $offset
            assignedMember: $assignedMember
            status: $status
            ticketType: $ticketType
            priority: $priority
            messageId: $messageId
            ticketId: $ticketId
            closed: $closed
        ) {
            count
            results {
                id
                title
                description
                image {
                    imageUrl
                }
                movie {
                    videoUrl
                    image {
                        imageUrl
                    }
                }
                movieUrl
                expiryAt
                priorityStatus
                type
                status
                comment
                type
                assignedMember {
                    username
                }
                createdBy {
                    username
                    email
                }
                createdAt
                message {
                    id
                    title
                }
            }
        }
    }
`;

export const GQL_QUERY_TICKET = gql`
    query getTicket($id: UUID!) {
        getTicket(id: $id) {
            id
            title
            description
            comments {
                text
                username
                id
            }
            image {
                imageUrl
            }
            movie {
                videoUrl
                amplifyVideoUrl
                image {
                    imageUrl
                }
            }
            priorityStatus
            status
            type
            assignedMember {
                username
            }
            expiryAt
            createdAt
            message {
                id
            }
        }
    }
`;

export const GQL_QUERY_TICKET_LOGS = gql`
    query getTicketMetrics($id: UUID!) {
        getTicket(id: $id) {
            title
            logs {
                id
                insertedAt
                insertedBy {
                    id
                    email
                    avatarUrl
                }
                text
                level
                platform
            }
        }
    }
`;

export const GQL_QUERY_ASSIGNED_TICKETS = gql`
    query getAssignedTickets($userId: Int) {
        getAssignedTickets(userId: $userId)
    }
`;

export const GQL_CREATE_TICKET_MUTATION = gql`
    mutation CreateTicket($ticket: TicketCreate!) {
        createTicket(ticket: $ticket) {
            ticket {
                id
            }
        }
    }
`;

export const GQL_UPDATE_TICKET_MUTATION = gql`
    mutation UpdateTicket($id: UUID!, $changes: TicketChanges!) {
        updateTicket(id: $id, changes: $changes) {
            ticket {
                id
            }
        }
    }
`;
export const GQL_ASSIGN_TICKET_MUTATION = gql`
    mutation AssignTicket($id: UUID!, $memberId: Int!) {
        assignTicket(id: $id, memberId: $memberId) {
            ticket {
                id
            }
        }
    }
`;
export const GQL_MUTATION_STATUS_TICKET = gql`
    mutation updateStatusTicket($id: UUID!, $status: String!) {
        updateStatusTicket(id: $id, status: $status) {
            ticket {
                id
            }
        }
    }
`;

export const GQL_MUTATION_COMMENT_TICKET = gql`
    mutation commentTicket($id: UUID!, $comment: String!) {
        commentTicket(id: $id, comment: $comment) {
            ticket {
                id
            }
        }
    }
`;

export const GQL_MUTATION_DELETE_TICKET = gql`
    mutation deleteTicket($id: UUID!) {
        deleteTicket(id: $id) {
            ok
        }
    }
`;
export const useMutationCreateTicket = () => {
    const [actionPromise, rest] = useMutation(GQL_CREATE_TICKET_MUTATION, {
        refetchQueries: [GQL_QUERY_TICKETS],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useQueryTicket = (id) => {
    const { data: { getTicket } = {}, ...rest } = useQuery(GQL_QUERY_TICKET, {
        variables: { id },
        fetchPolicy: "no-cache",
    });
    return { ticket: getTicket, ...rest };
};

export const useQueryAssignedTickets = (username) => {
    const { data: { getAssignedTickets } = {}, ...rest } = useQuery(GQL_QUERY_ASSIGNED_TICKETS, {
        variables: { username },
        fetchPolicy: "no-cache",
    });
    return { ticket: getAssignedTickets, ...rest };
};
export const useMutationUpdateTicket = () => {
    const [actionPromise, rest] = useMutation(GQL_UPDATE_TICKET_MUTATION, {
        refetchQueries: [GQL_QUERY_TICKETS],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
export const useMutationAssignTicket = () => {
    const [actionPromise, rest] = useMutation(GQL_ASSIGN_TICKET_MUTATION, {
        refetchQueries: [GQL_QUERY_TICKETS],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
export const useMutationUpdateStatusTicket = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_STATUS_TICKET, {
        refetchQueries: [GQL_QUERY_TICKETS],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
export const useMutationAddCommentTicket = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_COMMENT_TICKET, {
        refetchQueries: [GQL_QUERY_TICKETS],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationDeleteTicket = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_DELETE_TICKET, {
        refetchQueries: [GQL_QUERY_TICKETS],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useQueryTicketLogs = (id) => {
    const { data: { getTicket: ticket } = {}, ...rest } = useQuery(GQL_QUERY_TICKET_LOGS, {
        variables: { id },
    });
    return { ticket, ...rest };
};
