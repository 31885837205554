import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import MessagesCreatePage from "./create/message-create-page";
import MessageEditPage from "./edit/messasge-edit-page";
import MessageLogPage from "./logs/message-log-page";
import MessageDetailsGeneral from "./message-details-general";
import MessageDetailsInsights from "./message-details-insights";
import MessagesPage from "./messages-page";
import PlatformsPage from "./platforms/platforms-page";

const MessageRoutes = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.url}/`} component={MessagesPage} />
            <Route exact path={`${match.url}/new/`} component={MessagesCreatePage} />
            {/* message details */}
            <Route exact path={`${match.url}/:id/insights/`} component={MessageDetailsInsights} />
            <Route exact path={`${match.url}/:id/general/`} component={MessageDetailsGeneral} />
            <Route exact path={`${match.url}/:id/edit/`} component={MessageEditPage} />
            <Route exact path={`${match.url}/:id/logs/`} component={MessageLogPage} />
            <Route exact path={`${match.url}/:id/platforms/`} component={PlatformsPage} />
        </Switch>
    );
};

MessageRoutes.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default MessageRoutes;
