import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { GQL_ALL_USERS_QUERY } from "../staff/users/gql-users";

export const GQL_BUSINESS_FRAGMENT = gql`
    fragment business on Business {
        id
        name
        shortName
        facebookData {
            selectedPageName
            selectedPageId
        }
        surveyData {
            version
            objective {
                input
                value
            }
            businessSegment {
                input
                value
            }
            hasCompanyName {
                input
                value
            }
            monthlyBudget {
                input
                value
            }
            hasWebsite {
                input
                value
            }
            hasVisualContent {
                input
                value
            }
            hasTargeting {
                input
                value
            }
        }
    }
`;

export const GQL_QUERY_GET_BUSINESS = gql`
    query GetBusiness($id: UUID!) {
        getBusiness(id: $id) {
            ...business
        }
    }
    ${GQL_BUSINESS_FRAGMENT}
`;

export const GQL_MUTATION_UPDATE_BUSINESS = gql`
    mutation UpdateBusiness($id: UUID, $userId: Int, $changes: BusinessChanges!) {
        updateBusiness(id: $id, userId: $userId, changes: $changes) {
            business {
                ...business
            }
        }
    }
    ${GQL_BUSINESS_FRAGMENT}
`;

export const useMutationUpdateBusiness = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_UPDATE_BUSINESS, {
        refetchQueries: [GQL_QUERY_GET_BUSINESS, GQL_ALL_USERS_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useQueryGetBusiness = (id) => {
    const { data: { getMessage } = {}, ...rest } = useQuery(GQL_QUERY_GET_BUSINESS, {
        variables: { id },
        fetchPolicy: "no-cache",
    });
    return { message: getMessage, ...rest };
};
