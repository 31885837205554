import { Button, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationDeleteTicket } from "../tickets/gql-tickets";

const DeleteTicketDialog = ({ hideDialog, isDialogProcessing, ticket }) => {
    const [deleteTicket, { error }] = useMutationDeleteTicket();

    const handleDelete = useCallback(async () => {
        await deleteTicket({ id: ticket.id });
        toast.success("Successfully ticket deleted");
        hideDialog(1, {});
    }, [deleteTicket, ticket.id, hideDialog]);
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while deleting ticket" error={error} />
                <br />
                <>
                    <p>
                        <strong>Are you sure you want to delete this ticket ? </strong>
                    </p>
                </>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing} text="No" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={false}
                        loading={isDialogProcessing}
                        intent={Intent.DANGER}
                        text="Yes, Delete "
                        onClick={() => handleDelete()}
                        rightIcon="cross"
                    />
                </div>
            </div>
        </>
    );
};

DeleteTicketDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    ticket: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

export default {
    title: "Delete Ticket",
    icon: "cross",
    component: DeleteTicketDialog,
};
