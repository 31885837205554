import { Intent, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import FileButton from "../../common/bp-file-button";
import SimpleSelect from "../../common/simple-select";
import { useMutationCreateImage, useUploadAsset } from "../../gql-assets";
import { StyledPropertiesAsset } from "./field-text";

export const StyledImage = styled.img`
    min-width: 100px;
    max-width: 300px;
    min-height: 100px;
    max-height: 300px;
    background: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2) 10px,
        rgba(0, 0, 0, 0.3) 10px,
        rgba(0, 0, 0, 0.3) 20px
    );
`;

const StyledFieldImage = styled.div`
    border-radius: 3px;
    background-color: ${({ theme, $disabled }) => ($disabled ? theme.lightGrayColor : "white")};
    border: 1px solid ${({ theme }) => theme.grayColor};
    padding: 5px;
    & > .actions {
        padding-top: 5px;
    }
`;

const image_roles = [
    {
        value: "default",
        label: "Default",
    },
    {
        value: "video_thumbnail",
        label: "Video Thumbnail",
    },
    {
        value: "logo",
        label: "Logo",
    },
    {
        value: "landscape_logo",
        label: "Landscape Logo",
    },
];
const image_types = [
    {
        value: "image",
        label: "Image",
    },
];

const FieldImage = ({ index, value, disabled, onChange }) => {
    const [progress, setProgress] = useState(null);
    const [uploadAsset] = useUploadAsset();
    const [createImage] = useMutationCreateImage();
    const handleSetImage = async (file) => {
        try {
            setProgress(0);
            const assetUrl = await uploadAsset(file, setProgress);
            const createdImageResult = await createImage({
                title: file.name,
                url: assetUrl,
            });
            onChange(createdImageResult.data.createImage.image);
        } finally {
            setProgress(null);
        }
    };
    if (!value) {
        return (
            <StyledFieldImage>
                <p>
                    <b>NEW IMAGE</b>
                </p>
                <StyledImage src={null} />
                <div className="actions">
                    {!disabled && (
                        <FileButton
                            inputId={"field-image-set-image-" + index}
                            text="Set image..."
                            disabled={disabled}
                            onFileUpload={handleSetImage}
                            progress={progress}
                            icon="media"
                            rightIcon="folder-open"
                            title="Select an image an upload it"
                        />
                    )}
                </div>
            </StyledFieldImage>
        );
    }
    return (
        <StyledFieldImage>
            <p>
                <b>IMAGE {index}</b>
            </p>
            <StyledImage src={value.imageUrl} />
            <div className="actions">
                {!disabled && (
                    <FileButton
                        inputId={"field-image-set-image-" + index}
                        text="Change image..."
                        disabled={disabled}
                        onFileUpload={handleSetImage}
                        progress={progress}
                        icon="folder-open"
                        title="Select an image an upload it"
                    />
                )}
                <StyledPropertiesAsset>
                    {!disabled && (
                        <div>
                            <Tag minimal active intent={Intent.SUCCESS}>
                                Type :{" "}
                            </Tag>
                            <SimpleSelect
                                disabled={disabled}
                                options={image_types}
                                onSelect={(v) => onChange({ ...value, type: v })}
                                selected={value.type}
                            />
                        </div>
                    )}
                    {!disabled && (
                        <div>
                            <Tag minimal active intent={Intent.PRIMARY}>
                                Role :{" "}
                            </Tag>
                            <SimpleSelect
                                disabled={disabled}
                                options={image_roles}
                                onSelect={(v) => onChange({ ...value, role: v })}
                                selected={value.role}
                            />
                        </div>
                    )}
                </StyledPropertiesAsset>
            </div>
        </StyledFieldImage>
    );
};

FieldImage.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        role: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

FieldImage.defaultProps = {
    value: null,
    disabled: false,
};

export default FieldImage;
