import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

export const GQL_QUERY_ALL_EMAIL_TEMPLATE = gql`
    query getAllEmailTemplates {
        getAllEmailTemplates {
            value
            label
        }
    }
`;

export const GQL_MUTATION_SEND_EMAIL = gql`
    mutation sendTestEmail($templateName: String!, $lang: String!) {
        sendTestEmail(templateName: $templateName, lang: $lang) {
            ok
        }
    }
`;

export const GQL_MUTATION_SEND_AI_GENERATED_EMAIL = gql`
    mutation sendAiGeneratedEmail($message: String!, $subject: String!, $body: String!, $type: String!) {
        sendAiGeneratedEmail(message: $message, subject: $subject, body: $body, type: $type) {
            ok
        }
    }
`;

export const useAllEmailTemplates = () => {
    const { data, ...rest } = useQuery(GQL_QUERY_ALL_EMAIL_TEMPLATE);
    return { templates: data?.getAllEmailTemplates, ...rest };
};

export const useMutationSendEmail = () => {
    const [actionPromise, info] = useMutation(GQL_MUTATION_SEND_EMAIL);
    const func = useCallback((variables) => actionPromise({ variables }), [actionPromise]);
    return [func, info];
};

export const useMutationSendAiGeneratedEmail = () => {
    const [actionPromise, info] = useMutation(GQL_MUTATION_SEND_AI_GENERATED_EMAIL);
    const func = useCallback((variables) => actionPromise({ variables }), [actionPromise]);
    return [func, info];
};
