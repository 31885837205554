import { Button, Classes, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { Tooltip2 } from "@blueprintjs/popover2";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { PREFIX_REGEX } from "../../common/coupon";
import { DialogContext } from "../bp-dialog-context";
import SimpleSelect from "../common/simple-select";
import { useMutationUpdateGeoTargeting } from "./gql-coupons";

function getMomentFormatter(format) {
    return {
        formatDate: (date) => moment(date).format(format),
        parseDate: (str) => moment(str, format, true).toDate(),
        placeholder: format,
    };
}

const StyledGeneratedCoupon = styled.div`
    cursor: pointer;
    padding: 20px;
`;

const displayError = (error) => {
    var result = "";
    if (Array.isArray(error)) {
        result = error.map((e) => e.message).join(" - ");
    } else {
        result = error.message;
    }
    return result;
};

const GeneratedCoupon = ({ createdCoupon, error }) => {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        setCopied(false);
    }, [createdCoupon]);

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(createdCoupon ? createdCoupon.coupon.code.toUpperCase() : "");
        setCopied(true);
    }, [createdCoupon, setCopied]);
    return (
        <StyledGeneratedCoupon>
            {error && <p>{displayError(error)}</p>}
            {!error && (
                <>
                    <FormGroup label="Your code">
                        <InputGroup
                            className={Classes.MONOSPACE_TEXT}
                            value={createdCoupon ? createdCoupon.coupon.code.toUpperCase() : ""}
                            onChange={() => {}}
                            rightElement={
                                <Tooltip2 content="Copy coupon to pasteboard">
                                    <Button
                                        minimal
                                        icon="clipboard"
                                        intent={Intent.PRIMARY}
                                        onClick={handleCopy}
                                        text="copy"
                                    />
                                </Tooltip2>
                            }
                        />
                    </FormGroup>
                    <p>{copied ? "The code has been copied in your clipboard" : <span>&nbsp;</span>}</p>
                </>
            )}
        </StyledGeneratedCoupon>
    );
};
GeneratedCoupon.propTypes = {
    createdCoupon: PropTypes.shape({
        coupon: PropTypes.shape({ amount: PropTypes.number, code: PropTypes.string }),
    }),
    error: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
        PropTypes.shape({ message: PropTypes.string }),
    ]),
};

GeneratedCoupon.defaultProps = {
    createdCoupon: null,
    error: null,
};

const StyledCouponCreate = styled.div`
    margin: 20px 0px;
    .button-bar {
        padding-bottom: 10px;
        text-align: center;
    }
`;
const CouponCreate = () => {
    const { showDialog } = useContext(DialogContext);
    const [prefix, setPrefix] = React.useState("ampl");
    const [amount, setAmount] = React.useState(50);
    const [validPrefix, setValidPrefix] = React.useState(false);
    const [validUntil, setValidUntil] = React.useState(moment().add(moment.duration(1, "years")).toDate());
    const [couponType, setCouponType] = React.useState("PROMO");
    const [generateCoupon] = useMutationUpdateGeoTargeting();

    const generateCouponCallback = useCallback(
        async (theAmount, thePefix, theValidUntil, theCouponType) => {
            try {
                const {
                    data: { createCoupon: createdCoupon },
                    error,
                } = await generateCoupon({
                    amount: theAmount,
                    prefix: thePefix,
                    validUntil: theValidUntil,
                    couponType: theCouponType,
                });

                showDialog(
                    {
                        title: "Coupon creation result",
                        icon: "barcode",
                        component: GeneratedCoupon,
                    },
                    { createdCoupon, error }
                );
            } catch (e) {
                showDialog(
                    {
                        title: "Error while generating coupon",
                        icon: "barcode",
                        component: GeneratedCoupon,
                    },
                    { error: e.graphQLErrors }
                );
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        []
    );

    useEffect(() => {
        setValidPrefix(prefix.length === 4 && prefix.match(PREFIX_REGEX));
    }, [prefix]);

    return (
        <StyledCouponCreate>
            <FormGroup
                label="Prefix"
                labelFor="coupon-prefix-input"
                intent={Intent.DANGER}
                helperText={validPrefix ? "" : "Invalid prefix. Must be 4 chars (I,O,0 and 1 are not allowed)"}
            >
                <InputGroup
                    id="coupon-prefix-input"
                    large
                    value={prefix}
                    onChange={(event) => setPrefix(event.target.value)}
                />
            </FormGroup>
            <FormGroup label="Amount" labelFor="coupon-amount-input">
                <InputGroup
                    id="coupon-amount-input"
                    large
                    type="number"
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                />
            </FormGroup>
            <FormGroup label="Valid until" labelFor="coupon-date-input">
                <DateInput
                    large
                    locale="fr"
                    value={validUntil}
                    maxDate={moment("2100-01-01").toDate()}
                    onChange={(selectedDate) => setValidUntil(selectedDate)}
                    {...getMomentFormatter("DD/MM/YYYY")}
                />
            </FormGroup>
            <FormGroup label="Type" labelFor="coupon-type-input">
                <SimpleSelect
                    large
                    outlined
                    id="coupon-type-input"
                    options={[
                        { value: "PROMO", label: "PROMO" },
                        { value: "PREPAID", label: "PREPAID" },
                    ]}
                    selected={couponType}
                    onSelect={(value) => setCouponType(value)}
                />
            </FormGroup>
            <div className="button-bar">
                <Button
                    fill
                    large
                    disabled={!validPrefix}
                    intent={Intent.PRIMARY}
                    text="Generate Coupon"
                    onClick={() => {
                        generateCouponCallback(amount * 100, prefix, validUntil, couponType);
                    }}
                />
            </div>
        </StyledCouponCreate>
    );
};

export default CouponCreate;
