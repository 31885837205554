import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

export const EMPTY = "__empty__";
export const ANY = "__any__";

/**
 * filter specification ( filterSpec )
 * {
 *     [KEY]: {
 *         type: (String|function) "in" | "compare" | "search" | "range" | function (val) => graphQl input param
 *         parse: (function) parse from url query
 *         format: (function) format to url query
 *         build: (function) build query for graphql input parameter
 *         urlKey: (String) key used in url parameter
 *         buildKey: (String) key used for graphql call
 *     }
 * }
 */

export const buildQueryFilters = (newFilters, filterSpecs) => {
    const result = {};
    for (const [k, v] of Object.entries(newFilters)) {
        const filterSpec = filterSpecs[k] || {};
        const realKey = filterSpec.buildKey || k;
        if (filterSpec.type === "search") {
            if (v === EMPTY) {
                result[realKey] = { isNull: true };
            } else if (v === ANY) {
                result[realKey] = { isNull: false };
            } else {
                result[realKey] = { search: v };
            }
        } else if (filterSpec.type === "equal") {
            result[realKey] = { eq: v };
        } else if (filterSpec.type === "in") {
            if (v && v.includes(EMPTY)) {
                result[realKey] = { isNull: true };
            } else if (v && v.includes(ANY)) {
                result[realKey] = { isNull: false };
            } else {
                result[realKey] = { in: v };
            }
        } else if (filterSpec.type === "range") {
            result[realKey] = { gte: v[0], lte: v[1] };
        } else if (filterSpec.type === "bool") {
            result[realKey] = { is: v };
        } else if (filterSpec.type === "onoff") {
            if (v) {
                result[realKey] = filterSpec.queryPayload || {};
            }
        } else if (typeof filterSpec.type === "function") {
            try {
                result[realKey] = filterSpec.type(v);
            } catch (ex) {
                // wrong content
            }
        } else {
            result[realKey] = v;
        }
    }
    return result;
};

export const parseQuery = (search, filterSpecs) => {
    const result = {};
    const searchParams = new URLSearchParams(search);
    for (const [k, filterSpec] of Object.entries(filterSpecs)) {
        const key = filterSpec.urlKey || k;
        if (searchParams.has(key)) {
            const rawValue = ["in"].includes(filterSpec.type) ? searchParams.getAll(key) : searchParams.get(key);
            let value = rawValue;
            if (filterSpec.parse) {
                if (Array.isArray(rawValue)) {
                    value = rawValue.map((v) => filterSpec.parse(v));
                } else {
                    value = filterSpec.parse(rawValue);
                }
            }
            result[key] = value;
        }
    }
    return result;
};

export const setUrlFilters = (history, filters, filterSpecs) => {
    const searchParams = new URLSearchParams(history.location.search);
    for (const [k, filterSpec] of Object.entries(filterSpecs)) {
        const key = filterSpec.urlKey || k;
        searchParams.delete(key); // remove all keys
        if (key in filters && filters[key] !== null) {
            const value = filters[key];
            const transFunc = filterSpec.format || ((x) => x);
            if (["in"].includes(filterSpec.type)) {
                value.forEach((v) => searchParams.append(key, transFunc(v)));
            } else if ("onoff" === filterSpec.type) {
                if (transFunc(value)) {
                    searchParams.set(key, "true");
                }
            } else {
                const urlVal = transFunc(value);
                if (urlVal !== null && urlVal !== "") {
                    searchParams.set(key, transFunc(value));
                }
            }
        }
    }
    history.push({
        pathname: history.location.pathname,
        search: searchParams.toString(),
    });
};

export const useUrlFilters = (filterSpecs = {}) => {
    const history = useHistory();
    const filters = useMemo(() => {
        return parseQuery(history.location.search, filterSpecs);
    }, [filterSpecs, history.location.search]);
    const setFilters = useCallback(
        (newFilters) => {
            let filtersToSet = newFilters;
            if (typeof newFilters === "function") {
                filtersToSet = newFilters(filters);
            }
            setUrlFilters(history, filtersToSet, filterSpecs);
        },
        [filters, history, filterSpecs]
    );
    return [filters, setFilters];
};
