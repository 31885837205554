import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

import MessageReviewListPage from "./message-review-list-page";
import MessageReviewPage from "./message-review-page";

const ValidationPage = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.url}/`} component={MessageReviewListPage} />
            <Route exact path={`${match.url}/:id`} component={MessageReviewPage} />
        </Switch>
    );
};

ValidationPage.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default ValidationPage;
