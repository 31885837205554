import { Button, Classes, FormGroup, Intent, TextArea } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { useRejectChanges, useRejectMessage } from "./gql-validation";
import ReviewNew, { DEFAULT_DATA, asGraphqlParameters } from "./new-review-form";

const ModalReject = ({ hideDialog, setIsDialogProcessing, isDialogProcessing, message }) => {
    const [data, setData] = useState(DEFAULT_DATA);
    const [rejectTextValue, setRejectTextValue] = useState("");
    const rejectMessage = useRejectMessage();
    const rejectChanges = useRejectChanges();

    const canReject = !!rejectTextValue && (message.isNew ? !!data.topic : true);

    const handleReject = async () => {
        setIsDialogProcessing(true);
        try {
            if (message.isNew) {
                await rejectMessage({
                    id: message.id,
                    rejectText: rejectTextValue,
                    ...asGraphqlParameters(data),
                });
            } else {
                await rejectChanges({
                    id: message.id,
                    rejectText: rejectTextValue,
                });
            }
            toast.warning("Message review rejected!");
            hideDialog(1);
        } catch (error) {
            toast.error(`Error ${error.message}`);
            hideDialog(-1);
        } finally {
            setIsDialogProcessing(true);
        }
    };
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                {message.isNew && <ReviewNew data={data} setData={setData} message={message} />}
                <FormGroup label="Rejection explanations" labelInfo="(required)">
                    <TextArea
                        fill
                        value={rejectTextValue}
                        onChange={(e) => setRejectTextValue(e.currentTarget.value)}
                    />
                </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        loading={isDialogProcessing}
                        disabled={isDialogProcessing || !canReject}
                        intent={Intent.DANGER}
                        text="Reject"
                        onClick={handleReject}
                    />
                </div>
            </div>
        </>
    );
};

ModalReject.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        isNew: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default {
    title: "Reject review",
    icon: "ban-circle",
    component: ModalReject,
};
