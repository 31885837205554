import { Icon, Intent, Menu, MenuDivider, MenuItem, Text } from "@blueprintjs/core";
import { ColumnHeaderCell } from "@blueprintjs/table";
import React from "react";
import styled from "styled-components";

const StyledHeaderCell = styled.div`
    display: flex;
    align-items: center;
    .col-title {
        font-weight: bold;
    }
    .bp4-intent-primary {
        color: var(--blueprint-color-primary);
    }
`;

/***
 * @typedef {{order:(-1|1),key:string}} Ordering
 *
 * @param name Name of the column
 * @param sortOptions Sorting options, List of Object({
 *     label: Label of the sorting
 *     sortKey: sorting key ( both URL and graphql)
 * })
 * @param sortKey (deprecated) same as sortOptions: [{ sortKey : <KEY>}]
 * @param {Ordering} ordering current ordering
 * @param {function(?Ordering):{}} onChangeSort function when ordering change
 * @param icon Column icon
 * @returns {function()} usable in blueprintjs <Column columnHeaderCellRenderer={THIS_RESULT} />
 */
export const sortableColumnRenderer = ({ name, sortOptions, sortKey, ordering = {}, onChangeSort, icon }) => {
    if (sortKey && !sortOptions) {
        sortOptions = [{ sortKey }];
    }

    const { order = 0, key = null } = ordering || {};

    const menuRenderer = sortOptions
        ? () => (
              <Menu>
                  {sortOptions.map((opt, idx) => {
                      const sortAscSelected = order === 1 && opt.sortKey === key;
                      const sortDescSelected = order === -1 && opt.sortKey === key;
                      return (
                          <React.Fragment key={idx}>
                              {opt.label && <MenuDivider title={opt.label} />}
                              <MenuItem
                                  intent={sortAscSelected ? Intent.PRIMARY : Intent.NONE}
                                  icon="sort-asc"
                                  onClick={
                                      sortAscSelected
                                          ? () => onChangeSort(null)
                                          : () =>
                                                onChangeSort({
                                                    order: 1,
                                                    key: opt.sortKey,
                                                })
                                  }
                                  text="Sort Asc"
                              />
                              <MenuItem
                                  intent={sortDescSelected ? Intent.PRIMARY : Intent.NONE}
                                  icon="sort-desc"
                                  onClick={
                                      sortDescSelected
                                          ? () => onChangeSort(null)
                                          : () =>
                                                onChangeSort({
                                                    order: -1,
                                                    key: opt.sortKey,
                                                })
                                  }
                                  text="Sort Desc"
                              />
                          </React.Fragment>
                      );
                  })}
              </Menu>
          )
        : null;

    let sortIcon = null;
    let sorting = false;

    for (let opt of sortOptions || []) {
        if (key === opt.sortKey) {
            sorting = true;
            if (order === -1) {
                sortIcon = <Icon color="gray" icon="sort-desc" size={14} />;
            } else if (order === 1) {
                sortIcon = <Icon color="gray" icon="sort-asc" size={14} />;
            }
        }
    }
    return () => (
        <ColumnHeaderCell
            name={name}
            nameRenderer={() => (
                <StyledHeaderCell>
                    {icon && (
                        <>
                            <Icon icon={icon} intent={sorting ? Intent.PRIMARY : Intent.NONE} />
                            &nbsp;
                        </>
                    )}
                    {name && (
                        <>
                            <Text className={`col-title ${sorting ? "bp4-intent-primary" : ""}`} ellipsize>
                                {name}
                            </Text>
                            &nbsp;
                        </>
                    )}
                    {sortIcon}
                </StyledHeaderCell>
            )}
            menuRenderer={menuRenderer}
        />
    );
};

export const ORDER_BY_SPECS = {
    buildKey: "orderBy",
    parse: (v) => {
        if (v && v.startsWith("-")) {
            return {
                order: -1,
                key: v.substring(1),
            };
        }
        if (v) {
            return {
                order: 1,
                key: v,
            };
        }
        return null;
    },
    format: ({ order = 0, key }) => `${order > 0 ? "" : "-"}${key}`,
    type: ({ order = 0, key }) => `${order > 0 ? "" : "-"}${key}`,
};
