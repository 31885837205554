import { Alignment, Callout, Icon, Intent, Navbar, Spinner, Tab, Tabs } from "@blueprintjs/core";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import { RiAdvertisementLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import {
    Bar,
    BarChart,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    LineChart,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import styled from "styled-components";

import { formatCurrency } from "../../common/i18n";
import { formatPercent } from "../../common/utils";
import NavBreadcrumb from "../common/nav-breadcrumb";
import { getPlatformIcon } from "../platforms";
import { useQueryMessageMetrics } from "./gql-messages";
import NavigationMenu from "./message-menu";

const DELTA_KEYS = ["impressions", "uniqueClickCount", "clickCount", "reach", "conversion", "spent"];
const PLATFORM_COLORS = {
    facebook: {
        fill: "#4b6ab2DD",
        stroke: "#4b6ab2",
    },
    instagram: {
        fill: "#9d6d9cDD",
        stroke: "#9d6d9c",
    },
    twitter: {
        fill: "#58b8aeDD",
        stroke: "#58b8ae",
    },
    linkedin: {
        fill: "#6aae6aDD",
        stroke: "#6aae6a",
    },
    google_ads_display: {
        fill: "#d4ad5aDD",
        stroke: "#d4ad5a",
    },
    google_ads_search: {
        fill: "#d17257DD",
        stroke: "#d17257",
    },
};

const TOOLTIP_BAR_COLOR = "#99999955";

const StyledTooltip = styled.div`
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
    .date {
        font-weight: bold;
    }
`;

const StyledGraphTitle = styled.div`
    font-weight: bold;
    text-align: center;
    margin: 15px 0 5px 0;
`;
const formatValue = ({ value, unit, currency }) => {
    if (unit === "currency") {
        return formatCurrency(value, currency);
    }
    if (unit === "percent") {
        return formatPercent(value);
    }
    return value;
};

const getMarkerAt = (markers, unixtime) => {
    if (!markers) {
        return null;
    }
    return markers.find((m) => m.unixtime === unixtime);
};

const CustomTooltip = ({ active, payload, label, markers, ...props }) => {
    if (active && payload && payload.length) {
        const date = moment.unix(label);
        const marker = getMarkerAt(markers, label);
        let displayList = payload;
        if (marker) {
            displayList = [...payload, marker];
        }
        return (
            <StyledTooltip>
                <div className="date">{`${date.format("LL")} [${date.format("dddd")}]`}</div>
                {displayList.map((p) => (
                    <div key={p.name}>{`${p.name} : ${formatValue({ value: p.value, unit: p.unit, ...props })}`}</div>
                ))}
            </StyledTooltip>
        );
    }

    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.bool.isRequired,
    payload: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    label: PropTypes.number.isRequired,
    markers: PropTypes.arrayOf(PropTypes.shape({})),
};

CustomTooltip.defaultProps = {
    markers: null,
};

const computePlatformData = (data, platform) => {
    let previous = {};
    return data
        .filter((m) => m.platform === platform)
        .map((m) => {
            // replace key by delta
            const result = {
                ...m,
                leftBudget: m.adBudget - m.spent,
                totalBudget: m.adBudget,
                totalSpent: m.spent,
                unixtime: moment(m.date).unix(),
            };
            DELTA_KEYS.forEach((k) => {
                result[k] = result[k] - (previous[k] || 0);
            });

            result.cpc = result.uniqueClickCount ? result.spent / 100.0 / result.uniqueClickCount : null;
            result.cpm = result.impressions ? (10.0 * result.spent) / result.impressions : null;
            result.ctr = result.impressions ? result.uniqueClickCount / result.impressions : null;

            previous = m;
            return result;
        });
};

const getPlatformColorProps = (platform) => {
    return (
        PLATFORM_COLORS[platform] || {
            fill: "red",
            stroke: "blue",
        }
    );
};

const buildTimeXAxis = ({ data }) => (
    <XAxis
        ticks={data ? data.map((d) => d.unixtime) : null}
        allowDecimals={false}
        allowDataOverflow
        dataKey="unixtime"
        domain={[(min) => min - 43200, (max) => max + 43200]}
        name="Date"
        tickFormatter={(unixtime) => moment.unix(unixtime).format("L")}
        type="number"
        tick={{ fontSize: 10 }}
    />
);

const buildZoomArea = ({ left, right }) =>
    left && right ? <ReferenceArea x1={left} x2={right} strokeOpacity={0.1} fill="#00FF0033" /> : null;

const EMPTY_AREA = { left: null, right: null };

const useZoomHandler = () => {
    const [refArea, setRefArea] = useState(EMPTY_AREA);

    const graphProps = useMemo(
        () => ({
            onMouseDown: (e) => e && setRefArea({ left: e.activeLabel, right: null }),
            onMouseMove: (e) => e && setRefArea(({ left }) => (left ? { left, right: e.activeLabel } : EMPTY_AREA)),
            onMouseUp: () => setRefArea(EMPTY_AREA),
        }),
        [setRefArea]
    );
    return [refArea, graphProps];
};

const PlatformMetricsGraph = ({ platformData, currency }) => {
    const displayedData = platformData;
    const syncId = `graph-platform`;
    return (
        <div>
            <StyledGraphTitle>Budget</StyledGraphTitle>
            <ResponsiveContainer width="100%" height={200}>
                <ComposedChart data={displayedData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} syncId={syncId}>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <YAxis yAxisId="left" type="number" tickFormatter={(v) => (v / 100.0).toFixed(2)} />
                    <YAxis yAxisId="money" orientation="right" tickFormatter={(v) => (v / 100.0).toFixed(2)} />

                    <Bar
                        yAxisId="left"
                        type="monotone"
                        dataKey="totalSpent"
                        fill="#48819F"
                        unit="currency"
                        stackId="budget"
                        name="Spent"
                    />
                    <Bar
                        yAxisId="left"
                        type="monotone"
                        dataKey="leftBudget"
                        fill="#BFC965"
                        unit="currency"
                        stackId="budget"
                        name="Left"
                    />
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="spent"
                        stroke="#d17257"
                        fill="#d17257AA"
                        name="Daily Spent"
                        unit="currency"
                        yAxisId="money"
                    />

                    {buildTimeXAxis({ data: displayedData })}

                    <Tooltip content={<CustomTooltip currency={currency} />} cursor={{ fill: TOOLTIP_BAR_COLOR }} />
                </ComposedChart>
            </ResponsiveContainer>

            <StyledGraphTitle>Impressions</StyledGraphTitle>
            <ResponsiveContainer width="100%" height={150}>
                <ComposedChart data={displayedData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} syncId={syncId}>
                    <YAxis />
                    <YAxis yAxisId="money" orientation="right" tickFormatter={(v) => (v / 100.0).toFixed(2)} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                    <Bar
                        isAnimationActive={false}
                        dataKey="impressions"
                        stroke="#63411E"
                        fill="#A38364DD"
                        name="Impressions"
                    />
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="cpm"
                        stroke="#d17257"
                        fill="#d17257AA"
                        name="Daily CPM"
                        unit="currency"
                        yAxisId="money"
                    />

                    {buildTimeXAxis({ data: displayedData })}
                    <Tooltip content={<CustomTooltip />} cursor={{ fill: TOOLTIP_BAR_COLOR }} />
                </ComposedChart>
            </ResponsiveContainer>

            <StyledGraphTitle>Unique click count</StyledGraphTitle>
            <ResponsiveContainer width="100%" height={150}>
                <ComposedChart data={displayedData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} syncId={syncId}>
                    <YAxis />
                    <YAxis yAxisId="money" orientation="right" tickFormatter={(v) => (v / 100.0).toFixed(2)} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                    <Bar
                        type="monotone"
                        dataKey="uniqueClickCount"
                        stroke="#5C255C"
                        fill="#9D6D9CAA"
                        name="Unique clicks"
                    />
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="cpc"
                        stroke="#d17257"
                        fill="#d17257AA"
                        name="Daily CPC"
                        unit="currency"
                        yAxisId="money"
                    />

                    {buildTimeXAxis({ data: displayedData })}
                    <Tooltip content={<CustomTooltip />} cursor={{ fill: TOOLTIP_BAR_COLOR }} />
                </ComposedChart>
            </ResponsiveContainer>

            <StyledGraphTitle>Conversion</StyledGraphTitle>
            <ResponsiveContainer width="100%" height={150}>
                <ComposedChart data={displayedData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} syncId={syncId}>
                    <YAxis />
                    <YAxis yAxisId="percent" orientation="right" tickFormatter={(v) => formatPercent(v)} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                    <Bar
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="conversion"
                        stroke="#728c23"
                        fill="#ADC16DAA"
                        name="Conversions"
                    />
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="ctr"
                        stroke="#6f8acb"
                        fill="#6f8acbAA"
                        name="Daily CTR"
                        unit="percent"
                        yAxisId="percent"
                    />
                    {buildTimeXAxis({ data: displayedData })}
                    <Tooltip content={<CustomTooltip />} cursor={{ fill: TOOLTIP_BAR_COLOR }} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

PlatformMetricsGraph.propTypes = {
    platformData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    currency: PropTypes.string.isRequired,
};

const BudgetBreakdownsGraph = ({ budgetBreakdowns, allPlatforms, currency, markers }) => {
    const [areaRef, graphProps] = useZoomHandler();

    return (
        <ResponsiveContainer width="100%" height={250}>
            <BarChart
                data={budgetBreakdowns}
                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                syncId="global"
                {...graphProps}
            >
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                <YAxis type="number" tickFormatter={(v) => (v / 100.0).toFixed(2)} />

                {allPlatforms.map((p) => (
                    <Bar
                        isAnimationActive={false}
                        stackId="budget"
                        key={p}
                        name={p}
                        dataKey={p}
                        unit="currency"
                        {...getPlatformColorProps(p)}
                    />
                ))}
                {markers &&
                    markers.map((m) => (
                        <ReferenceLine
                            isFront
                            x={m.unixtime}
                            stroke="blue"
                            strokeDasharray="3 3"
                            label={<Label position="top" value={m.label} />}
                        />
                    ))}
                {buildTimeXAxis({ data: budgetBreakdowns })}

                <Tooltip
                    content={<CustomTooltip currency={currency} markers={markers} />}
                    cursor={{ fill: TOOLTIP_BAR_COLOR }}
                />
                <Legend verticalAlign="top" height={36} />
                {buildZoomArea(areaRef)}
            </BarChart>
        </ResponsiveContainer>
    );
};

BudgetBreakdownsGraph.propTypes = {
    budgetBreakdowns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    allPlatforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    currency: PropTypes.string.isRequired,
    markers: PropTypes.arrayOf(PropTypes.shape({})),
};

BudgetBreakdownsGraph.defaultProps = {
    markers: null,
};

const renderChangeMarker = ({ viewBox: { x, y } }) => {
    const d = 12;
    const r = d / 2;

    const transform = `translate(${x - r} ${y - d - 1})`;

    return (
        <g transform={transform}>
            <FaArrowDown size={d} />
        </g>
    );
};

const CtrCompareGraph = ({ ctrCompare, allPlatforms, markers }) => {
    return (
        <ResponsiveContainer width="100%" height={250}>
            <LineChart data={ctrCompare} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} syncId="global">
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                <YAxis type="number" tickFormatter={(v) => formatPercent(v)} />

                {allPlatforms.map((p) => (
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        key={p}
                        name={p}
                        dataKey={p}
                        unit="percent"
                        {...getPlatformColorProps(p)}
                    />
                ))}
                {markers &&
                    markers.map((m) => (
                        <ReferenceLine
                            x={m.unixtime}
                            stroke="blue"
                            strokeDasharray="3 1"
                            label={<Label position="insideTop" content={renderChangeMarker} />}
                        />
                    ))}

                {buildTimeXAxis({ data: ctrCompare })}

                <Tooltip content={<CustomTooltip markers={markers} />} cursor={{ fill: TOOLTIP_BAR_COLOR }} />
                <Legend verticalAlign="top" wrapperStyle={{ textAlign: "right" }} height={36} />
            </LineChart>
        </ResponsiveContainer>
    );
};

CtrCompareGraph.propTypes = {
    ctrCompare: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    allPlatforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    markers: PropTypes.arrayOf(PropTypes.shape({})),
};

CtrCompareGraph.defaultProps = {
    markers: null,
};

const MessageInsights = ({ message }) => {
    const [currentTab, setCurrentTab] = useState(null);
    const { metrics, currency, revisions, backers } = message;

    // add unixtime for graph on each metrics ( display + x axix )
    const data = useMemo(
        () =>
            metrics.map((m) => {
                return {
                    ...m,
                    unixtime: moment(m.date).unix(),
                };
            }),
        [metrics]
    );
    const allRevisions = useMemo(
        () =>
            (revisions || []).map((r) => {
                return {
                    changes: r.changes,
                    label: `Rev-ID: ${r.id}`,
                    name: "Rev ID",
                    value: r.id,
                    unixtime: moment(r.lastStateChangedAt).startOf("day").unix(),
                };
            }),
        [revisions]
    );

    const allPayments = useMemo(
        () =>
            (backers || []).map((p) => {
                return {
                    amount: p.amount,
                    label: formatCurrency(p.amount, currency),
                    name: "Add money",
                    value: formatCurrency(p.amount, currency),
                    unixtime: moment(p.insertedAt).startOf("day").unix(),
                };
            }),
        [backers, currency]
    );

    const [allPlatforms, budgetBreakdowns, ctrCompare] = useMemo(() => {
        const platforms = [];
        const breakdowns = Object.values(
            data.reduce((acc, m) => {
                const byPlatform = acc[m.unixtime] || { unixtime: m.unixtime };
                byPlatform[m.platform] = m.adBudget;
                if (platforms.indexOf(m.platform) === -1) {
                    platforms.push(m.platform);
                }
                acc[m.unixtime] = byPlatform;
                return acc;
            }, {})
        );
        const previousByPlatform = {};
        const ctrCompare = Object.values(
            data.reduce((acc, m) => {
                const byPlatform = acc[m.unixtime] || { unixtime: m.unixtime };
                const previous = previousByPlatform[m.platform] || {
                    impressions: 0,
                    uniqueClickCount: 0,
                };
                const dayImpression = m.impressions - previous.impressions;
                const dayUniqueClickCount = m.uniqueClickCount - previous.uniqueClickCount;
                const ctr = dayImpression && dayUniqueClickCount ? dayUniqueClickCount / dayImpression : null;
                previousByPlatform[m.platform] = m;
                byPlatform[m.platform] = ctr;
                acc[m.unixtime] = byPlatform;
                return acc;
            }, {})
        );
        return [platforms, breakdowns, ctrCompare];
    }, [data]);

    useEffect(() => {
        setCurrentTab((old) => {
            if (allPlatforms && allPlatforms.length > 0 && allPlatforms.indexOf(old) === -1) {
                return allPlatforms[0];
            }
            return old;
        });
    }, [allPlatforms]);

    const byPlatformData = useMemo(() => {
        return allPlatforms.reduce((acc, p) => {
            acc[p] = computePlatformData(data, p);
            return acc;
        }, {});
    }, [data, allPlatforms]);

    return (
        <div>
            <NavBreadcrumb
                items={[
                    { icon: "home", to: "/" },
                    { text: "Messages", icon: <RiAdvertisementLine />, to: "/messages" },
                    {
                        text: message.id,
                    },
                    {
                        text: "Insights",
                        current: true,
                        icon: "timeline-bar-chart",
                    },
                ]}
                currentBreadcrumbRenderer={({ text, icon }) => (
                    <NavigationMenu message={message} text={text} icon={icon} />
                )}
            />
            <StyledGraphTitle>Budget breakdown</StyledGraphTitle>

            <BudgetBreakdownsGraph
                budgetBreakdowns={budgetBreakdowns}
                allPlatforms={allPlatforms}
                currency={currency}
                markers={allPayments}
            />
            <StyledGraphTitle>CTR comparison</StyledGraphTitle>
            <CtrCompareGraph ctrCompare={ctrCompare} allPlatforms={allPlatforms} markers={allRevisions} />

            <Navbar>
                <Navbar.Group>
                    <Navbar.Heading className="flex-center">
                        <Icon icon={getPlatformIcon(currentTab)} />
                        &nbsp;
                        <strong className="upper-title">{currentTab}</strong>
                        &nbsp;insights
                    </Navbar.Heading>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    {/* controlled mode & no panels (see h1 below): */}
                    <Tabs
                        animate
                        id="platform-tabs"
                        large
                        onChange={(v) => setCurrentTab(v)}
                        selectedTabId={currentTab}
                    >
                        {Object.entries(byPlatformData).map(([k, v]) => (
                            <Tab id={k} key={k} title={k} />
                        ))}
                    </Tabs>
                </Navbar.Group>
            </Navbar>
            {currentTab && (
                <PlatformMetricsGraph
                    platform={currentTab}
                    platformData={byPlatformData[currentTab]}
                    currency={currency}
                />
            )}
        </div>
    );
};

MessageInsights.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        metrics: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.string,
            })
        ).isRequired,
        backers: PropTypes.arrayOf(PropTypes.shape({})),
        revisions: PropTypes.arrayOf(PropTypes.shape({})),
        currency: PropTypes.string.isRequired,
    }).isRequired,
};
const StyledMessageInsightsPage = styled.div`
    padding: 10px;
    background-color: white;
    .upper-title {
        text-transform: capitalize;
    }
    .flex-center {
        display: flex;
        align-items: center;
    }
`;

const MessageDetailsInsights = () => {
    const { id } = useParams();
    const { message, loading, error } = useQueryMessageMetrics(id);
    return (
        <StyledMessageInsightsPage>
            {loading && (
                <Callout title="Loading metrics, please wait...">
                    <Spinner />
                </Callout>
            )}
            {error && (
                <Callout intent={Intent.DANGER} title="Error while loading metrics">
                    {error.message}
                </Callout>
            )}

            {message ? (
                <MessageInsights message={message} />
            ) : (
                !loading &&
                !error && (
                    <Callout intent={Intent.DANGER} title="Nothing found here">
                        {id}
                    </Callout>
                )
            )}
        </StyledMessageInsightsPage>
    );
};

export default MessageDetailsInsights;
