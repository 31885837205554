import { Dialog, Intent, Spinner } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

const StyledProcessing = styled(({ className }) => (
    <div className={className}>
        <Spinner intent={Intent.PRIMARY} size={100} />
    </div>
))`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const DialogContext = React.createContext({
    currentDialog: null,
    showDialog: async () => {},
    hideDialog: async () => {},
});

const Modal = ({ currentDialog, hideDialog, setIsDialogProcessing, isDialogProcessing }) => {
    const content = useMemo(
        () =>
            React.createElement(currentDialog.component, {
                hideDialog,
                setIsDialogProcessing,
                isDialogProcessing,
                ...currentDialog.dialogProps,
            }),
        [hideDialog, currentDialog, isDialogProcessing, setIsDialogProcessing]
    );
    return (
        currentDialog && (
            <Dialog
                onClose={() => !isDialogProcessing && hideDialog()}
                isCloseButtonShown={!isDialogProcessing}
                isOpen
                title={currentDialog.title}
                icon={currentDialog.icon}
            >
                <div style={{ position: "relative" }}>
                    {content}
                    {isDialogProcessing && <StyledProcessing />}
                </div>
            </Dialog>
        )
    );
};

Modal.propTypes = {
    currentDialog: PropTypes.shape({
        component: PropTypes.func.isRequired,
        dialogProps: PropTypes.shape({}),
        title: PropTypes.string,
        icon: PropTypes.string,
    }).isRequired,
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
};

export const DialogProvider = ({ children }) => {
    const [currentDialog, setCurrentDialog] = useState(null);
    const [resolver, setResolver] = useState(null);
    const [isDialogProcessing, setIsDialogProcessing] = useState(false);

    const hideDialog = useCallback(
        (code = 0, data = {}) => {
            setCurrentDialog(null);
            if (resolver) {
                // resolve the promise
                resolver({ code, data });
            }
            setResolver(null);
            setIsDialogProcessing(false);
        },
        [resolver]
    );

    const showDialog = useCallback(async (definition, dialogProps) => {
        setIsDialogProcessing(false);
        return new Promise((r) => {
            // this is a trick as if we send the resolve function 'r' into a react state it will resolve it immediately
            // as the pattern setState(oldState => newState) is applied
            setResolver(() => r);
            const newDialog = { ...definition, dialogProps };
            setCurrentDialog(newDialog);
        });
    }, []);

    return (
        <DialogContext.Provider
            value={{
                currentDialog,
                showDialog,
                hideDialog,
            }}
        >
            {currentDialog && (
                <Modal
                    currentDialog={currentDialog}
                    hideDialog={hideDialog}
                    setIsDialogProcessing={setIsDialogProcessing}
                    isDialogProcessing={isDialogProcessing}
                />
            )}
            {children}
        </DialogContext.Provider>
    );
};

DialogProvider.propTypes = {
    children: PropTypes.node,
    dialogs: PropTypes.shape({}),
};

DialogProvider.defaultProps = {
    children: null,
    dialogs: {},
};
