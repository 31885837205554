import { Button } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledInterestItem = styled.div`
    border-bottom: 1px solid ${({ $dark }) => ($dark ? "black" : "lightgray")};
    display: grid;
    grid-template-columns: auto 50px;
    align-items: center;
    padding: 5px 10px;

    small {
        font-size: 0.8em;
    }
    .interest-info {
        display: flex;
    }
    .actions {
        justify-items: right;
        text-align: right;
    }
`;

function InterestItem({ onAction, onActionLabel, onActionIcon, name, topic, audience, actionIntent, dark }) {
    return (
        <StyledInterestItem $dark={dark}>
            <div>
                <div className="title">{name}</div>
                <div className="interest-info">
                    {topic ? <small>{topic}</small> : <small>&nbsp;</small>}
                    {audience && <small>{audience}</small>}
                </div>
            </div>
            {onAction ? (
                <div className="actions">
                    <Button intent={actionIntent} text={onActionLabel} icon={onActionIcon} onClick={() => onAction()} />
                </div>
            ) : null}
        </StyledInterestItem>
    );
}

InterestItem.propTypes = {
    onAction: PropTypes.func,
    onActionLabel: PropTypes.string,
    onActionIcon: PropTypes.node,
    name: PropTypes.string.isRequired,
    topic: PropTypes.string,
    audience: PropTypes.string,
    actionIntent: PropTypes.string,
    dark: PropTypes.bool,
};

InterestItem.defaultProps = {
    onAction: null,
    onActionLabel: null,
    onActionIcon: null,
    topic: null,
    audience: null,
    actionIntent: null,
    dark: false,
};

export default InterestItem;
