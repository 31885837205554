import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ProfileDetailsPage from "./profile-details-page";
import ProfilesPage from "./profiles-page";

const Routes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route key="profile-list" exact path={path} component={ProfilesPage} />
            <Route key="profile-detail" path={`${path}/:tpId`} component={ProfileDetailsPage} />
        </Switch>
    );
};

export default Routes;
