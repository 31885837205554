import { Button, ControlGroup, Intent, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledOptionSelect = styled.div`
    display: flex;
    button {
        white-space: nowrap;
        min-width: max-content;
    }
`;

const FilterChoice = ({ id, options, value, onChange, renderText, compareValue }) => {
    const findOption = (v) =>
        options.find((opt) => opt.value === v || (compareValue ? compareValue(opt.value, v) : isEqual(opt.value, v)));
    const selectedItem = findOption(value);
    //console.log("Value", value);
    const renderOption = (option, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                key={option.value}
                onClick={handleClick}
                text={option.label}
                shouldDismissPopover={false}
            />
        );
    };

    const text = (renderText && renderText(value)) || `${value}`;

    return (
        <StyledOptionSelect>
            <ControlGroup fill={true} vertical={false}>
                <Select
                    items={options}
                    itemRenderer={renderOption}
                    onItemSelect={(item) => onChange(item.value)}
                    activeItem={selectedItem}
                    filterable={false}
                    fill
                >
                    <Button
                        fill
                        alignText="left"
                        intent={Intent.NONE}
                        rightIcon="caret-down"
                        text={selectedItem?.label || text}
                    />
                </Select>
            </ControlGroup>
        </StyledOptionSelect>
    );
};

FilterChoice.propTypes = {
    id: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
        })
    ).isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    compareValue: PropTypes.func,
    renderText: PropTypes.func,
};

FilterChoice.defaultProps = {
    id: null,
    value: null,
    compareValue: null,
    renderText: null,
};

export default FilterChoice;
