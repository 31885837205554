import { Button, Callout, InputGroup, Intent } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useIdentity } from "../../common/identity-context";
import Box from "../common/box";
import ErrorCallout from "../common/error-callout";
import SimpleSelect from "../common/simple-select";
import { useAllEmailTemplates, useMutationSendEmail } from "./gql-emails";

const LANGS = [
    {
        label: "English",
        value: "en",
    },
    {
        label: "Français",
        value: "fr",
    },
    {
        label: "Deutsch",
        value: "de",
    },
    {
        label: "Español (mexicano)",
        value: "es-mx",
    },
    {
        label: "Dutch",
        value: "nl",
    },
];

const StyledEmailContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.33);
    min-width: 0px;
    min-height: 0px;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`;

const InputZone = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > * {
        margin: 5px;
        display: inline-block;
    }
`;

const EmailsPage = () => {
    const [loadedIdentity, identity] = useIdentity();
    const { templates, loading, error } = useAllEmailTemplates();
    const [sendTestEmail, { loading: sending }] = useMutationSendEmail();
    const [value, setValue] = React.useState("");
    const [lang, setLang] = React.useState(LANGS[0].value);
    const [email, setEmail] = React.useState("");

    useEffect(() => {
        setEmail(identity.email);
    }, [identity]);

    const handleSend = async () => {
        await sendTestEmail({ templateName: value, lang });
        setValue(null);
        toast.success(`Email sent to ${email}`);
    };

    if (loading) {
        return <Callout>Loading, please wait</Callout>;
    }
    return loadedIdentity ? (
        <Box direction="column">
            {error && <ErrorCallout title="Loading error" error={{ message: error.message }} />}
            <Box pad="medium">
                <Callout intent={Intent.NONE}>
                    <p>
                        All email can be modified on <a href="https://app.lokalise.com/">Lokalise</a>
                    </p>
                    <p>
                        To sent a test email on your email account, please select the email template name and the
                        language you want to receive the email. Then click SEND and check your inbox...
                    </p>
                </Callout>
            </Box>
            <Box pad="medium">
                <InputZone>
                    Sent template
                    <SimpleSelect filterable options={templates} selected={value} onSelect={setValue} />
                    in language
                    <SimpleSelect options={LANGS} selected={lang} onSelect={setLang} />
                    to
                    <InputGroup fill={false} inline value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                    <Button
                        intent={Intent.PRIMARY}
                        disabled={sending || !value}
                        rightIcon="send-message"
                        alignText="left"
                        text="Send test email"
                        onClick={handleSend}
                        loading={sending}
                    />
                </InputZone>
            </Box>
            {value && lang && (
                <StyledEmailContainer>
                    <iframe
                        style={{ height: `inherit`, width: "100%", boxSizing: "border-box" }}
                        frameBorder={0}
                        title="Email sample"
                        src={`${window.APP_SERVER_URL}/admin/_emails/${value}/?admin_email_lang=${lang}`}
                    />
                </StyledEmailContainer>
            )}
        </Box>
    ) : (
        <Box>Loading please wait...</Box>
    );
};

export default EmailsPage;
