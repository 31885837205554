import { ErrorBoundary, Provider } from "@rollbar/react";
import React from "react";
import ReactDOM from "react-dom";

import "./main.css";
import reportWebVitals from "./reportWebVitals";
import "./reset.css";
import App from "./staff/app";

const rollbarConfig = {
    accessToken: window.ROLLBAR_CLIENT_KEY,
    environment: window.ENVIRONMENT,
};

ReactDOM.render(
    <Provider config={rollbarConfig}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
