import { Alignment, Icon, Navbar, Tab, Tabs } from "@blueprintjs/core";
import React, { useState } from "react";

import Box from "../common/box";
import CouponCreate from "./coupon-create";
import CouponSearch from "./coupon-search";

const CouponsPage = () => {
    const [selectedTab, setSelectedTab] = useState("create");

    return (
        <Box pad="small">
            <Navbar>
                <Navbar.Group>
                    <Navbar.Heading>
                        <Icon icon="barcode" /> Coupons
                    </Navbar.Heading>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Tabs large onChange={(newTab) => setSelectedTab(newTab)} selectedTabId={selectedTab}>
                        <Tab id="create" title="Create" />
                        <Tab id="search" title="Search" />
                        <Tabs.Expander />
                    </Tabs>
                </Navbar.Group>
            </Navbar>
            {selectedTab === "create" && <CouponCreate />}
            {selectedTab === "search" && <CouponSearch />}
        </Box>
    );
};

export default CouponsPage;
