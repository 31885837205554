import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import TicketEditPage from "./ticket-edit-page";
import TicketLogPage from "./ticket-log-page";
import TicketsPage from "./tickets-page";

const TicketRoutes = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.url}/`} component={TicketsPage} />
            <Route exact path={`${match.url}/:id/edit/`} component={TicketEditPage} />
            <Route exact path={`${match.url}/:id/logs/`} component={TicketLogPage} />
        </Switch>
    );
};

TicketRoutes.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default TicketRoutes;
