import { gql } from "@apollo/client";

import { GQL_BUSINESS_FRAGMENT } from "./gql-business";

export const GQL_USER_FRAGMENT = gql`
    fragment user on User {
        username
        firstName
        lastName
        email
        avatarUrl
        location
        lang
        isStaff
        isMe
        isActive
        verified
        id
        memberships
        serviceSubscription {
            product {
                name
                interval
            }
            status
            startedAt
            cancelAt
            canceledAt
            nextDueDate
        }
        staffNotificationCount
        intercomUserHash
        dateJoined
        business {
            ...business
        }
        liveMessageCount
        agency {
            name
        }
    }

    ${GQL_BUSINESS_FRAGMENT}
`;
