import { Icon, MenuItem } from "@blueprintjs/core";
import { Omnibar } from "@blueprintjs/select";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import { useQueryGeoTargeting } from "../gql-geotargeting";
import { getPlatformIcon } from "../platforms";

const ITEM_ICONS = {
    country: "globe",
    region: "area-of-interest",
    city: "office",
};

export const getItemInfo = (item) => {
    return {
        icon: ITEM_ICONS[item?.subtype],
        label: item?.localeName,
    };
};

const GeoTargetingChooser = ({ existing, onSelect, isOpen, onClose }) => {
    const [q, setQ] = useState("");
    const [loadGeoTargeting, { results, loading, updateQuery }] = useQueryGeoTargeting();
    const [preloading, setPreloading] = useState(false);

    useEffect(() => {
        if (isOpen === false) {
            setQ("");
            updateQuery(() => ({ searchGeoTargeting: [] }));
        }
    }, [updateQuery, isOpen]);

    const queryDebounced = useMemo(() => {
        const _query = async (newQ) => {
            await loadGeoTargeting({ q: newQ });
            setPreloading(false);
        };
        const debounced = debounce(_query, 500);
        return (_queryPre) => {
            if (_queryPre.length > 2) {
                setPreloading(true);
                debounced(_queryPre);
            }
        };
    }, [loadGeoTargeting]);

    useEffect(() => {
        queryDebounced(q);
    }, [queryDebounced, q]);

    const itemRenderer = (item, { modifiers, handleClick }) => {
        const { label, icon } = getItemInfo(item);
        return (
            <MenuItem
                text={
                    <div>
                        <Icon icon={icon} /> <span>{label}</span>
                    </div>
                }
                onClick={handleClick}
                key={item.id}
                active={modifiers.active}
                icon={(existing || []).map((v) => v.id).includes(item.id) ? "tick" : "blank"}
                label={
                    <span>
                        {item.platforms.map((p) => (
                            <span key={p}>
                                {getPlatformIcon(p, { key: p, size: "12px" })}
                                &nbsp;
                            </span>
                        ))}
                    </span>
                }
            />
        );
    };

    const loadingIndicator = loading || preloading;
    return (
        <Omnibar
            isOpen={isOpen}
            items={loadingIndicator ? [] : results || []}
            itemRenderer={itemRenderer}
            onItemSelect={(item) => {
                onSelect && onSelect(item);
            }}
            query={q}
            onQueryChange={setQ}
            noResults={<MenuItem disabled={true} text={loadingIndicator ? "loading..." : "No geo targeting."} />}
            onClose={onClose}
            itemPredicate={(query, opt, _index, exactMatch) => {
                const normalizedLabel = opt.localeName.toLowerCase();
                const normalizedQuery = query.toLowerCase();
                if (exactMatch) {
                    return normalizedLabel === normalizedQuery;
                } else {
                    return normalizedLabel.indexOf(normalizedQuery) >= 0;
                }
            }}
        />
    );
};

GeoTargetingChooser.propTypes = {
    onSelect: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    existing: PropTypes.arrayOf(PropTypes.shape({})),
};

GeoTargetingChooser.defaultProps = {
    existing: [],
};

export default GeoTargetingChooser;
