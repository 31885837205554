import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import TargetingCreate from "./targeting-create-page";
import TargetingUpdate from "./targeting-update-page";
import TargetingsPage from "./targetings-page";

const TargetingsRoutes = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.url}/`} component={TargetingsPage} />
            <Route exact path={`${match.url}/new/`} component={TargetingCreate} />
            <Route exact path={`${match.url}/:id/update/`} component={TargetingUpdate} />
        </Switch>
    );
};

TargetingsRoutes.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default TargetingsRoutes;
