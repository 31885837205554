import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, useQuery } from "@apollo/client";
import { Menu } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import { BrowserRouter } from "react-router-dom";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { ThemeProvider } from "styled-components";

import { getIdentity } from "../common/gql-actions";
import { getRootUserAgencyPromise } from "../common/gql-promises";
import { useAsyncHook } from "../common/hooks";
import { IdentityConsumer, IdentityManager, RequireLogin } from "../common/identity-context";
import NotFoundPage from "../common/not-found-page";
import theme from "../common/theme";
import { getIn } from "../common/utils";
import Login from "../sso/login-page";
import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarItem } from "./app-sidebar";
import { DialogProvider } from "./bp-dialog-context";
import UserBadge from "./common/user-badge";
import CouponsPage from "./coupons/coupons-page";
import EmailPage from "./emails/emails-page";
import { getGlobalStats } from "./gql-promises";
import MainPage from "./main-page";
import MessageRoutes from "./messges/message-routes";
import ProfileRoute from "./targeting-profile/routes";
import TargetingsRoutes from "./targeting/targetings-routes";
import { GQL_QUERY_ASSIGNED_TICKETS } from "./tickets/gql-tickets";
import TicketRoutes from "./tickets/ticket-routes";
import { generate_token, initAmplifyAi } from "./toolsAi";
import TransactionsPage from "./transactions/transactions-page";
import UsersPage from "./users/users-page";
import ValidationPage from "./validation/validation-page";
import WizardCompletionPage from "./wizard-completion/wizard-completion-page";

const apolloLink = createHttpLink({
    uri: `${window.APP_SERVER_URL}/graphql`,
    credentials: "include",
});

const apolloClient = new ApolloClient({
    link: apolloLink,
    cache: new InMemoryCache(),
});

// eslint-disable-next-line react/prop-types
const StyledBadge = styled.span`
    background: ${({ $background }) => $background};
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px 6px;
    border-radius: 20px;
    line-height: 14px;
    font-size: 12px;
    font-weight: bold;
`;

const Badge = ({ label, background = "#eee" }) => <StyledBadge $background={background}>{label}</StyledBadge>;

Badge.propTypes = {
    label: PropTypes.node.isRequired,
    background: PropTypes.string,
};

Badge.defaultProps = {
    background: "#eee",
};

const InnerContent = styled.div`
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 250px;
    background-color: rgb(242, 242, 242);
    @media screen and (max-width: 800px) {
        left: 70px;
    }
`;

const MainContent = styled.div`
    position: relative;
    display: flex;
    min-height: 100%;
`;

const TicketsBadge = ({ identity }) => {
    const { data } = useQuery(GQL_QUERY_ASSIGNED_TICKETS, {
        variables: {
            userId: identity?.id,
        },
        fetchPolicy: "no-cache",
        skip: !identity,
    });
    if (data?.getAssignedTickets)
        return <Badge background="#2d72d22b" label={parseInt(data?.getAssignedTickets, 10)} />;
    else return null;
};
TicketsBadge.propTypes = {
    identity: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

const App = withRouter(({ history, location }) => {
    const [stats, statsFetched] = useAsyncHook(getGlobalStats, {});
    const [sideBarItems, setSideBarItems] = useState([]);

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log("Launch AI");
        initAmplifyAi({}, "STAFF_" + generate_token(16))
            .then(() => {
                // eslint-disable-next-line no-console
                console.log("AI ok");
            })
            .catch(() => {
                // eslint-disable-next-line no-console
                console.log("AI ko");
            });
    }, []);

    const handlesidebarItems = useCallback(async () => {
        const pendingCount = statsFetched ? getIn(stats, "needReviewCount") : 0;
        const wizardEntriesCount = statsFetched ? getIn(stats, "wizardEntriesCount") : 0;
        const identity = await getIdentity();
        const rootUser = await getRootUserAgencyPromise();
        const itemsSide = [
            {
                id: "home",
                label: "Home",
                path: "/",
                icon: "home",
                exact: true,
                Component: MainPage,
            },
            {
                id: "wizardEntries",
                label: "New wizard entries",
                path: "/wizard-completion",
                icon: "inbox",
                exact: false,
                Component: WizardCompletionPage,
                widget: pendingCount > 0 ? <Badge label={parseInt(wizardEntriesCount, 10)} /> : <Badge label="..." />,
            },

            {
                id: "profiles",
                label: "Targeting profiles",
                path: "/targeting-profiles",
                icon: "locate",
                exact: false,
                Component: ProfileRoute,
            },
            {
                id: "targetings",
                label: "Geo Targetings",
                path: "/targetings",
                icon: "map-marker",
                exact: false,
                Component: TargetingsRoutes,
            },
            {
                id: "users",
                label: "Users",
                path: "/users",
                clickParams: { isActive: "t" },
                icon: "people",
                exact: true,
                Component: UsersPage,
            },
            {
                id: "transactions",
                label: "Transactions",
                path: "/transactions",
                icon: "exchange",
                exact: true,
                Component: TransactionsPage,
            },

            {
                id: "tickets",
                label: "Staff Tickets",
                path: "/tickets",
                icon: "label",
                exact: false,
                Component: TicketRoutes,
                widget: <TicketsBadge identity={identity} />,
            },
            {
                id: "messages",
                label: "Messages",
                path: "/messages",
                clickParams: { order: "-insertedAt" },
                icon: <RiAdvertisementLine />,
                exact: false,
                Component: MessageRoutes,
            },
        ];
        if (!rootUser) {
            itemsSide.splice(1, 0, {
                id: "validation",
                label: "Message validation",
                path: "/validation",
                icon: "inbox",
                exact: false,
                Component: ValidationPage,
                widget: pendingCount > 0 ? <Badge label={parseInt(pendingCount, 10)} /> : <Badge label="..." />,
            });
            itemsSide.splice(3, 0, {
                id: "coupons",
                label: "Coupons",
                path: "/coupons",
                icon: "barcode",
                exact: true,
                Component: CouponsPage,
            });
            itemsSide.splice(8, 0, {
                id: "emails",
                label: "Emails",
                path: "/emails",
                icon: "envelope",
                exact: true,
                Component: EmailPage,
            });
        }
        setSideBarItems(itemsSide);
    }, [stats, statsFetched]);

    // ... rest of your component
    useEffect(() => {
        handlesidebarItems();
    }, [handlesidebarItems]);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const onSidebarSelect = useCallback((item) => {
        if (item.onClick) {
            item.onClick();
        } else {
            const search = item.clickParams ? new URLSearchParams(item.clickParams).toString() : "";
            history.push({ pathname: item.path, search });
        }
    });

    const selectedItemIndex = sideBarItems?.findIndex((i) =>
        i.exact ? location.pathname === i.path : location.pathname.startsWith(i.path)
    );

    return (
        <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={theme}>
                <IdentityManager>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route
                            path="*"
                            render={() => (
                                <RequireLogin staffOnly>
                                    <IdentityConsumer>
                                        {({ identity }) => (
                                            <DialogProvider>
                                                <MainContent>
                                                    <Sidebar>
                                                        <SidebarHeader>
                                                            <UserBadge user={identity} showEmail />
                                                        </SidebarHeader>
                                                        <SidebarContent>
                                                            <Menu large>
                                                                {sideBarItems?.map((item, idx) => (
                                                                    <SidebarItem
                                                                        key={item.id}
                                                                        item={item}
                                                                        onSelect={onSidebarSelect}
                                                                        selected={selectedItemIndex === idx}
                                                                    />
                                                                ))}
                                                            </Menu>
                                                        </SidebarContent>
                                                        <SidebarFooter />
                                                    </Sidebar>
                                                    <InnerContent>
                                                        <Switch>
                                                            {sideBarItems.map((item) =>
                                                                item.path ? (
                                                                    <Route
                                                                        key={item.id}
                                                                        exact={item.exact}
                                                                        path={item.path}
                                                                        component={item.Component}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )
                                                            )}
                                                            <Route key="not-found" path="*" component={NotFoundPage} />
                                                        </Switch>
                                                    </InnerContent>
                                                </MainContent>
                                            </DialogProvider>
                                        )}
                                    </IdentityConsumer>
                                </RequireLogin>
                            )}
                        />
                    </Switch>
                </IdentityManager>
            </ThemeProvider>
        </ApolloProvider>
    );
});

const StyledApp = styled.div`
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    width: 100vw;
    height: 100vh;
    overflow: auto;
`;

const AppExport = () => (
    <BrowserRouter basename="/">
        <StyledApp>
            <ToastContainer position="top-center" hideProgressBar />
            <App />
        </StyledApp>
    </BrowserRouter>
);

export default AppExport;
