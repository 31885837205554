import { Intent, ProgressBar } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { useUrlCheck } from "../../common/hooks";

export const UrlReadyChecker = ({ url, children, timeout, retry, loadingPlaceholder }) => {
    const status = useUrlCheck(url, { timeout, retry });
    if (typeof children === "function") {
        return children(status);
    }
    if (status === 0 && loadingPlaceholder) {
        return loadingPlaceholder;
    }
    return status === 1 ? children : null;
};

UrlReadyChecker.propTypes = {
    url: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    timeout: PropTypes.number,
    retry: PropTypes.number,
    loadingPlaceholder: PropTypes.node,
};

UrlReadyChecker.defaultProps = {
    timeout: 10000,
    retry: 1000,
    loadingPlaceholder: null,
};

const StyledProcessingVideoPlaceHolder = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8em;
    text-align: center;
    color: ${({ theme }) => theme.altColor};
`;

const StyledVideoPlayer = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #eee;
    position: relative;
    & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const URL_MAP = new Map([
    ["youtube", "https://www.youtube.com/embed/"],
    ["vimeo", "https://player.vimeo.com/video/"],
    ["dailymotion", "https://www.dailymotion.com/embed/video/"],
    ["amplify", `${window.APP_SERVER_URL}/video/`],
    ["amplify-prod", "https://www.amplify.ooo/video/"],
]);

const VideoPlayer = ({ videoUrl, imageUrl, ...htmlTags }) => {
    if (!videoUrl) {
        return null;
    }
    const [serviceName, id] = videoUrl.split(":");
    const service = URL_MAP.get(serviceName);

    if (!service) {
        return (
            <div>
                Video service <span>{serviceName}</span> not supported
            </div>
        );
    }
    const src = `${service}${id}${imageUrl ? `?img=${imageUrl}` : ""}`;

    return (
        <StyledVideoPlayer>
            <UrlReadyChecker
                url={imageUrl}
                timeout={30000}
                retry={2000}
                loadingPlaceholder={
                    <StyledProcessingVideoPlaceHolder>
                        <div>
                            <ProgressBar intent={Intent.PRIMARY} />
                        </div>
                        <div>Loading...</div>
                    </StyledProcessingVideoPlaceHolder>
                }
            >
                <iframe
                    title={`${service} - Video`}
                    src={src}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    {...htmlTags}
                />
            </UrlReadyChecker>
        </StyledVideoPlayer>
    );
};

VideoPlayer.propTypes = {
    videoUrl: PropTypes.string,
    imageUrl: PropTypes.string,
};

VideoPlayer.defaultProps = {
    videoUrl: null,
    imageUrl: null,
};

export default VideoPlayer;
