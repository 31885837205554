import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import NavBreadcrumb from "../common/nav-breadcrumb";
import { useMutationCreateGeoTargeting } from "./gql-targeting";
import TargetingForm from "./targeting-form";

const StyledTargetingCreatePage = styled.div`
    padding: 10px;
`;

const TargetingCreatePage = () => {
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const createTargetingPromise = useMutationCreateGeoTargeting();

    const handleSaveTargeting = useCallback(
        async (targeting) => {
            setSaving(true);
            try {
                const result = await createTargetingPromise({
                    targeting,
                });
                toast.success("Targeting created successfully");
                history.push(`/targetings/${result.id}/update/`);
            } finally {
                setSaving(false);
            }
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
        },
        [history, createTargetingPromise]
    );

    const onCancel = useCallback(() => {
        history.push("/targetings/");
    }, [history]);

    return (
        <StyledTargetingCreatePage>
            <NavBreadcrumb
                items={[
                    { icon: "home", to: "/" },
                    {
                        text: "Geo Targeting",
                        icon: "map-marker",
                        to: {
                            pathname: `/targetings/`,
                            search: history.location.search,
                        },
                    },
                    {
                        text: "NEW",
                    },
                ]}
            />
            <TargetingForm
                actionLabel="Create"
                onSave={handleSaveTargeting}
                onCancel={onCancel}
                height="500px"
                loading={saving}
            />
        </StyledTargetingCreatePage>
    );
};

export default TargetingCreatePage;
