import { Radio, RadioGroup } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const TRUE = "true";
const FALSE = "false";
const NULL = "null";

const toInternal = (value) => {
    if (value === true) {
        return TRUE;
    } else if (value === false) {
        return FALSE;
    }
    return NULL;
};

const toExternal = (value) => {
    if (value === TRUE) {
        return true;
    } else if (value === FALSE) {
        return false;
    }
    return null;
};

const FilterBoolean = ({ id, value, onChange, positive = "true", negative = "false" }) => {
    const [v, setV] = useState(toInternal(value));

    const handleOnChange = (e) => {
        const v = e.currentTarget.value;
        onChange(toExternal(v));
    };

    useEffect(() => {
        setV(toInternal(value));
    }, [value]);

    return (
        <RadioGroup id={id} inline onChange={handleOnChange} selectedValue={v}>
            <Radio label="All" value={NULL} />
            <Radio label={positive} value={TRUE} />
            <Radio label={negative} value={FALSE} />
        </RadioGroup>
    );
};

FilterBoolean.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    emptyText: PropTypes.string,
    positive: PropTypes.node,
    negative: PropTypes.node,
};

FilterBoolean.defaultProps = {
    id: null,
    emptyText: null,
    value: "",
    positive: null,
    negative: null,
};

export default FilterBoolean;
