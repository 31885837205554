import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

const MESSAGE_FRAGMENT = `
    fragment message on Message {
        id
        title
        texts {
            value
            type
        }
        wizardMetas
        owner {
            id
            username
        }
    }
`;

export const GQL_QUERY_STAFF_FOR_COMPLETION_MESSAGES = gql`
    query GetStaffForCompletionMessagesFromWizard {
        getStaffForCompletionMessagesFromWizard {
            ...message
        }
    }
    ${MESSAGE_FRAGMENT}
`;

export const useQueryForCompletionMessagesFromWizard = (id) => {
    const {
        data: { getDraftMessage } = {},
        loading,
        error,
        ...rest
    } = useQuery(GQL_QUERY_STAFF_FOR_COMPLETION_MESSAGES, {
        fetchPolicy: "cache-and-network",
    });
    const message = useMemo(() => {
        return getDraftMessage && id && getDraftMessage.find((mess) => mess.id === id);
    }, [id, getDraftMessage]);
    const notFound = !loading && !message && !error;
    return { message, notFound, loading, error, ...rest };
};
