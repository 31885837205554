import { Callout, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";

const ErrorCallout = ({ title, error, message, className }) => {
    if (!error && !message) {
        return null;
    }
    return (
        <Callout className={className} icon="error" intent={Intent.DANGER} title={title}>
            {error?.message && <p>{error.message}</p>}
            {message}
        </Callout>
    );
};

ErrorCallout.propTypes = {
    title: PropTypes.string.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    message: PropTypes.node,
    className: PropTypes.string,
};

ErrorCallout.defaultProps = {
    error: null,
    message: null,
    className: null,
};

export default ErrorCallout;
