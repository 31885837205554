import { Button, FormGroup, InputGroup, Intent, Tag, TextArea } from "@blueprintjs/core";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { peek } from "../../common/utils";
import { DialogContext } from "../bp-dialog-context";
import ErrorCallout from "../common/error-callout";
import SimpleSelect from "../common/simple-select";
import AddComment from "../dialogs/add-comment";
import { ALL_TICKET_PRIORITY, ALL_TICKET_TYPE, FieldGroup } from "../dialogs/create-ticket";
import MessageDate from "../messges/edit/field-date";
import FieldImage from "../messges/edit/field-image";
import FieldVideo from "../messges/edit/field-video";
import MessageField from "../messges/message-field";
import { useMutationUpdateTicket, useQueryTicket } from "./gql-tickets";
import { getIntentForState } from "./tickets-table";

const StyledMessageEditPage = styled.div`
    background-color: ${({ theme }) => theme.editorBackgroundColor};
`;

const StyledComments = {
    Main: styled.div`
        display: flex;
        background: white;
        padding: 10px 20px;
        border-radius: 5px;
        margin: 20px 0px;
        flex-direction: column;
    `,
    Comment: styled.div`
        border: 1px solid #ccc;
        margin: 10px 0;
        padding: 10px;
        border-radius: 5px;
    `,
    Username: styled.div`
        font-weight: bold;
        margin-bottom: 5px; ;
    `,
    Content: styled.div`
        font-weight: bold;
        margin-bottom: 5px; ;
    `,
};

const removeTypename = (data) => {
    const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
    return JSON.parse(JSON.stringify(data), omitTypename);
};

const TicketEditPage = () => {
    const { id } = useParams();
    const { ticket, loading, loadError, refetch } = useQueryTicket(id);
    const [saveTicket, { loading: saving }] = useMutationUpdateTicket();
    const [data, setData] = useState(ticket);
    const { showDialog } = useContext(DialogContext);
    useEffect(() => {
        setData(ticket);
    }, [ticket]);

    const onChangeValue = useCallback((k, v) => {
        setData((oldValues) => ({
            ...oldValues,
            [k]: v,
        }));
    }, []);
    const onChangeTicket = useCallback(
        async (newMessageData, close) => {
            // sanitize message data, peek only change-able key and remove __type
            const changes = peek(newMessageData, [
                "title",
                "description",
                "expiryAt",
                "priorityStatus",
                "type",
                "expiryAt",
                "comment",
                // undefined will remove the key, it is different from setting the key to null
                ["image", "imageId", (image) => image?.id || undefined],
                ["movie", "movieId", (movie) => movie?.id || undefined],
                ["movie", "movieImageId", (movie) => movie?.image?.id || undefined],
            ]);
            await saveTicket({ id, changes: removeTypename(changes) });

            toast.success("Ticket saved successfully");

            if (close) {
                setTimeout(() => {
                    window.location = "/tickets";
                }, 12);
            } else {
                await refetch();
            }
        },
        [refetch, id, saveTicket]
    );
    const onSubmit = useCallback(
        (close) => {
            onChangeTicket(data, close);
        },
        [data, onChangeTicket]
    );
    const handleAddComment = useCallback(
        async (ticket) => {
            await showDialog(AddComment, { ticket });
            await refetch();
        },
        [refetch, showDialog]
    );

    return (
        <StyledMessageEditPage>
            {data && (
                <div style={{ padding: "20px" }}>
                    <ErrorCallout title="Error while loading profile" error={loadError} />

                    <h1>Details Ticket</h1>
                    <Tag style={{ margin: "15px 0" }} minimal intent={getIntentForState(data.status)}>
                        {ticket.status.toUpperCase()}
                    </Tag>
                    <br />
                    {ticket?.createdAt && (
                        <Tag style={{ marginBottom: "15px" }} minimal intent={Intent.PRIMARY}>
                            Created at : {moment(ticket.createdAt).format("LL")}
                        </Tag>
                    )}
                    <FormGroup label="Title">
                        <InputGroup
                            aria-label="title"
                            value={data?.title}
                            onChange={(e) => onChangeValue("title", e.target.value)}
                            maxLength={80}
                        />
                    </FormGroup>

                    <FormGroup label="Description" labelFor="comments-input" helperText="Optional description">
                        <TextArea
                            fill
                            id="comments-input"
                            value={data?.description}
                            onChange={(e) => onChangeValue("description", e.target.value)}
                            growVertically={false}
                            rows={10}
                        />
                    </FormGroup>
                    {data?.message?.id && (
                        <Button
                            style={{ marginBottom: "15px" }}
                            intent={Intent.WARNING}
                            onClick={() => window.open(`/messages/${ticket?.message?.id}/edit/`)}
                            text="Open Message Link to the Ticket "
                            icon="share"
                        />
                    )}
                    <FieldGroup>
                        <MessageField
                            label="Expiry date"
                            name="expiryAt"
                            values={data}
                            onChangeValue={onChangeValue}
                            renderComponent={({ value, setValue, disabled }) => (
                                <MessageDate
                                    disabled={disabled}
                                    onChange={(v) => setValue(moment(v).format("YYYY-MM-DD"))}
                                    value={value}
                                    maxDate={moment().add()}
                                />
                            )}
                        />
                        <FormGroup
                            label="Priority"
                            helperText="Select an appropriate priority for the ticket"
                            intent={Intent.WARNING}
                        >
                            <SimpleSelect
                                outlined
                                options={ALL_TICKET_PRIORITY}
                                onSelect={(v) => onChangeValue("priorityStatus", v)}
                                selected={data.priorityStatus}
                            />
                        </FormGroup>
                        <FormGroup label="Type">
                            <SimpleSelect
                                outlined
                                options={ALL_TICKET_TYPE}
                                onSelect={(v) => onChangeValue("type", v)}
                                selected={data.type.toLowerCase()}
                            />
                        </FormGroup>
                    </FieldGroup>
                    <MessageField
                        label="Image"
                        name="image"
                        values={data}
                        onChangeValue={onChangeValue}
                        renderComponent={({ value, setValue, disabled }) => (
                            <FieldImage value={value} onChange={setValue} disabled={disabled} />
                        )}
                    />
                    <MessageField
                        label="Video"
                        name="movie"
                        values={data}
                        onChangeValue={onChangeValue}
                        renderComponent={({ value, setValue, disabled }) => (
                            <FieldVideo value={value} onChange={setValue} disabled={disabled} />
                        )}
                    />
                    <StyledComments.Main>
                        <h2>Comments Ticket</h2>
                        <Button
                            style={{ width: "fit-content" }}
                            icon="plus"
                            intent={Intent.PRIMARY}
                            onClick={() => handleAddComment(ticket)}
                            text="Add Comment"
                        />
                        {ticket?.comments &&
                            ticket.comments.map((comment) => {
                                return (
                                    <StyledComments.Comment key={comment.id}>
                                        <StyledComments.Username>{comment.username}</StyledComments.Username>
                                        {comment.text}
                                    </StyledComments.Comment>
                                );
                            })}
                    </StyledComments.Main>
                    <Button
                        intent={Intent.SUCCESS}
                        loading={saving}
                        onClick={(e) => onSubmit(true)}
                        disabled={loading}
                        text="Save Ticket and close"
                        icon="log-out"
                    />
                </div>
            )}
        </StyledMessageEditPage>
    );
};

export default TicketEditPage;
