import { Button } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";

import ErrorCallout from "../common/error-callout";
import LoadingCallout from "../common/loading-callout";
import { useQuerySearchTargetingProfiles } from "./gql-profile";
import ProfileTable from "./profile-table";

const ProfileList = ({ search }) => {
    const [
        searchTargetingProfilePromise,
        {
            result: { result: profiles, nextOffset, count },
            error,
            loading,
        },
    ] = useQuerySearchTargetingProfiles();

    const loadItems = useCallback(
        async (q, offset) => {
            await searchTargetingProfilePromise({
                q,
                offset,
            });
        },
        [searchTargetingProfilePromise]
    );

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const onMore = useCallback(() => (nextOffset !== -1 ? loadItems(search, nextOffset) : null), [nextOffset, search]);

    useEffect(
        () => {
            loadItems(search, 0);
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [search]
    );

    return (
        <div>
            <ErrorCallout title="Error while loading list" error={error} />
            <LoadingCallout title="Loading, please wait..." loading={loading} />
            {!loading && profiles && <ProfileTable profiles={profiles} />}
            {nextOffset !== -1 && (
                <div>
                    <Button onClick={onMore} text="Load more..." />
                </div>
            )}
            {!loading && count !== -1 && <div>Count : {count}</div>}
        </div>
    );
};

ProfileList.propTypes = {
    search: PropTypes.string.isRequired,
};

export default ProfileList;
