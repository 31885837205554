import { Button, Divider } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import UserBadge from "../../common/user-badge";
import UserChooser from "../../common/user-chooser";

const StyledFieldOwner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > *:first-child {
        flex-shrink: 1;
    }
`;

const FieldOwner = ({ value, onChange, disabled, onlyStaff }) => {
    const [searchOpen, setSearchOpen] = useState(false);

    return (
        <StyledFieldOwner>
            <UserBadge user={value} showEmail size={30} />
            <Divider />
            {!disabled && (
                <Button
                    text="Change"
                    rightIcon="search"
                    onClick={() => {
                        setSearchOpen(true);
                    }}
                />
            )}
            <UserChooser
                selected={value}
                onSelect={(user) => {
                    setSearchOpen(false);
                    onChange(user);
                }}
                isOpen={!disabled && searchOpen}
                onClose={() => setSearchOpen(false)}
                onlyStaff={onlyStaff}
            />
        </StyledFieldOwner>
    );
};

FieldOwner.propTypes = {
    value: PropTypes.shape({}),
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onlyStaff: PropTypes.bool,
};

FieldOwner.defaultProps = {
    value: null,
    emptyValue: null,
    onChange: null,
    disabled: false,
    onlyStaff: false,
};

export default FieldOwner;
