import { Callout, Intent, Spinner } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";

const LoadingCallout = ({ title, loading, info }) => {
    if (!loading) {
        return null;
    }
    return (
        <Callout icon="waves" intent={Intent.PRIMARY} title={title}>
            <div>
                <Spinner />
            </div>
            <div>{info}</div>
        </Callout>
    );
};

LoadingCallout.propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    info: PropTypes.node,
};

LoadingCallout.defaultProps = {
    info: null,
    loading: false,
    title: "Loading, ...please wait",
};

export default LoadingCallout;
