import { Button, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { parseQueryString } from "../../common/utils";
import Box from "../common/box";
import Search from "../search";
import ProfileList from "./profile-list";

const StyledProfilesPage = styled.div`
    height: 100vh;
    width: 100%;
    display: grid;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    .profile-list {
        position: relative;
        align-items: stretch;
        .amp-table-container {
            position: absolute;
            bottom: 10px;
            top: 0;
            right: 10px;
            left: 10px;
        }
    }
`;

const ProfilesPage = () => {
    const history = useHistory();
    const query = parseQueryString(history.location.search);
    const [inputValue, setInputValue] = useState(query.q || "");

    const handleSearch = useCallback(
        (q) => {
            const params = new URLSearchParams({ q }).toString();
            history.push({
                search: `?${params}`,
            });
        },
        [history]
    );

    const handleCreate = useCallback(() => {
        history.push(`${history.location.pathname}/__new__`);
    }, [history]);

    return (
        <StyledProfilesPage>
            <Box direction="row" pad="small" gap="small">
                <Search
                    value={inputValue}
                    setValue={setInputValue}
                    onSearch={handleSearch}
                    onReset={() => {
                        setInputValue("");
                        handleSearch("");
                    }}
                />
                {window.ALLOW_TARGETINGS_EDITION && (
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={handleCreate}
                        type="button"
                        text="Add profile"
                        icon="add"
                    />
                )}
            </Box>
            <Box pad="small" className="profile-list">
                <ProfileList search={query.q || ""} />
            </Box>
        </StyledProfilesPage>
    );
};

ProfilesPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default ProfilesPage;
