import { Icon, Intent, Tag } from "@blueprintjs/core";
import React from "react";

import { formatCurrency } from "../../../common/i18n";

export const getMessageBackerText = (messageBacker, currency) => {
    if (messageBacker.paymentType === "card") {
        return (
            <span>
                <Icon icon="credit-card" />{" "}
                {messageBacker.isSubscription && (
                    <Tag minimal intent={Intent.PRIMARY}>
                        Subscription
                    </Tag>
                )}{" "}
                Added <Tag minimal>{formatCurrency(messageBacker.amount, currency)}</Tag> on message using credit card
            </span>
        );
    } else if (messageBacker.paymentType === "manual") {
        return (
            <span>
                <Icon icon="hand" /> Manually added <Tag minimal>{formatCurrency(messageBacker.amount, currency)}</Tag>{" "}
                on message by staff
            </span>
        );
    } else if (messageBacker.paymentType === "coupon") {
        return (
            <span>
                <Icon icon="barcode" /> Added <Tag minimal>{formatCurrency(messageBacker.amount, currency)}</Tag> on
                message using coupon
            </span>
        );
    } else if (messageBacker.paymentType === "prepaid_coupon") {
        return (
            <span>
                <Icon icon="barcode" /> Added <Tag minimal>{formatCurrency(messageBacker.amount, currency)}</Tag> on
                message using prepaid coupon
            </span>
        );
    } else if (messageBacker.paymentType === "wallet") {
        return (
            <span>
                <Icon icon="briefcase" /> Added <Tag minimal>{formatCurrency(messageBacker.amount, currency)}</Tag> from
                wallet on message
            </span>
        );
    } else if (messageBacker.paymentType === "restore") {
        return (
            <span>
                <Icon icon="undo" /> Removed <Tag minimal>{formatCurrency(messageBacker.amount, currency)}</Tag> from
                message and restored back to wallet
            </span>
        );
    }
    return (
        <span>
            Unknown transaction transaction type of <Tag minimal>{formatCurrency(messageBacker.amount, currency)}</Tag>{" "}
            on message
        </span>
    );
};

export const getMessageBackerLog = (messageBacker, currency) => {
    return {
        text: (
            <>
                {getMessageBackerText(messageBacker, currency)} <Tag minimal>Status: {messageBacker.status}</Tag>
            </>
        ),
        level: "INFO",
        icon: "euro",
        at: new Date(messageBacker.insertedAt),
    };
};
