import { Button, Callout, Classes, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Prompt, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { peek, removeEmpty } from "../../common/utils";
import { useQueryUserAgency } from "../agency/gql-agency";
import ErrorCallout from "../common/error-callout";
import LoadingCallout from "../common/loading-callout";
import NavBreadcrumb from "../common/nav-breadcrumb";
import SimpleMultiSelect from "../common/simple-multi-select";
import { ALL_PLATFORMS, getPlatformIcon } from "../platforms";
import {
    useGetTargetingProfile,
    useMutationCreateTargetingProfile,
    useMutationUpdateTargetingProfile,
} from "./gql-profile";
import InterestsInput from "./interests-input";

function computeSaveValue(original) {
    return peek(original, ["title", "platforms", "items"]);
}

function computeChanges(valA, valB) {
    const peekA = computeSaveValue(valA);
    const peekB = computeSaveValue(valB);

    return Object.entries(peekA).reduce((acc, [key, vA]) => {
        const vB = peekB[key];
        if (vB !== vA) {
            acc.push(key);
        }
        return acc;
    }, []);
}

const StyledProfileDetailsPage = styled.div`
    padding: 10px;
`;

const ProfileDetailsPage = () => {
    const [forceNavigate, setForceNavigate] = useState(false);
    const { tpId } = useParams();
    const history = useHistory();
    const { path } = useRouteMatch();
    const [value, setValue] = useState(null);
    const isNew = !(tpId && tpId !== "__new__");
    const editable = isNew || (value && value.usedCount === 0);
    const [loadTargetingProfile, { result: targetingProfile, loading, error: loadError }] = useGetTargetingProfile();
    const [updateProfile, { loading: saving, error: saveError }] = useMutationUpdateTargetingProfile();
    const [createProfile, { loading: creating, error: createError }] = useMutationCreateTargetingProfile();
    const [loadAgency, { agency }] = useQueryUserAgency();

    useEffect(() => {
        loadAgency({ username: targetingProfile?.owner?.username });
    }, [agency, loadAgency, targetingProfile]);

    const platformOptions = useMemo(() => {
        return ALL_PLATFORMS.map((p) => ({
            value: p,
            label: p,
            icon: getPlatformIcon(p),
        }));
    }, []);

    const loadProfile = useCallback(
        async (profileId) => {
            setValue(null);
            await loadTargetingProfile(profileId);
        },
        [loadTargetingProfile]
    );

    const setEmptyProfile = useCallback(() => {
        setValue({
            title: "",
            items: [],
            platforms: [],
            interests: [],
        });
    }, [setValue]);

    useEffect(() => {
        if (targetingProfile) {
            setValue(targetingProfile);
        } else {
            setEmptyProfile();
        }
    }, [targetingProfile, setEmptyProfile]);

    const changedFields = useMemo(
        () =>
            ["title", "items", "platforms"].filter(
                (k) =>
                    (targetingProfile ? JSON.stringify(targetingProfile[k]) : null) !==
                    (value ? JSON.stringify(value[k]) : null)
            ),
        [targetingProfile, value]
    );

    useEffect(
        () => {
            if (isNew) {
                setEmptyProfile();
            } else {
                loadProfile(tpId);
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [tpId]
    );

    const setData = useCallback((key, v) => {
        setValue((prevValue) => {
            const newValue = {
                ...prevValue,
                [key]: v,
            };
            return newValue;
        });
    }, []);

    const errors = useMemo(
        () =>
            removeEmpty({
                title: !value || !value.title || value.title.length === 0 ? "This field is required" : null,
                platforms:
                    !value || !value.platforms || value.platforms.length === 0
                        ? "You must select at less 1 platform"
                        : null,
            }),
        [value]
    );
    const canSave = Object.keys(errors).length === 0;

    const saveProfile = useCallback(
        async (val) => {
            const touched = computeChanges(val, targetingProfile || {});
            if (!touched || touched.length === 0) {
                // no changes
                return;
            }
            if (val.id) {
                const toSave = Object.keys(val)
                    .filter((key) => touched.indexOf(key) !== -1)
                    .reduce((obj, key) => ({ ...obj, [key]: val[key] }), {});
                toSave.id = val.id;
                await updateProfile(toSave);
                toast.success("Profile saved");
            } else {
                const ret = await createProfile(val);
                // navigate to
                toast.success("Profile created");
                setForceNavigate(true);
                history.push(path.replace(":tpId", ret.id));
            }
        },
        [history, targetingProfile, updateProfile, createProfile, path]
    );

    const handleSave = useCallback(async () => {
        await saveProfile(value);
    }, [value, saveProfile]);

    return (
        <StyledProfileDetailsPage>
            <Prompt
                when={changedFields.length > 0 && !forceNavigate}
                message={() => "You have unsaved changes, do you really want to leave this page?"}
            />
            <NavBreadcrumb
                items={[
                    { icon: "home", to: "/" },
                    {
                        text: "Targeting profiles",
                        icon: "locate",
                        to: {
                            pathname: `/targeting-profiles/`,
                            search: history.location.search,
                        },
                    },
                    {
                        text: isNew ? "NEW PROFILE" : targetingProfile?.title,
                    },
                ]}
            />
            <br />
            <LoadingCallout title="Loading..." loading={loading} />
            <LoadingCallout title="Saving..." loading={saving} />
            <LoadingCallout title="Creating new profile..." loading={creating} />

            <ErrorCallout title="Error while loading profile" error={loadError} />
            <ErrorCallout title="Error while saving profile" error={saveError} />
            <ErrorCallout title="Error while creating profile" error={createError} />
            {agency?.name && (
                <Callout
                    intent={Intent.WARNING}
                    icon="warning-sign"
                    title={`Warning your are editing the targeting profile of the ${agency.name} agency`}
                />
            )}
            {value && (
                <div>
                    {!editable && (
                        <Callout title="Unable to modify this profile" intent={Intent.WARNING} icon="info-sign">
                            <p>Unable to modify this profile, it is in use in {value.usedCount} message(s)</p>
                        </Callout>
                    )}
                    <div>
                        <FormGroup
                            label="Title"
                            labelFor="profile-input-title"
                            helperText={errors.title}
                            intent={errors.title ? Intent.DANGER : null}
                        >
                            <InputGroup
                                fill
                                outlined
                                id="profile-input-title"
                                value={value.title}
                                disabled={!editable}
                                onChange={(e) => setData("title", e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup
                            name="platforms"
                            label="Platforms"
                            helperText={errors.platforms}
                            intent={errors.platforms ? Intent.DANGER : null}
                        >
                            <SimpleMultiSelect
                                disabled={!editable}
                                onChange={(v) => setData("platforms", v)}
                                value={value.platforms || []}
                                options={platformOptions}
                            />
                        </FormGroup>
                        <FormGroup name="items" label="Interests" selectedPlatforms={value.platforms}>
                            <InterestsInput
                                onChange={(v) => setData("items", v)}
                                value={value.items}
                                disabled={!editable}
                                selectedPlatforms={value.platforms}
                            />
                        </FormGroup>
                        {editable && (
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button
                                    disabled={changedFields.length === 0 || saving || loading}
                                    type="button"
                                    intent={Intent.NONE}
                                    text="Reset"
                                    onClick={() => (isNew ? setEmptyProfile() : setValue(targetingProfile))}
                                />
                                <Button
                                    disabled={changedFields.length === 0 || saving || loading || !canSave}
                                    type="button"
                                    intent={Intent.PRIMARY}
                                    text={isNew ? "Create" : "Save changes"}
                                    onClick={handleSave}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </StyledProfileDetailsPage>
    );
};

export default ProfileDetailsPage;
