import { Button, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationRefundWalletToCard } from "../messges/gql-messages";

const DialogRefundWalletAmount = ({ hideDialog, user }) => {
    const [cannotRestore, setCannotRestore] = useState(false);
    const [refundRemainingAmount, { loading, error }] = useMutationRefundWalletToCard();

    useEffect(() => {
        if (user.liveMessageCount > 0) {
            setCannotRestore(true);
        }
    }, [user]);

    const handleRefundRemainingAmount = useCallback(async () => {
        await refundRemainingAmount({
            userId: user.id,
        });
        toast.success("Successfully refund remaining amount to credit card");
        hideDialog(1, {});
    }, [user, hideDialog, refundRemainingAmount]);

    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while refunding message to credit card" error={error} />
                {cannotRestore && <p>User has {user.liveMessageCount} live ads you can't refund his wallet.</p>}
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={loading} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={loading || cannotRestore}
                        loading={loading}
                        intent={Intent.SUCCESS}
                        text="Refund Wallet to credit card"
                        onClick={handleRefundRemainingAmount}
                        rightIcon="bank-account"
                    />
                </div>
            </div>
        </>
    );
};

DialogRefundWalletAmount.propTypes = {
    hideDialog: PropTypes.func.isRequired,

    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        liveMessageCount: PropTypes.number,
    }).isRequired,
};

export default {
    title: "Refund Wallet to credit card",
    icon: "credit-card",
    component: DialogRefundWalletAmount,
};
