import { Button, ButtonGroup, Icon, Intent } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ALL_LANG } from "../../lang";
import FilterChoice from "../common/filter-choice";
import FilterOnOff from "../common/filter-onoff";
import FilterOptionsSelect from "../common/filter-options-select";
import FilterPerfInput from "../common/filter-perfs-input";
import FilterTextSearch from "../common/filter-text-search";
import { ALL_PLATFORMS } from "../platforms";
import { useQueryTopics } from "../topics";
import { ALL_PRODUCTS } from "../users/user-filter-pane";

const ALL_STATUS = [
    {
        value: "draft",
        label: "Draft",
    },
    {
        value: "live",
        label: "Live",
    },
    {
        value: "paused",
        label: "Paused",
    },
    {
        value: "review",
        label: "In review",
    },
    {
        value: "completed",
        label: "Completed",
        color: "green",
    },
];

const ALL_SUBSCRIPTIONS = [
    {
        value: "DAY",
        label: "DAY",
    },
    {
        value: "MONTH",
        label: "MONTH",
    },
];

const ALL_PLATFORM_STATUS = [
    {
        value: "init",
        label: "Init",
    },
    {
        value: "pending_validation",
        label: "Pending validation",
    },
    {
        value: "valid",
        label: "Valid",
    },
    {
        value: "invalid",
        label: "Invalid",
    },
    {
        value: "running",
        label: "Running",
    },
    {
        value: "completed",
        label: "Completed",
    },
    {
        value: "learning",
        label: "Learning",
    },
    {
        value: "learning_fail",
        label: "Learning failed",
    },
    {
        value: "paused",
        label: "Paused",
    },
];

export const StyledFiltersZone = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 100px 1fr);
    grid-gap: 10px;
    overflow: hidden;
    padding: 5px;
    .flex-row {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        & > *:nth-child(1) {
            flex-shrink: 0;
            //width: 200px;
            margin-right: 10px;
        }
        & > *:nth-child(2) {
            flex-grow: 1;
        }
    }
    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(1, 100px 1fr);
    }
`;

export const Label = styled.div`
    font-weight: bold;
    text-align: right;
    align-self: auto;
    font-size: 0.8em;
    line-height: 1.8rem;
    overflow: hidden;
    white-space: nowrap;
`;

const StyledButtonZone = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    & > :nth-child(2) {
        justify-self: end;
    }
    padding: 10px;
    grid-gap: 10px;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        & > :nth-child(2) {
            justify-self: center;
            grid-row: 1;
        }
    }
`;

const MessageFilterPane = ({ filters, setFilters, actions }) => {
    // title keep change locally until the user hit "search"
    const platforms = ALL_PLATFORMS.map((p) => ({
        label: p,
        value: p,
    }));

    const { topics } = useQueryTopics();

    const handleResetFilters = () => {
        setFilters({});
    };

    const updateFilter = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    const handleSearch = () => {
        setFilters(filters);
    };

    return (
        <>
            <StyledFiltersZone>
                <>
                    <Label>ID</Label>
                    <FilterTextSearch
                        value={filters.id}
                        onChange={(v) => updateFilter("id", v)}
                        emptyText="Search for ID"
                    />
                </>
                <>
                    <Label>Title</Label>
                    <FilterTextSearch
                        value={filters.title}
                        onChange={(v) => updateFilter("title", v)}
                        emptyText="Search for title"
                    />
                </>
                <>
                    <Label>Metas</Label>
                    <FilterTextSearch
                        value={filters.wizardMetas}
                        onChange={(v) => updateFilter("wizardMetas", v)}
                        emptyText="Search for metas"
                    />
                </>
                <>
                    <Label>Language</Label>
                    <FilterOptionsSelect
                        options={ALL_LANG}
                        value={filters.lang}
                        onChange={(value) => updateFilter("lang", value)}
                        emptyText="Any language"
                    />
                </>
                <>
                    <Label>Topic</Label>
                    <FilterOptionsSelect
                        options={topics}
                        value={filters.topic}
                        onChange={(value) => updateFilter("topic", value)}
                        emptyText="All topics"
                    />
                </>
                <>
                    <Label>Owner</Label>
                    <FilterTextSearch
                        value={filters.owner}
                        onChange={(v) => updateFilter("owner", v)}
                        emptyText="Search for owner email, first name or last name"
                    />
                </>
                <>
                    <Label>Owner product</Label>
                    <FilterOptionsSelect
                        options={ALL_PRODUCTS}
                        value={filters.product}
                        onChange={(value) => updateFilter("product", value)}
                        emptyText="No filter"
                        allowEmpty
                    />
                </>
                <>
                    <Label>State</Label>
                    <FilterOptionsSelect
                        options={ALL_STATUS}
                        value={filters.state}
                        onChange={(value) => updateFilter("state", value)}
                        emptyText="Any status"
                    />
                </>
                <>
                    <Label>Platforms</Label>
                    <FilterOptionsSelect
                        options={platforms}
                        value={filters.platform}
                        onChange={(value) => updateFilter("platform", value)}
                        emptyText="Any platform"
                    />
                </>
                <>
                    <Label>Sync error</Label>
                    <FilterTextSearch
                        value={filters.platformSyncError}
                        onChange={(value) => updateFilter("platformSyncError", value)}
                        emptyText="Search for synchronization error"
                        hasSearchOptions
                    />
                </>
                <>
                    <Label>Platform state</Label>
                    <FilterOptionsSelect
                        options={ALL_PLATFORM_STATUS}
                        value={filters.platformState}
                        onChange={(value) => updateFilter("platformState", value)}
                        emptyText="Any status"
                    />
                </>
                <>
                    <Label>Perf</Label>

                    <div className="flex-row">
                        <FilterChoice
                            options={[
                                { value: null, label: "Any performance" },
                                { value: [0.01, 0.1], label: "Catastrophic" },
                                { value: [0.1, 0.2], label: "Very low" },
                                { value: [0.2, 0.35], label: "Low" },
                                { value: [0.35, 0.5], label: "Medium" },
                                { value: [0.5, 1.0], label: "Good" },
                            ]}
                            value={filters.perf}
                            onChange={(value) => updateFilter("perf", value)}
                            renderText={(v) => (v ? `${v[0]} - ${v[1]}` : "not set")}
                        />
                        <FilterPerfInput
                            value={filters.perf ? [filters.perf[0] || 0.0, filters.perf[1] || 1.0] : [0.0, 1.0]}
                            onChange={(values) => updateFilter("perf", values)}
                            disabled={!filters.perf}
                        />
                    </div>
                </>
                <>
                    <Label>Subscription</Label>
                    <FilterOptionsSelect
                        options={ALL_SUBSCRIPTIONS}
                        value={filters.initialInterval}
                        onChange={(value) => updateFilter("initialInterval", value)}
                        emptyText="All subscriptions"
                    />
                </>
                <>
                    <Label>Delivering</Label>
                    <FilterOnOff
                        value={!!filters.notdel}
                        onChange={(value) => updateFilter("notdel", value)}
                        onText={
                            <span>
                                Display only not delivering&nbsp;
                                <Tooltip2 content="'Not delivering' is an ad that have not spent any budget for the last 3 days and has more than 5.00 Frs of unspent budget">
                                    <Icon size={20} color="gray" icon="help" />
                                </Tooltip2>
                            </span>
                        }
                        offText="Any delivery"
                    />
                </>
            </StyledFiltersZone>
            <StyledButtonZone>
                <ButtonGroup>
                    {actions.map(({ key, ...actionProps }) => (
                        <Button key={key} {...actionProps} />
                    ))}
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        intent={Intent.NONE}
                        icon="cross"
                        disabled={filters === {}}
                        onClick={() => handleResetFilters()}
                    >
                        Reset filters
                    </Button>
                    <Button
                        intent={Intent.PRIMARY}
                        icon="search"
                        disabled={filters === {}}
                        onClick={() => handleSearch()}
                    >
                        Search
                    </Button>
                </ButtonGroup>
            </StyledButtonZone>
        </>
    );
};

MessageFilterPane.propTypes = {
    filters: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        wizardMetas: PropTypes.string,
        lang: PropTypes.arrayOf(PropTypes.string),
        topic: PropTypes.arrayOf(PropTypes.string),
        owner: PropTypes.string,
        product: PropTypes.arrayOf(PropTypes.string),
        state: PropTypes.arrayOf(PropTypes.string),
        platform: PropTypes.arrayOf(PropTypes.string),
        platformSyncError: PropTypes.string,
        platformState: PropTypes.arrayOf(PropTypes.string),
        perf: PropTypes.arrayOf(PropTypes.number),
        notdel: PropTypes.string,
        initialInterval: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    setFilters: PropTypes.func.isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
        })
    ),
};

MessageFilterPane.defaultProps = {
    actions: [],
};

export default MessageFilterPane;
