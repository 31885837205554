import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useIdentity } from "../../common/identity-context";
import { ORDER_BY_SPECS } from "../common/bp-table-helper";
import ErrorCallout from "../common/error-callout";
import { buildQueryFilters, useUrlFilters } from "../common/filter-utils";
import Pagination from "../common/pagination";
import { GQL_ALL_MESSAGES_QUERY } from "./gql-messages";
import MessageFilterPane from "./message-filter-pane";
import MessagesTable from "./messages-table";

const LIMIT = 20;

const FILTER_SPECS = {
    title: {
        type: "search",
    },
    wizardMetas: {
        type: "search",
    },
    id: {
        type: "equal",
    },
    lang: {
        type: "in",
    },
    topic: {
        type: "in",
    },
    initialInterval: {
        type: "in",
    },
    owner: {
        type: "search",
    },
    product: {
        type: "in",
        buildKey: "ownerSubscriptionProduct",
    },
    state: {
        type: "in",
    },
    platform: {
        type: "in",
    },
    platformSyncError: {
        type: "search",
    },
    platformState: {
        type: "in",
    },
    notdel: {
        type: "onoff",
        buildKey: "notDelivering",
        queryPayload: {
            days: 3,
            budgetLeft: 500,
        },
    },
    perf: {
        type: "range",
        parse: (v) => v.split(",").map((vs) => parseFloat(vs)),
        format: (v) => v.map((vn) => vn.toFixed(2)).join(","),
    },
    offset: {
        parse: (v) => parseInt(v || "0", 10),
        format: (v) => v,
    },
    order: ORDER_BY_SPECS,
};

const HeaderInfo = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
    .fill {
        flex-grow: 1;
    }
`;

const MessageListPage = () => {
    const history = useHistory();
    const [filters, setFilters] = useUrlFilters(FILTER_SPECS);
    const offset = filters.offset || 0;
    const [loadMessages, { data, loading, error }] = useLazyQuery(GQL_ALL_MESSAGES_QUERY, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
    });
    const [, , , hasPermission] = useIdentity();
    const count = data?.allMessages?.count;

    const nbPages = Math.ceil(count / LIMIT);
    const currentPage = Math.floor(offset / LIMIT) + 1;
    const indices = [offset + 1, Math.min(offset + LIMIT, count)];

    const onChangeSort = (v) => {
        setFilters((old) => ({
            ...old,
            order: v,
        }));
    };

    useEffect(() => {
        if (!("order" in filters)) {
            // If there is no sort order, set the default order
            const currentSearchParams = new URLSearchParams(history.location.search);
            currentSearchParams.append("order", "-insertedAt");
            history.replace({ search: currentSearchParams.toString() });
        }
    }, [filters, history]);

    useEffect(
        () =>
            (async () => {
                await loadMessages({
                    variables: {
                        limit: LIMIT,
                        ...buildQueryFilters(filters, FILTER_SPECS),
                    },
                });
            })(),
        [offset, filters, loadMessages]
    );

    const handleChangePage = async ({ startIndex, endIndex }) => {
        setFilters({ ...filters, offset: startIndex });
    };

    const handleChangeFilters = (newFilters) => {
        setFilters({ ...newFilters, offset: 0 });
    };
    return (
        <div>
            <MessageFilterPane filters={filters} setFilters={handleChangeFilters} />
            <HeaderInfo>
                {(() => {
                    if (loading) {
                        return "Loading, please wait...";
                    }
                    if (indices[1] === 0) {
                        return "No message to display";
                    }
                    return `See messages from ${indices[0]} to ${indices[1]} on a total of ${count}`;
                })()}
            </HeaderInfo>
            <ErrorCallout title="Error while loading messages" error={error} />
            <MessagesTable
                messages={data?.allMessages?.results || []}
                loading={loading}
                onChangeSort={onChangeSort}
                ordering={filters.order}
                hasPermission={hasPermission}
            />

            <HeaderInfo>
                {!loading && <div className="fill">{count} messages</div>}
                <div>
                    {nbPages > 1 ? (
                        <Pagination
                            step={LIMIT}
                            numberItems={count}
                            page={currentPage}
                            numberEdgePages={2}
                            onChange={handleChangePage}
                        />
                    ) : (
                        <div />
                    )}
                </div>
            </HeaderInfo>
        </div>
    );
};

export default MessageListPage;
