import { gql, useMutation } from "@apollo/client";
import Logger from "loglevel";
import { useCallback } from "react";

import { createGraphQLPromise } from "../common/graphql";

export const GQL_QUERY_UPLOAD_ASSET = gql`
    query AssetUploadRequest($fileName: String!) {
        assetUploadRequest(fileName: $fileName) {
            url
            fields
            accessUrl
        }
    }
`;

export const GQL_MUTATION_CREATE_IMAGE = gql`
    mutation CreateImage($title: String!, $url: String!) {
        createImage(title: $title, url: $url) {
            image {
                id
                imageUrl
                height
                width
                type
            }
        }
    }
`;

export const GQL_MUTATION_CREATE_TEXT = gql`
    mutation CreateText($value: String!, $type: String!) {
        createText(value: $value, type: $type) {
            text {
                id
                value
                type
                role
            }
        }
    }
`;

export const GQL_MUTATION_CREATE_VIDEO = gql`
    mutation CreateVideo($title: String!, $url: String!) {
        createVideo(title: $title, url: $url) {
            movie {
                id
                size
                amplifyVideoUrl
                videoUrl
                duration
                height
                width
                image {
                    id
                    imageUrl
                }
                type
                role
            }
        }
    }
`;

const queryAssetUploadPromise = createGraphQLPromise({
    query: GQL_QUERY_UPLOAD_ASSET.loc.source.body,
    resolveSuccess: (data) => data.assetUploadRequest,
});

const useQueryUploadAsset = () => {
    const fetcher = async (variables) => {
        // DO NOT USE APOLLO HERE
        // fixing bug https://github.com/apollographql/apollo-client/issues/9137
        // data return does not contains the correct value using useLazyQuery
        return await queryAssetUploadPromise(variables);
    };
    return [fetcher];
};

const normalUploadFileToS3 = (file, info, onProgress) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", info.url, true);
        const postData = new FormData();
        Object.keys(info.fields).forEach((key) => {
            postData.append(key, info.fields[key]);
        });
        postData.append("file", file);

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200 || xhr.status === 204 || xhr.status === 201) {
                    resolve(info.accessUrl);
                } else {
                    Logger.warn(
                        "If you have CORS errors after redirection, and it is a new S3 bucket you just created, wait an hour and the problem should resolve by itself, it is a know problem"
                    );
                    reject(xhr.responseText || "upload error");
                }
            }
        };
        if (onProgress) {
            xhr.upload.onprogress = (e) => {
                const percent = e.loaded / e.total;
                onProgress(percent);
            };
        }
        xhr.send(postData);
    });
};

const testUploadFileToS3 = (file, info, onProgress) => {
    return new Promise((resolve, reject) => {
        if (onProgress) {
            onProgress(0.5);
        }
        if (!info) {
            setTimeout(() => reject(new Error("Missing info to upload")), 1);
        } else {
            setTimeout(() => resolve(info.accessUrl), 1);
        }
    });
};

const uploadFileToS3 = process.env.NODE_ENV === "test" ? testUploadFileToS3 : normalUploadFileToS3;

export const useUploadAsset = () => {
    const [assetUploadRequestPromise] = useQueryUploadAsset();

    const fun = useCallback(
        async (file, onProgress) => {
            const uploadInfo = await assetUploadRequestPromise({ fileName: file.name });
            return uploadFileToS3(file, uploadInfo, onProgress);
        },
        [assetUploadRequestPromise]
    );
    // returns an async function fx(file) to upload file
    return [fun];
};

export const useMutationCreateImage = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_CREATE_IMAGE);
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationCreateText = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_CREATE_TEXT);
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationCreateVideo = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_CREATE_VIDEO);
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
