export const CLICK_VAL = { value: "click", label: "Ad clicks" };
export const REACH_VAL = { value: "reach", label: "Ad reach" };
export const VIEW_VAL = { value: "view", label: "Ad video view" };
export const CONVERSION_VAL = { value: "conversion", label: "Ad conversion" };
export const PAGE_LIKES = { value: "page_likes", label: "Page likes (Only for FB)" };
export const AUTO_VAL = { value: "", label: "Auto" };

export const FACEBOOK_SPECIAL_CATEGORIES = [
    { value: "HOUSING", label: "Housing" },
    { value: "CREDIT", label: "Credit" },
    { value: "EMPLOYMENT", label: "Employment" },
    { value: "ISSUES_ELECTIONS_POLITICS", label: "Issues / Elections / Politics" },
];
