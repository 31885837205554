import { ReactComponent as FormNoIcon } from "../survey-engine/icons/form_no.svg";
import { ReactComponent as FormOtherIcon } from "../survey-engine/icons/form_other.svg";
import { ReactComponent as FormYesIcon } from "../survey-engine/icons/form_yes.svg";
import { ReactComponent as AttractPeopleIcon } from "./icons/attract_people.svg";
import { ReactComponent as ECommerceIcon } from "./icons/e_commerce.svg";
import { ReactComponent as FinancialServicesIcon } from "./icons/financial_services.svg";
import { ReactComponent as FreelancerIcon } from "./icons/freelancer.svg";
import { ReactComponent as HealthIcon } from "./icons/health.svg";
import { ReactComponent as HospitalityIcon } from "./icons/hospitality.svg";
import { ReactComponent as IncreaseSalesIcon } from "./icons/increase_sales.svg";
import { ReactComponent as InfoTechIcon } from "./icons/info_tech.svg";
import { ReactComponent as LearningIcon } from "./icons/learning.svg";
import { ReactComponent as MediaIcon } from "./icons/media_comm.svg";
import { ReactComponent as Over500Icon } from "./icons/over_500.svg";
import { ReactComponent as PromoteEcommerceIcon } from "./icons/promote_ecommerce.svg";
import { ReactComponent as PromoteEventsIcon } from "./icons/promote_events.svg";
import { ReactComponent as PromoteJobIcon } from "./icons/promote_job.svg";
import { ReactComponent as PromoteServiceIcon } from "./icons/promote_service.svg";
import { ReactComponent as PromoteSocialNetIcon } from "./icons/promote_social_net.svg";
import { ReactComponent as PromoteWebsiteIcon } from "./icons/promote_website.svg";
import { ReactComponent as RealEstateIcon } from "./icons/realestate.svg";
import { ReactComponent as ReduceCostIcon } from "./icons/reduce_cost.svg";
import { ReactComponent as RetailIcon } from "./icons/retail.svg";
import { ReactComponent as UpTo500Icon } from "./icons/upto_500.svg";

export default [
    {
        key: "objective",
        title: "What is your main objective?",
        options: [
            {
                value: "increase_sales",
                label: "Increase sales",
                icon: <IncreaseSalesIcon />,
            },
            {
                value: "promote_ecommerce",
                label: "Promote E-Commerce",
                icon: <PromoteEcommerceIcon />,
            },
            {
                value: "attract_people",
                label: "Attract people in my shop",
                icon: <AttractPeopleIcon />,
            },
            {
                value: "promote_event",
                label: "Promote event",
                icon: <PromoteEventsIcon />,
            },
            {
                value: "reduce_cost",
                label: "Reduce my marketing cost",
                icon: <ReduceCostIcon />,
            },
            {
                value: "promote_job",
                label: "Promote job offers",
                icon: <PromoteJobIcon />,
            },
            {
                value: "promote_website",
                label: "Promote my new website",
                icon: <PromoteWebsiteIcon />,
            },
            {
                value: "promote_service",
                label: "Promote new services/product",
                icon: <PromoteServiceIcon />,
            },
            {
                value: "promote_social_network",
                label: "Promote my social networks",
                icon: <PromoteSocialNetIcon />,
            },
            {
                value: "other",
                label: "Other",
                icon: <FormOtherIcon />,
                inputType: "text",
                inputPlaceHolder: "Describe your main objective here",
                inputValidation: (v) => v && v.length > 0,
            },
        ],
    },
    {
        key: "business_segment",
        title: "What is your business segment?",
        options: [
            {
                value: "financial_services",
                label: "Financial services",
                icon: <FinancialServicesIcon />,
            },
            {
                value: "e_commerce",
                label: "E-commerce",
                icon: <ECommerceIcon />,
            },
            {
                value: "hospitality",
                label: "Hospitality and leisure",
                icon: <HospitalityIcon />,
            },
            {
                value: "media",
                label: "Media and communication",
                icon: <MediaIcon />,
            },
            {
                value: "freelancer",
                label: "Freelancer",
                icon: <FreelancerIcon />,
            },
            {
                value: "real_estate",
                label: "Real estate",
                icon: <RealEstateIcon />,
            },
            {
                value: "learning",
                label: "Learning and education",
                icon: <LearningIcon />,
            },
            {
                value: "health",
                label: "Health and beauty",
                icon: <HealthIcon />,
            },
            {
                value: "info_tech",
                label: "Information technology",
                icon: <InfoTechIcon />,
            },
            {
                value: "retail",
                label: "Retail",
                icon: <RetailIcon />,
            },
        ],
    },
    {
        key: "has_company_name",
        title: "Do you have a company name?",
        options: [
            {
                value: false,
                label: "No",
                icon: <FormNoIcon />,
            },
            {
                value: true,
                label: "Yes",
                icon: <FormYesIcon />,
                inputType: "string",
                inputPlaceHolder: "Enter your company name",
                inputValidation: (v) => v && v.length > 0,
            },
        ],
    },
    {
        key: "monthly_budget",
        title: "What is your current online advertising monthly budget?",
        options: [
            {
                value: "no",
                label: "I don't do any online advertising",
                icon: <FormNoIcon />,
            },
            {
                value: "upto_500",
                label: "I spend up to 500 CHF per month",
                icon: <UpTo500Icon />,
            },
            {
                value: "over_500",
                label: "I spend over 500 CHF per month",
                icon: <Over500Icon />,
            },
        ],
    },
    {
        key: "has_website",
        title: "Do you have a website?",
        options: [
            {
                value: false,
                label: "No",
                icon: <FormNoIcon />,
            },
            {
                value: true,
                label: "Yes",
                icon: <FormYesIcon />,
                inputType: "string",
                inputPlaceHolder: "www.mycompany.com",
                inputValidation: (v) => v && v.length > 0,
            },
        ],
    },
    {
        key: "has_visual_content",
        title: "Do you have visual content for your advertisement?",
        options: [
            {
                value: false,
                label: "No",
                icon: <FormNoIcon />,
            },
            {
                value: true,
                label: "Yes",
                icon: <FormYesIcon />,
            },
        ],
    },
    {
        key: "has_targeting",
        title: "Have you already planned your targeting criterias (target audience, geolocalisation, interests, keywords...)?",
        options: [
            {
                value: false,
                label: "No",
                icon: <FormNoIcon />,
            },
            {
                value: true,
                label: "Yes",
                icon: <FormYesIcon />,
            },
        ],
    },
];
