import { Button, ButtonGroup, Icon, Intent, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const EmptyValue = styled.span`
    opacity: 0.5;
`;

const StyledLabelIcon = styled.div`
    line-height: 0;
    svg {
        max-height: 1.5em;
        max-width: 1.5em;
    }

    .bp4-icon:first-child:last-child {
        margin: 0;
    }
`;

const SimpleSelect = ({
    options,
    selected,
    onSelect,
    filterable,
    loading,
    clearable,
    placeholder,
    outlined,
    fill,
    disabled,
}) => {
    const renderOption = (option, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                icon={selected === option.value ? "tick" : "blank"}
                labelElement={
                    <StyledLabelIcon>
                        <Icon icon={option.icon || "blank"} />
                    </StyledLabelIcon>
                }
                key={option.value}
                onClick={handleClick}
                text={option.label}
                shouldDismissPopover={false}
            />
        );
    };

    const selectedOption = options && options.find((item) => item?.value === selected);

    const areOptionEqual = (a, b) => {
        return a.value === b.value;
    };

    return (
        <Select
            fill={fill}
            popoverProps={{
                minimal: true,
                disabled,
            }}
            items={options || []}
            itemRenderer={renderOption}
            onItemSelect={(item) => onSelect(item.value)}
            filterable={filterable}
            itemPredicate={(query, opt, _index, exactMatch) => {
                const normalizedLabel = opt.label.toLowerCase();
                const normalizedQuery = query.toLowerCase();
                if (exactMatch) {
                    return normalizedLabel === normalizedQuery;
                } else {
                    return normalizedLabel.indexOf(normalizedQuery) >= 0;
                }
            }}
            itemsEqual={areOptionEqual}
        >
            <ButtonGroup fill={fill} style={disabled ? {} : { backgroundColor: "white" }}>
                <Button
                    fill
                    outlined={outlined}
                    className="select-button"
                    intent={Intent.None}
                    alignText="left"
                    rightIcon="double-caret-vertical"
                    text={selectedOption?.label || <EmptyValue>{placeholder || "Select..."}</EmptyValue>}
                    icon={
                        selectedOption?.icon && (
                            <StyledLabelIcon>
                                <Icon icon={selectedOption.icon} />
                            </StyledLabelIcon>
                        )
                    }
                    loading={loading}
                    disabled={disabled}
                />
                {clearable && selectedOption && (
                    <Button
                        intent={Intent.NONE}
                        outlined={outlined}
                        icon="cross"
                        disabled={!selectedOption || disabled || loading}
                        onClick={(e) => {
                            e.preventDefault();
                            onSelect(null);
                            e.stopPropagation();
                        }}
                    />
                )}
            </ButtonGroup>
        </Select>
    );
};

SimpleSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        })
    ).isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    onSelect: PropTypes.func.isRequired,
    filterable: PropTypes.bool,
    loading: PropTypes.bool,
    clearable: PropTypes.bool,
    placeholder: PropTypes.string,
    outlined: PropTypes.bool,
    fill: PropTypes.bool,
    disabled: PropTypes.bool,
};

SimpleSelect.defaultProps = {
    selected: null,
    filterable: false,
    loading: false,
    clearable: false,
    placeholder: null,
    outlined: false,
    fill: false,
    disabled: false,
};

/**
 * We intentionally not setting a StyledSimpleSelect inside the component too allow
 * composition of the Select element in a FormGroup
 */
export default SimpleSelect;
