import { FormGroup, Intent, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownArrow } from "../../img/down-arrow.svg";
import { ReactComponent as UpArrow } from "../../img/up-arrow.svg";

const StyledFormGroup = styled.div`
    position: relative;
    .hidden {
        display: none;
    }
    .button {
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;

const StyledContent = styled.div`
    .hidden {
        display: none;
    }
`;

const StyledArrow = styled.div`
    width: 16px;
    height: 16px;
    margin: 5px;
    vertical-align: middle;
`;

const MessageField = ({
    values,
    onChangeValue,
    name,
    changes = {},
    renderComponent,
    label,
    errors = {},
    loading,
    disabled,
    onRevert,
    collapsed,
}) => {
    const subErrors = Object.keys(errors).reduce((acc, k) => {
        const subKey = `${name}.`;
        if (k.startsWith(subKey)) {
            acc[k.substring(subKey.length)] = errors[k];
        }
        return acc;
    }, {});

    const [visible, setVisible] = useState(false);
    return (
        <StyledFormGroup>
            <FormGroup
                label={label}
                labelInfo={
                    changes[name] && (
                        <Tag minimal intent={Intent.PRIMARY} onRemove={onRevert ? () => onRevert(name) : null}>
                            Has changes
                        </Tag>
                    )
                }
                helperText={errors[name]}
                intent={errors[name] ? Intent.DANGER : changes[name] ? Intent.PRIMARY : Intent.NONE}
            >
                <StyledContent hidden={collapsed && !visible}>
                    {renderComponent({
                        value: values[name],
                        setValue: onChangeValue ? (x) => onChangeValue(name, x) : null,
                        disabled: loading || disabled,
                        errors: subErrors,
                    })}
                </StyledContent>
            </FormGroup>
            <StyledArrow className="button" hidden={!collapsed || visible} onClick={() => setVisible(!visible)}>
                <DownArrow>SHOW</DownArrow>
            </StyledArrow>
            <StyledArrow className="button" hidden={!collapsed || !visible} onClick={() => setVisible(!visible)}>
                <UpArrow>HIDE</UpArrow>
            </StyledArrow>
        </StyledFormGroup>
    );
};

MessageField.propTypes = {
    values: PropTypes.shape({}).isRequired,
    name: PropTypes.string.isRequired,
    renderComponent: PropTypes.func.isRequired,
    label: PropTypes.node.isRequired,
    onChangeValue: PropTypes.func,
    onRevert: PropTypes.func,
    changes: PropTypes.object,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    collapsed: PropTypes.bool,
};

MessageField.defaultProps = {
    onChangeValue: null,
    onRevert: null,
    changes: {},
    errors: {},
    loading: false,
    disabled: false,
    collapsed: false,
};

export default MessageField;
