import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export const TOPICS_QUERY = gql`
    query allTopics {
        allTopics: __type(name: "Topic") {
            name
            enumValues {
                name
            }
        }
    }
`;

export const useQueryTopics = (defaultValue = [], overwrite) => {
    const { loading, error, data } = useQuery(TOPICS_QUERY);
    const result = useMemo(() => {
        if (data && data.allTopics) {
            return data.allTopics.enumValues.map((t) => ({
                label: t.name,
                value: t.name,
            }));
        }
        return [];
    }, [data]);

    return { loading, error, topics: result };
};
