import { Callout, Intent, Spinner } from "@blueprintjs/core";
import React from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import NavBreadcrumb from "../../common/nav-breadcrumb";
import { useQueryMessage } from "../gql-messages";
import NavigationMenu from "../message-menu";
import PlatformsSection from "./platforms-section";

const StyledPlatformsPage = styled.div`
    padding: 10px;
`;

const PlatformsPage = () => {
    const { id } = useParams();
    const { message, loading, error } = useQueryMessage(id);

    return (
        <StyledPlatformsPage>
            {loading && (
                <Callout title="Loading message, please wait...">
                    <Spinner />
                </Callout>
            )}
            {error && (
                <Callout intent={Intent.DANGER} title="Error while loading message">
                    {error.message}
                </Callout>
            )}

            {message ? (
                <div>
                    <NavBreadcrumb
                        items={[
                            { icon: "home", to: "/" },
                            { text: "Messages", icon: <RiAdvertisementLine />, to: "/messages" },
                            {
                                text: message.id,
                            },
                            {
                                text: "Platforms",
                                icon: "search-around",
                                current: true,
                            },
                        ]}
                        currentBreadcrumbRenderer={({ text, icon }) => (
                            <NavigationMenu message={message} text={text} icon={icon} />
                        )}
                    />
                    <PlatformsSection
                        messageId={message.id}
                        platforms={message.platformsInfo}
                        currency={message.currency}
                        disabled={message.state !== "live"}
                    />
                </div>
            ) : (
                !loading &&
                !error && (
                    <Callout intent={Intent.DANGER} title="Nothing found here">
                        {id}
                    </Callout>
                )
            )}
        </StyledPlatformsPage>
    );
};

export default PlatformsPage;
