import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { fetchListOfCustomAudiences } from "../../common/gql-promises";
import SimpleMultiSelect from "../common/simple-multi-select";
import SimpleSelect from "../common/simple-select";
import {
    AUTO_VAL,
    CLICK_VAL,
    CONVERSION_VAL,
    FACEBOOK_SPECIAL_CATEGORIES,
    PAGE_LIKES,
    REACH_VAL,
    VIEW_VAL,
} from "../goals";
import { useQueryTopics } from "../topics";
import TargetingProfileChooser from "./targeting-profile-chooser";

export const DEFAULT_DATA = {
    businessName: null,
    topic: null,
    goal: "",
    targetingProfile: null,
    facebookSpecialCategories: [],
    facebookCustomAudiences: [],
};

export const asGraphqlParameters = (data) => {
    return {
        businessName: data.businessName,
        goal: data.goal || null,
        topic: data.topic,
        facebookSpecialCategories: data.facebookSpecialCategories,
        facebookCustomAudiences: data.facebookCustomAudiences,
        targetingProfileId: data.targetingProfile?.id,
    };
};

const NewReviewForm = ({ message, data = {}, setData }) => {
    const { loading, topics } = useQueryTopics();
    const [facebookCustomAudiences, setFacebookCustomAudiences] = useState(null);

    const handleSetData = useCallback(
        (key, value) => {
            setData((old) => ({
                ...(old || {}),
                [key]: value,
            }));
        },
        [setData]
    );
    useEffect(() => {
        handleSetData("targetingProfile", message?.targetingProfile);
    }, [handleSetData, message]);

    useEffect(() => {
        async function fetchData() {
            const fbResult = await fetchListOfCustomAudiences({ messageId: message.messageId, platform: "facebook" });
            return fbResult;
        }
        fetchData().then((r) => {
            setFacebookCustomAudiences(r.map((v) => ({ value: v.id, label: v.name })));
        });
    }, [message.messageId]);

    const availableGoals = useMemo(() => {
        const result = [AUTO_VAL, CLICK_VAL, REACH_VAL, PAGE_LIKES];
        if (message.format === "video") {
            result.push(VIEW_VAL);
        }
        //if (message.landing) {
        result.push(CONVERSION_VAL);
        //}
        return result;
    }, [message]);
    return (
        <>
            <FormGroup
                label="Owner business name"
                labelInfo="(required)"
                helperText="ATTENTION. this data will be public and used for all the ads of the customer"
                intent={Intent.DANGER}
            >
                <InputGroup
                    value={data.businessName || message.owner.business?.billingAddress?.company}
                    onChange={(v) => {
                        handleSetData("businessName", v.target.value);
                    }}
                    maxLength={100}
                />
            </FormGroup>
            <FormGroup label="Amplify topic" labelInfo="(required)">
                <SimpleSelect
                    fill
                    outlined
                    options={topics}
                    selected={data.topic}
                    onSelect={(v) => handleSetData("topic", v)}
                    disable={loading}
                />
            </FormGroup>
            <FormGroup label="Targeting profile">
                <TargetingProfileChooser
                    disable={loading}
                    onChange={(v) => handleSetData("targetingProfile", v)}
                    value={data.targetingProfile}
                />
            </FormGroup>
            <FormGroup
                label="Ad goal"
                helperText="ATTENTION. select auto if you dont know what you are doing"
                intent={Intent.DANGER}
            >
                <SimpleSelect
                    fill
                    outlined
                    options={availableGoals}
                    onSelect={(v) => handleSetData("goal", v)}
                    selected={data.goal || message?.adGoal}
                />
            </FormGroup>
            <FormGroup label="Facebook special categories">
                <SimpleMultiSelect
                    value={data.facebookSpecialCategories}
                    onChange={(v) => handleSetData("facebookSpecialCategories", v)}
                    options={FACEBOOK_SPECIAL_CATEGORIES}
                />
            </FormGroup>
            {facebookCustomAudiences && (
                <FormGroup label="Facebook custom audiences">
                    <SimpleMultiSelect
                        value={data.facebookCustomAudiences}
                        onChange={(v) => handleSetData("facebookCustomAudiences", v)}
                        options={facebookCustomAudiences}
                    />
                </FormGroup>
            )}
        </>
    );
};

NewReviewForm.propTypes = {
    data: PropTypes.shape({}).isRequired,
    setData: PropTypes.func.isRequired,
    message: PropTypes.shape({
        messageId: PropTypes.string.isRequired,
        format: PropTypes.string.isRequired,
        landing: PropTypes.any,
        owner: PropTypes.shape({
            business: PropTypes.shape({
                billingAddress: PropTypes.shape({
                    company: PropTypes.string,
                }),
            }),
        }),
        targetingProfile: PropTypes.shape({}),
        adGoal: PropTypes.string,
    }).isRequired,
};
export default NewReviewForm;
