import { Button, Divider, Intent, Menu, MenuDivider, MenuItem, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Cell, Column, SelectionModes, Table2 } from "@blueprintjs/table";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { DialogContext } from "../bp-dialog-context";
import { buildQuestionDialog } from "../dialogs/helpers";
import { getPlatformIcon } from "../platforms";
import { useMutationCloneTargetingProfile, useMutationDeleteTargetingProfile } from "./gql-profile";

const StyledProfileTable = styled.div`
    .bp4-table-cell {
        align-items: center;
        display: flex;
        .striked {
            text-decoration: line-through;
        }
    }
    small {
        font-size: 10px;
    }
`;

const byPlatforms = (items) => {
    return items.reduce((acc, item) => {
        const current = acc[item.platform] || 0;
        acc[item.platform] = current + 1;
        return acc;
    }, {});
};

const renderCellMenu = (profile, { history, url, showDialog, cloneTargeting, deleteTargeting }) => {
    const cloneProfileWithId = async (id) => {
        const ret = await cloneTargeting(id);
        toast.success("Profile duplicated successfully");
        history.push(`${url}/${ret.id}`);
    };

    const editProfileWithId = (id) => {
        history.push(`${url}/${id}`);
    };

    const deleteProfileWithId = async (profile) => {
        const ret = await showDialog(
            buildQuestionDialog({
                title: "Delete targeting profile",
                body: `Do you really want to remove profile ${profile.title} from targeting profile?`,
                actions: [
                    {
                        text: "Yes DELETE IT",
                        code: 1,
                        icon: "delete",
                        intent: Intent.DANGER,
                    },
                ],
            })
        );
        if (ret.code === 1) {
            await deleteTargeting(profile.id);
            toast.success("Profile DELETED successfully");
        }
    };

    return (
        <Cell style={{ padding: 0 }}>
            {window.ALLOW_TARGETINGS_EDITION && (
                <Popover2
                    content={
                        <Menu>
                            <MenuDivider title="Actions" />
                            <MenuItem
                                icon="duplicate"
                                onClick={() => cloneProfileWithId(profile.id)}
                                text="Duplicate"
                            />
                            <MenuItem
                                icon={profile.usedCount > 0 ? "eye-open" : "edit"}
                                onClick={() => editProfileWithId(profile.id)}
                                text={profile.usedCount > 0 ? "Show profile" : "Edit profile"}
                            />
                            <Divider />
                            <MenuItem
                                icon="delete"
                                text="Delete"
                                intent={Intent.DANGER}
                                onClick={() => deleteProfileWithId(profile)}
                            />
                        </Menu>
                    }
                >
                    <Button large minimal icon="menu" />
                </Popover2>
            )}
        </Cell>
    );
};

const ProfileTable = ({ profiles }) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { showDialog } = useContext(DialogContext);
    const cloneTargeting = useMutationCloneTargetingProfile();
    const deleteTargeting = useMutationDeleteTargetingProfile();

    const [columnWidths, setColumnWidths] = useState([40, 200, 80, 200, 200, 100]);
    return (
        <StyledProfileTable className="amp-table-container">
            <Table2
                enableGhostCells
                numRows={profiles.length}
                enableRowHeader={false}
                defaultRowHeight={40}
                fill
                columnWidths={columnWidths}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
                selectionModes={SelectionModes.ROWS_AND_CELLS}
                selectedRegionTransform={(e) => {
                    if (!e.rows) {
                        return e;
                    }
                    return {
                        rows: [e.rows[0], e.rows[0]],
                    };
                }}
            >
                <Column
                    name=""
                    cellRenderer={(index) =>
                        renderCellMenu(profiles[index], { history, url, showDialog, cloneTargeting, deleteTargeting })
                    }
                />
                <Column name="Name" cellRenderer={(index) => <Cell>{profiles[index].title}</Cell>} />
                <Column name="Used" cellRenderer={(index) => <Cell>{profiles[index].usedCount}</Cell>} />
                <Column name="Owner" cellRenderer={(index) => <Cell>{profiles[index].owner.username}</Cell>} />
                <Column
                    name="Platforms"
                    cellRenderer={(index) => (
                        <Cell>
                            {Object.entries(byPlatforms(profiles[index].items)).map(([platform, count]) => (
                                <span key={platform}>
                                    <Tag icon={getPlatformIcon(platform, { key: platform, size: "14px" })} minimal>
                                        {count}
                                    </Tag>
                                    &nbsp;
                                </span>
                            ))}
                        </Cell>
                    )}
                />
                <Column
                    name=""
                    cellRenderer={(index) => (
                        <Cell>
                            {window.ALLOW_TARGETINGS_EDITION && (
                                <Button
                                    outlined
                                    minimal
                                    small
                                    intent={profiles[index].usedCount > 0 ? Intent.NONE : Intent.PRIMARY}
                                    text={profiles[index].usedCount > 0 ? "show" : "edit"}
                                    icon={profiles[index].usedCount > 0 ? "eye-open" : "edit"}
                                    onClick={() => history.push(`${url}/${profiles[index].id}`)}
                                />
                            )}
                        </Cell>
                    )}
                />
            </Table2>
        </StyledProfileTable>
    );
};

ProfileTable.propTypes = {
    profiles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            usedCount: PropTypes.number.isRequired,
            owner: PropTypes.shape({
                username: PropTypes.string.isRequired,
            }).isRequired,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    platform: PropTypes.string.isRequired,
                })
            ),
        })
    ).isRequired,
};
export default ProfileTable;
