import { createGraphQLPromise } from "./graphql";

export const USER_FRAGMENT = `
    fragment user on User {
        username
        firstName
        lastName
        email
        avatarUrl
        location
        lang
        isStaff
        isMe
        id
        memberships
        serviceSubscription {
            product {
                name
                interval
            }
            status
            startedAt
            cancelAt
            canceledAt
            nextDueDate
        }
        staffNotificationCount
        intercomUserHash
        dateJoined
        business {
            id
            facebookData {
                selectedPageName
                selectedPageId
            }
        }
        liveMessageCount
    }
`;

export const getIdentity = createGraphQLPromise({
    name: "me",
    query: `
        query identity {
            identity {
                ...user
            }
        }

        ${USER_FRAGMENT}
    `,
    resolveSuccess: (data) => {
        return data.identity;
    },
});

export const logoutPromise = createGraphQLPromise({
    query: `
        mutation Logout {
            logout {
                ok
            }
        }
    `,
    resolveSuccess: (data) => {
        return data.logout;
    },
});

export const createStripeSubscriptionPromise = createGraphQLPromise({
    query: `
        mutation CreateStripeSubscription(
            $currency: Currency!
            $amount: Int!
            $paymentMethodId: String
            $messageId: UUID!
        ) {
            createStripeSubscription(
                currency: $currency,
                amount: $amount,
                paymentMethodId: $paymentMethodId,
                messageId: $messageId
            ) {
                status,
                intentId,
                clientSecret,
                needAction
            }
        }
    `,
    resolveSuccess: (data) => data.createStripeSubscription,
});

export const cancelStripeSubscriptionPromise = createGraphQLPromise({
    query: `
        mutation CancelStripeSubscription(
            $messageId: UUID!
        ) {
            cancelStripeSubscription(
                messageId: $messageId
            ) {
                status
            }
        }
    `,
    resolveSuccess: (data) => data.cancelStripeSubscription,
});

export const createStripeIntentPromise = createGraphQLPromise({
    query: `
        mutation CreateStripeIntent(
            $currency: Currency!
            $amount: Int!
            $messageId: UUID!
        ) {
            createStripeIntent(
                currency: $currency,
                amount: $amount,
                messageId: $messageId
            ) {
                clientSecret
                intentId
            }
        }
    `,
    resolveSuccess: (data) => data.createStripeIntent,
});

export const getPaymentPromise = createGraphQLPromise({
    query: `
        query GetPayment(
            $id: String!
            $refresh: Boolean
        ) {
            getPayment(
                id: $id
                refresh: $refresh
            ) {
                paymentId
                status
                lastPaymentErrorMessage
            }
        }
    `,
    resolveSuccess: (data) => data.getPayment,
});

export const retryStripeIntentPromise = createGraphQLPromise({
    query: `
        mutation RetryStripeIntent(
            $messageId: UUID
            $intentId: String!
            $useDefaultPaymentMethod: Boolean!
        ) {
            retryStripeIntent(
                messageId: $messageId
                intentId: $intentId
                useDefaultPaymentMethod: $useDefaultPaymentMethod
            ) {
                clientSecret
                intentId
                paymentMethodId
                needAction
            }
        }
    `,
    resolveSuccess: (data) => data.retryStripeIntent,
});

export const updateStripePaymentProfilePromise = createGraphQLPromise({
    query: `
        mutation UpdateStripePaymentProfile(
            $paymentMethodId: String!
        ) {
            updateStripePaymentProfile(
                paymentMethodId: $paymentMethodId
            ) {
                status
                errorMessage
            }
        }
    `,
    resolveSuccess: (data) => data.updateStripePaymentProfile,
});

export const removeStripePaymentProfilePromise = createGraphQLPromise({
    query: `
        mutation RemoveStripePaymentProfile {
            removeStripePaymentProfile {
                status
            }
        }
    `,
    resolveSuccess: (data) => data.removeStripePaymentProfile,
});
