import { Intent, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import FileButton from "../../common/bp-file-button";
import SimpleSelect from "../../common/simple-select";
import VideoPlayer from "../../common/video-player";
import { useMutationCreateImage, useMutationCreateVideo, useUploadAsset } from "../../gql-assets";
import { StyledPropertiesAsset } from "./field-text";

export const StyledVideo = styled.div`
    max-width: 480px;
    max-height: 480px;
    margin-bottom: 5px;
`;

const StyledFieldVideo = styled.div`
    border-radius: 3px;
    background-color: ${({ theme, $disabled }) => ($disabled ? theme.lightGrayColor : "white")};
    border: 1px solid ${({ theme }) => theme.grayColor};
    padding: 5px;
`;

const video_roles = [
    {
        value: "default",
        label: "Default",
    },
];
const video_types = [
    {
        value: "video",
        label: "Video",
    },
];
const FieldVideo = ({ index, value, disabled, onChange }) => {
    const [progress, setProgress] = useState(null);
    const [progressImage, setProgressImage] = useState(null);
    const [uploadAsset] = useUploadAsset();
    const [createVideo] = useMutationCreateVideo();
    const [createImage] = useMutationCreateImage();
    const handleSetVideo = async (file) => {
        try {
            setProgress(0);
            const assetUrl = await uploadAsset(file, setProgress);
            const createdVideoResult = await createVideo({
                title: file.name,
                url: assetUrl,
            });
            onChange(createdVideoResult.data.createVideo.movie);
        } finally {
            setProgress(null);
        }
    };
    const handleSetVideoImage = async (file) => {
        try {
            setProgressImage(0);
            const assetUrl = await uploadAsset(file, setProgressImage);
            const createdImageResult = await createImage({
                title: file.name,
                url: assetUrl,
            });
            const image = createdImageResult.data.createImage.image;
            onChange({ ...value, image });
        } finally {
            setProgressImage(null);
        }
    };
    if (!value) {
        return (
            <StyledFieldVideo $disabled={disabled}>
                <p>
                    <b>NEW VIDEO</b>
                </p>
                <div className="actions">
                    {!disabled && (
                        <FileButton
                            inputId={"field-video-set-image-" + index}
                            text="Set video..."
                            disabled={disabled}
                            onFileUpload={handleSetVideo}
                            progress={progress}
                            icon="folder-open"
                            title="Select a video an upload it"
                        />
                    )}
                </div>
            </StyledFieldVideo>
        );
    }
    return (
        <StyledFieldVideo $disabled={disabled}>
            <p>
                <b>VIDEO {index}</b>
            </p>
            <StyledVideo>
                <VideoPlayer width={480} videoUrl={value.amplifyVideoUrl} imageUrl={value.image?.imageUrl} />
            </StyledVideo>
            <div className="actions">
                {!disabled && (
                    <>
                        <FileButton
                            inputId={"field-video-set-image-" + index}
                            text="Change video..."
                            disabled={disabled}
                            onFileUpload={handleSetVideo}
                            progress={progress}
                            icon="mobile-video"
                            rightIcon="folder-open"
                            title="Select a video an upload it"
                        />
                        &nbsp;&nbsp;
                        <FileButton
                            inputId={"field-video-change-image-" + index}
                            text="Change thumbnail..."
                            disabled={disabled}
                            onFileUpload={handleSetVideoImage}
                            progress={progressImage}
                            icon="media"
                            rightIcon="folder-open"
                            title="Replace the thumbnail image"
                        />
                    </>
                )}
                <StyledPropertiesAsset>
                    {!disabled && (
                        <div>
                            <Tag minimal active intent={Intent.SUCCESS}>
                                Type :{" "}
                            </Tag>
                            <SimpleSelect
                                disabled={disabled}
                                options={video_types}
                                onSelect={(v) => onChange({ ...value, type: v })}
                                selected={value?.type}
                            />
                        </div>
                    )}
                    {!disabled && (
                        <div>
                            <Tag minimal active intent={Intent.PRIMARY}>
                                Role :{" "}
                            </Tag>
                            <SimpleSelect
                                disabled={disabled}
                                options={video_roles}
                                onSelect={(v) => onChange({ ...value, role: v })}
                                selected={value?.role}
                            />
                        </div>
                    )}
                </StyledPropertiesAsset>
            </div>
        </StyledFieldVideo>
    );
};

FieldVideo.propTypes = {
    value: PropTypes.shape({
        amplifyVideoUrl: PropTypes.string.isRequired,
        image: PropTypes.shape({
            imageUrl: PropTypes.string.isRequired,
        }),
        role: PropTypes.string,
        type: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    index: PropTypes.number.isRequired,
};

FieldVideo.defaultProps = {
    value: null,
    disabled: false,
};

export default FieldVideo;
