import React, { useCallback, useState } from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { peek } from "../../../common/utils";
import ErrorCallout from "../../common/error-callout";
import NavBreadcrumb from "../../common/nav-breadcrumb";
import { useMutationCreateMessage } from "../gql-messages";
import CreateForm from "./create-form";

const StyledMessageCreatePage = styled.div`
    background-color: ${({ theme }) => theme.editorBackgroundColor};
`;

const MessageCreatePage = () => {
    const history = useHistory();
    const [createMessage, { loading: saving, error }] = useMutationCreateMessage();
    const [disablePrompt, setDisablePrompt] = useState(false);

    const handleCreate = useCallback(
        async (data) => {
            const saveResult = await createMessage({
                message: peek(data, [
                    "topic",
                    "currency",
                    "title",
                    "format",
                    // undefined will remove the key, it is different from setting the key to null
                    //TODO ...
                    ["image", "imageId", (image) => image?.id || undefined],
                    ["movie", "movieId", (movie) => movie?.id || undefined],
                    ["movie", "movieImageId", (movie) => movie?.image?.id || undefined],
                    "adLink",
                ]),
            });
            const newMessageId = saveResult?.data?.createMessage.message.id;
            if (newMessageId) {
                setDisablePrompt(true);
                toast.success("Message created");
                history.replace(`/messages/${newMessageId}/edit/`);
            }
        },
        [createMessage, history]
    );
    return (
        <StyledMessageCreatePage>
            <CreateForm
                onNewMessage={handleCreate}
                loading={saving}
                disablePrompt={disablePrompt}
                header={
                    <div>
                        <NavBreadcrumb
                            items={[
                                { icon: "home", to: "/" },
                                { text: "Messages", icon: <RiAdvertisementLine />, to: "/messages" },
                                saving
                                    ? {
                                          icon: "",
                                          text: "saving...",
                                      }
                                    : {
                                          text: "Create new",
                                      },
                            ]}
                        />
                        <ErrorCallout title="Error while creating message" error={error} />
                    </div>
                }
            />
        </StyledMessageCreatePage>
    );
};

export default MessageCreatePage;
