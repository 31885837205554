import { Button, Callout, Card, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import MapboxCoordRangeView from "../../common/mapbox-coord-range-view";
import Box from "../common/box";
import Search from "../search";
import { useQueryPlatformGeoloc } from "./gql-targeting";

const StyledJSON = styled.div`
    background-color: antiquewhite;
`;

const TargetingPlatformCell = ({ targeting, onSelect }) => (
    <Card>
        <h5>
            {targeting.name} [{targeting.type || targeting.location_type}]
        </h5>
        <StyledJSON>
            <div className="json-data">{JSON.stringify(targeting, null, 4)}</div>
        </StyledJSON>
        <br />
        <Button
            text="select this one"
            intent={Intent.PRIMARY}
            plain={false}
            size="small"
            icon="paperclip"
            onClick={onSelect}
            alignText="left"
            rightIcon="arrow-right"
        />
    </Card>
);

TargetingPlatformCell.propTypes = {
    targeting: PropTypes.shape({
        name: PropTypes.string.isRequired,
        location_type: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
};

const TargetingPlatform = ({ platform, onSelect, searchValue }) => {
    const [search, setSearch] = useState(searchValue || "");

    const [loadPlatformGeoloc, { loading, results, updateQuery }] = useQueryPlatformGeoloc();

    const doSearch = useCallback(async () => {
        await loadPlatformGeoloc({
            platform,
            q: search,
        });
    }, [loadPlatformGeoloc, platform, search]);

    const clearResult = useCallback(() => {
        updateQuery(() => ({ searchPlatformGeoloc: null }));
    }, [updateQuery]);

    useEffect(() => {
        setSearch(searchValue);
    }, [searchValue]);

    useEffect(() => {
        clearResult();
    }, [platform, clearResult]);

    return (
        <>
            <div>
                <h2>Set or replace {platform} data</h2>

                <Box pad="small">
                    <Search setValue={setSearch} value={search} onSearch={doSearch} loading={loading} />
                </Box>
                {loading && <Callout>Loading...</Callout>}
                {results && results.length > 0 && (
                    <div>
                        {results.map((t) => (
                            <TargetingPlatformCell
                                key={t.key || t.targeting_value || t.urn}
                                targeting={t}
                                onSelect={() => {
                                    onSelect(t);
                                    clearResult();
                                }}
                            />
                        ))}
                    </div>
                )}
                {results && results.length === 0 && <Callout>Nothing found with that name</Callout>}
            </div>
            {platform === "facebook" || platform === "instagram" ? (
                <div>
                    <h2>Custom locations</h2>
                    <p>
                        Drag & drop the marker to the center of the required region, then set the radius and hit the{" "}
                        <b>SET</b> button when you're ready, it will replace the current JSON
                    </p>
                    <MapboxCoordRangeView
                        onSelect={(json) => {
                            onSelect(json);
                            clearResult();
                        }}
                    />
                </div>
            ) : null}
        </>
    );
};

TargetingPlatform.propTypes = {
    platform: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
};

TargetingPlatform.defaultProps = {
    searchValue: "",
};

export default TargetingPlatform;
