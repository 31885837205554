import { Button, ButtonGroup, Classes, Intent, Menu, MenuItem, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import classNames from "classnames";
import log from "loglevel";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import GeoTargetingChooser, { getItemInfo } from "../../common/geo-targeting-chooser";

const CLIPBOARD_TYPE = "geoTargetingList";

const StyledGeoTargetingField = styled.div`
    .field-list {
        display: flex;
        flex-wrap: wrap;
        border-radius: 3px;
        padding: 3px;
        background-color: white;
        min-height: 1em;
        & > *:not(:last-child) {
            margin-right: 5px;
        }
    }
`;

const GeoTargetingField = ({ value, onChange, disabled }) => {
    const [searchOpen, setSearchOpen] = useState(false);

    const removeItem = useCallback(
        (id) => {
            onChange(value.filter((v) => v.id !== id));
        },
        [onChange, value]
    );

    const addItem = useCallback(
        ({ _typename, ...item }) => {
            onChange([...value, item]);
        },
        [onChange, value]
    );

    const handleCopy = useCallback(async () => {
        const permission = await navigator.permissions.query({ name: "clipboard-write" });
        if (permission.state === "denied") {
            throw new Error("Not allowed to write clipboard.");
        }
        const content = JSON.stringify({
            type: CLIPBOARD_TYPE,
            data: value,
        });
        await navigator.clipboard.writeText(content);
    }, [value]);

    const handlePaste = useCallback(async () => {
        try {
            const permission = await navigator.permissions.query({ name: "clipboard-read" });
            if (permission.state === "denied") {
                throw new Error("Not allowed to read clipboard.");
            }
            const contentText = await navigator.clipboard.readText();
            const jsonContent = JSON.parse(contentText);
            if (jsonContent.type === CLIPBOARD_TYPE) {
                const geoData = jsonContent.data;
                const already = [];
                const result = [...value, ...geoData].filter((e) => {
                    if (already.includes(e.id)) {
                        return false;
                    }
                    already.push(e.id);
                    return true;
                });
                onChange(result);
            }
        } catch (error) {
            log.error(error);
        }
    }, [onChange, value]);
    const fieldClasses = [Classes.TAG_INPUT, Classes.INPUT, Classes.LARGE];
    if (disabled) {
        fieldClasses.push(Classes.DISABLED);
    }
    return (
        <StyledGeoTargetingField $disabled={disabled}>
            <div className={classNames(fieldClasses)}>
                <div className={classNames([Classes.TAG_INPUT_VALUES])}>
                    {(value || []).map((v) => (
                        <Tag
                            {...getItemInfo(v)}
                            large
                            key={v.id}
                            minimal
                            intent={disabled ? Intent.NONE : Intent.PRIMARY}
                            onRemove={disabled ? null : () => removeItem(v.id)}
                        >
                            {getItemInfo(v).label}
                        </Tag>
                    ))}
                </div>
                &nbsp;
                <ButtonGroup>
                    {!disabled && (
                        <Button
                            alignText="left"
                            rightIcon="plus"
                            disabled={disabled}
                            loading={searchOpen}
                            intent={Intent.SUCCESS}
                            text="Add"
                            onClick={() => setSearchOpen(true)}
                        />
                    )}
                    <Popover2
                        interactionKind="click"
                        placement="bottom"
                        content={
                            <Menu>
                                <MenuItem
                                    text="Copy all"
                                    icon="duplicate"
                                    disabled={!value || value.length === 0}
                                    onClick={handleCopy}
                                />
                                <MenuItem text="Paste" icon="clipboard" disabled={disabled} onClick={handlePaste} />
                            </Menu>
                        }
                        renderTarget={({ isOpen, ref, ...targetProps }) => (
                            <Button {...targetProps} elementRef={ref} icon="chevron-down" />
                        )}
                    />
                </ButtonGroup>
            </div>
            <GeoTargetingChooser
                existing={value}
                onSelect={(item) => {
                    setSearchOpen(false);
                    addItem(item);
                }}
                isOpen={!disabled && searchOpen}
                onClose={() => setSearchOpen(false)}
            />
        </StyledGeoTargetingField>
    );
};

GeoTargetingField.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({})),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

GeoTargetingField.defaultProps = {
    value: null,
    disabled: false,
};

export default GeoTargetingField;
