import { USER_FRAGMENT } from "./gql-actions";
import { createGraphQLPromise } from "./graphql";

export const GEO_TARGETING_FRAGMENT = `
    fragment targetingLinks on Targeting {
        id
        name
        localeName
        countryLocaleName
        subtype
        platforms
        isoCode
        geojson
    }
`;

export const refundMessageBackerPromise = createGraphQLPromise({
    query: `
        mutation RefundPayment($paymentId: String!) {
            refundPayment(paymentId: $paymentId) {
                result
            }
        }
    `,
    resolveSuccess: (data) => data.refundPayment.result,
});

export const searchTargetingPromise = createGraphQLPromise({
    query: `
        query QueryLocation($q: String!, $country: String) {
            searchGeoTargeting(q: $q, country: $country) {
                ...targetingLinks
            }
        }

        ${GEO_TARGETING_FRAGMENT}
    `,
    resolveSuccess: (data) => data.searchGeoTargeting,
});

export const searchInterestsPromise = createGraphQLPromise({
    query: `
        query QueryInterests($q: String!) {
            searchInterests(q: $q) {
                id
                name
                localeName
            }
        }
    `,
    resolveSuccess: (data) => data.searchInterests,
});

export const searchUserPromise = createGraphQLPromise({
    query: `
        query QueryUsers($offset: Int, $limit: Int, $q: String!) {
            searchUsers(offset: $offset, limit: $limit, q: $q) {
                results {
                    ...user
                }
            }
        }

        ${USER_FRAGMENT}
    `,
    resolveSuccess: (data) => data.searchUsers.results,
});

export const getRootUserAgencyPromise = createGraphQLPromise({
    query: `
        query getRootUserAgency {
            getRootUserAgency {
                ...user
                wallets {
                    id
                    balance
                    currency
                }
            }
        }

        ${USER_FRAGMENT}
    `,
    resolveSuccess: (data) => data.getRootUserAgency,
});

export const getCountriesPromise = createGraphQLPromise({
    query: `
        query getCountries {
            getCountries {
                isoCode
                name
            }
        }
    `,
    resolveSuccess: (data) => data.getCountries,
});

export const searchTargetingProfilePromise = createGraphQLPromise({
    query: `
        query QueryTargetingProfiles($q: String!, $offset: Int) {
            searchTargetingProfiles(q: $q, offset: $offset) {
                count
                nextOffset
                result {
                    id
                    title
                    platforms
                }
            }
        }
    `,
    resolveSuccess: (data) => data.searchTargetingProfiles.result || [],
});

export const getCouponInfo = createGraphQLPromise({
    query: `
        query getCouponInfo($code: String!) {
            getCouponInfo(code:$code) {
                amount
            }
        }
    `,
    resolveSuccess: (data) => data.getCouponInfo,
});

export const getUserPaymentProfile = createGraphQLPromise({
    query: `
        query getUserPaymentProfile {
            getUserPaymentProfile {
                cardBrand
                cardExpYear
                cardExpMonth
                cardLast4
                paymentMethodId
            }
        }
    `,
    resolveSuccess: (data) => data.getUserPaymentProfile,
});

export const getUserPaymentHistory = createGraphQLPromise({
    query: `
        query getUserPaymentHistory {
            getUserPaymentHistory {
                id
                currency
                paidAmount
                insertedAt
                invoiceUrl
                isSubscription
                paymentType
                status
                paymentId
                message {
                    id
                    title
                    editUrl
                    state
                }
                serviceSubscription {
                    product {
                        name
                    }
                }
            }
        }
    `,
    resolveSuccess: (data) => data.getUserPaymentHistory,
});

export const fetchPriceInfo = createGraphQLPromise({
    query: `
        query getPrices($adAmount: Int!) {
            getPriceInfo(adAmount: $adAmount) {
                vatAmount
                adAmount
                transactionFeeAmount
                paidAmount
                vat
                amplifyFee
              }
        }
    `,
    resolveSuccess: (data) => data.getPriceInfo,
});

export const fetchMessageInsights = createGraphQLPromise({
    query: `
        query getMessageInsights($messageId: UUID!) {
            getMessageInsights(messageId: $messageId) {
                conversionCount
              }
        }
    `,
    resolveSuccess: (data) => data.getMessageInsights,
});

export const fetchLandingInsights = createGraphQLPromise({
    query: `
        query getLandingInsights($messageId: UUID!) {
            getLandingInsights(messageId: $messageId) {
                visitCount
                conversionCount
                contactCount
                contactsDownloadUrl
              }
        }
    `,
    resolveSuccess: (data) => data.getLandingInsights,
});

export const fetchListOfCustomAudiences = createGraphQLPromise({
    query: `
        query ListAdAccountCustomAudiencesForMessage($messageId: UUID!, $platform: String!) {
            listAdAccountCustomAudiencesForMessage(messageId: $messageId, platform: $platform)
        }
    `,
    resolveSuccess: (data) => data.listAdAccountCustomAudiencesForMessage,
});
