import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import _transform from "lodash/transform";
import moment from "moment";

export function getIn(obj, key, def = undefined) {
    const result = key.split(".").reduce((o, i) => (o !== undefined && o !== null ? o[i] : undefined), obj);
    if (result === undefined) {
        return def;
    }
    return result;
}

export function formatSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1000 ** i).toFixed(1)} ${sizes[i]}`;
}

export function formatScience(value, sizes = ["", "K", "M", "G", "T"]) {
    if (value === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(value) / Math.log(1000)), 10);
    if (i === 0) return `${value} ${sizes[i]}`;
    return `${(value / 1000 ** i).toFixed(1)} ${sizes[i]}`;
}

export function isObject(val) {
    if (val === null) {
        return false;
    }
    return typeof val === "function" || typeof val === "object";
}

export function omitDeep(value, keys) {
    if (typeof value === "undefined") {
        return undefined;
    }

    if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
            value[i] = omitDeep(value[i], keys);
        }
        return value;
    }

    if (!isObject(value)) {
        return value;
    }

    if (typeof keys === "string") {
        keys = [keys];
    }

    if (!Array.isArray(keys)) {
        return value;
    }

    for (let j = 0; j < keys.length; j++) {
        delete value[keys[j]];
    }

    for (let key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
            value[key] = omitDeep(value[key], keys);
        }
    }

    return value;
}

/**
 *  @callback getValue
 *  @param {String} value
 *  @return {Object}
 */

/**
 * peek value in a map
 * @param {Object} obj
 * @param {Array<
 *    String |
 *    Array<{key: String, func: getValue}> |
 *    Array<{key: String, newKey: String, func: getValue}>
 *  >} keys
 *  If array element is:
 *  - a single string: will simply get the key in `obj` and return its value
 *  - an array of [string, func]: will call `func` to get the new value of `key`
 *  If [string, string, func], will call `func` to get the new value of `key` and rename it to `newKey`
 *  i.e
 *  peek(
 *      {a: "1", b: 2},
 *      [
 *        "a",
 *        ["b", v => v * 2 ],
 *        ["b", "c", v => v * 3 ]
 *      ]
 *  )
 *  > {"a": 1, "b": 4, "c": 6}
 *
 *  useful to chain peek for sub-objects
 * @returns {*}
 */
export function peek(obj, keys) {
    return keys.reduce((acc, k) => {
        if (Array.isArray(k)) {
            if (k.length === 2) {
                const [key, fun] = k;
                if (key in obj) {
                    const ret = fun.call(null, obj[key]);
                    if (ret !== undefined) {
                        acc[key] = ret;
                    }
                }
            } else if (k.length === 3) {
                const [key, newKey, fun] = k;
                if (key in obj) {
                    const ret = fun.call(null, obj[key]);
                    if (ret !== undefined) {
                        acc[newKey] = ret;
                    }
                }
            } else {
                throw new RangeError("Array parity should be 2 or 3 no more");
            }
        } else if (k in obj) {
            const ret = obj[k];
            if (ret !== undefined) {
                acc[k] = ret;
            }
        }
        return acc;
    }, {});
}

export function currentReach(message, dateNow, def = 0) {
    if (message.stats.reachCount > 0) {
        return message.stats.reachCount;
    }
    if (!message.liveEndDate) {
        return def;
    }

    // [==========X--]
    // linear regression on (liveEndDate - startDate) from Date.now
    const start = moment(message.startDate); // should had 0
    const end = moment(message.liveEndDate); // should had message.stats.reachProjection
    const now = moment(dateNow);
    const totalMs = end.diff(start);
    const nowMs = now.diff(start);
    const percent = Math.min(nowMs > 0 ? nowMs / totalMs : 0, 1.0);
    return Math.round(percent * message.stats.reachProjection);
}

export function lastPath(path) {
    const pathElements = path.split("/");
    return pathElements[1];
}

export function messageViewUrl(message) {
    return `/m/${message.slug}/`;
}

export function setPageTitle(info) {
    let display = info;
    if (Array.isArray(info)) {
        display = info.join(" — ");
    }
    document.title = `Amplify — ${display}`;
}

export function parseQueryString(url) {
    const decodedUrl = decodeURIComponent(url);
    const url2 = decodedUrl.charAt(0) === "?" ? decodedUrl.slice(1) : decodedUrl; // remove the "?"
    const arr = url2.split(/&|=/); // split with "&" and "="
    if (!arr) {
        return {};
    }
    const params = {};

    for (let i = 0; i < arr.length; i += 2) {
        const key = arr[i];
        params[key] = arr[i + 1];
    }
    return params;
}

export function pathMatcher(url, exactMatch = true) {
    if (url.charAt(0) !== "/") {
        throw new Error("URL should start with /");
    }

    const { location: { pathname } = {} } = window;
    let match = false;
    let to = null;
    let href = null;
    const pathnameSplit = pathname.substring(1).split("/");
    const urlSplit = url.substring(1).split("/");
    if (pathnameSplit[0] === urlSplit[0]) {
        urlSplit.splice(0, 1, null);
        to = urlSplit.join("/") || "/";
    } else {
        href = url;
    }
    if (exactMatch ? pathname === url : pathname.startsWith(url)) {
        match = true;
    }
    return {
        to,
        href,
        selected: match,
    };
}

export function isUrlValid(url) {
    const pattern = new RegExp("^https?://.*");
    return !!pattern.test(url);
}

export function isUrlEmpty(url) {
    return ["https://", "http://"].findIndex((s) => s === url) >= 0;
}

export function getHostname(url) {
    try {
        const urlParsed = new URL(url);
        return urlParsed.hostname;
    } catch (ex) {
        return "ERROR";
    }
}

export function debounce(func, wait, immediate) {
    let timeout;
    const result = (...args) => {
        const later = () => {
            timeout = null;
            if (!immediate) {
                func(...args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func(...args);
        }
    };
    result.clear = () => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };
    return result;
}

export function formatPercent(value) {
    if (value <= 0) {
        return "";
    }
    const amount = (value * 100.0).toFixed(2);
    return `${amount}%`;
}

export function removeEmpty(obj) {
    const result = {};
    Object.keys(obj).forEach((k) => {
        if (obj[k]) {
            result[k] = obj[k];
        }
    });
    return result;
}

export function isAmplify500(user) {
    return user.memberships && user.memberships.indexOf("amplify500") !== -1;
}

export function canCreateNewMessage(user) {
    if (user && user.isStaff) {
        return [true, null];
    }
    if (isAmplify500(user)) {
        return [true, null];
    }
    if (getIn(user, "serviceSubscription.product.name")) {
        return [true, null];
    }
    if (getIn(user, "liveMessageCount", 1) === 0) {
        return [true, null];
    }
    return [false, "alerts.new-message-too-many-message"];
}

export function canShowMessage(message, user) {
    if (user && user.isStaff) {
        return [true, null];
    }
    if (isAmplify500(user)) {
        return [true, null];
    }
    if (getIn(user, "serviceSubscription.product.name")) {
        return [true, null];
    }
    const endDate = message.completedEndDate ? moment(message.completedEndDate) : null;
    if (!endDate || endDate.isAfter(moment().add(-30, "days"))) {
        return [true, null];
    }
    return [false, "alerts.show-message-too-old"];
}

export function shuffle(array) {
    return array.sort(() => 0.5 - Math.random());
}

export function shiftAround(array) {
    const firstElem = array.shift();
    array.push(firstElem);
    return array;
}

export function buildAppUrl(path) {
    const appUrl = new URL(path, window.APP_SERVER_URL);
    return appUrl.href;
}

export function removeDeepByKey(obj, keyToBeRemoved) {
    return _transform(obj, (result, value, key) => {
        if (_isObject(value)) {
            value = removeDeepByKey(value, keyToBeRemoved);
        }
        if (key !== keyToBeRemoved) {
            _isArray(obj) ? result.push(value) : (result[key] = value);
        }
    });
}
