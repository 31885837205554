import { Button, ButtonGroup, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getPlatformIcon } from "../platforms";
import { useQueryTargetingProfiles } from "./gql-targeting-profile";

const SelectPlaceholder = styled.span`
    opacity: 0.5;
`;

const TargetingProfileChooser = ({ value, onChange, id, disabled }) => {
    const [q, setQ] = useState("");
    const [loadTargetingProfile, { targetingProfiles }] = useQueryTargetingProfiles();

    useEffect(() => {
        if (q) {
            loadTargetingProfile({ q }).then(() => {});
        }
    }, [q, loadTargetingProfile]);

    const itemRenderer = (item, { modifiers, handleClick }) => {
        return (
            <MenuItem
                text={item.title}
                onClick={handleClick}
                active={modifiers.active}
                key={item.id}
                label={
                    <span>
                        {item.platforms.map((p) => (
                            <span key={p}>
                                {getPlatformIcon(p, { key: p, size: "12px" })}
                                &nbsp;
                            </span>
                        ))}
                    </span>
                }
            />
        );
    };
    return (
        <div id={id}>
            <Select
                items={targetingProfiles ? targetingProfiles.result || [] : []}
                itemRenderer={itemRenderer}
                onItemSelect={(item) => {
                    onChange(item);
                }}
                query={q}
                onQueryChange={setQ}
                noResults={<MenuItem disabled={true} text="No targeting." />}
                fill
                popoverProps={{
                    minimal: true,
                }}
            >
                <ButtonGroup fill>
                    <Button
                        fill
                        outlined
                        alignText="left"
                        rightIcon="double-caret-vertical"
                        disabled={disabled}
                        text={
                            value?.title ? (
                                value.title
                            ) : (
                                <SelectPlaceholder>Select a targeting profile...</SelectPlaceholder>
                            )
                        }
                    />
                    <Button
                        outlined
                        icon="cross"
                        disabled={!value?.title || disabled}
                        onClick={(e) => {
                            e.preventDefault();
                            onChange({});
                            e.stopPropagation();
                        }}
                    />
                </ButtonGroup>
            </Select>
        </div>
    );
};

TargetingProfileChooser.propTypes = {
    value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        platforms: PropTypes.arrayOf(PropTypes.string),
    }),
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

TargetingProfileChooser.defaultProps = {
    value: null,
    id: null,
    disabled: false,
};

export default TargetingProfileChooser;
