import { io } from "socket.io-client";

export let ampHistoryToken = null;
export let ampAiWebsocket = null;

const AI_CALL_TIMEOUT = 20000;

export const initServerSide = async (tokenAi) => {
    try {
        const appUrl = window.ENVIRONMENT === "local" ? "http://localhost:11001" : window.APP_SERVER_URL;
        const url = `${appUrl}/_ai/staff`;
        const controller = new AbortController();
        const fetchTimeout = setTimeout(() => {
            // eslint-disable-next-line no-console
            console.log("Cannot join AI server");
            controller.abort();
            return false;
        }, AI_CALL_TIMEOUT);
        let response = await fetch(url, {
            signal: controller.signal,
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            },
            credentials: "include",
            body: JSON.stringify({
                tokenAi,
            }),
        });
        clearTimeout(fetchTimeout);
        if (response.ok) {
            response = await response.json();
            ampHistoryToken = response.history_token;
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
};

export const callAi = (formData, lang, action) =>
    new Promise((resolve, reject) => {
        // eslint-disable-next-line no-console
        // console.log("CALLING AI WITH DATA", ampAiWebsocket, formData);
        const data = {
            adLink: formData.adLink,
            user_lang: lang,
            ai_business_description: formData.wizardMetas?.ai_business_description
                ? formData.wizardMetas?.ai_business_description
                : formData.texts?.find((val) => val.type === "primary_text")?.value,
        };
        const timeoutHandler = () => {
            // eslint-disable-next-line no-console
            console.log("AI call timeout");
            reject("AI call timeout");
        };
        let callTimeout = setTimeout(timeoutHandler, AI_CALL_TIMEOUT);
        ampAiWebsocket.volatile.emit(action, JSON.stringify({ form_data: data, history_token: ampHistoryToken }));
        ampAiWebsocket.on(`${action}_answer`, (fdata, callback) => {
            clearTimeout(callTimeout);
            resolve(JSON.parse(fdata));
            callback("received");
        });
        ampAiWebsocket.on(`wait`, (fdata, callback) => {
            // Still waiting but we need to reset the timeout (the server is still alive...)
            clearTimeout(callTimeout);
            callback("received");
            callTimeout = setTimeout(timeoutHandler, AI_CALL_TIMEOUT);
        });
    });

export const generate_token = (length) => {
    const a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    const b = [];
    for (let i = 0; i < length; i++) {
        const j = (Math.random() * (a.length - 1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
};

export const initAmplifyAi = (data, tokenAi) =>
    new Promise((resolve, reject) => {
        try {
            initServerSide(tokenAi).then((initResult) => {
                if (initResult === false) {
                    reject();
                    return;
                }

                if (ampAiWebsocket == null) {
                    ampAiWebsocket = io(window.WIZARD_AI_WEBSOCKET_URL, {
                        path: "/ai/socket.io",
                        transports: ["websocket"],
                        query: {
                            history_id: ampHistoryToken,
                        },
                    });

                    ampAiWebsocket.on("connect", () => {
                        resolve();
                    });

                    ampAiWebsocket.on("error", () => {
                        ampAiWebsocket = null;
                        reject("Cannot init AI");
                    });
                } else {
                    resolve();
                }
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            reject(error);
        }
    });
