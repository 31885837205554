import { Button, Classes, ControlGroup, Dialog, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import Box from "../common/box";
import Search from "../search";
import { useQueryGeoTargeting } from "./gql-targeting";
import TargetingTable from "./targeting-table";

const TargetingChooser = styled(({ onChange, onClose, value }) => {
    const [selection, setSelection] = useState(value);
    const [searchValue, setSearchValue] = useState("");
    const [loadTargetings, { loading, targetings: results = [] }] = useQueryGeoTargeting();

    const doSearch = useCallback(async () => {
        await loadTargetings({ q: searchValue });
    }, [loadTargetings, searchValue]);

    return (
        <Box gap="medium" style={{ height: "550px" }}>
            <div className={Classes.DIALOG_BODY}>
                <Search value={searchValue} setValue={setSearchValue} onSearch={doSearch} loading={loading} />
                <br />
                {loading && <div>Loading please wait...</div>}
                <TargetingTable
                    targetings={results}
                    onSelect={(t) => setSelection(t)}
                    loading={loading}
                    height="250px"
                    selected={selection}
                />
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Cancel" onClick={onClose} />
                    <Button
                        disabled={!selection}
                        text="Select"
                        intent={Intent.PRIMARY}
                        onClick={() => onChange(selection)}
                    />
                </div>
            </div>
        </Box>
    );
})``;

const TargetingField = ({ value, onChange }) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [selecting, setSelecting] = useState(false);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleChange = useCallback(
        (val) => {
            setSelecting(false);
            onChange(val);
        },
        [onChange]
    );

    return (
        <div>
            <Dialog
                title="Select parent"
                isOpen={selecting}
                onClose={() => setSelecting(false)}
                style={{ height: "450px", width: "800px" }}
            >
                <TargetingChooser onClose={() => setSelecting(false)} onChange={handleChange} value={currentValue} />
            </Dialog>

            <ControlGroup fill>
                {currentValue ? (
                    <Button
                        fill
                        minimal
                        outlined
                        text={currentValue.name}
                        alignText="left"
                        onClick={() => setSelecting(true)}
                    />
                ) : (
                    <Button
                        fill
                        minimal
                        outlined
                        text="Not set"
                        className={Classes.DISABLED}
                        alignText="left"
                        onClick={() => setSelecting(true)}
                    />
                )}
                <Button onClick={() => setSelecting(true)} intent={Intent.PRIMARY}>
                    <span style={{ margin: "0 5px" }}>{currentValue ? " Change" : " Set "}</span>
                </Button>
                {currentValue && <Button onClick={() => handleChange(null)} icon="cross" />}
            </ControlGroup>
        </div>
    );
};

TargetingField.propTypes = {
    value: PropTypes.shape({}),
    onChange: PropTypes.func,
};

TargetingField.defaultProps = {
    value: null,
    onChange: null,
};

export default TargetingField;
