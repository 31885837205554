import { Button, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { parseQueryString } from "../../common/utils";
import Box from "../common/box";
import Search from "../search";
import TargetingList from "./targeting-list";

const StyledTargetingPage = styled.div`
    height: 100vh;
    width: 100%;
    display: grid;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    .targeting-list {
        position: relative;
        align-items: stretch;
        .amp-table-container {
            position: absolute;
            bottom: 10px;
            top: 0;
            right: 10px;
            left: 10px;
        }
    }
`;

const TargetingPage = ({ history, location }) => {
    const { search } = location;
    const query = parseQueryString(search);
    const [inputValue, setInputValue] = useState(query.q);
    const [currentSearch, setCurrentSearch] = useState(query.q);

    const selectedId = parseInt(query.sel || "-1", 10);
    const pushHistoryQuery = useCallback(
        (newQuery, replace) => {
            const { search: s } = location;
            const q = parseQueryString(s || "");
            const newParams = replace
                ? newQuery
                : {
                      ...q,
                      ...newQuery,
                  };
            const params = new URLSearchParams(
                Object.entries(newParams).reduce((acc, [k, v]) => {
                    if (v !== null) {
                        acc[k] = v;
                    }
                    return acc;
                }, {})
            ).toString();
            history.push({
                search: `?${params}`,
            });
        },
        [history, location]
    );

    const handleChangeSelection = (sel) => pushHistoryQuery({ sel });

    const handleSearch = useCallback(
        () => {
            setCurrentSearch(inputValue);
            pushHistoryQuery({
                q: inputValue,
            });
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [history, inputValue]
    );

    const handleCreate = useCallback(() => {
        history.push("/targetings/new/");
    }, [history]);

    return (
        <StyledTargetingPage>
            <Box direction="row" pad="small" gap="small">
                <Search
                    setValue={setInputValue}
                    value={inputValue}
                    onSearch={handleSearch}
                    onReset={() => setInputValue("")}
                    placeholder="Search for existing location"
                />
                {window.ALLOW_TARGETINGS_EDITION && (
                    <Button
                        intent={Intent.PRIMARY}
                        text="Add targeting"
                        onClick={handleCreate}
                        type="button"
                        icon="add"
                    />
                )}
            </Box>
            <Box pad="small" className="targeting-list">
                <TargetingList
                    search={currentSearch}
                    selectedId={selectedId}
                    onSelectionChanged={handleChangeSelection}
                />
            </Box>
        </StyledTargetingPage>
    );
};

TargetingPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
};

export default TargetingPage;
