import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";

import SurveyStep, { surveyStepPropTypes } from "./survey-step";

const Survey = ({ steps, data, setData, setIsValid, disabled }) => {
    const updateValue = useCallback(
        (key, v) => {
            setData((oldValue) => ({
                ...oldValue,
                [key]: { ...(oldValue[key] || {}), value: v },
            }));
        },
        [setData]
    );
    const updateInputValue = useCallback(
        (key, v) => {
            setData((oldValue) => ({
                ...oldValue,
                [key]: { ...(oldValue[key] || {}), input: v },
            }));
        },
        [setData]
    );

    const isFormValid = useCallback(
        (data) =>
            steps.reduce((acc, step) => {
                const val = data[step.key]; // this is an object { value: "", input: ""}
                let valid = !_isEmpty(val);
                if (valid && step.options) {
                    step.options.forEach((opt) => {
                        if (opt.value === val.value && opt.inputValidation) {
                            valid = valid && opt.inputValidation(val.input);
                        }
                    });
                }
                return acc && valid;
            }),
        [steps]
    );

    useEffect(() => {
        setData(data);
        setIsValid(isFormValid(data));
    }, [data, setData, setIsValid, isFormValid]);

    return (
        <>
            {steps.map((step, idx) => (
                <SurveyStep
                    key={step.key}
                    step={step}
                    position={idx + 1}
                    value={data[step.key]?.value}
                    onChangeValue={(newValue) => updateValue(step.key, newValue)}
                    inputValue={data[step.key]?.input}
                    onChangeInputValue={(newValue) => updateInputValue(step.key, newValue)}
                    disabled={disabled}
                />
            ))}
        </>
    );
};

Survey.propTypes = {
    steps: PropTypes.arrayOf(surveyStepPropTypes).isRequired,
    data: PropTypes.shape({}),
    setData: PropTypes.func.isRequired,
    setIsValid: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

Survey.defaultProps = {
    data: {},
    disabled: false,
    callout: null,
};

export default Survey;
