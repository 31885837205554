import { useQuery } from "@apollo/client";
import { Callout, Intent } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import NavBreadcrumb from "../common/nav-breadcrumb";
import { GQL_QUERY_ALL_MESSAGE_REVIEW } from "./gql-validation";
import MessageReviewTable from "./message-review-table";

const StyledValidationPage = styled.div`
    padding: 10px;
`;

const MessageReviewListPage = () => {
    const { data, loading, error } = useQuery(GQL_QUERY_ALL_MESSAGE_REVIEW, {
        fetchPolicy: "cache-and-network",
    });

    return (
        <StyledValidationPage>
            <NavBreadcrumb items={[{ text: "Validations", icon: "inbox" }]} />
            {error && (
                <Callout title="Error while loading messages" intent={Intent.DANGER}>
                    {error.message}
                </Callout>
            )}
            <MessageReviewTable messages={data?.getStaffMessagesToReview || []} loading={loading} />
        </StyledValidationPage>
    );
};

export default MessageReviewListPage;
