import { Callout, Intent, Spinner } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import NavBreadcrumb from "../common/nav-breadcrumb";
import { useMutationUpdateGeoTargeting, useQueryGeoTargetingById } from "./gql-targeting";
import TargetingInfo from "./targeting-info";

const StyledTargetingUpdatePage = styled.div`
    padding: 10px;
`;

const TargetingDetails = ({ targeting }) => {
    const history = useHistory();
    const updateTargetingMutation = useMutationUpdateGeoTargeting();

    const updateTargeting = useCallback(
        async (changes) => {
            await updateTargetingMutation({
                id: targeting.id,
                changes,
            });
            toast.success("Geo targeting saved successfully");
        },
        [targeting, updateTargetingMutation]
    );

    return (
        <div>
            <NavBreadcrumb
                items={[
                    { icon: "home", to: "/" },
                    {
                        text: "Geo Targeting",
                        icon: "map-marker",
                        to: {
                            pathname: `/targetings/`,
                            search: history.location.search,
                        },
                    },
                    {
                        text: targeting.name,
                    },
                ]}
            />
            <TargetingInfo targeting={targeting} onChange={updateTargeting} />
        </div>
    );
};

TargetingDetails.propTypes = {
    targeting: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};

const TargetingUpdatePage = () => {
    const { id } = useParams();
    const [loadTargeting, { targeting, loading, error }] = useQueryGeoTargetingById();

    useEffect(() => {
        loadTargeting(id);
    }, [id, loadTargeting]);

    return (
        <StyledTargetingUpdatePage>
            {loading && (
                <Callout title="Loading geo targeting, please wait...">
                    <Spinner />
                </Callout>
            )}
            {error && (
                <Callout intent={Intent.DANGER} title="Error while loading geo targeting">
                    {error.message}
                </Callout>
            )}

            {targeting ? (
                <TargetingDetails targeting={targeting} />
            ) : (
                !loading &&
                !error && (
                    <Callout intent={Intent.DANGER} title="Nothing found here">
                        {id}
                    </Callout>
                )
            )}
        </StyledTargetingUpdatePage>
    );
};

export default TargetingUpdatePage;
