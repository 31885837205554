import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { GQL_ALL_MESSAGES_QUERY } from "./messges/gql-messages";
import { GQL_ALL_USERS_QUERY } from "./users/gql-users";

const GQL_MUTATION_REGISTER_FIELD_SALES = gql`
    mutation registerFieldSale($userId: Int!, $currency: Currency!, $amount: Int!, $adAmount: Int!, $comment: String) {
        registerFieldSale(
            userId: $userId
            currency: $currency
            amount: $amount
            adAmount: $adAmount
            comment: $comment
        ) {
            wallet {
                balance
            }
        }
    }
`;

export const useMutationRegisterFieldSales = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_REGISTER_FIELD_SALES, {
        refetchQueries: [GQL_ALL_USERS_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

const GQL_MUTATION_ADD_MONEY_FROM_WALLET = gql`
    mutation addMoneyFromWallet($messageId: UUID!, $amount: Int!) {
        addMoneyFromWallet(messageId: $messageId, amount: $amount) {
            wallet {
                balance
            }
        }
    }
`;

export const useMutationAddMoneyFromWallet = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_ADD_MONEY_FROM_WALLET, {
        refetchQueries: [GQL_ALL_USERS_QUERY, GQL_ALL_MESSAGES_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

const GQL_MUTATION_RESTORE_MONEY_TO_WALLET = gql`
    mutation restoreMoneyToWallet($messageId: UUID!) {
        restoreMoneyToWallet(messageId: $messageId) {
            wallet {
                balance
            }
        }
    }
`;

export const useMutationRestoreMoneyToWallet = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_RESTORE_MONEY_TO_WALLET, {
        refetchQueries: [GQL_ALL_USERS_QUERY, GQL_ALL_MESSAGES_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

const GQL_MUTATION_ADD_MONEY_TO_WALLET = gql`
    mutation addMoneyToWallet($userId: Int!, $currency: Currency!, $amount: Int!, $comment: String) {
        addMoneyToWallet(userId: $userId, currency: $currency, amount: $amount, comment: $comment) {
            wallet {
                balance
            }
        }
    }
`;

export const useMutationAddMoneyToWallet = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_ADD_MONEY_TO_WALLET, {
        refetchQueries: [GQL_ALL_USERS_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

const GQL_MUTATION_ADD_MONEY_TO_WALLET_FROM_AGENCY = gql`
    mutation addMoneyToWalletFromAgency(
        $userId: Int!
        $agencyUserId: Int!
        $currency: Currency!
        $amount: Int!
        $comment: String
    ) {
        addMoneyToWalletFromAgency(
            userId: $userId
            agencyUserId: $agencyUserId
            currency: $currency
            amount: $amount
            comment: $comment
        ) {
            wallet {
                balance
            }
        }
    }
`;

export const useMutationAddMoneyToWalletFromAgency = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_ADD_MONEY_TO_WALLET_FROM_AGENCY, {
        refetchQueries: [GQL_ALL_USERS_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
