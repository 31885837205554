import { Colors } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledPropertiesGrid = styled.div`
    display: grid;
    grid-template-columns: auto ${({ $left }) => $left || "auto"};
    column-gap: ${({ $gap }) => $gap || "5px"};
    row-gap: 2px;
    padding: ${({ $padding }) => $padding || "0"};
    .pg-label {
        opacity: 0.5;
        text-align: right;
        .label-component {
            min-height: 25px;
        }
    }
    .pg-value {
        font-weight: bold;
    }
    .pg-value-alert {
        color: ${Colors.RED2};
    }
`;

const PropertiesGrid = ({ values, gap, left, padding }) => (
    <StyledPropertiesGrid $gap={gap} $left={left} $padding={padding}>
        {values.map((opt, idx) => (
            <React.Fragment key={opt.label || idx}>
                <div className="pg-label">{opt.label ? <div className="label-component">{opt.label}</div> : null}</div>
                <div className={opt.alert ? "pg-value pg-value-alert" : "pg-value"}>{opt.value}</div>
            </React.Fragment>
        ))}
    </StyledPropertiesGrid>
);

PropertiesGrid.propTypes = {
    values: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.node,
        })
    ).isRequired,
    gap: PropTypes.string,
    left: PropTypes.string,
    padding: PropTypes.string,
};
PropertiesGrid.defaultProps = {
    values: null,
    gap: null,
    left: null,
    padding: null,
};
export default PropertiesGrid;
