import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { GQL_USER_FRAGMENT } from "../../common/gql-user";

export const GQL_ALL_USERS_QUERY = gql`
    query allUsers(
        $limit: Int
        $offset: Int
        $q: StringTextFilter
        $agency: StringTextFilter
        $subscriptionProduct: StringSubscriptionproductFilter
        $lang: StringInFilter
        $isActive: BooleanBooleanFilter
        $verified: BooleanBooleanFilter
        $orderBy: String
        $isStaff: BooleanBooleanFilter
    ) {
        allUsers(
            limit: $limit
            offset: $offset
            q: $q
            agency: $agency
            subscriptionProduct: $subscriptionProduct
            lang: $lang
            isActive: $isActive
            verified: $verified
            orderBy: $orderBy
            isStaff: $isStaff
        ) {
            count
            results {
                ...user
                wallets {
                    id
                    balance
                    currency
                    subscriptions {
                        id
                        amount
                        interval
                        canceledAt
                        nextDueDate
                        startedAt
                    }
                }
            }
        }
    }

    ${GQL_USER_FRAGMENT}
`;

const GQL_MUTATION_CANCEL_ACTIVE_SERVICE_SUBSCRIPTION = gql`
    mutation cancelActiveServiceSubscription($userId: Int!) {
        cancelActiveServiceSubscription(userId: $userId) {
            cancelledSubscription {
                product {
                    name
                }
            }
        }
    }
`;

export const useQueryAllUsers = () => {
    const [caller, { data: { allUsers } = {}, ...rest }] = useLazyQuery(GQL_ALL_USERS_QUERY, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
    });
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { users: allUsers?.results, count: allUsers?.count, ...rest }];
};

export const useMutationCancelActiveServiceSubscription = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_CANCEL_ACTIVE_SERVICE_SUBSCRIPTION, {
        refetchQueries: [GQL_ALL_USERS_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
