export const LINKEDIN_FACETS = {
    "urn:li:adTargetingFacet:interests": "Interest",
    "urn:li:adTargetingFacet:industries": "Industry",
    "urn:li:adTargetingFacet:memberBehaviors": "Trait",
    "urn:li:adTargetingFacet:degrees": "Degree",
    "urn:li:adTargetingFacet:fieldsOfStudy": "Field of study",
    "urn:li:adTargetingFacet:titles": "Job title",
    "urn:li:adTargetingFacet:skills": "Skills",
};

export const FORMATTERS = {
    facebook: (item) => ({
        key: `fb-${item.id}`,
        name: item.name,
        topic: item.topic,
        description: item.description,
    }),
    instagram: (item) => ({
        key: `in-${item.id}`,
        name: item.name,
        topic: item.topic,
        description: item.description,
    }),
    twitter: (item) => ({
        key: `tw-${item.targeting_value}`,
        name: item.localized_name || item.name,
        topic: item.targeting_type,
        description: null,
    }),
    linkedin: (item) => ({
        key: `li-${item.urn}`,
        name: item.name,
        topic: LINKEDIN_FACETS[item.facetUrn] || "",
        description: null,
    }),
};

export function formatItemForPlatform(item, platform) {
    if (!platform) {
        throw Error("Missing platform");
    }
    const formatter = FORMATTERS[platform] || ((i) => i);
    return { ...formatter(item), platform };
}
