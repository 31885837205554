import { Classes, Icon, Intent, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import PropertiesGrid from "../layout/properties-grid";

const StyledUserBadge = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    & > .avatar-email {
        margin-left: 0.5em;
    }
    .staff-icon {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const StyledUserBadgeImage = styled.div`
    background: url(${({ $imageUrl }) => $imageUrl});
    background-size: cover;
    overflow: hidden;
    border-radius: 50%;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    border: 1px solid rgba(0, 0, 0, 0.15);
`;

const StyledAvatarTooltip = styled.div`
    padding: 10px;
`;

export const getUserIntercomLink = (user) =>
    `https://app.intercom.com/apps/${window.INTERCOM_APP_ID}/users/show/?${new URLSearchParams({
        email: user.email,
    }).toString()}`;

const UserBadge = ({ user, size, showEmail }) => {
    const history = useHistory();
    if (!user) {
        return null;
    }

    return (
        <Popover2
            content={
                <StyledAvatarTooltip>
                    <h2>
                        {user.firstName} {user.lastName}
                    </h2>
                    <p>
                        <PropertiesGrid values={[{ label: "Email", value: user.email }]} />
                    </p>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Menu>
                            <MenuItem
                                text="Show on Intercom"
                                label={<Icon icon="share" />}
                                intent={Intent.PRIMARY}
                                onClick={() => {
                                    window.open(getUserIntercomLink(user), "_blank");
                                }}
                            />
                            <MenuItem
                                text="Use Info"
                                label={<Icon icon="arrow-right" />}
                                intent={Intent.NONE}
                                onClick={() => {
                                    history.push(`/users/?q=${user.email}`);
                                }}
                            />
                        </Menu>
                    </div>
                </StyledAvatarTooltip>
            }
        >
            <StyledUserBadge tabIndex={0}>
                {user.isStaff && <Icon intent={Intent.PRIMARY} className="staff-icon" icon="crown" size={size / 3} />}
                <StyledUserBadgeImage $imageUrl={user.avatarUrl} $size={size}>
                    {!user.avatarUrl && <Icon icon="user" size={size} color="#ddd" />}
                </StyledUserBadgeImage>
                {showEmail && <span className="avatar-email">{user.email}</span>}
            </StyledUserBadge>
        </Popover2>
    );
};

UserBadge.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        avatarUrl: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        isStaff: PropTypes.bool,
    }),
    size: PropTypes.number,
    showEmail: PropTypes.bool,
};

UserBadge.defaultProps = {
    size: 50,
    showEmail: false,
    user: null,
};
export default UserBadge;
