import { Button, FormGroup, Icon, InputGroup, Intent, NumericInput, Tag, TextArea } from "@blueprintjs/core";
import isEqual from "lodash/isEqual";
import { trim } from "lodash/string";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled, { keyframes } from "styled-components";

import { buildAppUrl } from "../../../common/utils";
import { ReactComponent as Robot } from "../../../img/robot.svg";
import { ALL_LANG } from "../../../lang";
import { DialogContext } from "../../bp-dialog-context";
import ErrorCallout from "../../common/error-callout";
import SimpleSelect from "../../common/simple-select";
import StickyContainer from "../../common/sticky-container";
import CancelPublishing from "../../dialogs/cancel-publishing";
import PauseMessage from "../../dialogs/pause-message";
import PublishMessage from "../../dialogs/publish-message";
import RestoreMessage from "../../dialogs/restore-message";
import ResumeMessage from "../../dialogs/resume-message";
import StopMessage from "../../dialogs/stop-message";
import { useMutationSendAiGeneratedEmail } from "../../emails/gql-emails";
import { callAi } from "../../toolsAi";
import { useQueryTopics } from "../../topics";
import BudgetProfileForm from "../budget-profile-form";
import { useCreateAiDraft } from "../gql-messages";
import MessageField from "../message-field";
import MessageMultipleField from "../message-multiple-field";
import FieldAge from "./field-age";
import MessageDate from "./field-date";
import FieldGender from "./field-gender";
import GeoTargetingField from "./field-geotargeting";
import FieldGoogleTexts from "./field-google-texts";
import FieldImage, { StyledImage } from "./field-image";
import FieldLang from "./field-language";
import FieldOwner from "./field-owner";
import FieldState from "./field-state";
import FieldTargetingProfile from "./field-targeting-profile";
import FieldText from "./field-text";
import FieldUrl from "./field-url";
import FieldVideo, { StyledVideo } from "./field-video";
import { getUrlValidationError } from "./url-validator";

export const DAILY_MIN_AMOUNT = 10;

const StyledMessageForm = styled.div`
    .input-message-edit {
        color: red;
    }
    .bp4-form-group {
        padding: 5px;
        border-radius: 5px;
        background-color: white;
        overflow: hidden;
        & > label {
            padding: 5px 10px;
            margin: -6px -6px 6px -6px;
            background-color: #fff;
            font-weight: bold;
            line-height: 1.5em;
            small {
                font-weight: lighter;
                opacity: 0.75;
            }
        }
    }
    .bp4-input:disabled,
    .bp4-input.bp4-disabled {
        background: transparent;
    }
    .bp4-form-content {
        textarea {
            resize: vertical;
        }
    }
    .sticky-container {
        height: 100vh;
    }
`;

const StyledMessageFormFooter = styled.div`
    background-color: #fafafa;
    padding: 15px;
    .footer-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .footer-alert {
        margin-bottom: 10px;
    }
`;

const StyledInnerForm = styled.div`
    padding: 10px;
`;
const StyledInputAmount = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const StyleCommandBar = styled.div`
    display: flex;
    p {
        align-items: center;
    }
`;

const StyledButtonsGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 5px;
    background-color: #fcefcc;
`;

const StyledButtonsGroupHeader = styled.div`
    margin: 5px;
`;

const StyledButtonsGroupButtons = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledButton = styled.div`
    margin: 5px;
`;

const spinAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const StyledLoadingText = styled.div`
    margin: 5px;
    align-items: center;
    display: inline-flex;
`;

const StyledRobot = styled.div`
    width: 32px;
    height: 32px;
    margin: 5px;
    vertical-align: middle;
    .animated {
        animation-name: ${spinAnimation};
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
`;

const FieldGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`;

const StyledWizardMetas = {
    Main: styled.div`
        display: flex;
    `,
    Key: styled.div`
        display: flex;
        background-color: #dedede;
        border-radius: 5px;
        padding: 5px;
        margin: 5px 0px 5px 5px;
        flex-wrap: wrap;
    `,
    Value: styled.div`
        display: flex;
        background-color: #efefef;
        border-radius: 5px;
        padding: 5px;
        margin: 5px 5px 5px 2px;
        display: flex;
    `,
    Subvalues: styled.div`
        display: flex;
        flex-direction: column;
    `,
};

const DEFAULT_EMAIL_TYPE = "idle-draft";
const PREMIUM_OFFER_EMAIL_TYPE = "e-counter";
export const textTypesGoogle = ["headline", "long_headline", "keyword", "description","sitelink"];
const MessageForm = ({ message, landings, onChangeMessage, loading, initialChanges, disablePrompt, header }) => {
    const { showDialog } = useContext(DialogContext);
    const history = useHistory();
    const { topics } = useQueryTopics([]);
    const [values, setValues] = useState({ ...message, ...initialChanges });
    const [loadingAi, setLoadingAI] = useState(false);
    const [aiResult, setAiResult] = useState(null);
    const [aiResultImage, setAiResultImage] = useState(null);
    const [aiResultVideo, setAiResultVideo] = useState(null);
    const [aiResultVideoImage, setAiResultVideoImage] = useState(null);
    const [aiResultEmailReady, setAiResultEmailReady] = useState(false);
    const [aiResultError, setAiResultError] = useState(null);
    const [emailSubject, setEmailSubject] = useState(null);
    const [emailBody, setEmailBody] = useState(null);
    const [emailType, setEmailType] = useState(DEFAULT_EMAIL_TYPE);
    const [aiLang, setAiLang] = useState(null);
    const [sendAiGeneratedEmail, { loading: sending }] = useMutationSendAiGeneratedEmail();
    const [createAiDraft, createAiError] = useCreateAiDraft();
    const [creatingDraft, setCreatingDraft] = useState(false);
    const [isLandingUrl, setIsLandingUrl] = useState(!!message.landing);
    const onSubmit = useCallback(
        (close) => {
            onChangeMessage(values, close);
        },
        [values, onChangeMessage]
    );

    const handleSend = async () => {
        await sendAiGeneratedEmail({ message: message.id, subject: emailSubject, body: emailBody, type: emailType });
        setEmailBody(null);
        setEmailSubject(null);
        setEmailType(DEFAULT_EMAIL_TYPE);
        setAiResultEmailReady(false);
        setAiResultError(null);
        toast.success(`Email sent`);
    };

    const onRevert = useCallback(() => {
        setValues(message);
    }, [message]);

    const onRevertKey = useCallback(
        (key) => {
            setValues((oldValues) => ({ ...oldValues, [key]: message[key] }));
        },
        [message]
    );

    const onChangeValue = useCallback((k, v) => {
        setValues((oldValues) => ({
            ...oldValues,
            [k]: v,
        }));
    }, []);
    const onChangeUrl = useCallback((k, v) => {
        setValues((oldValues) => ({
            ...oldValues,
            [k]: (v ?? "").replace(/ /g, ""),
        }));
    }, []);
    const onChangeLandingUrl = useCallback((k, v) => {
        setValues((oldValues) => ({
            ...oldValues,
            [k]: { slug: (v ?? "").replace(/ /g, "") },
        }));
    }, []);

    useEffect(() => {
        setAiLang(message.lang);
    }, [message.lang]);

    const handleSetLang = (val) => {
        setAiLang(val);
    };

    useEffect(() => {
        setValues({ ...message, ...initialChanges });
    }, [message, initialChanges]);

    const changes = useMemo(() => {
        const allKeys = Object.keys(message || {}); // detect only change, a key must exists in message to detect changes
        // if the key is missing, your probably forget to add it in the graphql query message details call
        return allKeys.reduce((acc, k) => {
            if (!isEqual(values[k], message[k])) {
                acc[k] = true;
            }
            return acc;
        }, {});
    }, [values, message]);

    const hasChanges = Object.keys(changes).length > 0;
    const hasGoogleAds = useMemo(() => {
        return !!(values.platformsMessage || [])?.find((pm) => {
            return pm.platform.startsWith("google_ads_") && pm.active;
        });
    }, [values]);

    const errors = useMemo(() => {
        const errors = {};
        if (message.state === "completion") return errors;
        const primaryText = values.texts?.find((val) => val.type === "primary_text");
        if (!primaryText || !primaryText.value) {
            errors["title"] = "Need Minimum 1 PRIMARY TEXT";
        }
        if (
            values.initialAmount <
            values.platformsMessage?.filter((plt) => plt?.active).length * DAILY_MIN_AMOUNT * 100
        )
            errors["initialAmount"] = "The Budget is under the minimum required";
        if (!values.adLink && !values?.landing?.slug)
            errors["adLink"] = !values.adLink && !values?.landing?.slug ? "Destination URL needed for the ad" : null;
        const adLinkError = getUrlValidationError(values?.adLink);
        if (adLinkError) {
            errors["adLink"] = adLinkError;
        }
        // if (message.state === "draft" && values.format === "video" && !values?.movie)
        //     errors["format"] = "Need to upload a video";
        // if (message.state === "draft" && values.format === "image" && !values?.image)
        //     errors["format"] = "Need to upload an image";
        if (hasGoogleAds) {
            const textsGoogle = values?.texts?.filter((elt) => textTypesGoogle.includes(elt.type));
            const headlines = textsGoogle?.filter((val) => val.type === "headline");
            const descriptions = textsGoogle?.filter((val) => val.type === "headline");

            if (!textsGoogle?.find((val) => val.type === "keyword")?.value) {
                errors["googleAdsSearchStandard.keywords"] = "You must provide at less one Google ads 'keywords' value";
            }
            if ((headlines || []).length < 1) {
                errors["googleAdsSearchStandard.headline2"] = "Missing Google ads headlines";
            }
            if ((descriptions || []).length < 1) {
                errors["googleAdsSearchStandard.description1"] = "Missing Google ads descriptions";
            }
        }
        return errors;
    }, [message.state, values, hasGoogleAds]);

    const handlePublish = useCallback(async () => {
        await showDialog(PublishMessage, { message });
    }, [showDialog, message]);

    const handleStop = useCallback(async () => {
        await showDialog(StopMessage, { message });
    }, [showDialog, message]);

    const handlePause = useCallback(async () => {
        await showDialog(PauseMessage, { message });
    }, [showDialog, message]);

    const handleResume = useCallback(async () => {
        await showDialog(ResumeMessage, { message });
    }, [showDialog, message]);

    const handleCancelPublishing = useCallback(async () => {
        await showDialog(CancelPublishing, { message });
    }, [showDialog, message]);

    const hasError = Object.keys(errors).length > 0;

    const editable = message?.state !== "completed" && message?.commercialState !== "generating_draft";

    const resetAiResult = () => {
        setAiResult(null);
        setAiResultImage(null);
        setAiResultVideo(null);
        setAiResultVideoImage(null);
        setAiResultEmailReady(false);
        setAiResultError(null);
    };

    const activateAiLoading = useCallback(() => {
        setLoadingAI(true);
        resetAiResult();
    }, []);

    const loadAiWizardImage = useCallback(() => {
        activateAiLoading();
        callAi(values, aiLang, "wizard_generate_image").then(
            (successData) => {
                let imgUrl = successData?.image_url;
                if (imgUrl) setAiResultImage(trim(imgUrl));
                setLoadingAI(false);
            },
            (reason) => {
                setAiResultError(reason);
                setLoadingAI(false);
            }
        );
    }, [activateAiLoading, aiLang, values]);

    const loadAiWizardVideo = useCallback(() => {
        activateAiLoading();
        callAi(values, aiLang, "wizard_generate_video").then(
            (successData) => {
                let video_url = successData?.video_url;
                if (video_url) setAiResultVideo(trim(video_url));
                let video_image_url = successData?.image_url;
                if (video_image_url) setAiResultVideoImage(trim(video_image_url));
                setLoadingAI(false);
            },
            (reason) => {
                setAiResultError(reason);
                setLoadingAI(false);
            }
        );
    }, [activateAiLoading, aiLang, values]);

    const loadAiWizardText = useCallback(() => {
        activateAiLoading();
        callAi(values, aiLang, "wizard_get_new_content").then(
            (successData) => {
                let aiTitle = successData?.ai_text;
                if (typeof aiTitle === "object")
                    // eslint-disable-next-line prefer-destructuring
                    aiTitle = Object.values(aiTitle)[0];
                if (aiTitle) setAiResult(trim(aiTitle));
                setLoadingAI(false);
            },
            (reason) => {
                setAiResultError(reason);
                setLoadingAI(false);
            }
        );
    }, [activateAiLoading, aiLang, values]);

    const loadAiWizardGoogle = useCallback(() => {
        activateAiLoading();
        callAi(values, aiLang, "wizard_get_new_google").then(
            (successData) => {
                let response = "";
                response += "Headline 1:\n";
                response += successData.ai_headline_1 + "\n\n";
                response += "Headline 2:\n";
                response += successData.ai_headline_2 + "\n\n";
                response += "Headline 3:\n";
                response += successData.ai_headline_3 + "\n\n";
                response += "Description 1:\n";
                response += successData.ai_description_1 + "\n\n";
                response += "Description 2:\n";
                response += successData.ai_description_2 + "\n\n";
                response += "Keywords:\n";
                response += successData.ai_keywords;
                setAiResult(trim(response));
                setLoadingAI(false);
            },
            (reason) => {
                setAiResultError(reason);
                setLoadingAI(false);
            }
        );
    }, [values, aiLang, activateAiLoading]);

    const loadAiWizardEmailPendingDraft = useCallback(() => {
        activateAiLoading();
        callAi(values, aiLang, "wizard_draft_follow_up_email").then(
            (successData) => {
                if (successData) {
                    const wizard_ad_link = buildAppUrl(`/edit/wizard2/?messageId=${message.id}`);
                    const body = successData.body.replace("{wizard_ad_link}", wizard_ad_link);
                    setEmailSubject(successData.subject);
                    setEmailBody(body);
                    setAiResultEmailReady(true);
                }
                setLoadingAI(false);
            },
            (reason) => {
                setAiResultError(reason);
                setLoadingAI(false);
            }
        );
    }, [activateAiLoading, aiLang, message, values]);

    const seePremiumOffer = useCallback(async () => {
        const premium_offer_link = buildAppUrl(`/edit/premium-offer-view/${message.id}/`);
        window.open(premium_offer_link, "_blank");
    }, [message.id]);

    const regeneratePremiumOffer = useCallback(async () => {
        if (
            window.confirm(
                "Are you sure you want to restart the offer generation ? This will overwrite current AD data."
            ) !== true
        )
            return;
        if (creatingDraft) {
            toast.error(
                `Premium offer generation already started. Please wait a few minutes and then refresh this page.`
            );
        }
        setCreatingDraft(true);
        activateAiLoading();
        await createAiDraft({ messageId: message.id, lang: aiLang });
        setLoadingAI(false);
        if (createAiError) {
            toast.error(createAiError);
        } else {
            toast.success(`Premium offer generation started. Please wait a few minutes and then refresh this page.`);
            setTimeout(() => {
                history.push("/messages/");
            }, 1);
        }
    }, [activateAiLoading, aiLang, message, createAiDraft, createAiError, creatingDraft, history]);

    const loadAiPredefinedEmailPremiumOffer = useCallback(() => {
        const premium_offer_link = buildAppUrl(`/edit/premium-offer-view/${message.id}/c`);
        const premium_offer_price = "380";
        const metas = JSON.parse(message.wizardMetas);
        const body = metas.offer_mail_body
            .replace("{premium_offer_link}", premium_offer_link)
            .replace("{premium_offer_price}", premium_offer_price);
        setEmailSubject(metas.offer_mail_subject);
        setEmailBody(body);
        setEmailType(PREMIUM_OFFER_EMAIL_TYPE);
        setAiResultEmailReady(true);
    }, [message]);

    const handleRestoreMessage = useCallback(
        async (message) => {
            await showDialog(RestoreMessage, { message });
        },
        [showDialog]
    );

    const displayLeafValue = useCallback((entry) => {
        try {
            return entry !== null && (entry + "").startsWith("http") ? (
                <a target="_blank" rel="noreferrer" href={entry}>
                    {entry}
                </a>
            ) : (
                Object.keys(entry).length > 0 && entry
            );
        } catch (e) {
            return entry;
        }
    }, []);

    const displaySubElements = (entries) => {
        try {
            return Object.keys(entries).map((entry) => (
                <StyledWizardMetas.Main>
                    <StyledWizardMetas.Key>{entry}</StyledWizardMetas.Key>
                    {displayValue(entries[entry])}
                </StyledWizardMetas.Main>
            ));
        } catch (e) {
            return null;
        }
    };

    const displayValue = (entry) => {
        if (typeof entry === "object" && entry !== null && Object.keys(entry).length > 0)
            return <StyledWizardMetas.Subvalues>{displaySubElements(entry)}</StyledWizardMetas.Subvalues>;
        else return <StyledWizardMetas.Value>{displayLeafValue(entry)}</StyledWizardMetas.Value>;
    };
    return (
        <StyledMessageForm>
            <StickyContainer
                className="sticky-container"
                header={header}
                footer={
                    <StyledMessageFormFooter>
                        <ErrorCallout
                            className="footer-alert"
                            title="Cannot save, there is errors in your inputs"
                            message={
                                hasError && (
                                    <ul>
                                        {Object.entries(errors).map(([k, v]) => (
                                            <li key={k}>{v}</li>
                                        ))}
                                    </ul>
                                )
                            }
                        />
                        <div className="footer-buttons">
                            <Button
                                intent={Intent.NONE}
                                onClick={onRevert}
                                disabled={loading || !hasChanges}
                                text="Revert"
                                icon="reset"
                            />
                            &nbsp;
                            <Button
                                intent={Intent.PRIMARY}
                                onClick={(e) => onSubmit(true)}
                                disabled={loading || !hasChanges || hasError}
                                text="Save and close"
                                icon="log-out"
                            />
                            &nbsp;
                            <Button
                                intent={Intent.PRIMARY}
                                onClick={(e) => onSubmit(false)}
                                disabled={loading || !hasChanges || hasError}
                                text="Save & continue editing"
                            />
                        </div>
                    </StyledMessageFormFooter>
                }
            >
                <StyledInnerForm>
                    <Prompt
                        when={hasChanges && !disablePrompt}
                        message={() => "You have unsaved changes, do you really want to leave this page?"}
                    />
                    <FieldGroup>
                        <FormGroup label="ID">
                            <code>{message.id}</code>
                        </FormGroup>
                        <MessageField
                            label="Status"
                            name="state"
                            values={values}
                            disabled={!editable}
                            renderComponent={() => (
                                <>
                                    <FieldState
                                        message={message}
                                        disabled={hasChanges || hasError}
                                        onPublish={handlePublish}
                                        onStop={handleStop}
                                        onPause={handlePause}
                                        onResume={handleResume}
                                        onCancelPublishing={handleCancelPublishing}
                                    />
                                    {message.state === "completed" ? (
                                        <Button
                                            intent={Intent.SUCCESS}
                                            text="Restore Message"
                                            icon="automatic-updates"
                                            onClick={() => {
                                                handleRestoreMessage(message);
                                            }}
                                        />
                                    ) : null}
                                </>
                            )}
                        />
                        <MessageField
                            label="Owner"
                            name="owner"
                            values={values}
                            changes={changes}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldOwner value={value} onChange={setValue} disabled={disabled} />
                            )}
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <FormGroup label="Creation date">
                            <MessageDate value={message.createdDate} />
                        </FormGroup>
                        <FormGroup label="Start date">
                            <MessageDate value={message.startDate} />
                        </FormGroup>
                        <MessageField
                            label="End date"
                            name="liveEndDate"
                            changes={changes}
                            values={values}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable}
                            renderComponent={({ value, setValue, disabled }) => (
                                <MessageDate
                                    disabled={disabled}
                                    onChange={(v) => setValue(v)}
                                    value={value}
                                    maxDate={moment().add()}
                                />
                            )}
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <MessageField
                            label="Category"
                            name="topic"
                            changes={changes}
                            values={values}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable}
                            renderComponent={({ value, setValue, disabled }) => (
                                <SimpleSelect
                                    disabled={disabled}
                                    options={topics}
                                    onSelect={(v) => setValue(v)}
                                    selected={value}
                                />
                            )}
                        />

                        <MessageField
                            label="Language"
                            name="lang"
                            values={values}
                            renderComponent={({ value }) => (
                                <span>{ALL_LANG?.find((x) => x.value === value, { label: value }).label}</span>
                            )}
                        />

                        <MessageField
                            label="Review status"
                            name="lastReview"
                            values={values}
                            renderComponent={({ value }) => {
                                return (
                                    <>
                                        {value ? (
                                            <Tag
                                                minimal
                                                large
                                                intent={(() => {
                                                    if (value?.state === "accepted") {
                                                        return Intent.SUCCESS;
                                                    }
                                                    if (value?.state === "rejected") {
                                                        return Intent.DANGER;
                                                    }
                                                    if (value?.state === "need_review") {
                                                        return Intent.WARNING;
                                                    }
                                                    return Intent.NONE;
                                                })()}
                                            >
                                                {value?.state || "-----"}
                                            </Tag>
                                        ) : (
                                            "Ad still in draft"
                                        )}
                                        {value?.state === "need_review" ? (
                                            <Button
                                                onClick={() => history.push(`/validation/${value.id}`)}
                                                intent={Intent.NONE}
                                                rightIcon="arrow-right"
                                            />
                                        ) : (
                                            <small>
                                                &nbsp;<code>{value?.id}</code>
                                            </small>
                                        )}
                                    </>
                                );
                            }}
                        />
                        <MessageField
                            label="Initial amount"
                            name="initialAmount"
                            values={values}
                            changes={changes}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable}
                            renderComponent={({ value, setValue, disabled }) => (
                                <StyledInputAmount>
                                    <strong style={{ textAlign: "center" }}>{message.currency}</strong>
                                    <NumericInput
                                        disabled={disabled}
                                        value={value / 100}
                                        onValueChange={(v) => setValue(v * 100)}
                                        stepSize={10}
                                        min={
                                            DAILY_MIN_AMOUNT *
                                            message?.platformsMessage?.filter((plt) => plt?.active).length
                                        }
                                        max={10000}
                                    />
                                </StyledInputAmount>
                            )}
                        />
                        <MessageField
                            label="Initial interval"
                            name="initialInterval"
                            values={values}
                            renderComponent={({ value }) => <span>{value}</span>}
                        />
                        {message?.landing && (
                            <MessageField
                                label="Landing"
                                name="landing"
                                values={values}
                                renderComponent={({ value }) => (
                                    <span>
                                        <Button
                                            intent={Intent.SUCCESS}
                                            text="Open Landing"
                                            icon="open-application"
                                            onClick={() => {
                                                window.open(`${window.LANDING_SERVER_URL}/l/${value.slug}`);
                                            }}
                                        />
                                        <Button
                                            intent={Intent.PRIMARY}
                                            outlined
                                            text="Edit Landing"
                                            icon="edit"
                                            onClick={() => {
                                                window.open(buildAppUrl(`/edit/landing/editor/${value.slug}`));
                                            }}
                                        />
                                    </span>
                                )}
                            />
                        )}
                    </FieldGroup>
                    <MessageField
                        collapsed
                        label="Wizard metas"
                        name="wizardMetas"
                        values={values}
                        renderComponent={({ value }) => {
                            if (!value) return null;
                            const entries = JSON.parse(value);
                            if (Object.keys(entries).length < 1)
                                return (
                                    <StyledWizardMetas.Main>
                                        <StyledWizardMetas.Key>No wizard metas for this ad</StyledWizardMetas.Key>
                                    </StyledWizardMetas.Main>
                                );
                            return displaySubElements(entries);
                        }}
                    />
                    {window.AMPLIFY_TOOLS_AI_URL && window.AMPLIFY_TOOLS_AI_URL !== "None" && (
                        <FormGroup label="Amplify AI" disabled={loading || loadingAi}>
                            <StyleCommandBar>
                                <StyledRobot>
                                    <Robot className={loadingAi ? "animated" : ""} />
                                </StyledRobot>
                                {loadingAi ? (
                                    <StyledLoadingText>Amplify AI is working, please wait...</StyledLoadingText>
                                ) : (
                                    <>
                                        <StyledButtonsGroup>
                                            <StyledButtonsGroupHeader>
                                                <b>AI language</b>
                                            </StyledButtonsGroupHeader>
                                            <StyledButtonsGroupButtons>
                                                <FieldLang value={aiLang || message.lang} onChange={handleSetLang} />
                                            </StyledButtonsGroupButtons>
                                        </StyledButtonsGroup>
                                        <StyledButtonsGroup>
                                            <StyledButtonsGroupHeader>
                                                <b>Base tools</b>
                                            </StyledButtonsGroupHeader>
                                            <StyledButtonsGroupButtons>
                                                <StyledButton>
                                                    <Button
                                                        onClick={loadAiWizardText}
                                                        disabled={loading || loadingAi || !editable}
                                                    >
                                                        AD <b>text</b>
                                                    </Button>
                                                </StyledButton>
                                                <StyledButton>
                                                    <Button
                                                        onClick={loadAiWizardGoogle}
                                                        disabled={loading || loadingAi || !editable}
                                                    >
                                                        AD <b>google elements</b>
                                                    </Button>
                                                </StyledButton>
                                                <StyledButton>
                                                    <Button
                                                        onClick={loadAiWizardImage}
                                                        disabled={loading || loadingAi || !editable}
                                                    >
                                                        AD <b>image</b>
                                                    </Button>
                                                </StyledButton>
                                                <StyledButton>
                                                    <Button
                                                        onClick={loadAiWizardVideo}
                                                        disabled={loading || loadingAi || !editable}
                                                    >
                                                        AD <b>video</b> (ALPHA)
                                                    </Button>
                                                </StyledButton>
                                                <StyledButton>
                                                    <Button
                                                        onClick={loadAiWizardEmailPendingDraft}
                                                        disabled={
                                                            loading ||
                                                            loadingAi ||
                                                            message.state !== "draft" ||
                                                            !editable
                                                        }
                                                    >
                                                        Email <b>follow-up draft</b>
                                                    </Button>
                                                </StyledButton>
                                            </StyledButtonsGroupButtons>
                                        </StyledButtonsGroup>
                                        <StyledButtonsGroup>
                                            <StyledButtonsGroupHeader>
                                                <b>Premium offer tools</b>
                                            </StyledButtonsGroupHeader>
                                            <StyledButtonsGroupButtons>
                                                <StyledButton>
                                                    <Button
                                                        onClick={seePremiumOffer}
                                                        disabled={
                                                            loading ||
                                                            loadingAi ||
                                                            message.state !== "draft" ||
                                                            (message.commercialState !== "draft_ready" &&
                                                                message.commercialState !== "customer_contacted" &&
                                                                message.commercialState !== "e_counter_customer" &&
                                                                message.commercialState !== "asks_premium_monthly" &&
                                                                message.commercialState !== "asks_premium_yearly")
                                                        }
                                                    >
                                                        See premium offer
                                                    </Button>
                                                </StyledButton>
                                                <StyledButton>
                                                    <Button
                                                        onClick={regeneratePremiumOffer}
                                                        disabled={
                                                            loading ||
                                                            loadingAi ||
                                                            message.state !== "draft" ||
                                                            message.commercialState === "not_in_commercial_pipe" ||
                                                            message.commercialState === "customer_contacted" ||
                                                            message.commercialState === "e_counter_customer" ||
                                                            message.commercialState === "asks_premium_monthly" ||
                                                            message.commercialState === "asks_premium_yearly" ||
                                                            message.commercialState === "started_premium"
                                                        }
                                                    >
                                                        Regenerate premium offer
                                                    </Button>
                                                </StyledButton>

                                                <StyledButton>
                                                    <Button
                                                        onClick={loadAiPredefinedEmailPremiumOffer}
                                                        disabled={
                                                            loading ||
                                                            loadingAi ||
                                                            (message.commercialState !== "draft_ready" &&
                                                                message.commercialState !== "e_counter_customer") ||
                                                            !editable ||
                                                            message?.wizardMetas?.indexOf("offer_mail_subject") ===
                                                                -1 ||
                                                            message?.wizardMetas?.indexOf("offer_mail_subject") === -1
                                                        }
                                                    >
                                                        Check & send email
                                                    </Button>
                                                </StyledButton>
                                            </StyledButtonsGroupButtons>
                                        </StyledButtonsGroup>
                                    </>
                                )}
                            </StyleCommandBar>
                            <p>
                                {aiResultError && (
                                    <>
                                        <label htmlFor="errorArea">
                                            <h3>Unfortunately, an error occured</h3>
                                        </label>

                                        <TextArea id="errorArea" value={aiResultError} fill rows={6}></TextArea>
                                    </>
                                )}
                                {aiResult && (
                                    <>
                                        <label htmlFor="resultArea">
                                            <h3>Generated title</h3>
                                        </label>

                                        <TextArea id="resultArea" value={aiResult} fill rows={18}></TextArea>
                                    </>
                                )}
                                {aiResultImage && (
                                    <>
                                        <label htmlFor="resultAreaImg">
                                            <h3>Generated image</h3>
                                        </label>
                                        <p>
                                            <StyledImage id="resultAreaImg" src={aiResultImage}></StyledImage>
                                        </p>
                                    </>
                                )}
                                {aiResultVideo && (
                                    <>
                                        <label htmlFor="resultAreaImg">
                                            <h3>Generated video (ALPHA)</h3>
                                        </label>
                                        <p>
                                            <StyledVideo>
                                                <video
                                                    width="480"
                                                    height="480"
                                                    controls
                                                    loop
                                                    poster={aiResultVideoImage}
                                                >
                                                    <source src={aiResultVideo} type="video/mp4" />
                                                </video>
                                            </StyledVideo>
                                        </p>
                                    </>
                                )}
                                {aiResultEmailReady && (
                                    <>
                                        <label>
                                            <h3>Generated email</h3>
                                        </label>
                                        <p>
                                            <label htmlFor="aiEmailTitle">
                                                <b>Subject:</b>
                                            </label>
                                            <InputGroup
                                                id="aiEmailTitle"
                                                value={emailSubject || ""}
                                                onChange={(e) => setEmailSubject(e.currentTarget.value)}
                                            />
                                            <label htmlFor="aiEmailBody">
                                                <b>Body:</b>
                                            </label>
                                            <TextArea
                                                id="aiEmailBody"
                                                value={emailBody || ""}
                                                onChange={(e) => setEmailBody(e.currentTarget.value)}
                                                fill
                                                rows={10}
                                            ></TextArea>
                                            <Button
                                                intent={Intent.PRIMARY}
                                                rightIcon="send-message"
                                                alignText="left"
                                                text="Send email"
                                                onClick={handleSend}
                                                loading={sending}
                                            />
                                        </p>
                                    </>
                                )}
                            </p>
                        </FormGroup>
                    )}
                    <MessageMultipleField
                        collapsed
                        label="Texts"
                        name="texts"
                        changes={changes}
                        values={values}
                        onChangeValue={onChangeValue}
                        errors={errors}
                        onRevert={onRevertKey}
                        disabled={!editable}
                        useColumnLayout
                        renderComponent={({ index, value, setValue, removeEntry, disabled }) => (
                            <FieldText
                                index={index}
                                value={value}
                                onChange={setValue}
                                onRemove={removeEntry}
                                disabled={disabled}
                            />
                        )}
                        addElement={({ setValue }) => (
                            <Button onClick={() => setValue("")} text="Add Text" icon="plus" />
                        )}
                        removeElement={({ index, value, setValue }) => {
                            return (
                                !textTypesGoogle.includes(value?.type) && (
                                    <Button
                                        index={index}
                                        intent={Intent.NONE}
                                        onClick={setValue}
                                        text="Remove"
                                        icon="delete"
                                    />
                                )
                            );
                        }}
                    />

                    <MessageMultipleField
                        label="Images"
                        name="images"
                        changes={changes}
                        values={values}
                        onChangeValue={onChangeValue}
                        errors={errors}
                        onRevert={onRevertKey}
                        disabled={!editable}
                        renderComponent={({ index, value, setValue, removeEntry, disabled }) => (
                            <FieldImage
                                index={index}
                                value={value}
                                onChange={setValue}
                                onRemove={removeEntry}
                                disabled={disabled}
                            />
                        )}
                        addElement={({ setValue }) => <FieldImage value={null} onChange={setValue} />}
                        removeElement={({ index, setValue }) => (
                            <Button index={index} intent={Intent.NONE} onClick={setValue} text="Remove" icon="delete" />
                        )}
                    />
                    <MessageMultipleField
                        label="Videos"
                        name="movies"
                        changes={changes}
                        values={values}
                        onChangeValue={onChangeValue}
                        errors={errors}
                        onRevert={onRevertKey}
                        disabled={!editable}
                        renderComponent={({ index, value, setValue, removeEntry, disabled }) => (
                            <FieldVideo
                                index={index}
                                value={value}
                                onChange={setValue}
                                onRemove={removeEntry}
                                disabled={disabled}
                            />
                        )}
                        addElement={({ setValue }) => <FieldVideo value={null} onChange={setValue} />}
                        removeElement={({ index, setValue }) => (
                            <Button index={index} intent={Intent.NONE} onClick={setValue} text="Remove" icon="delete" />
                        )}
                    />
                    <h3>Destination URL</h3>
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={() => {
                            onChangeValue(isLandingUrl ? "landing" : "adLink", null);
                            setIsLandingUrl(!isLandingUrl);
                        }}
                        text={`Link to ${isLandingUrl ? "Website" : "Landing"} URL`}
                        icon="open-application"
                    />
                    {isLandingUrl ? (
                        <div>
                            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                                {landings?.results?.map((landing, index) => {
                                    return (
                                        <div
                                            style={{
                                                border: "1px solid black",
                                                width: "fit-content",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "10px",
                                                padding: "10px",
                                            }}
                                        >
                                            <h4>Landing {index + 1}</h4>
                                            <Button
                                                intent={Intent.SUCCESS}
                                                text="Open Landing"
                                                icon="open-application"
                                                onClick={() => {
                                                    window.open(`${window.LANDING_SERVER_URL}/l/${landing.slug}`);
                                                }}
                                            />
                                            <Button
                                                intent={Intent.DANGER}
                                                text="Link this landing Page"
                                                icon="open-application"
                                                onClick={() => {
                                                    onChangeLandingUrl("landing", landing.slug);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <MessageField
                                label="Landing URL"
                                name="landing"
                                changes={changes}
                                values={values}
                                onChangeValue={onChangeLandingUrl}
                                errors={errors}
                                onRevert={onRevertKey}
                                disabled={!editable || !isLandingUrl}
                                renderComponent={({ value, setValue, disabled }) => (
                                    <FieldUrl value={value?.slug} onChange={setValue} disabled={disabled} landing />
                                )}
                            />
                        </div>
                    ) : (
                        <MessageField
                            label="Website URL"
                            name="adLink"
                            changes={changes}
                            values={values}
                            onChangeValue={onChangeUrl}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable || isLandingUrl}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldUrl value={value} onChange={setValue} disabled={disabled} />
                            )}
                        />
                    )}
                    <MessageField
                        label="Utm for the ad"
                        name="utm"
                        changes={changes}
                        values={values}
                        onChangeValue={onChangeValue}
                        errors={errors}
                        onRevert={onRevertKey}
                        disabled={!editable}
                        renderComponent={({ value, setValue, disabled }) => (
                            <InputGroup
                                type="text"
                                id="utm"
                                autofocus
                                onChange={(e) => setValue(e.target.value)}
                                value={value}
                                placeholder="Example: utm_content=Ad"
                                disabled={disabled}
                            />
                        )}
                    />
                    <h3>Targeting</h3>
                    <MessageField
                        label="Geography"
                        name="targetingLinks"
                        changes={changes}
                        values={values}
                        onChangeValue={onChangeValue}
                        errors={errors}
                        onRevert={onRevertKey}
                        disabled={!editable}
                        renderComponent={({ value, setValue, disabled }) => (
                            <GeoTargetingField value={value} onChange={setValue} disabled={disabled} />
                        )}
                    />
                    <FieldGroup>
                        <MessageField
                            label="Age"
                            name="targetingAge"
                            changes={changes}
                            values={values}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldAge
                                    disabled={disabled}
                                    value={[value?.min, value?.max]}
                                    onChange={([min, max]) => {
                                        setValue({ min, max });
                                    }}
                                />
                            )}
                        />

                        <MessageField
                            label="Gender"
                            name="targetingGender"
                            changes={changes}
                            values={values}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldGender value={value} onChange={setValue} disabled={disabled} />
                            )}
                        />

                        <MessageField
                            label={
                                <span>
                                    <Icon icon="locate" /> Targeting Profile
                                </span>
                            }
                            name="targetingProfile"
                            changes={changes}
                            values={values}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldTargetingProfile value={value} onChange={setValue} disabled={disabled} />
                            )}
                        />
                    </FieldGroup>
                    <MessageField
                        label={
                            <div>
                                Platform and budget allocation{" "}
                                {message.isAutoPlatform ? (
                                    <Tag minimal active intent={Intent.SUCCESS}>
                                        Amplify optimized
                                    </Tag>
                                ) : (
                                    <Tag minimal active intent={Intent.WARNING}>
                                        Customer choice
                                    </Tag>
                                )}
                            </div>
                        }
                        name="platformsMessage"
                        changes={changes}
                        values={values}
                        onChangeValue={onChangeValue}
                        errors={errors}
                        onRevert={onRevertKey}
                        disabled={!editable}
                        renderComponent={({ value, setValue, disabled }) => (
                            <BudgetProfileForm
                                value={value || []}
                                onChange={setValue}
                                disabled={disabled}
                                showPercent
                                status={message.state}
                            />
                        )}
                    />
                    {hasGoogleAds && (
                        <MessageField
                            label="Google Ads"
                            name="texts"
                            changes={changes}
                            values={values}
                            onChangeValue={onChangeValue}
                            errors={errors}
                            onRevert={onRevertKey}
                            disabled={!editable}
                            renderComponent={({ value, setValue, disabled, errors }) => (
                                <FieldGoogleTexts
                                    disabled={disabled}
                                    onChange={(v) => setValue(v)}
                                    value={value}
                                    errors={errors}
                                />
                            )}
                        />
                    )}
                </StyledInnerForm>
            </StickyContainer>
        </StyledMessageForm>
    );
};

MessageForm.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        commercialState: PropTypes.string.isRequired,
        createdDate: PropTypes.string,
        startDate: PropTypes.string,
        liveEndDate: PropTypes.string,
        isAutoPlatform: PropTypes.bool,
        currency: PropTypes.string,
        lang: PropTypes.string,
        title: PropTypes.string,
        adLink: PropTypes.string,
        owner: PropTypes.shape({
            email: PropTypes.string,
        }),
        platformsMessage: PropTypes.arrayOf(PropTypes.shape({})),
        wizardMetas: PropTypes.string,
        landing: PropTypes.shape({
            slug: PropTypes.string,
        }),
    }).isRequired,
    landings: PropTypes.shape({
        results: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    initialChanges: PropTypes.shape({}),
    onChangeMessage: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disablePrompt: PropTypes.bool,
    header: PropTypes.node,
};

MessageForm.defaultProps = {
    loading: false,
    initialChanges: {},
    disablePrompt: false,
    header: null,
    landings: null,
};

export default MessageForm;
