import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const SIZE_MAPPING = {
    none: "0",
    xsmall: "5px",
    small: "10px",
    medium: "15px",
    large: "20px",
    xlarge: "30px",
    xxlarge: "50px",
};

const getCssSize = (name) => {
    return name ? SIZE_MAPPING[name] || null : null;
};

const StyledBox = styled.div`
    padding: ${({ $padding }) => getCssSize($padding)};
    display: flex;
    flex-direction: ${({ $direction }) => $direction || "column"};
    ${({ $background }) =>
        $background &&
        css`
            background-color: ${$background};
        `};
    ${({ $fill }) =>
        $fill &&
        css`
            height: 100%;
            width: 100%;
        `};
    ${({ $gap }) =>
        $gap &&
        css`
            & > *:not(:last-child) {
                margin-right: ${({ $gap }) => getCssSize($gap)};
            }
            & > *:not(:first-child) {
                margin-left: ${({ $gap }) => getCssSize($gap)};
            }
        `}
`;

const Box = ({ pad, gap, direction, fill, background, children, ...args }) => {
    return (
        <StyledBox $padding={pad} $gap={gap} $direction={direction} $fill={fill} $background={background} {...args}>
            {children}
        </StyledBox>
    );
};

Box.propTypes = {
    pad: PropTypes.oneOf(Object.keys(SIZE_MAPPING)),
    gap: PropTypes.oneOf(Object.keys(SIZE_MAPPING)),
    direction: PropTypes.oneOf(["row", "column", "row-responsive", "row-reverse", "column-reverse"]),
    fill: PropTypes.string,
    background: PropTypes.string,
    children: PropTypes.node,
};

Box.defaultProps = {
    pad: null,
    direction: null,
    children: null,
    fill: null,
    gap: null,
    background: null,
};

export default Box;
