import { Button, ButtonGroup, InputGroup } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledFieldUrl = styled.div``;

const FieldUrl = ({ value, onChange, disabled, loading, landing }) => {
    return (
        <StyledFieldUrl>
            <InputGroup
                fill
                disabled={loading || disabled}
                onChange={(e) => {
                    onChange(e.target.value || null);
                }}
                value={value || ""}
                leftIcon="link"
                placeholder="Destination not set..."
                rightElement={
                    value && (
                        <ButtonGroup>
                            <Button
                                icon="arrow-right"
                                loading={loading}
                                onClick={() => {
                                    landing
                                        ? window.open(`${window.LANDING_SERVER_URL}/l/${value}`, "_blank")
                                        : window.open(value, "_blank");
                                }}
                            />
                        </ButtonGroup>
                    )
                }
            />
        </StyledFieldUrl>
    );
};

FieldUrl.propTypes = {
    value: PropTypes.string,
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    landing: PropTypes.bool,
};

FieldUrl.defaultProps = {
    value: null,
    emptyValue: null,
    onChange: null,
    disabled: false,
    loading: false,
    landing: false,
};

export default FieldUrl;
