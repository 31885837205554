import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { omitDeep } from "../../common/utils";

const TARGETING_PROFILE_FRAGMENT = gql`
    fragment targetingprofile on TargetingProfile {
        id
        title
        platforms
        usedCount
        items {
            platform
            type
            data
        }
        owner {
            username
        }
    }
`;

export const GQL_QUERY_SEARCH_TARGETING_PROFILES = gql`
    query QueryTargetingProfiles($q: String!, $offset: Int) {
        searchTargetingProfiles(q: $q, offset: $offset) {
            count
            nextOffset
            result {
                ...targetingprofile
            }
        }
    }
    ${TARGETING_PROFILE_FRAGMENT}
`;

export const useQuerySearchTargetingProfiles = () => {
    const [caller, { data: { searchTargetingProfiles } = { searchTargetingProfiles: { result: null } }, ...rest }] =
        useLazyQuery(GQL_QUERY_SEARCH_TARGETING_PROFILES, {
            fetchPolicy: "cache-and-network",
        });
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { result: searchTargetingProfiles, ...rest }];
};

export const GQL_QUERY_GET_TARGETING_PROFILE = gql`
    query QueryTargetingProfile($id: UUID!) {
        getTargetingProfile(id: $id) {
            ...targetingprofile
        }
    }
    ${TARGETING_PROFILE_FRAGMENT}
`;

export const useGetTargetingProfile = () => {
    const [caller, { data: { getTargetingProfile } = { getTargetingProfile: null }, ...rest }] = useLazyQuery(
        GQL_QUERY_GET_TARGETING_PROFILE,
        {
            fetchPolicy: "no-cache",
        }
    );
    const queryFunction = useCallback((id) => caller({ variables: { id } }), [caller]);
    return [queryFunction, { result: getTargetingProfile, ...rest }];
};

const GQL_MUTATION_CLONE_TARGETING_PROFILE = gql`
    mutation CloneTargetingProfile($id: UUID!) {
        cloneTargetingProfile(id: $id) {
            targetingProfile {
                ...targetingprofile
            }
        }
    }
    ${TARGETING_PROFILE_FRAGMENT}
`;

export const useMutationCloneTargetingProfile = () => {
    const [actionPromise] = useMutation(GQL_MUTATION_CLONE_TARGETING_PROFILE, {
        refetchQueries: [GQL_QUERY_SEARCH_TARGETING_PROFILES],
    });
    return useCallback(
        async (id) => {
            const ret = await actionPromise({ variables: { id } });
            return ret.data.cloneTargetingProfile.targetingProfile;
        },
        [actionPromise]
    );
};

const GQL_MUTATION_UPDATE_TARGETING_PROFILE = gql`
    mutation UpdateTargetingProfile(
        $id: UUID!
        $title: String
        $platforms: [String]
        $items: [TargetingProfileItemInput]
    ) {
        updateTargetingProfile(id: $id, title: $title, platforms: $platforms, items: $items) {
            targetingProfile {
                ...targetingprofile
            }
        }
    }
    ${TARGETING_PROFILE_FRAGMENT}
`;

export const useMutationUpdateTargetingProfile = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_UPDATE_TARGETING_PROFILE, {
        refetchQueries: [GQL_QUERY_GET_TARGETING_PROFILE],
    });
    return [
        useCallback(
            async (variables) => {
                const ret = await actionPromise({ variables: omitDeep(variables, "__typename") });
                return ret.data.updateTargetingProfile.targetingProfile;
            },
            [actionPromise]
        ),
        rest,
    ];
};

const GQL_MUTATION_CREATE_TARGETING_PROFILE = gql`
    mutation CreateTargetingProfile($title: String!, $platforms: [String]!, $items: [TargetingProfileItemInput]!) {
        createTargetingProfile(title: $title, platforms: $platforms, items: $items) {
            targetingProfile {
                ...targetingprofile
            }
        }
    }
    ${TARGETING_PROFILE_FRAGMENT}
`;

export const useMutationCreateTargetingProfile = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_CREATE_TARGETING_PROFILE);
    return [
        useCallback(
            async (variables) => {
                const ret = await actionPromise({ variables: omitDeep(variables, "__typename") });
                return ret.data.createTargetingProfile.targetingProfile;
            },
            [actionPromise]
        ),
        rest,
    ];
};

const GQL_MUTATION_DELETE_TARGETING_PROFILE = gql`
    mutation DeleteTargetingProfile($id: UUID!) {
        deleteTargetingProfile(id: $id) {
            result
        }
    }
`;

export const useMutationDeleteTargetingProfile = () => {
    const [actionPromise] = useMutation(GQL_MUTATION_DELETE_TARGETING_PROFILE, {
        refetchQueries: [GQL_QUERY_SEARCH_TARGETING_PROFILES],
    });
    return useCallback(async (id) => actionPromise({ variables: { id } }), [actionPromise]);
};

export const GQL_QUERY_SEARCH_PLATFORM_INTERESTS = gql`
    query QueryPlatformInterest($platform: String!, $q: String!, $lang: String!) {
        searchPlatformInterest(platform: $platform, q: $q, lang: $lang)
    }
`;

export const useQuerySearchPlatformInterests = () => {
    const [caller, { data: { searchPlatformInterest } = { searchPlatformInterest: null }, ...rest }] = useLazyQuery(
        GQL_QUERY_SEARCH_PLATFORM_INTERESTS,
        {
            fetchPolicy: "no-cache",
        }
    );
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { result: searchPlatformInterest, ...rest }];
};
