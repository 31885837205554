import { Callout, NonIdealState, Spinner, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import ErrorPane from "../../common/error-pane";
import { formatCurrency } from "../../common/i18n";
import { useQueryTransactions } from "./gql-transactions";
import TransactionsTable from "./transactions-table";

const TransactionsList = ({ search }) => {
    const [loadTransactions, { loading, error, wallet }] = useQueryTransactions();

    useEffect(() => {
        if (search) {
            loadTransactions({
                q: search,
            });
        }
    }, [search, loadTransactions]);
    return (
        <div>
            {error ? <ErrorPane error={error} /> : null}
            {loading && (
                <Callout title="Loading, please wait...">
                    <Spinner />
                </Callout>
            )}
            {!loading && (
                <div>
                    {wallet ? (
                        wallet.transactions.length ? (
                            <div>
                                <Tag large>Balance : {formatCurrency(wallet.balance, wallet.currency)}</Tag>
                                <TransactionsTable transactions={wallet.transactions || []} loading={loading} />
                            </div>
                        ) : (
                            <NonIdealState icon="zoom-out" title="Nothing found" />
                        )
                    ) : (
                        <NonIdealState icon="search" title="You need to search for a location first" />
                    )}
                </div>
            )}
        </div>
    );
};

TransactionsList.propTypes = {
    search: PropTypes.string,
    selectedId: PropTypes.number,
};

TransactionsList.defaultProps = {
    search: null,
    selectedId: null,
};

export default TransactionsList;
