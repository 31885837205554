import { Button, Callout, Card, Classes, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useIdentity } from "../common/identity-context";
import { parseQueryString } from "../common/utils";
import ErrorCallout from "../staff/common/error-callout";
import { loginPromise } from "./gql-promises";

const LoginFrame = styled.div`
    margin: 30px auto;
    width: 400px;
`;

const LoginPage = ({ location }) => {
    const [login, setLogin] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [identityLoaded, identity] = useIdentity();

    const nextUrl = useMemo(() => {
        const queries = parseQueryString(location.search);
        return queries.next;
    }, [location]);
    const goNext = useCallback(() => {
        window.location = nextUrl || "/";
    }, [nextUrl]);
    useEffect(() => {
        if (identityLoaded && identity && nextUrl) {
            window.location = nextUrl;
        }
    }, [identityLoaded, identity, nextUrl]);

    useEffect(() => {
        if (window && window.AMPLIFY_MESSAGES && window.AMPLIFY_MESSAGES.length) {
            setError({ errors: window.AMPLIFY_MESSAGES });
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLogin(true);
        setError(null);

        try {
            // token is not sent to the server for verification
            await loginPromise({
                email,
                password,
            });
            goNext();
        } catch (ex) {
            setError(ex);
            setPassword("");
        } finally {
            setLogin(false);
        }
    };

    const formValid = password.length > 3 && email.length > 3;

    return (
        <LoginFrame>
            {identityLoaded && identity ? (
                <Card elevation={4}>
                    <Callout intent={Intent.PRIMARY}>
                        <p>
                            Already logged-in as: <strong>{identity.username}</strong>
                        </p>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={Intent.PRIMARY} href="/" text="Back to Home" icon="home" />
                        </div>
                    </Callout>
                </Card>
            ) : (
                <Card elevation={4}>
                    <h3>Amplify staff login</h3>
                    <form onSubmit={handleSubmit}>
                        <FormGroup>
                            <InputGroup
                                type="text"
                                id="email"
                                autofocus
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                value={email}
                                placeholder="email"
                                disabled={login}
                            />
                        </FormGroup>
                        <FormGroup>
                            <InputGroup
                                type="password"
                                id="password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                value={password}
                                placeholder="password"
                                disabled={login}
                            />
                        </FormGroup>
                        {error && <ErrorCallout title="Error while login" error={error} />}
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                intent={Intent.PRIMARY}
                                disabled={!formValid}
                                type="submit"
                                text="Login"
                                icon="log-in"
                            />
                        </div>
                    </form>
                </Card>
            )}
        </LoginFrame>
    );
};

LoginPage.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
};

export default LoginPage;
