import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";

export const FRAGMENT_MESSAGE_REVIEW = gql`
    fragment messageReview on MessageReview {
        id
        title
        texts {
            value
            type
        }
        images {
            imageUrl
        }
        imageUrl
        state
        isNew
        createdAt
        slug
        messageId
        adLink
        owner {
            avatarUrl
            email
            firstName
            lastName
            business {
                billingAddress {
                    company
                }
            }
        }
        changes {
            key
            old
            new
        }
        backers(limit: 1) {
            id
            isSubscription
            lastPaymentErrorMessage
            status
        }
        landing {
            slug
            version
        }
        targetingProfile {
            id
            title
            platforms
            usedCount
            items {
                platform
                type
                data
            }
            owner {
                username
            }
        }
        adGoal
    }
`;

export const GQL_QUERY_ALL_MESSAGE_REVIEW = gql`
    query getStaffMessagesToReview {
        getStaffMessagesToReview {
            ...messageReview
        }
    }
    ${FRAGMENT_MESSAGE_REVIEW}
`;

const GQL_MUTATION_APPROVE_MESSAGE = gql`
    mutation ApproveMessage(
        $id: UUID!
        $goal: MessageAdGoal
        $topic: Topic!
        $targetingProfileId: UUID
        $facebookSpecialCategories: [FacebookSpecialAdCategory]
        $facebookCustomAudiences: [String]
        $businessName: String
    ) {
        approveMessage(
            id: $id
            goal: $goal
            topic: $topic
            targetingProfileId: $targetingProfileId
            facebookSpecialCategories: $facebookSpecialCategories
            facebookCustomAudiences: $facebookCustomAudiences
            businessName: $businessName
        ) {
            message {
                id
            }
        }
    }
`;

const GQL_MUTATION_APPROVE_CHANGES = gql`
    mutation ApproveChanges($id: UUID!) {
        approveChanges(id: $id) {
            message {
                id
            }
        }
    }
`;

const GQL_MUTATION_REJECT_MESSAGE = gql`
    mutation RejectMessage(
        $id: UUID!
        $rejectText: String!
        $topic: Topic!
        $targetingProfileId: UUID
        $businessName: String
    ) {
        rejectMessage(
            id: $id
            rejectText: $rejectText
            topic: $topic
            targetingProfileId: $targetingProfileId
            businessName: $businessName
        ) {
            message {
                id
            }
        }
    }
`;

const GQL_MUTATION_REJECT_CHANGES = gql`
    mutation RejectChanges($id: UUID!, $rejectText: String!) {
        rejectChanges(id: $id, rejectText: $rejectText) {
            message {
                id
            }
        }
    }
`;

export const useApproveMessage = () => {
    const [actionPromise] = useMutation(GQL_MUTATION_APPROVE_MESSAGE, {
        refetchQueries: [GQL_QUERY_ALL_MESSAGE_REVIEW],
    });
    return useCallback((variables) => actionPromise({ variables }), [actionPromise]);
};

export const useApproveChanges = () => {
    const [actionPromise] = useMutation(GQL_MUTATION_APPROVE_CHANGES, {
        refetchQueries: [GQL_QUERY_ALL_MESSAGE_REVIEW],
    });
    return useCallback((variables) => actionPromise({ variables }), [actionPromise]);
};

export const useRejectMessage = () => {
    const [actionPromise] = useMutation(GQL_MUTATION_REJECT_MESSAGE, {
        refetchQueries: [GQL_QUERY_ALL_MESSAGE_REVIEW],
    });
    return useCallback((variables) => actionPromise({ variables }), [actionPromise]);
};

export const useRejectChanges = () => {
    const [actionPromise] = useMutation(GQL_MUTATION_REJECT_CHANGES, {
        refetchQueries: [GQL_QUERY_ALL_MESSAGE_REVIEW],
    });
    return useCallback((variables) => actionPromise({ variables }), [actionPromise]);
};

export const useQueryMessageReview = (id) => {
    const {
        data: { getStaffMessagesToReview } = {},
        loading,
        error,
        ...rest
    } = useQuery(GQL_QUERY_ALL_MESSAGE_REVIEW, {
        fetchPolicy: "cache-and-network",
    });
    const message = useMemo(() => {
        return getStaffMessagesToReview && id && getStaffMessagesToReview.find((mess) => mess.id === id);
    }, [id, getStaffMessagesToReview]);
    const notFound = !loading && !message && !error;
    return { message, notFound, loading, error, ...rest };
};
