import { Button, Divider, Intent, Tag } from "@blueprintjs/core";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledFieldState = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > *:first-child {
        flex-shrink: 1;
    }
    .info {
        opacity: 0.5;
        padding: 0 5px;
    }
`;

const COMPLETED_MESSAGES = {
    stopped: "Manually stopped",
    canceled: "Canceled by staff",
    scheduled: "Reached its end date",
    no_budget: "Not spending for a long time",
};

const FieldState = ({ message, disabled, onStop, onPause, onResume, onPublish, onCancelPublishing }) => {
    const state = message?.state;
    return (
        <StyledFieldState>
            <Tag
                large
                minimal
                intent={(() => {
                    if (state === "live") {
                        return Intent.SUCCESS;
                    }
                    if (state === "review") {
                        return Intent.WARNING;
                    }
                    if (state === "completed") {
                        return Intent.PRIMARY;
                    }
                    return Intent.NONE;
                })()}
            >
                {state}
            </Tag>
            <Divider />
            {(state === "draft" || state === "completion") && onPublish && (
                <>
                    <Button disabled={disabled} intent={Intent.PRIMARY} text="Publish" onClick={onPublish} />
                    <Divider />
                </>
            )}
            {(state === "review" || state === "completion") && onCancelPublishing && (
                <Button
                    disabled={disabled}
                    intent={Intent.WARNING}
                    text="Cancel publishing"
                    onClick={onCancelPublishing}
                />
            )}
            {state === "live" && onStop && onPause && (
                <>
                    <Button intent={Intent.WARNING} text="Pause" icon="pause" onClick={onPause} />
                    <Divider />
                    <Button disabled={disabled} intent={Intent.DANGER} text="Stop ads" icon="stop" onClick={onStop} />
                </>
            )}
            {state === "paused" && onResume && (
                <>
                    <Button intent={Intent.SUCCESS} text="Resume Message" icon="play" onClick={onResume} />
                </>
            )}
            {message?.completedReason && (
                <div className="info">
                    {COMPLETED_MESSAGES[message.completedReason] || message.completedReason}
                    &nbsp;on&nbsp;
                    {message.completedEndDate ? <small>{moment(message.completedEndDate).format("LL")}</small> : "---"}
                </div>
            )}
        </StyledFieldState>
    );
};

FieldState.propTypes = {
    message: PropTypes.shape({
        state: PropTypes.string.isRequired,
        completedEndDate: PropTypes.string,
        completedReason: PropTypes.string,
    }),
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    onPublish: PropTypes.func,
    onStop: PropTypes.func,
    onPause: PropTypes.func,
    onResume: PropTypes.func,
    onCancelPublishing: PropTypes.func,
    disabled: PropTypes.bool,
};

FieldState.defaultProps = {
    message: null,
    emptyValue: null,
    onChange: null,
    onPublish: null,
    onStop: null,
    onPause: null,
    onResume: null,
    onCancelPublishing: null,
    disabled: false,
};

export default FieldState;
