import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import SimpleSelect from "../../common/simple-select";

const StyledFieldGender = styled.div``;

const GENDER_MAP = {
    null: "Any",
    M: "Male",
    F: "Female",
};

const GENDER_OPTIONS = [null, "M", "F"].map((v) => ({
    value: v,
    label: GENDER_MAP[v],
}));

const FieldGender = ({ value, onChange, disabled }) => {
    return (
        <StyledFieldGender>
            <SimpleSelect disabled={disabled} selected={value} options={GENDER_OPTIONS} onSelect={onChange} />
        </StyledFieldGender>
    );
};

FieldGender.propTypes = {
    value: PropTypes.string,
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FieldGender.defaultProps = {
    value: null,
    emptyValue: null,
    onChange: null,
    disabled: false,
};

export default FieldGender;
