import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback } from "react";

const GQL_GEO_TARGETING_FRAGMENT = gql`
    fragment targeting on Targeting {
        __typename
        id
        name
        nameFr
        nameEn
        nameDe
        localeName
        locale
        subtype
        platforms
        geojson
        validated
        names
        countryLocaleName
        isoCode
        population
        mapboxId
        platformData {
            platform
            data
        }
        parent {
            name
            subtype
        }
        owner {
            username
            id
        }
    }
`;

export const GQL_QUERY_GEO_TARGETING = gql`
    query searchGeoTargeting($q: String!) {
        searchGeoTargeting(q: $q, showInvalid: true) {
            ...targeting
        }
    }
    ${GQL_GEO_TARGETING_FRAGMENT}
`;

export const GQL_QUERY_GEO_TARGETING_BY_ID = gql`
    query getGeoTargeting($id: String!) {
        getGeoTargeting(id: $id) {
            ...targeting
        }
    }
    ${GQL_GEO_TARGETING_FRAGMENT}
`;

const GQL_UPDATE_GEO_TARGETING = gql`
    mutation updateGeoTargeting($id: Int!, $changes: TargetingChanges!) {
        updateTargeting(id: $id, changes: $changes) {
            targeting {
                ...targeting
            }
        }
    }
    ${GQL_GEO_TARGETING_FRAGMENT}
`;

const GQL_CREATE_GEO_TARGETING = gql`
    mutation createGeoTargeting($targeting: TargetingCreate!) {
        createTargeting(targeting: $targeting) {
            targeting {
                ...targeting
            }
        }
    }
    ${GQL_GEO_TARGETING_FRAGMENT}
`;

export const GQL_QUERY_PLATFORM_GEOLOC = gql`
    query searchPlatformGeoloc($q: String!, $platform: String!) {
        searchPlatformGeoloc(q: $q, platform: $platform)
    }
`;

export const useMutationUpdateGeoTargeting = () => {
    const [actionPromise] = useMutation(GQL_UPDATE_GEO_TARGETING);
    return useCallback((variables) => actionPromise({ variables }), [actionPromise]);
};

export const useMutationCreateGeoTargeting = () => {
    const [actionPromise] = useMutation(GQL_CREATE_GEO_TARGETING);
    return useCallback(
        async (variables) => {
            const { data } = await actionPromise({ variables });
            return data.createTargeting.targeting;
        },
        [actionPromise]
    );
};

export const useQueryPlatformGeoloc = () => {
    const [caller, { data: { searchPlatformGeoloc } = {}, ...rest }] = useLazyQuery(GQL_QUERY_PLATFORM_GEOLOC, {
        fetchPolicy: "cache-and-network",
    });
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { results: searchPlatformGeoloc, ...rest }];
};

export const useQueryGeoTargeting = () => {
    const [caller, { data: { searchGeoTargeting } = {}, ...rest }] = useLazyQuery(GQL_QUERY_GEO_TARGETING);
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { targetings: searchGeoTargeting, ...rest }];
};

export const useQueryGeoTargetingById = () => {
    const [caller, { data: { getGeoTargeting } = {}, ...rest }] = useLazyQuery(GQL_QUERY_GEO_TARGETING_BY_ID);
    const queryFunction = useCallback((id) => caller({ variables: { id: id } }), [caller]);
    return [queryFunction, { targeting: getGeoTargeting, ...rest }];
};
