import { Button, Callout, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationStopMessage } from "../messges/gql-messages";

const StopMessageDialog = ({ hideDialog, isDialogProcessing, message }) => {
    const [stopMessage, { loading, error }] = useMutationStopMessage();
    const handleStopMessage = useCallback(async () => {
        await stopMessage({
            id: message.id,
        });
        toast.success("Message stopped successfully");
        hideDialog(1, {});
    }, [message, hideDialog, stopMessage]);

    return message.state === "live" ? (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while stopping message" error={error} />
                <p>
                    <strong>{message.texts?.find((val) => val.type === "primary_text")?.value}</strong>
                </p>
                <p>Do you really want to stop this message?</p>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="No" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={loading}
                        loading={loading || isDialogProcessing}
                        intent={Intent.WARNING}
                        text="YES! Stop it"
                        onClick={handleStopMessage}
                        rightIcon="arrow-right"
                    />
                </div>
            </div>
        </>
    ) : (
        <>
            <div className={Classes.DIALOG_BODY}>
                <Callout intent={Intent.WARNING} icon="warning-sign" title="Only 'live' messages can be stopped" />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="Ok" onClick={() => hideDialog(0)} />
                </div>
            </div>
        </>
    );
};

StopMessageDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
    }).isRequired,
};

export default {
    title: "Stop message",
    icon: "stopwatch",
    component: StopMessageDialog,
};
