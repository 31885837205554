import { Button, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { useApproveChanges, useApproveMessage } from "./gql-validation";
import ReviewNew, { DEFAULT_DATA, asGraphqlParameters } from "./new-review-form";

const ModalAccept = ({ hideDialog, setIsDialogProcessing, isDialogProcessing, message }) => {
    const [data, setData] = useState(DEFAULT_DATA);
    const canApprove = !!data.topic || !message.isNew;
    const approveMessage = useApproveMessage();
    const approveChanges = useApproveChanges();

    const handleAccept = async () => {
        setIsDialogProcessing(true);
        try {
            if (message.isNew) {
                const approveParam = {
                    id: message.id,
                    ...asGraphqlParameters(data),
                };
                await approveMessage(approveParam);
            } else {
                await approveChanges({ id: message.id });
            }
            toast.success("Message accepted!");
            hideDialog(1);
        } catch (error) {
            toast.error(`Error ${error.message}`);
            hideDialog(-1);
        } finally {
            setIsDialogProcessing(true);
        }
    };

    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                {message.isNew ? (
                    <ReviewNew data={data} setData={setData} message={message} />
                ) : (
                    <div>Do you really want to accept this review</div>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={isDialogProcessing || !canApprove}
                        loading={isDialogProcessing}
                        intent={Intent.SUCCESS}
                        text="Accept"
                        onClick={handleAccept}
                    />
                </div>
            </div>
        </>
    );
};

ModalAccept.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isNew: PropTypes.bool.isRequired,
    }).isRequired,
};

export default {
    title: "Accept review",
    icon: "confirm",
    intent: Intent.SUCCESS,
    component: ModalAccept,
};
