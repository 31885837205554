import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

import WizardCompletionListPage from "./wizard-completion-list-page";

const WizardCompletionPage = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.url}/`} component={WizardCompletionListPage} />
        </Switch>
    );
};

WizardCompletionPage.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default WizardCompletionPage;
