import { MenuItem } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ReactComponent as Logo } from "../img/logo.svg";

export const Sidebar = styled.div`
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 250px;
    background-color: #fafafa;
    .bp4-menu {
        background-color: #fafaff;
    }
    @media screen and (max-width: 800px) {
        width: 70px;
        .avatar-email {
            display: none;
        }
        .bp4-text-overflow-ellipsis {
            display: none;
            width: 0;
        }
        ul {
            min-width: 60px;
        }
    }
`;

export const SidebarHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
`;

export const SidebarFooter = styled(({ className }) => (
    <div className={className}>
        <Logo />
        <span>for STAFF</span>
    </div>
))`
    display: flex;
    align-items: center;
    padding: 10px;
    position: absolute;
    bottom: 0;
    svg {
        height: 2em;
        width: 100px;
        flex-shrink: 1;
    }
    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        text-align: center;
        svg {
            width: 50px;
        }
    }
`;

export const SidebarContent = styled.div`
    position: absolute;
    bottom: 50px;
    top: 70px;
    left: 0;
    right: 0;
    overflow-y: auto;
`;

export const SidebarItem = ({ item, selected, onSelect }) => (
    <MenuItem
        onClick={() => onSelect(item)}
        text={item.label}
        icon={item.icon}
        selected={selected}
        label={item.widget}
    />
);

SidebarItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.node,
        widget: PropTypes.node,
    }).isRequired,
    selected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
};
