import { Classes, Icon, Intent } from "@blueprintjs/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { BsInfo } from "react-icons/bs";
import styled from "styled-components";

const StyledLogView = styled.div``;
const StyledLogCell = styled.div`
    display: flex;

    .inserted-at {
        position: relative;
        width: 150px;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        padding: 13px 0;

        .days {
            position: relative;
            font-size: 0.8em;
            z-index: 2;
        }
        .days-content {
            background-color: #f2f2f2;
            padding: 2px 5px;
            z-index: 2;
        }

        &.separator:before {
            position: absolute;
            display: block;
            transform: translateY(-50%);
            background-color: #aaa;
            content: " ";
            left: 50%;
            right: -20px;
            height: 1px;
            top: 20px;
            z-index: 1;
        }
    }

    .level {
        width: 50px;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        position: relative;
        padding: 10px 0;

        span {
            position: relative;
            z-index: 2;
            background-color: white;
            padding: 4px;
            border-radius: 3px;
            border: 1px solid #aaa;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        }

        &:before {
            position: absolute;
            display: block;
            margin-left: 50%;
            transform: translateX(-50%);
            width: 5px;
            background-color: #aaa;
            content: " ";
            top: 0;
            bottom: 0;
            height: 100%;
            z-index: 1;
        }
    }

    .text {
        flex-grow: 1;
        padding: 0 20px;
        margin: 5px 0 5px -25px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        .text-content {
            padding: 10px 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
`;

const getIntentForLevel = (level) => {
    switch (level) {
        case "INFO":
            return Intent.PRIMARY;
        case "ERROR":
            return Intent.DANGER;
        case "WARN":
            return Intent.WARNING;
        default:
            return null;
    }
};
const getIconForLevel = (level) => {
    switch (level) {
        case "INFO":
            return (
                <span className={classNames(Classes.ICON, Classes.INTENT_PRIMARY)}>
                    <BsInfo size={20} />
                </span>
            );
        case "ERROR":
            return <Icon size={20} icon="error" intent={Intent.DANGER} />;
        case "WARN":
            return <Icon size={20} icon="warning-sign" intent={Intent.WARNING} />;
        default:
            return null;
    }
};

const getBgColorForLevel = (level) => {
    switch (level) {
        case "INFO":
            return "#ddd";
        case "ERROR":
            return "#f1cfcf";
        case "WARN":
            return "#ffeec4";
        default:
            return "white";
    }
};

const LogCell = ({ log }) => {
    const insertedAt = log.at;
    return (
        <StyledLogCell>
            <div className="inserted-at">
                <div>
                    <div className="days">
                        {insertedAt.toLocaleDateString(navigator.language, {
                            month: "short",
                            day: "numeric",
                        })}
                    </div>
                    <div className="hours">
                        {insertedAt.toLocaleTimeString(navigator.language, { hour: "2-digit", minute: "2-digit" })}
                    </div>
                </div>
            </div>
            <div className="level">
                {log.icon ? (
                    <Icon icon={log.icon} size={20} intent={getIntentForLevel(log.level)} />
                ) : (
                    getIconForLevel(log.level)
                )}
            </div>
            <div className="text" style={{ backgroundColor: getBgColorForLevel(log.level) }}>
                <div className="text-content">{log.text}</div>
            </div>
        </StyledLogCell>
    );
};

LogCell.propTypes = {
    log: PropTypes.shape({
        text: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
        at: PropTypes.instanceOf(Date).isRequired,
        icon: PropTypes.string,
    }).isRequired,
};

const LogSeparator = ({ at }) => {
    return (
        <StyledLogCell>
            <div className="separator inserted-at">
                <div className="days">
                    <span className="days-content">
                        {at.toLocaleDateString(navigator.language, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        })}
                    </span>
                </div>
            </div>
            <div className="separator level">
                <Icon icon="full-circle" size={14} tagName="small" style={{ color: "#aaa" }} />
            </div>
            <div className="text" />
        </StyledLogCell>
    );
};

LogSeparator.propTypes = {
    at: PropTypes.instanceOf(Date).isRequired,
};

const nextDate = (oldDate, newDate) => {
    if (!oldDate) {
        return [true, newDate];
    }
    if (
        oldDate.getDate() !== newDate.getDate() ||
        oldDate.getMonth() !== newDate.getMonth() ||
        oldDate.getYear() !== newDate.getYear()
    ) {
        return [true, newDate];
    }
    return [false, oldDate];
};

const LogView = ({ logs }) => {
    let currentDate = null;
    let changed = false;

    return (
        <StyledLogView>
            {logs.reduce((acc, l) => {
                [changed, currentDate] = nextDate(currentDate, l.at);
                if (changed) {
                    acc.push(<LogSeparator key={`sep-${currentDate}`} at={currentDate} />);
                }
                acc.push(<LogCell key={`${l.text}-${l.at}`} log={l} />);
                return acc;
            }, [])}
        </StyledLogView>
    );
};

LogView.propTypes = {
    logs: PropTypes.arrayOf(
        PropTypes.shape({
            at: PropTypes.instanceOf(Date).isRequired,
            text: PropTypes.string.isRequired,
            level: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default LogView;
