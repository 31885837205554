import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import TargetingProfileChooser from "../../validation/targeting-profile-chooser";

const StyledFieldTargetingProfile = styled.div``;

const FieldTargetingProfile = ({ value, onChange, disabled }) => {
    return (
        <StyledFieldTargetingProfile>
            <TargetingProfileChooser value={value} onChange={onChange} disabled={disabled} />
        </StyledFieldTargetingProfile>
    );
};

FieldTargetingProfile.propTypes = {
    value: PropTypes.shape({}),
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FieldTargetingProfile.defaultProps = {
    value: null,
    emptyValue: null,
    onChange: null,
    disabled: false,
};

export default FieldTargetingProfile;
