import { Alignment, Button, Classes, Intent, Navbar } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import ErrorCallout from "../common/error-callout";
import LoadingCallout from "../common/loading-callout";
import Search from "../search";
import { useQuerySearchPlatformInterests } from "./gql-profile";
import InterestItem from "./interest-item";
import { formatItemForPlatform } from "./intersets-format";

const StyledInterestsChooser = styled.div`
    .bp4-navbar {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: 2px;
    }
    .list-display {
        background-color: #404854;
        border: 1px solid black;
        color: white;
    }
`;

function InterestsChooser({ platform, onCancel, onSelect, existingItems, defaultSearch }) {
    const [searchValue, setSearchValue] = useState(defaultSearch || "");
    const [searchLang, setSearchLang] = useState("en");
    const [searchPlatformInterests, { loading, result, error, updateQuery }] = useQuerySearchPlatformInterests();

    const handleSearch = useCallback(
        async (searchPlatform, q, lang) => {
            await searchPlatformInterests({
                platform: searchPlatform,
                q,
                lang,
            });
        },
        [searchPlatformInterests]
    );

    const doSearch = () => searchValue && handleSearch(platform, searchValue, searchLang);

    const doReset = () => {
        updateQuery(() => ({ searchPlatformInterest: null }));
        setSearchValue("");
    };

    const existingKeys = (existingItems || []).map((existing) => formatItemForPlatform(existing, platform).key);
    return (
        <StyledInterestsChooser>
            <Navbar className="bp4-dark">
                <Navbar.Group>
                    <Navbar.Heading>Add new {platform} interests</Navbar.Heading>
                </Navbar.Group>
                <Navbar.Group className={Classes.FILL}>
                    <Search
                        value={searchValue}
                        setValue={setSearchValue}
                        onSearch={() => doSearch()}
                        onReset={() => doReset()}
                        lang={searchLang}
                        setLang={setSearchLang}
                    />
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Button onClick={onCancel} text="Cancel" icon="cross" />
                </Navbar.Group>
            </Navbar>
            <div>
                <div className="list-display">
                    {(result || []).map((item) => {
                        const formattedItem = formatItemForPlatform(item, platform);
                        const alreadySet = existingKeys.indexOf(formattedItem.key) !== -1;
                        return (
                            <InterestItem
                                item={item}
                                {...formattedItem}
                                onActionIcon="add"
                                actionIntent={Intent.SUCCESS}
                                onAction={alreadySet ? null : () => onSelect(item)}
                                alreadySet={alreadySet}
                                dark
                            />
                        );
                    })}
                </div>
                <LoadingCallout loading={loading} />
                <ErrorCallout title="Error while getting interests" error={error} />
            </div>
        </StyledInterestsChooser>
    );
}

InterestsChooser.propTypes = {
    platform: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    onSelect: PropTypes.func,
    existingItems: PropTypes.arrayOf(PropTypes.shape({})),
    defaultSearch: PropTypes.string,
};

InterestsChooser.defaultProps = {
    onCancel: null,
    onSelect: null,
    existingItems: [],
    defaultSearch: "",
};

export default InterestsChooser;
