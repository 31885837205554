import { gql, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

export const GQL_QUERY_MAPBOX_ADDRESS = gql`
    query SearchMapboxAddress($q: String!) {
        searchMapboxAddress(q: $q, placeTypes: [region, country, place]) {
            id
            placeType
            placeName
        }
    }
`;

export const GQL_QUERY_MAPBOX_GEOMETRIES = gql`
    query searchMapGeometries($q: String!) {
        searchMapGeometries(q: $q)
    }
`;

export const useQueryMapboxAddress = () => {
    const [caller, { data: { searchMapboxAddress } = {}, ...rest }] = useLazyQuery(GQL_QUERY_MAPBOX_ADDRESS, {
        fetchPolicy: "network-only",
    });
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { addresses: searchMapboxAddress, ...rest }];
};

export const useQueryMapboxGeometry = () => {
    const [caller, { data: { searchMapGeometries } = {}, ...rest }] = useLazyQuery(GQL_QUERY_MAPBOX_GEOMETRIES);
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { geometries: searchMapGeometries, ...rest }];
};
