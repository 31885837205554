import { Button, Callout, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationDeleteMessage } from "../messges/gql-messages";

const DeleteMessageDialog = ({ hideDialog, isDialogProcessing, message }) => {
    const [deleteMessage, { loading, error }] = useMutationDeleteMessage();
    const handleDeleteMessage = useCallback(async () => {
        await deleteMessage({
            id: message.id,
        });
        toast.success("Message deleted");
        hideDialog(1, {});
    }, [message, hideDialog, deleteMessage]);

    return message.state === "draft" ? (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while deleting message" error={error} />
                <p>
                    <strong>{message.texts?.find((val) => val.type === "primary_text")?.value}</strong>
                </p>
                <p>Do you really want to delete this message?</p>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="No" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={loading}
                        loading={loading || isDialogProcessing}
                        intent={Intent.DANGER}
                        text="YES! Delete it"
                        onClick={handleDeleteMessage}
                        rightIcon="arrow-right"
                    />
                </div>
            </div>
        </>
    ) : (
        <>
            <div className={Classes.DIALOG_BODY}>
                <Callout intent={Intent.DANGER} icon="warning-sign" title="Only 'draft' messages can be deleted" />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="Ok" onClick={() => hideDialog(0)} />
                </div>
            </div>
        </>
    );
};

DeleteMessageDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
    }).isRequired,
};

export default {
    title: "Delete message",
    icon: "trash",
    component: DeleteMessageDialog,
};
