import mapboxgl, { LngLat, Map, Marker } from "mapbox-gl";
import MapboxCircle from "mapbox-gl-circle";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

mapboxgl.accessToken = window.MAPBOX_ACCESS_TOKEN;

const MAPBOX_STYLE = "mapbox://styles/devamplify/ckfqfxosb05ra19t8o1jj52p4";

export const WORLD_BOUNDS = [
    [180.0, 90.0],
    [-180.0, -90.0],
];

export default styled(({ className, onSelect }) => {
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [radius, setRadius] = useState(10);
    const containerRef = useRef(null);
    const mapInitialCenter = new LngLat(6.6241820155220195, 46.53201246724793);
    useEffect(
        () => {
            if (containerRef.current) {
                const map = new Map({
                    container: containerRef.current,
                    style: MAPBOX_STYLE,
                    center: mapInitialCenter,
                    zoom: 7,
                });

                // empty source
                map.on("load", () => {
                    setLng(mapInitialCenter.lng.toFixed(4));
                    setLat(mapInitialCenter.lat.toFixed(4));
                });

                const radiusCircle = new MapboxCircle(
                    { lat: mapInitialCenter.lat, lng: mapInitialCenter.lng },
                    radius * 1000,
                    {
                        editable: true,
                        minRadius: 1500,
                        fillColor: "#29AB87",
                    }
                ).addTo(map);

                radiusCircle.on("radiuschanged", function (circleObj) {
                    setRadius(circleObj.getRadius() / 1000);
                });

                const marker = new Marker({
                    draggable: true,
                })
                    .setLngLat(mapInitialCenter)
                    .addTo(map);

                marker.on("dragend", () => {
                    const lngLat = marker.getLngLat();
                    setLng(lngLat.lng.toFixed(4));
                    setLat(lngLat.lat.toFixed(4));
                    radiusCircle.setCenter(lngLat);
                });

                return () => {
                    map.remove();
                };
            }
            return null;
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [containerRef]
    );

    const generateJson = useCallback(() => {
        const result = {
            type: "custom_locations",
            latitude: lat,
            longitude: lng,
            radius: radius,
            distance_unit: "kilometer",
        };
        onSelect(result);
    }, [lat, lng, radius, onSelect]);

    return (
        <div className={className}>
            {lng != null ? (
                <div className="sidebar">
                    <div className="latLng">
                        Longitude: {lng} | Latitude: {lat}
                    </div>
                    <div className="radiusInput">
                        Radius: {radius}km&nbsp;
                        <input type="button" value="SET" onClick={generateJson} />
                    </div>
                </div>
            ) : null}
            <div className="mapContainer" ref={containerRef} />
        </div>
    );
})`
    position: relative;
    .mapContainer {
        position: relative;
        overflow: hidden;
        width: auto;
        height: 400px;
    }
    .mapboxgl-control-container {
        display: none;
    }
    .mapboxgl-marker {
        position: absolute;
        top: 0;
        left: 0;
        will-change: transform;
        opacity: 1;
        transition: opacity 0.2s;
        cursor: pointer;
    }
    .sidebar {
        background-color: rgba(35, 55, 75, 0.9);
        color: #fff;
        padding: 6px 12px;
        font-family: monospace;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        margin: 12px;
        border-radius: 4px;
    }
    .latLng {
        float: left;
    }
    .radiusInput {
        clear: both;
        float: left;
    }
`;
