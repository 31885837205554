import { Button } from "@blueprintjs/core";
import { DatePicker } from "@blueprintjs/datetime";
import { Popover2 } from "@blueprintjs/popover2";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

const StyledFieldDate = styled.div`
    small {
        padding-left: 10px;
    }
`;

const FieldDate = ({ value, onChange, emptyValue, disabled }) => {
    // value is a string (iso date) , onChange should be called with a string (iso date)
    const date = value ? moment(value) : null;
    const [isOpen, setIsOpen] = useState(false);
    const buttonActive = onChange && !disabled;
    return (
        <StyledFieldDate>
            <Popover2
                content={
                    <DatePicker
                        placeholder={emptyValue || "Not set"}
                        showActionsBar
                        canClearSelection
                        highlightCurrentDay
                        onChange={(v) => {
                            onChange(v?.toISOString());
                            setIsOpen(false);
                        }}
                        value={date?.toDate()}
                        formatDate={(d, l) => moment(d).format("LL")}
                        parseDate={(s, l) => moment(s, "LL").toDate()}
                    />
                }
                interactionKind="click"
                isOpen={isOpen}
                onInteraction={(state) => !disabled && onChange && setIsOpen(state)}
                placement="right"
            >
                <div>
                    <Button
                        text={date ? date.format("LL") : emptyValue || "Not set"}
                        minimal={!buttonActive}
                        //outlined={buttonActive}
                        onClick={() => buttonActive && setIsOpen(true)}
                        rightIcon={buttonActive ? "caret-down" : null}
                        disabled={disabled}
                    />
                    <br />
                    <small>{date ? date.fromNow() : ""}</small>
                </div>
            </Popover2>
        </StyledFieldDate>
    );
};

FieldDate.propTypes = {
    value: PropTypes.string,
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FieldDate.defaultProps = {
    value: null,
    emptyValue: null,
    onChange: null,
    disabled: false,
};

export default FieldDate;
