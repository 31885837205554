import { Button, Classes } from "@blueprintjs/core";
import React from "react";

export const buildQuestionDialog = ({ title, icon, body, actions, hideCancel = false }) => {
    return {
        title,
        component: ({ isDialogProcessing, hideDialog }) => (
            <div>
                <div className={Classes.DIALOG_BODY}>{body}</div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        {!hideCancel && (
                            <Button disabled={isDialogProcessing} text="Cancel" onClick={() => hideDialog(0)} />
                        )}
                        {(actions || []).map(({ code, ...action }) => (
                            <Button
                                key={code}
                                disabled={isDialogProcessing}
                                onClick={() => hideDialog(code || 0)}
                                {...action}
                            />
                        ))}
                    </div>
                </div>
            </div>
        ),
        icon,
    };
};
