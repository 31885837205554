import { gql, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

export const GQL_QUERY_TRANSACTIONS = gql`
    query getUserTransactions($q: String!) {
        getUserTransactions(username: $q) {
            balance
            currency
            transactions {
                id
                messageId
                messageTitle
                currency
                insertedAt
                paymentType
                destinationType
                voucherUrl
                invoiceUrl
                receiptUrl
                status
                paidAmount
                isSubscription
                paymentId
                paymentGateway
                amount
            }
        }
    }
`;

export const useQueryTransactions = () => {
    const [caller, { data: { getUserTransactions } = {}, ...rest }] = useLazyQuery(GQL_QUERY_TRANSACTIONS);
    const queryFunction = useCallback((variables) => caller({ variables }), [caller]);
    return [queryFunction, { wallet: getUserTransactions, ...rest }];
};
