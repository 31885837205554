import { Button, Callout, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { formatCurrency } from "../../common/i18n";
import ErrorCallout from "../common/error-callout";
import { useMutationRestoreMessage } from "../messges/gql-messages";

/**
 * Restore message completed -> live
 */
const ResoreMessageDialog = ({ hideDialog, isDialogProcessing, message }) => {
    const [restoreMessage, { loading, error }] = useMutationRestoreMessage();
    const handleRestoreMessage = useCallback(async () => {
        await restoreMessage({
            id: message.id,
        });
        toast.success("Restored message successfully");
        hideDialog(1, {});
    }, [restoreMessage, message, hideDialog]);
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while restoring message" error={error} />
                <Callout
                    intent={Intent.WARNING}
                    icon="warning-sign"
                    title={`There is ${formatCurrency(
                        message.stats.totalAmount - message.stats.totalSpent,
                        message.currency
                    )} left budget on this message `}
                />
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="No" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={loading}
                        loading={isDialogProcessing || loading}
                        intent={Intent.SUCCESS}
                        text="Yes, restore message !"
                        onClick={handleRestoreMessage}
                        rightIcon="arrow-right"
                    />
                </div>
            </div>
        </>
    );
};

ResoreMessageDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        stats: PropTypes.shape({
            totalAmount: PropTypes.number,
            totalSpent: PropTypes.number,
        }).isRequired,
        currency: PropTypes.string.isRequired,
    }).isRequired,
};

export default {
    title: "Restore Message",
    icon: "automatic-updates",
    component: ResoreMessageDialog,
};
