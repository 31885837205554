import { Icon, Intent, Menu, MenuItem, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Cell, Column, SelectionModes, Table2, TableLoadingOption } from "@blueprintjs/table";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { formatCurrency, formatDateString } from "../../common/i18n";
import { buildAppUrl } from "../../common/utils";

const StyledTransactionsTable = styled.div`
    margin-top: 50px;
    .bp4-table-cell {
        align-items: center;
        display: flex;
        .striked {
            text-decoration: line-through;
        }
    }
    small {
        font-size: 10px;
    }
`;

const getIntentForState = (state) => {
    if (state === "success") {
        return Intent.SUCCESS;
    }
    if (state === "pending") {
        return Intent.WARNING;
    }
    if (state === "error") {
        return Intent.DANGER;
    }
    if (state === "failed") {
        return Intent.DANGER;
    }
    return null;
};
const getPaymentType = (payment) => {
    if (payment === "CARD") {
        return (
            <Tag minimal intent={Intent.PRIMARY}>
                <Icon style={{ marginRight: "5px" }} icon="credit-card" color="grey" />
                {payment}
            </Tag>
        );
    }
    if (payment === "WALLET") {
        return (
            <Tag minimal intent={Intent.WARNING}>
                <Icon style={{ marginRight: "5px" }} icon="bank-account" color="grey" />
                {payment}
            </Tag>
        );
    }
    if (payment === "MESSAGE") {
        return (
            <Tag minimal intent={Intent.NONE}>
                <Icon style={{ marginRight: "5px" }} icon="document" color="grey" />
                {payment}
            </Tag>
        );
    }
    return <Tag minimal>{payment}</Tag>;
};
const handleShowMessage = (message) => {
    window.open(buildAppUrl(`/edit/ads/${message}`), message);
};
const TransactionsTable = ({ transactions, loading }) => {
    const [columnWidths, setColumnWidths] = useState([140, 140, 120, 120, 80, 110, 110, 110, 150]);
    // const selectedIndex = transactions.findIndex((v) => v.id === selected?.id);
    return (
        <StyledTransactionsTable className="amp-table-container">
            <Table2
                enableGhostCells
                numRows={transactions.length}
                enableRowHeader={false}
                defaultRowHeight={40}
                fill
                columnWidths={columnWidths}
                loadingOptions={loading ? [TableLoadingOption.CELLS] : []}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
                selectionModes={SelectionModes.ROWS_AND_CELLS}
                selectedRegionTransform={(e) => {
                    if (!e.rows) {
                        return e;
                    }
                    return {
                        rows: [e.rows[0], e.rows[0]],
                    };
                }}
            >
                <Column
                    name="Source"
                    cellRenderer={(index) => (
                        <Cell>{getPaymentType(transactions[index].paymentType.toUpperCase())}</Cell>
                    )}
                />
                <Column
                    name="Destination"
                    cellRenderer={(index) => (
                        <Cell>{getPaymentType(transactions[index].destinationType.toUpperCase())}</Cell>
                    )}
                />
                <Column
                    name="Paid Amount"
                    cellRenderer={(index) => (
                        <Cell>{formatCurrency(transactions[index].paidAmount, transactions[index].currency)}</Cell>
                    )}
                />
                <Column
                    name="Amount"
                    cellRenderer={(index) => (
                        <Cell>{formatCurrency(transactions[index].amount, transactions[index].currency)}</Cell>
                    )}
                />
                <Column
                    name="Status"
                    cellRenderer={(index) => (
                        <Cell>
                            <Tag minimal intent={getIntentForState(transactions[index].status)}>
                                {transactions[index].status}
                            </Tag>
                        </Cell>
                    )}
                />
                <Column name="Gateway" cellRenderer={(index) => <Cell>{transactions[index].paymentGateway}</Cell>} />
                <Column
                    name="Date"
                    cellRenderer={(index) => <Cell>{formatDateString(transactions[index].insertedAt, "short")}</Cell>}
                />
                <Column
                    name="Subscription"
                    cellRenderer={(index) => (
                        <Cell wrapText style={{ justifyContent: "center" }}>
                            {transactions[index].isSubscription ? (
                                <Icon icon="tick" intent={Intent.SUCCESS} />
                            ) : (
                                <Icon icon="none" />
                            )}
                        </Cell>
                    )}
                />
                <Column
                    name="Message"
                    cellRenderer={(index) => (
                        <Cell>
                            {transactions[index].messageTitle && (
                                <Popover2
                                    content={
                                        <Menu>
                                            <MenuItem
                                                icon={<Icon icon="none" />}
                                                onClick={() => handleShowMessage(transactions[index].messageId)}
                                                text="Show message"
                                                label={<Icon icon="document-open" />}
                                            />
                                        </Menu>
                                    }
                                >
                                    {transactions[index].messageTitle}
                                </Popover2>
                            )}
                        </Cell>
                    )}
                />
            </Table2>
        </StyledTransactionsTable>
    );
};

TransactionsTable.propTypes = {
    transactions: PropTypes.arrayOf(
        PropTypes.shape({
            messageId: PropTypes.string,
            messageTitle: PropTypes.string,
            isSubscription: PropTypes.bool,
            insertedAt: PropTypes.string,
            status: PropTypes.string,
            currency: PropTypes.string,
            paymentType: PropTypes.string,
            destinationType: PropTypes.string,
            paymentGateway: PropTypes.string,
            paidAmount: PropTypes.number,
            amount: PropTypes.number,
        })
    ).isRequired,
    loading: PropTypes.bool,
};

TransactionsTable.defaultProps = {
    loading: false,
    selected: null,
    height: null,
    onInspect: false,
};
export default TransactionsTable;
