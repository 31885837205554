import { Button, ControlGroup, Intent, MenuItem } from "@blueprintjs/core";
import { MultiSelect, Select } from "@blueprintjs/select";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ANY, EMPTY } from "./filter-utils";

const StyledOptionSelect = styled.div`
    display: flex;
    button {
        white-space: nowrap;
        min-width: max-content;
    }
`;

const FilterOptionsSelect = ({ id, options, value, onChange, emptyText, allowEmpty }) => {
    const baseOptions = [
        { value: "contains", label: "Contains" },
        { value: "empty", label: "Is not set", disableChoice: true },
        { value: "any", label: "Is set", disableChoice: true },
    ];

    let baseOption = baseOptions[0];
    if (allowEmpty) {
        if (value.includes(EMPTY)) {
            baseOption = baseOptions[1];
        } else if (value.includes(ANY)) {
            baseOption = baseOptions[2];
        }
    }

    const handleBaseOption = (item) => {
        if (item === baseOptions[1]) {
            onChange([EMPTY]);
        } else if (item === baseOptions[2]) {
            onChange([ANY]);
        } else {
            onChange([]);
        }
    };

    const findOption = (v) => options.find((opt) => opt.value === v);
    const selectedOptions = value.map(findOption);

    const selectOptionValue = (val) => onChange([...value, val]);
    const deselectOptionValue = (val) => onChange(value.filter((k) => k !== val));

    const handleItemSelect = (opt) => {
        if (value.includes(opt.value)) {
            deselectOptionValue(opt.value);
        } else {
            selectOptionValue(opt.value);
        }
    };

    const handleClear = () => {
        onChange([]);
    };

    const clearButton = value.length > 0 ? <Button icon="cross" minimal={true} onClick={handleClear} /> : undefined;

    const renderBaseOption = (option, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                icon={option === baseOption ? "tick" : "blank"}
                key={option.value}
                onClick={handleClick}
                text={option.label}
                shouldDismissPopover={false}
            />
        );
    };

    const renderOption = (option, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                icon={value.includes(option.value) ? "tick" : "blank"}
                key={option.value}
                onClick={handleClick}
                text={option.label}
                shouldDismissPopover={false}
            />
        );
    };

    const areOptionEqual = (a, b) => {
        return a.value === b.value;
    };

    const handleTagRemove = (_tag, index) => {
        const newValue = value.filter((_opt, i) => i !== index);
        onChange(newValue);
    };

    return (
        <StyledOptionSelect>
            <ControlGroup fill={true} vertical={false}>
                {allowEmpty && (
                    <Select
                        items={baseOptions}
                        itemRenderer={renderBaseOption}
                        onItemSelect={(item) => handleBaseOption(item)}
                        filterable={false}
                    >
                        <Button intent={Intent.NONE} rightIcon="double-caret-vertical" text={baseOption.label} />
                    </Select>
                )}
                <MultiSelect
                    id={id}
                    fill
                    resetOnSelect
                    items={options}
                    selectedItems={selectedOptions}
                    onItemSelect={handleItemSelect}
                    itemRenderer={renderOption}
                    placeholder={baseOption.disableChoice ? "" : emptyText}
                    itemPredicate={(query, opt, _index, exactMatch) => {
                        const normalizedLabel = opt.label.toLowerCase();
                        const normalizedQuery = query.toLowerCase();
                        if (exactMatch) {
                            return normalizedLabel === normalizedQuery;
                        } else {
                            return normalizedLabel.indexOf(normalizedQuery) >= 0;
                        }
                    }}
                    itemsEqual={areOptionEqual}
                    noResults={<MenuItem disabled={true} text="No results." />}
                    popoverProps={{ minimal: true, canEscapeKeyClose: true }}
                    tagRenderer={(opt) => opt?.label}
                    tagInputProps={{
                        disabled: baseOption.disableChoice,
                        onRemove: handleTagRemove,
                        rightElement: clearButton,
                        tagProps: {
                            intent: Intent.PRIMARY,
                            minimal: true,
                        },
                    }}
                />
            </ControlGroup>
        </StyledOptionSelect>
    );
};

FilterOptionsSelect.propTypes = {
    id: PropTypes.string,
    emptyText: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ).isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    allowEmpty: PropTypes.bool,
};

FilterOptionsSelect.defaultProps = {
    id: null,
    emptyText: null,
    value: [],
    allowEmpty: false,
};

export default FilterOptionsSelect;
