import { Button, FormGroup, Intent } from "@blueprintjs/core";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import ErrorCallout from "../../common/error-callout";
import { PlatformProperties } from "../../common/platform-info-badge";
import { useMutationUpdatePlatform } from "../gql-messages";
import FieldCpcMax from "./field-cpcmax";

const StyledPlatformSection = styled.div`
    h2 {
        vertical-align: center;
        display: flex;
        align-items: center;
        .title {
            margin-left: 0.5em;
            text-transform: capitalize;
        }
    }

    .bp4-form-group {
        padding: 5px;
        border-radius: 5px;
        background-color: white;
        overflow: hidden;
        & > label {
            padding: 5px 10px;
            margin: -6px -6px 6px -6px;
            background-color: #fff;
            font-weight: bold;
            line-height: 1.5em;
            small {
                font-weight: lighter;
                opacity: 0.75;
            }
        }
    }
`;

const buildOriginal = (platform) => ({
    cpcMax: platform.cpcMax || null,
});

const PlatformSection = ({ messageId, platform, disabled, currency }) => {
    const original = useMemo(() => buildOriginal(platform), [platform]);
    const [values, setValues] = useState(original);
    const cpcMaxMaximum = window.CPC_MAX_MAXIMUM_VALUE;
    const [updatePlatform, { loading, error }] = useMutationUpdatePlatform();

    useEffect(() => {
        setValues(original);
    }, [original]);

    const handleSave = useCallback(async () => {
        await updatePlatform({
            messageId: messageId,
            platform: platform.name,
            changes: values,
        });
        toast.success(`Platform '${platform.name}' changed successfully`);
    }, [values, platform, messageId, updatePlatform]);

    const hasChanges = !isEqual(values, original);
    const handleChange = useCallback((v) => {
        setValues((currentValues) => ({ ...currentValues, cpcMax: v }));
    }, []);

    return (
        <StyledPlatformSection>
            <h3>Biding</h3>
            <FormGroup label="Max CPC" helperText="Maximum cost per click biding">
                <FieldCpcMax
                    currency={currency}
                    disabled={disabled}
                    value={values.cpcMax}
                    onChange={handleChange}
                    max={cpcMaxMaximum}
                />
            </FormGroup>
            <ErrorCallout title="Error while saving" error={error} />
            {!disabled && (
                <div>
                    <Button
                        intent={Intent.PRIMARY}
                        text="Save"
                        disabled={!hasChanges || disabled}
                        onClick={handleSave}
                        loading={loading}
                    />
                </div>
            )}
            <h3>Info</h3>
            <PlatformProperties platform={platform} currency={currency} isAdmin={false} messageBusinessUuid="" />
        </StyledPlatformSection>
    );
};

PlatformSection.propTypes = {
    messageId: PropTypes.string.isRequired,
    platform: PropTypes.shape({
        name: PropTypes.string.isRequired,
        cpcMax: PropTypes.number,
    }).isRequired,
    disabled: PropTypes.bool,
    currency: PropTypes.string.isRequired,
};

PlatformSection.defaultProps = {
    disabled: false,
};

export default PlatformSection;
