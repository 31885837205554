export function getLocale() {
    return window.CURRENT_LOCALE || navigator.language || "en";
}

export function formatCurrency(value, currency = "CHF", digits = 0, maxDigits = null, emptyValue = null) {
    const options = {
        style: "currency",
        currency,
        minimumFractionDigits: digits,
    };
    if (maxDigits !== null) {
        options.maximumFractionDigits = maxDigits;
    }
    if (value?.toLocaleString) {
        const displayValue = value / 100.0;
        return displayValue.toLocaleString(getLocale(), options);
    }
    if (emptyValue) {
        return emptyValue;
    }
    return value;
}

export function formatLargeNumber(value, emptyValue = null) {
    if (value && value.toLocaleString) {
        return value.toLocaleString();
    }
    if (emptyValue && !value) {
        return emptyValue;
    }
    return value;
}

function zeroPad(num, places) {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

export function splitDuration(value) {
    const milli = (value % 1).toFixed(3) * 1000;

    const secs = Math.round(value);
    const hours = Math.floor(secs / (60 * 60));

    const divisor4min = secs % (60 * 60);
    const minutes = Math.floor(divisor4min / 60);

    const divisor4secs = divisor4min % 60;
    const seconds = Math.ceil(divisor4secs);
    return [hours, minutes, seconds, milli];
}

export function formatDuration(value) {
    const [hours, minutes, seconds, milli] = splitDuration(value);

    if (hours > 0) {
        return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}'${zeroPad(seconds, 2)}"${zeroPad(milli, 3)}`;
    }
    if (minutes > 0) {
        return `${zeroPad(minutes, 2)}'${zeroPad(seconds, 2)}"${zeroPad(milli, 3)}`;
    }
    if (seconds > 0) {
        return `${zeroPad(seconds, 2)}"${zeroPad(milli, 3)}`;
    }
    return zeroPad(milli, 3);
}

export const LANG_VALUES = [
    { value: "en", label: "English" },
    { value: "fr", label: "Français" },
    { value: "de", label: "Deutsch" },
    { value: "nl", label: "Dutch" },
    { value: "es-mx", label: "Spanish (Mexico)" },
];

export const LANG_MAP = LANG_VALUES.reduce((acc, x) => {
    acc[x.value] = x.label;
    return acc;
}, {});

export const EMPTY_VALUE_STRING = "—";

export function formatDateString(strDate, dateStyle = "medium") {
    if (!strDate) {
        return "";
    }
    return new Intl.DateTimeFormat(getLocale(), { dateStyle }).format(Date.parse(strDate));
}

export function formatDate(date, dateStyle = "medium") {
    if (!date) {
        return "";
    }
    return new Intl.DateTimeFormat(getLocale(), { dateStyle }).format(date);
}
