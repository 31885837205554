import { Callout, NonIdealState, Spinner } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ErrorPane from "../../common/error-pane";
import { getRootUserAgencyPromise } from "../../common/gql-promises";
import { useQueryGeoTargeting } from "./gql-targeting";
import TargetingTable from "./targeting-table";

const TargetingList = ({ search, selectedId, onSelectionChanged }) => {
    const history = useHistory();
    const [loadTargeting, { loading, error, targetings }] = useQueryGeoTargeting();
    const selected = (targetings || []).find((t) => t.id === selectedId);
    const [rootUserAgency, setRootUserAgency] = useState(null);
    useEffect(() => {
        const getUserAgency = async () => {
            try {
                const rootUser = await getRootUserAgencyPromise();
                setRootUserAgency(rootUser);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error("Agency mode OFF", error);
            }
        };
        getUserAgency();
        if (search) {
            loadTargeting({
                q: search,
            });
        }
    }, [search, loadTargeting]);
    return (
        <div>
            {error ? <ErrorPane error={error} /> : null}
            {loading && (
                <Callout title="Loading, please wait...">
                    <Spinner />
                </Callout>
            )}
            {!loading && (
                <div>
                    {targetings ? (
                        targetings.length ? (
                            <TargetingTable
                                targetings={targetings || []}
                                loading={loading}
                                onSelect={(item) => onSelectionChanged(item?.id)}
                                selected={selected}
                                onInspect={(targeting) => {
                                    history.push({
                                        pathname: `/targetings/${targeting.id}/update/`,
                                        search: history.location.search,
                                    });
                                }}
                                rootUserAgency={rootUserAgency}
                            />
                        ) : (
                            <NonIdealState icon="zoom-out" title="Nothing found" />
                        )
                    ) : (
                        <NonIdealState icon="search" title="You need to search for a location first" />
                    )}
                </div>
            )}
        </div>
    );
};

TargetingList.propTypes = {
    search: PropTypes.string,
    selectedId: PropTypes.number,
    onSelectionChanged: PropTypes.func.isRequired,
};

TargetingList.defaultProps = {
    search: null,
    selectedId: null,
};

export default TargetingList;
