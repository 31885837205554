import { Button, Classes, Intent } from "@blueprintjs/core";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { formatCurrency } from "../../common/i18n";
import ErrorCallout from "../common/error-callout";
import { useMutationRestoreMoneyToWallet } from "../gql-wallet";

const RestoreMoneyToMessageDialog = ({ hideDialog, message }) => {
    const [cannotRestore, setCannotRestore] = useState(true);
    const [restoreMoneyToWallet, { loading, error }] = useMutationRestoreMoneyToWallet();

    useEffect(() => {
        if (message.completedEndDate) {
            const now = moment();
            const completedEndDate = moment(message.completedEndDate);
            const duration = moment.duration(now.diff(completedEndDate));
            setCannotRestore(duration.asHours() < 24);
        }
    }, [message]);
    const handleRestoreMoney = useCallback(async () => {
        await restoreMoneyToWallet({
            messageId: message.id,
        });
        toast.success("Successfully restored money To the wallet");
        hideDialog(1, {});
    }, [message, hideDialog, restoreMoneyToWallet]);

    const remainingAmount = message.stats.totalAmount - message.stats.totalSpent;
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while restoring money to wallet" error={error} />
                <p>
                    <strong>{message.texts?.find((val) => val.type === "primary_text")?.value}</strong>
                </p>
                <p>
                    <strong>{formatCurrency(remainingAmount, message.currency)}</strong> will be restored to the wallet
                    of the message's owner
                </p>
                {cannotRestore && <p>You must wait 24 hours after completed to restore money to a wallet</p>}
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={loading} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={loading || cannotRestore}
                        loading={loading}
                        intent={Intent.SUCCESS}
                        text="Restore money"
                        onClick={handleRestoreMoney}
                        rightIcon="bank-account"
                    />
                </div>
            </div>
        </>
    );
};

RestoreMoneyToMessageDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,

    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
        state: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        completedEndDate: PropTypes.string,
        stats: PropTypes.shape({
            totalAmount: PropTypes.number,
            totalSpent: PropTypes.number,
        }).isRequired,
    }).isRequired,
};

export default {
    title: "Restore remaining amount from message to wallet",
    icon: "bank-account",
    component: RestoreMoneyToMessageDialog,
};
