import { Button, Callout, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import ErrorCallout from "../common/error-callout";
import { useMutationResumeMessage } from "../messges/gql-messages";

const ResumeMessageDialog = ({ hideDialog, isDialogProcessing, message }) => {
    const [resumeMessage, { loading, error }] = useMutationResumeMessage();
    const handleResumeMessage = useCallback(async () => {
        await resumeMessage({
            id: message.id,
        });
        toast.success("Message resumed successfully");
        hideDialog(1, {});
    }, [message, hideDialog, resumeMessage]);
    const checkEndDate = () => {
        if (message.liveEndDate) {
            const endDate = new Date(message.liveEndDate);
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);
            return endDate >= tomorrow;
        }
        return true;
    };

    return message.state === "paused" ? (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while resuming message" error={error} />
                {!checkEndDate() && (
                    <ErrorCallout title="End date is in the past you need to change it !" error={"error"} />
                )}
                <p>
                    <strong>{message.texts?.find((val) => val.type === "primary_text")?.value}</strong>
                </p>
                <p>Do you really want to resume this message?</p>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="No" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={loading || !checkEndDate()}
                        loading={loading || isDialogProcessing}
                        intent={Intent.SUCCESS}
                        text="YES! Resume it"
                        onClick={handleResumeMessage}
                        rightIcon="arrow-right"
                    />
                </div>
            </div>
        </>
    ) : (
        <>
            <div className={Classes.DIALOG_BODY}>
                <Callout intent={Intent.WARNING} icon="warning-sign" title="Only 'paused' messages can be resumed" />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="Ok" onClick={() => hideDialog(0)} />
                </div>
            </div>
        </>
    );
};

ResumeMessageDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
        liveEndDate: PropTypes.string,
    }).isRequired,
};

export default {
    title: "Resume message",
    icon: "play",
    component: ResumeMessageDialog,
};
