import { Button, ControlGroup, InputGroup, Intent, MenuItem } from "@blueprintjs/core";
import { Select2 } from "@blueprintjs/select";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

import { ANY, EMPTY } from "./filter-utils";

const StyledFilterTextSearch = styled.div`
    display: flex;
    button {
        white-space: nowrap;
        min-width: max-content;
    }
`;

const FilterTextSearch = ({ id, value, onChange, emptyText, hasSearchOptions }) => {
    const notifyChanges = useMemo(() => debounce(onChange, 1000), [onChange]);

    useEffect(() => {
        return () => {
            notifyChanges.cancel();
        };
    }, [notifyChanges]);

    const handleOnChange = (e) => {
        const v = e.target.value;
        notifyChanges(v);
    };

    const searchOptions = [
        { value: "contains", label: "Contains" },
        { value: "empty", label: "Is not set", disableTextField: true },
        { value: "any", label: "Is set", disableTextField: true },
    ];

    const renderSearchOption = (option, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                selected={modifiers.active}
                icon={option === searchOption ? "tick" : "blank"}
                key={option.value}
                onClick={handleClick}
                text={option.label}
                shouldDismissPopover={false}
            />
        );
    };

    let searchOption = searchOptions[0];
    if (hasSearchOptions) {
        if (value.includes(EMPTY)) {
            searchOption = searchOptions[1];
        } else if (value.includes(ANY)) {
            searchOption = searchOptions[2];
        }
    }

    const handleSearchOption = (item) => {
        if (item === searchOptions[1]) {
            onChange(EMPTY);
        } else if (item === searchOptions[2]) {
            onChange(ANY);
        } else {
            onChange("");
        }
    };

    return (
        <StyledFilterTextSearch>
            <ControlGroup fill={true} vertical={false}>
                {hasSearchOptions && (
                    <Select2
                        items={searchOptions}
                        itemRenderer={renderSearchOption}
                        onItemSelect={(item) => handleSearchOption(item)}
                        filterable={false}
                    >
                        <Button intent={Intent.NONE} rightIcon="double-caret-vertical" text={searchOption.label} />
                    </Select2>
                )}
                <InputGroup
                    fill
                    asyncControl
                    id={id}
                    value={searchOption.disableTextField ? "" : value}
                    disabled={searchOption.disableTextField}
                    onChange={handleOnChange}
                    placeholder={searchOption.disableTextField ? "" : emptyText}
                    onBlur={(e) => onChange(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            onChange(e.target.value);
                        }
                    }}
                    rightElement={
                        value && (
                            <Button
                                minimal
                                icon="cross"
                                onClick={() =>
                                    handleOnChange({
                                        target: {
                                            value: "",
                                        },
                                    })
                                }
                            />
                        )
                    }
                />
            </ControlGroup>
        </StyledFilterTextSearch>
    );
};

FilterTextSearch.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    emptyText: PropTypes.string,
    hasSearchOptions: PropTypes.bool,
};

FilterTextSearch.defaultProps = {
    id: null,
    emptyText: null,
    value: "",
    hasSearchOptions: false,
};

export default FilterTextSearch;
