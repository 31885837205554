import { Alignment, Button, Intent, Navbar, Tab, Tabs } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { FiInfo } from "react-icons/fi";
import { SiMapbox } from "react-icons/si";
import { toast } from "react-toastify";
import styled from "styled-components";

import MapboxView from "../../common/mapbox-view";
import { DialogContext } from "../bp-dialog-context";
import Box from "../common/box";
import { buildQuestionDialog } from "../dialogs/helpers";
import { ALL_PLATFORMS, PLATFORM_ICON_CMPS, PLATFORM_NAME_CMPS } from "../platforms";
import TargetingForm from "./targeting-form";
import TargetingGeojson from "./targeting-geojson";
import TargetingPlatform from "./targeting-platform";

const JOINED_PLATFORMS = [
    ["facebook", "instagram"],
    ["google_ads_search", "google_ads_display"],
];

const StyledEmpty = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    opacity: 0.3;
`;

const Empty = ({ label }) => <StyledEmpty>{label}</StyledEmpty>;

Empty.propTypes = {
    label: PropTypes.string.isRequired,
};

const StyledTab = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-right: 5px;
    }
    &.disabled {
        opacity: 0.3;
    }
`;

const StyledTabContent = styled.div`
    width: 100%;
`;

const StyledTargetingInfo = styled.div`
    .json-data {
        overflow: scroll;
        max-width: 100vw;
        max-height: 200px;
        font-size: 12px;
        line-height: 14px;
        display: block;
        font-family: monospace;
        white-space: pre;
    }
`;

const TargetingInfo = ({ onChange, targeting }) => {
    const { showDialog } = useContext(DialogContext);
    const [tabIndex, setTabIndex] = React.useState("general");
    const byPlatformData = (targeting.platformData || []).reduce((acc, val) => {
        acc[val.platform] = val.data;
        return acc;
    }, {});

    const handleSetPlatformData = useCallback(
        (platform, data) => {
            const platforms = JOINED_PLATFORMS.reduce((acc, all) => {
                if (all.indexOf(platform) !== -1) {
                    return [...all, ...acc];
                }
                return acc;
            }, []);
            if (platforms.length === 0) {
                platforms.push(platform);
            }

            const oldData = (targeting.platformData || []).filter((x) => platforms.indexOf(x.platform) === -1);
            const newData = oldData
                .concat(
                    platforms.map((p) => ({
                        platform: p,
                        data,
                    }))
                )
                .map(({ __typename, ...d }) => d); // cleanup apollo __typename addition

            onChange({ platformData: newData });
        },
        [targeting, onChange]
    );

    const handleRemovePlatformData = useCallback(
        async (platform) => {
            const ret = await showDialog(
                buildQuestionDialog({
                    title: "Remove platform",
                    body: `Do you really want to remove ${platform} from this geo targeting?`,
                    actions: [
                        {
                            text: "Yes REMOVE IT",
                            code: 1,
                            icon: "remove",
                            intent: Intent.DANGER,
                        },
                    ],
                })
            );
            if (ret.code === 1) {
                onChange({
                    platformData: [
                        {
                            platform,
                            delete: true,
                            data: {},
                        },
                    ],
                });
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [targeting, onChange]
    );
    const handleSetGeometry = useCallback(
        async (geojson) => {
            onChange({ geojson });
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [targeting, onChange]
    );

    const handleSaveForm = useCallback(
        (data) => {
            onChange(data);
        },
        [onChange]
    );

    const tabs = [
        {
            key: "general",
            icon: FiInfo,
            label: "General",
            enabled: true,
            component: (
                <TargetingForm defaultValue={targeting} actionLabel="Save changes" onSave={handleSaveForm} autoSave />
            ),
        },
        {
            key: "geojson",
            icon: SiMapbox,
            label: "GeoJSON",
            enabled: !!targeting.geojson,
            component: (
                <>
                    <Box>
                        {targeting.geojson ? (
                            <>
                                <MapboxView geometries={[targeting.geojson]} />
                                <Button
                                    intent={Intent.DANGER}
                                    icon="trash"
                                    text="Remove geometry"
                                    fill
                                    onClick={async () => {
                                        const { code } = await showDialog(
                                            buildQuestionDialog({
                                                title: "Remove geometry",
                                                body: "Do you really want to remove the location geometry?",
                                                actions: [
                                                    { text: "Yes", code: 1, intent: Intent.DANGER, icon: "trash" },
                                                ],
                                            })
                                        );
                                        if (code === 1) {
                                            //await handleSetGeometry(null);
                                            // TODO This should be fixed in amplify-service graphql
                                            toast.error("Not yet working");
                                        }
                                    }}
                                />
                            </>
                        ) : (
                            <Empty label="No geometry set" />
                        )}
                    </Box>
                    <Box background="dark-2">
                        <TargetingGeojson searchValue={targeting.name} onSelect={handleSetGeometry} />
                    </Box>
                </>
            ),
        },
    ].concat(
        ALL_PLATFORMS.map((platform) => ({
            key: platform,
            icon: PLATFORM_ICON_CMPS[platform],
            label: PLATFORM_NAME_CMPS[platform] || platform,
            enabled: !!byPlatformData[platform],
            component: (
                <>
                    {byPlatformData[platform] ? (
                        <div>
                            <div className="json-data">{JSON.stringify(byPlatformData[platform], null, 4)}</div>
                            <Button
                                intent={Intent.DANGER}
                                size="small"
                                icon="trash"
                                onClick={() => handleRemovePlatformData(platform)}
                                text={`Remove ${platform} data`}
                                fill
                            />
                        </div>
                    ) : (
                        <Empty label="Not set" />
                    )}

                    <TargetingPlatform
                        platform={platform.split("/")[0]}
                        searchValue={targeting.name}
                        onSelect={(v) => handleSetPlatformData(platform, v)}
                    />
                </>
            ),
        }))
    );

    return (
        <StyledTargetingInfo>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Tabs selectedTabId={tabIndex} onChange={setTabIndex}>
                        {tabs.map((tab) => (
                            <Tab id={tab.key} key={tab.key}>
                                <StyledTab className={tab.enabled ? "enabled2" : "disabled2"}>
                                    <tab.icon size="20" color={tab.enabled ? "green" : "lightgray"} /> {tab.label}
                                </StyledTab>
                            </Tab>
                        ))}
                        <Tabs.Expander />
                    </Tabs>
                </Navbar.Group>
            </Navbar>
            <br />
            <StyledTabContent>{tabs.find((t) => t.key === tabIndex)?.component}</StyledTabContent>
        </StyledTargetingInfo>
    );
};

TargetingInfo.propTypes = {
    targeting: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        countryLocaleName: PropTypes.string.isRequired,
        subtype: PropTypes.string.isRequired,
        validated: PropTypes.bool,
        geojson: PropTypes.any,
        platformData: PropTypes.arrayOf(
            PropTypes.shape({
                platform: PropTypes.string,
            })
        ),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};
export default TargetingInfo;
