import { Button, ButtonGroup, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ALL_LANG } from "../../lang";
import FilterBoolean from "../common/filter-boolean";
import FilterOptionsSelect from "../common/filter-options-select";
import FilterTextSearch from "../common/filter-text-search";

export const ALL_PRODUCTS = [
    {
        value: "agency_month",
        label: "Agency (month)",
    },
    {
        value: "agency_field_sales",
        label: "Agency (field sales)",
    },
    {
        value: "premium_year",
        label: "Premium (year)",
    },
    {
        value: "premium_month",
        label: "Premium (month)",
    },
    {
        value: "premium_trial",
        label: "Premium (trial)",
        color: "blue",
    },
    {
        value: "plus_year",
        label: "Plus (year)",
    },
    {
        value: "plus_month",
        label: "Plus (month)",
    },
];

const StyledFilterPane = styled.div`
    display: grid;
    grid-template-columns: 100px auto;
    grid-gap: 10px;
    overflow: hidden;
    padding: 5px;
    align-items: center;
    .bp4-control {
        margin-bottom: 0;
    }
`;

const Label = styled.div`
    font-weight: bold;
    text-align: right;
    align-self: center;
    font-size: 0.8em;
    overflow: hidden;
    white-space: nowrap;
`;

const ButtonZone = styled.div`
    text-align: right;
`;

const UserFilterPane = ({ filters, setFilters, isAgency }) => {
    // title keep change locally until the user hit "search"

    const handleResetFilters = () => {
        setFilters({});
    };

    const updateFilter = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    const handleSearch = () => {
        setFilters(filters);
    };

    return (
        <StyledFilterPane>
            <>
                <Label>Search</Label>
                <FilterTextSearch
                    value={filters.q}
                    onChange={(v) => updateFilter("q", v)}
                    emptyText="Search for email, first name or last name"
                />
            </>
            {!isAgency && (
                <>
                    <Label>Agency</Label>
                    <FilterTextSearch
                        value={filters.agency}
                        onChange={(v) => updateFilter("agency", v)}
                        emptyText="Search for agency name"
                    />
                </>
            )}
            <>
                <Label>Active</Label>
                <FilterBoolean
                    value={filters.isActive}
                    onChange={(v) => updateFilter("isActive", v)}
                    emptyText="Is user active"
                    positive="Active"
                    negative="Inactive"
                />
            </>
            <>
                <Label>Verified</Label>
                <FilterBoolean
                    value={filters.verified}
                    onChange={(v) => updateFilter("verified", v)}
                    emptyText="Is user verified"
                    positive="Verified"
                    negative="Unverified"
                />
            </>
            <>
                <Label>Language</Label>
                <FilterOptionsSelect
                    options={ALL_LANG}
                    value={filters.lang}
                    onChange={(value) => updateFilter("lang", value)}
                    emptyText="Any language"
                />
            </>
            <>
                <Label>Product</Label>
                <FilterOptionsSelect
                    options={ALL_PRODUCTS}
                    value={filters.product}
                    onChange={(value) => updateFilter("product", value)}
                    emptyText="No filter"
                    allowEmpty
                />
            </>

            <>
                <div />
                <ButtonZone>
                    <ButtonGroup>
                        <Button
                            intent={Intent.NONE}
                            icon="cross"
                            disabled={filters === {}}
                            onClick={() => handleResetFilters()}
                        >
                            Reset filters
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            icon="search"
                            disabled={filters === {}}
                            onClick={() => handleSearch()}
                        >
                            Search
                        </Button>
                    </ButtonGroup>
                </ButtonZone>
            </>
        </StyledFilterPane>
    );
};

UserFilterPane.propTypes = {
    filters: PropTypes.shape({
        q: PropTypes.string,
        product: PropTypes.arrayOf(PropTypes.string),
        lang: PropTypes.arrayOf(PropTypes.string),
        isActive: PropTypes.bool,
        verified: PropTypes.bool,
        agency: PropTypes.string,
    }).isRequired,
    setFilters: PropTypes.func.isRequired,
    isAgency: PropTypes.bool,
};
UserFilterPane.defaultProps = {
    isAgency: false,
};

export default UserFilterPane;
