export const ALL_LANG = [
    {
        value: "en",
        label: "English",
    },
    {
        value: "fr",
        label: "French",
    },
    {
        value: "de",
        label: "German",
    },
    {
        value: "es",
        label: "Spanish",
    },
    {
        value: "nl",
        label: "Dutch",
    },
];
