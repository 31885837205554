import { Button, Divider, Icon, Intent, Menu, MenuDivider, MenuItem, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Cell, Column, Table2, TableLoadingOption } from "@blueprintjs/table";
import { RenderMode } from "@blueprintjs/table/lib/esnext";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { DialogContext } from "../bp-dialog-context";
import { sortableColumnRenderer } from "../common/bp-table-helper";
import AddComment from "../dialogs/add-comment";
import DialogAssignedTicket from "../dialogs/assigned-ticket";
import DeleteTicket from "../dialogs/delete-ticket";
import StatusTicket from "../dialogs/status-ticket";
import PropertiesGrid from "../layout/properties-grid";
import { useMutationAssignTicket, useMutationUpdateStatusTicket } from "./gql-tickets";

const StyledImage = styled.img`
    object-fit: scale-down;
    height: 55px;
    width: 55px;
`;

// BEGIN Cell menu
const handleShowMessage = (message) => {
    window.open(`/messages/${message.id}/edit/`);
};

const renderCellMenu = (
    ticket,
    { history, statusTicket, assignedTicketTo, handleAddComment, handleDeleteTicket, identity }
) => {
    return (
        <Cell style={{ padding: 5 }}>
            <Popover2
                content={
                    <Menu>
                        <MenuDivider title="Actions" />
                        <MenuItem
                            key="show-logs"
                            icon="properties"
                            onClick={() =>
                                history.push({
                                    pathname: `/tickets/${ticket.id}/logs`,
                                    search: history.location.search,
                                })
                            }
                            text="Show logs"
                        />
                        <>
                            <Divider />
                            <MenuItem
                                icon={<Icon icon="comment" />}
                                intent={Intent.SUCCESS}
                                onClick={() => handleAddComment(ticket)}
                                text="Add Comment"
                            />
                        </>
                        <Divider />
                        <MenuItem
                            icon={<Icon icon="edit" />}
                            intent={Intent.PRIMARY}
                            onClick={() =>
                                history.push({
                                    pathname: `/tickets/${ticket.id}/edit/`,
                                    search: history.location.search,
                                })
                            }
                            text="View Ticket"
                        />
                        <MenuItem
                            icon={<Icon icon="delete" />}
                            intent={Intent.DANGER}
                            onClick={async () => {
                                await statusTicket({ id: ticket.id, status: "closed" });
                            }}
                            text="Close Ticket"
                        />
                        <MenuItem
                            icon={<Icon icon="cross" />}
                            onClick={() => handleDeleteTicket(ticket)}
                            text="Delete Ticket"
                        />
                        <MenuItem
                            icon={<Icon icon="user" />}
                            onClick={() => assignedTicketTo(ticket)}
                            text="Assign Ticket To"
                        />

                        <Divider />
                        <MenuItem
                            icon="barcode"
                            text="Copy ID Ticket"
                            onClick={() => {
                                navigator.clipboard.writeText(ticket.id);
                            }}
                            label={<Icon icon="clipboard" />}
                        />
                    </Menu>
                }
            >
                <Button large minimal icon="cog" />
            </Popover2>
        </Cell>
    );
};

// END Cell menu

// BEGIN CELL image + menu

const renderCellImage = (ticket) => (
    <Cell>
        {ticket?.image?.imageUrl && (
            <div onClick={() => window.open(ticket?.image?.imageUrl)}>
                <StyledImage src={ticket?.image?.imageUrl} alt={ticket.title} />
            </div>
        )}
    </Cell>
);

// BEGIN CELL image + menu

const renderCellVideo = (ticket) => (
    <Cell>
        {ticket?.movie?.videoUrl && (
            <div onClick={() => window.open(ticket?.movie?.videoUrl)}>
                <StyledImage src={ticket?.movie?.image?.imageUrl} alt={ticket.title} />
            </div>
        )}
    </Cell>
);

// END CELL image + menu

// BEGIN CELL title
const StyledTitle = styled.div`
    font-size: 11px;
    line-height: 12px !important;
    display: inline-flex;
`;

const renderCellTitle = (ticket, { history }) => (
    <Cell wrapText>
        <div
            style={{ height: "100%", width: "100%" }}
            onClick={() =>
                history.push({
                    pathname: `/tickets/${ticket.id}/edit/`,
                    search: history.location.search,
                })
            }
        >
            <StyledTitle>{ticket.title}</StyledTitle>
        </div>
    </Cell>
);

const renderCellCreatedBy = (ticket) => <Cell>{ticket?.createdBy?.email}</Cell>;
const renderCellAssigned = (ticket, { identity }) => (
    <Cell
        wrapText
        style={{ backgroundColor: ticket.assignedMember?.username === identity.username ? "#2d72d226" : "" }}
    >
        <StyledTitle style={{ color: !ticket.assignedMember?.username && "red" }}>
            {ticket.assignedMember?.username ?? "Not Assigned"}
        </StyledTitle>
    </Cell>
);
const renderCellDescription = (ticket) => (
    <Cell wrapText>
        <StyledTitle>{ticket.description}</StyledTitle>
    </Cell>
);

// BEGIN STATE CELL
export const getIntentForState = (state) => {
    if (state === "resolved") {
        return Intent.SUCCESS;
    }
    if (state === "open" || state === "high") {
        return Intent.WARNING;
    }
    if (state === "in progress" || state === "normal") {
        return Intent.PRIMARY;
    }
    if (state === "closed" || state === "urgent") {
        return Intent.DANGER;
    }
    return null;
};

const getIconForPriority = (state) => {
    if (state === "low") {
        return <Icon style={{ marginRight: "5px" }} icon="minus" />;
    }
    if (state === "normal") {
        return <Icon style={{ marginRight: "5px" }} icon="dot" />;
    }
    if (state === "high") {
        return <Icon style={{ marginRight: "5px" }} icon="high-priority" />;
    }
    if (state === "urgent") {
        return <Icon style={{ marginRight: "5px" }} icon="warning-sign" />;
    }
    return null;
};
const renderCellStatus = (ticket) => {
    return (
        <Cell>
            <Popover2
                content={
                    <Menu>
                        <MenuItem
                            icon={<Icon icon="none" />}
                            text="Show message"
                            label={<Icon icon="document-open" />}
                        />
                    </Menu>
                }
            >
                <Tag minimal intent={getIntentForState(ticket.status)}>
                    {ticket.status}
                </Tag>
            </Popover2>
        </Cell>
    );
};

const renderCellExpiryAt = (ticket) => {
    const currentDate = moment();

    const differenceDays = moment(ticket.expiryAt).diff(currentDate, "days");
    const isExpired = moment(ticket.expiryAt).isBefore(currentDate);
    const isFinish = ticket.status === "resolved" || ticket.status === "closed";
    if (ticket.expiryAt)
        return (
            <Cell>
                {!isFinish && (
                    <Popover2
                        content={
                            <PropertiesGrid
                                padding="5px"
                                values={[
                                    {
                                        label: "Expiry Date",
                                        value: ticket.expiryAt,
                                    },
                                ]}
                            />
                        }
                    >
                        {isExpired ? (
                            <Tag minimal intent={Intent.DANGER}>
                                {-differenceDays} days ago
                            </Tag>
                        ) : (
                            <Tag minimal>{differenceDays} days left</Tag>
                        )}
                    </Popover2>
                )}
            </Cell>
        );
    else return <Cell />;
};
const renderCellPriorityStatus = (ticket) => {
    const intent = getIntentForState(ticket.priorityStatus);
    const icon = getIconForPriority(ticket.priorityStatus);
    return (
        <Cell>
            <Tag minimal intent={intent}>
                {icon} {ticket.priorityStatus.toUpperCase()}
            </Tag>
        </Cell>
    );
};

const renderCellType = (ticket) => {
    return (
        <Cell>
            <Tag minimal intent={getIntentForState(ticket.type)}>
                {ticket.type.toUpperCase()}
            </Tag>
        </Cell>
    );
};
const renderCellMessage = (ticket) => {
    return (
        <Cell>
            {ticket.message?.title && (
                <Popover2
                    content={
                        <Menu>
                            <MenuItem
                                icon={<Icon icon="none" />}
                                onClick={() => handleShowMessage(ticket.message)}
                                text="Show message"
                                label={<Icon icon="document-open" />}
                            />
                        </Menu>
                    }
                >
                    {ticket.message.texts?.find((val) => val.type === "primary_text")?.value}
                </Popover2>
            )}
        </Cell>
    );
};
const renderCellAction = (ticket, { identity, assignedTicketTo, handleChangeStatus }) => {
    return (
        <Cell>
            {identity?.username === ticket.assignedMember?.username ? (
                <Button
                    outlined
                    intent={Intent.PRIMARY}
                    onClick={() => handleChangeStatus(ticket)}
                    text={ticket.status === "open" ? "Start !" : ticket.status === "in progress" ? "Finish" : "Reopen"}
                    icon="log-out"
                />
            ) : (
                <Button
                    outlined
                    intent={Intent.PRIMARY}
                    onClick={() => assignedTicketTo(ticket)}
                    text={"Assign To"}
                    icon="log-in"
                />
            )}
        </Cell>
    );
};

const renderCellAssignToMe = (ticket, { identity, handleAssignMe }) => {
    return (
        <Cell>
            {identity?.username !== ticket.assignedMember?.username ? (
                <Button intent={Intent.PRIMARY} onClick={() => handleAssignMe(ticket)} text="Assign To Me" />
            ) : null}
        </Cell>
    );
};

const StyledMessagesTable = styled.div`
    width: 100%;
    padding: 10px;
    position: relative;
    .centered-table-cell,
    .bp4-table-cell {
        display: flex;
        align-items: center;
    }
`;

const TicketsTable = ({ tickets, loading, ordering = {}, identity }) => {
    const { showDialog, hideDialog } = useContext(DialogContext);
    const [statusTicket] = useMutationUpdateStatusTicket();
    const [assignTicket] = useMutationAssignTicket();

    const history = useHistory();

    const withTicket =
        (func, ...args) =>
        (index) =>
            func(tickets[index], ...args);
    const [columnWidths, setColumnWidths] = useState([
        50, 160, 170, 120, 200, 180, 100, 100, 100, 120, 120, 140, 130, 200,
    ]);

    const assignedTicketTo = useCallback(
        async (ticket) => {
            await showDialog(DialogAssignedTicket, { ticket });
        },
        [showDialog]
    );

    const handleChangeStatus = useCallback(
        async (ticket) => {
            await showDialog(StatusTicket, { ticket });
        },
        [showDialog]
    );
    const handleAddComment = useCallback(
        async (ticket) => {
            await showDialog(AddComment, { ticket });
        },
        [showDialog]
    );

    const handleDeleteTicket = useCallback(
        async (ticket) => {
            await showDialog(DeleteTicket, { ticket });
        },
        [showDialog]
    );
    const handleAssignMe = useCallback(
        async (ticket) => {
            await assignTicket({
                id: ticket.id,
                memberId: identity.id,
            });
            toast.success("Successfully assigned ticket");
            await hideDialog(1, {});
        },
        [assignTicket, hideDialog, identity?.id]
    );
    return (
        <StyledMessagesTable>
            <Table2
                numRows={tickets.length}
                enableRowHeader={false}
                numFrozenColumns={2}
                fill
                defaultRowHeight={55}
                columnWidths={columnWidths}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
                loadingOptions={loading ? [TableLoadingOption.COLUMN_HEADERS, TableLoadingOption.CELLS] : []}
                renderMode={RenderMode.NONE} // https://github.com/palantir/blueprint/issues/4094#issuecomment-689190282
            >
                <Column
                    name=""
                    cellRenderer={withTicket(renderCellMenu, {
                        history,
                        statusTicket,
                        assignedTicketTo,
                        handleAddComment,
                        handleDeleteTicket,
                        identity,
                    })}
                />
                <Column
                    name="Created By"
                    cellRenderer={withTicket(renderCellCreatedBy, { identity })}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <Icon icon="locate" color="#ac2f33cf" />,
                        name: "Created By",
                        ordering,
                    })}
                />
                <Column
                    name="Assigned To"
                    cellRenderer={withTicket(renderCellAssigned, { identity })}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <Icon icon="inherited-group" color="gray" />,
                        name: "Assigned To",
                        ordering,
                    })}
                />
                <Column name="Type" cellRenderer={withTicket(renderCellType)} />
                <Column name="Title" cellRenderer={withTicket(renderCellTitle, { history })} />
                <Column name="Description" cellRenderer={withTicket(renderCellDescription)} />
                <Column
                    name=""
                    cellRenderer={withTicket(renderCellImage)}
                    nameRenderer={() => <Icon icon="media" color="gray" />}
                />
                <Column
                    name=""
                    cellRenderer={withTicket(renderCellVideo)}
                    nameRenderer={() => <Icon icon="mobile-video" color="gray" />}
                />
                <Column name="Expiry At" cellRenderer={withTicket(renderCellExpiryAt)} />
                <Column name="Status" cellRenderer={withTicket(renderCellStatus)} />
                <Column name="Priority" cellRenderer={withTicket(renderCellPriorityStatus)} />
                <Column
                    name="Action"
                    cellRenderer={withTicket(renderCellAction, {
                        identity,
                        assignedTicketTo,
                        handleChangeStatus,
                    })}
                />
                <Column
                    name="Assign To Me"
                    cellRenderer={withTicket(renderCellAssignToMe, {
                        identity,
                        handleAssignMe,
                    })}
                />
                <Column name="Message" cellRenderer={withTicket(renderCellMessage)} />
            </Table2>
        </StyledMessagesTable>
    );
};

TicketsTable.propTypes = {
    tickets: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
        })
    ).isRequired,
    identity: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    loading: PropTypes.bool,
    ordering: PropTypes.shape({}),
    onChangeSort: PropTypes.func,
    hasPermission: PropTypes.func,
};

TicketsTable.defaultProps = {
    loading: false,
    ordering: {},
    onChangeSort: null,
    hasPermission: null,
};

export default TicketsTable;
