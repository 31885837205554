const INVALID_DOMAIN_NAMES = [".linkedin.com", ".tweeter.com", ".youtube.com", ".youtu.be"];

export const getUrlValidationError = (url) => {
    if (!url) {
        return null;
    }
    try {
        const theUrl = new URL(url);
        if (!["https:", "http:"].includes(theUrl.protocol)) {
            return "Only http and https protocol are allowed";
        }
        for (let invalid of INVALID_DOMAIN_NAMES) {
            if (theUrl.hostname.endsWith(invalid)) {
                return `domain "${invalid}" cannot be used as destination url for this ad`;
            }
        }
        return null;
    } catch (ex) {
        return "This is not a valid URL";
    }
};
