import { Button, Classes, FormGroup, InputGroup, Intent, TextArea } from "@blueprintjs/core";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { peek } from "../../common/utils";
import ErrorCallout from "../common/error-callout";
import SimpleSelect from "../common/simple-select";
import MessageDate from "../messges/edit/field-date";
import FieldImage from "../messges/edit/field-image";
import FieldOwner from "../messges/edit/field-owner";
import FieldVideo from "../messges/edit/field-video";
import MessageField from "../messges/message-field";
import { useMutationCreateTicket } from "../tickets/gql-tickets";

export const ALL_TICKET_PRIORITY = [
    { value: "urgent", label: "URGENT", icon: "warning-sign" },
    { value: "high", label: "HIGH", icon: "high-priority" },
    { value: "normal", label: "NORMAL", icon: "dot" },
    { value: "low", label: "LOW", icon: "minus" },
];
export const ALL_TICKET_TYPE = [
    { value: "bug", label: "Bug", icon: "warning-sign" },
    { value: "premium", label: "Premium", icon: "dollar" },
    { value: "marketing", label: "Marketing", icon: "shopping-cart" },
    { value: "sale", label: "Sale", icon: "chart" },
    { value: "platform", label: "Platform", icon: "social-media" },
    { value: "design", label: "Design", icon: "page-layout" },
    { value: "ai", label: "AI", icon: "heart" },
];
export const FieldGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`;
const CreateTicketDialog = ({ hideDialog, isDialogProcessing, message }) => {
    const [createTicket, { loading: saving, error }] = useMutationCreateTicket();
    const [data, setData] = useState({});

    const onChangeValue = useCallback((k, v) => {
        setData((oldValues) => ({
            ...oldValues,
            [k]: v,
        }));
    }, []);
    const handleCreate = useCallback(
        async (values) => {
            await createTicket({
                ticket: {
                    ...peek(values, [
                        "title",
                        "description",
                        "expiryAt",
                        "priorityStatus",
                        "assignedMember",
                        "type",
                        // undefined will remove the key, it is different from setting the key to null
                        ["image", "imageId", (image) => image?.id || undefined],
                        ["movie", "movieId", (movie) => movie?.id || undefined],
                        ["movie", "movieImageId", (movie) => movie?.image?.id || undefined],
                        ["assignedMember", (assignedMember) => assignedMember?.id || undefined],
                    ]),
                    messageId: message?.id,
                },
            });
            toast.success("Successfully ticket created");
            hideDialog(1, {});
        },
        [createTicket, hideDialog, message?.id]
    );
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while create ticket" error={error} />
                <br />
                <>
                    <FieldGroup>
                        <MessageField
                            label="Expiry date"
                            name="expiryAt"
                            values={data}
                            onChangeValue={onChangeValue}
                            renderComponent={({ value, setValue, disabled }) => (
                                <MessageDate
                                    disabled={disabled}
                                    onChange={(v) => setValue(v)}
                                    value={value}
                                    maxDate={moment().add()}
                                />
                            )}
                        />
                        <FormGroup
                            label="Priority"
                            helperText="Select an appropriate priority for the ticket"
                            intent={Intent.WARNING}
                        >
                            <SimpleSelect
                                outlined
                                options={ALL_TICKET_PRIORITY}
                                onSelect={(v) => onChangeValue("priorityStatus", v)}
                                selected={data.priorityStatus}
                            />
                        </FormGroup>
                        <FormGroup label="Type">
                            <SimpleSelect
                                outlined
                                options={ALL_TICKET_TYPE}
                                onSelect={(v) => onChangeValue("type", v)}
                                selected={data.type}
                            />
                        </FormGroup>
                        <MessageField
                            label="Assigned To"
                            name="assignedMember"
                            values={data}
                            onChangeValue={onChangeValue}
                            renderComponent={({ value, setValue, disabled }) => (
                                <FieldOwner value={value} onChange={setValue} disabled={disabled} onlyStaff />
                            )}
                        />
                    </FieldGroup>

                    <FormGroup label="Title">
                        <InputGroup
                            required
                            aria-label="title"
                            value={data?.title}
                            onChange={(e) => onChangeValue("title", e.target.value)}
                            maxLength={80}
                        />
                    </FormGroup>
                    <FormGroup label="Description" labelFor="comments-input" helperText="Optional description">
                        <TextArea
                            fill
                            id="comments-input"
                            value={data?.description}
                            onChange={(e) => onChangeValue("description", e.target.value)}
                            growVertically={false}
                        />
                    </FormGroup>
                    <MessageField
                        label="Image"
                        name="image"
                        values={data}
                        onChangeValue={onChangeValue}
                        renderComponent={({ value, setValue, disabled }) => (
                            <FieldImage value={value} onChange={setValue} disabled={disabled} />
                        )}
                    />
                    <MessageField
                        label="Video"
                        name="movie"
                        values={data}
                        onChangeValue={onChangeValue}
                        renderComponent={({ value, setValue, disabled }) => (
                            <FieldVideo value={value} onChange={setValue} disabled={disabled} />
                        )}
                    />
                </>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={false}
                        loading={saving || isDialogProcessing}
                        intent={Intent.SUCCESS}
                        text="Create Ticket"
                        onClick={() => handleCreate(data)}
                        rightIcon="plus"
                    />
                </div>
            </div>
        </>
    );
};

CreateTicketDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

export default {
    title: "Create Ticket",
    icon: "label",
    component: CreateTicketDialog,
};
