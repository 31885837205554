import { Button, Classes, Intent } from "@blueprintjs/core";
import _camelCase from "lodash/camelCase";
import _mapKeys from "lodash/mapKeys";
import _snakeCase from "lodash/snakeCase";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { removeDeepByKey } from "../../common/utils";
import businessSurveySteps from "../business-survey/business-survey-steps";
import { useMutationSetBusinessSurveyData } from "../business-survey/gql-business-survey";
import ErrorCallout from "../common/error-callout";
import Survey from "../survey-engine/survey";

const toCamelCase = (data) => _mapKeys(data, (value, key) => _camelCase(key));
const toSnakeCase = (data) => _mapKeys(data, (value, key) => _snakeCase(key));

const BusinessSurveyDialog = ({ hideDialog, isDialogProcessing, user }) => {
    const [setBusinessSurveyData, { loading, error }] = useMutationSetBusinessSurveyData();
    const [surveyData, setSurveyData] = useState({});
    const [isSurveyValid, setIsSurveyValid] = useState(false);

    // Load survey from user's business
    useEffect(() => {
        setSurveyData(toSnakeCase(removeDeepByKey(user?.business?.surveyData || {}, "__typename")));
    }, [user]);

    const handleSetBusinessSurveyData = async () => {
        await setBusinessSurveyData({
            surveyData: toCamelCase(surveyData),
            impersonateUserId: user.id,
        });
        toast.success("Business survey sent!");
        hideDialog(1, {});
    };

    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <ErrorCallout title="Error while sending survey" error={error} />
                <br />
                <Survey
                    steps={businessSurveySteps}
                    data={surveyData}
                    setData={setSurveyData}
                    setIsValid={setIsSurveyValid}
                />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing || loading} text="Cancel" onClick={() => hideDialog(0)} />
                    <Button
                        disabled={!isSurveyValid || loading}
                        loading={isDialogProcessing || loading}
                        intent={Intent.SUCCESS}
                        text="Send survey"
                        onClick={handleSetBusinessSurveyData}
                        rightIcon="office"
                    />
                </div>
            </div>
        </>
    );
};

BusinessSurveyDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        business: PropTypes.shape({
            surveyData: PropTypes.shape({}),
        }),
    }).isRequired,
};

export default {
    title: "Business survey",
    icon: "form",
    component: BusinessSurveyDialog,
};
