export const ALL_CURRENCIES = [
    {
        value: "CHF",
        label: "Swiss francs (CHF)",
    },
    {
        value: "EUR",
        label: "Euro (€)",
    },
    {
        value: "USD",
        label: "US Dollars ($)",
    },
    {
        value: "MXN",
        label: "Mexican peso (MX$)",
    },
];
