import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import SimpleSelect from "../../common/simple-select";

const StyledFieldLang = styled.div`
    margin: 5px;
`;

const LANGUAGE_MAP = {
    en: "English",
    fr: "French",
    de: "German",
    es: "Spanish",
    nl: "Dutch",
    ar: "Arabic",
};

const LANGUAGE_OPTIONS = ["en", "fr", "de", "es", "nl", "ar"].map((v) => ({
    value: v,
    label: LANGUAGE_MAP[v],
}));

const FieldLang = ({ value, onChange, disabled }) => {
    return (
        <StyledFieldLang>
            <SimpleSelect disabled={disabled} selected={value} options={LANGUAGE_OPTIONS} onSelect={onChange} />
        </StyledFieldLang>
    );
};

FieldLang.propTypes = {
    value: PropTypes.string,
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FieldLang.defaultProps = {
    value: null,
    emptyValue: null,
    onChange: null,
    disabled: false,
};

export default FieldLang;
