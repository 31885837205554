import { FormGroup, Switch } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";

const FilterOnOff = ({ id, value, onChange, onText, offText }) => {
    const handleOnChange = (e) => {
        const v = e.currentTarget.checked;
        onChange(v);
    };

    return (
        <FormGroup>
            <Switch large id={id} checked={value} onChange={handleOnChange} label={value ? onText : offText} />
        </FormGroup>
    );
};

FilterOnOff.propTypes = {
    id: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onText: PropTypes.node,
    offText: PropTypes.node,
};

FilterOnOff.defaultProps = {
    id: null,
    value: false,
    onText: null,
    offText: null,
};

export default FilterOnOff;
