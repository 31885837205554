import { Classes, Icon } from "@blueprintjs/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const StyledPagination = styled.div`
    li {
        list-style-type: none;
        min-width: 30px;
        text-align: center;
        padding: 0;
        a {
            min-width: 50px;
            text-decoration: none;
            outline: none;
        }
        .link {
            cursor: pointer;
            color: inherit;
        }
    }
`;

const Pagination = ({ numberItems, step, onChange, page }) => {
    return (
        <StyledPagination>
            <ReactPaginate
                breakLabel="..."
                previousLabel={<Icon icon="chevron-left" />}
                nextLabel={<Icon icon="chevron-right" />}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(numberItems / step)}
                renderOnZeroPageCount={null}
                forcePage={page - 1}
                onPageChange={async (e) => {
                    const startIndex = (e.selected * step) % numberItems;
                    const endIndex = Math.min(startIndex + step, numberItems);
                    await onChange({ startIndex, endIndex });
                }}
                className={classNames("pagination", Classes.BUTTON_GROUP)}
                previousClassName={classNames(Classes.BUTTON)}
                nextClassName={classNames(Classes.BUTTON)}
                pageClassName={classNames(Classes.BUTTON)}
                pageLinkClassName="link"
                containerClassName={Classes.BUTTON_GROUP}
                breakClassName={classNames(Classes.BUTTON, Classes.MINIMAL, Classes.DISABLED)}
                activeClassName={classNames(Classes.INTENT_PRIMARY)}
                disabledClassName={Classes.DISABLED}
            />
        </StyledPagination>
    );
};

Pagination.propTypes = {
    numberItems: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
};

export default Pagination;
