import { AnchorButton, Button, Divider, Icon, Intent, Menu, MenuDivider, MenuItem, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Cell, Column, Table2 } from "@blueprintjs/table";
import { RenderMode } from "@blueprintjs/table/lib/esnext";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { formatCurrency, formatDateString } from "../../common/i18n";
import { DialogContext } from "../bp-dialog-context";
import { sortableColumnRenderer } from "../common/bp-table-helper";
import UserBadge, { getUserIntercomLink } from "../common/user-badge";
import AddMoneyToWalletDialog from "../dialogs/add-money-to-wallet";
import AddMoneyToWalletFromAgencyDialog from "../dialogs/add-money-to-wallet-from-agency";
import BusinessSurveyDialog from "../dialogs/business-survey";
import CancelServiceSubscription from "../dialogs/cancel-service-subscription";
import EditBusinessDialog from "../dialogs/edit-business";
import RefundWalletAmount from "../dialogs/refund-wallet-amount";
import RegisterFieldSalesDialog from "../dialogs/register-field-sales";
import PropertiesGrid from "../layout/properties-grid";

const NotSet = styled.small`
    opacity: 0.3;
`;

// BEGIN Cell menu
const handleShowUserOnIntercom = (user) => {
    window.open(getUserIntercomLink(user), `intercom-${user.id}`);
};

const handleShowUserPaymentsOnStripe = (user) => {
    window.open(`https://dashboard.stripe.com/search?query=is%3Acustomers%20${user.id}`, `stripe-payments-${user.id}`);
};

const handleBusinessSurvey = async (user, showDialog) => {
    await showDialog(BusinessSurveyDialog, { user });
};
const handleAddMoneyToWallet = async (user, showDialog) => {
    await showDialog(AddMoneyToWalletDialog, { user });
};
const handleAddMoneyToWalletFromAgency = async (user, rootUserAgency, showDialog) => {
    await showDialog(AddMoneyToWalletFromAgencyDialog, { user, rootUserAgency });
};
const handleWalletRefundToCard = async (user, showDialog) => {
    await showDialog(RefundWalletAmount, { user });
};
const handleRegisterFieldSales = async (user, showDialog) => {
    await showDialog(RegisterFieldSalesDialog, { user });
};
const handleEditBusiness = async (user, showDialog) => {
    await showDialog(EditBusinessDialog, { user });
};
const handleCancelServiceSubscription = async (user, showDialog) => {
    await showDialog(CancelServiceSubscription, { user });
};

// BEGIN Cell menu
const renderCellMenu = (user, { rootUserAgency, showDialog }) => (
    <Cell style={{ padding: 5 }}>
        <Popover2
            content={
                <Menu>
                    <MenuDivider title="Actions" />

                    {rootUserAgency ? (
                        <MenuItem
                            icon={AddMoneyToWalletFromAgencyDialog.icon}
                            onClick={() => handleAddMoneyToWalletFromAgency(user, rootUserAgency, showDialog)}
                            text={AddMoneyToWalletFromAgencyDialog.title}
                        />
                    ) : (
                        <>
                            <MenuItem
                                icon="document-open"
                                onClick={() => handleShowUserOnIntercom(user)}
                                text="Show on intercom"
                            />
                            <MenuItem
                                icon="document-open"
                                onClick={() => handleShowUserPaymentsOnStripe(user)}
                                text="Show payments on Stripe"
                            />
                            <Divider />
                            <MenuItem
                                disabled={!user.business}
                                icon="edit"
                                onClick={() => handleEditBusiness(user, showDialog)}
                                text="Edit business info"
                            />
                            <MenuItem
                                icon={BusinessSurveyDialog.icon}
                                onClick={() => handleBusinessSurvey(user, showDialog)}
                                text="Fill business survey"
                            />
                            <Divider />
                            <MenuItem
                                icon={RefundWalletAmount.icon}
                                onClick={() => handleWalletRefundToCard(user, showDialog)}
                                text={RefundWalletAmount.title}
                            />
                            <MenuItem
                                icon={AddMoneyToWalletDialog.icon}
                                onClick={() => handleAddMoneyToWallet(user, showDialog)}
                                text={AddMoneyToWalletDialog.title}
                            />
                            <MenuItem
                                icon={RegisterFieldSalesDialog.icon}
                                onClick={() => handleRegisterFieldSales(user, showDialog)}
                                text={RegisterFieldSalesDialog.title}
                            />
                        </>
                    )}

                    <Divider />
                    <MenuItem
                        icon={CancelServiceSubscription.icon}
                        onClick={() => handleCancelServiceSubscription(user, showDialog)}
                        text={CancelServiceSubscription.title}
                    />
                </Menu>
            }
        >
            <Button large minimal icon="menu" />
        </Popover2>
    </Cell>
);
// END Cell menu

// BEGIN CELL image + menu
const renderCellAvatar = (user) => (
    <Cell>
        <UserBadge user={user} showEmail={false} size={30} />
    </Cell>
);
// END CELL image + menu

// BEGIN Cell lang
const StyledLang = styled.span`
    text-transform: uppercase;
`;
const renderCellLang = (message) => (
    <Cell>
        <StyledLang>{message.lang}</StyledLang>
    </Cell>
);
// END Cell lang

// BEGIN CELL budget

const renderCellProduct = (user) => {
    if (!user.serviceSubscription) {
        return <Cell />;
    }
    return (
        <Cell>
            <Popover2
                content={
                    <PropertiesGrid
                        padding="5px"
                        values={[
                            {
                                label: "Name",
                                value: user.serviceSubscription.product.name,
                            },
                            {
                                label: "Status",
                                value: user.serviceSubscription.status,
                            },
                            {
                                label: "Started at",
                                value:
                                    user.serviceSubscription.startedAt &&
                                    formatDateString(user.serviceSubscription.startedAt, "short"),
                            },
                            {
                                label: "Cancel at",
                                value:
                                    user.serviceSubscription.cancelAt &&
                                    formatDateString(user.serviceSubscription.cancelAt, "short"),
                            },
                            {
                                label: "Canceled at",
                                value:
                                    user.serviceSubscription.canceledAt &&
                                    formatDateString(user.serviceSubscription.canceledAt, "short"),
                            },
                            {
                                label: "Next due date",
                                value:
                                    user.serviceSubscription.nextDueDate &&
                                    formatDateString(user.serviceSubscription.nextDueDate, "short"),
                            },
                        ]}
                    />
                }
            >
                <Button
                    outlined
                    intent={user.serviceSubscription.product.name.endsWith("_trial") ? Intent.NONE : Intent.PRIMARY}
                    text={user.serviceSubscription.product.name}
                    onClick={() => {}}
                />
            </Popover2>
        </Cell>
    );
};
// END CELL budget

// BEGIN CELL CreatedAt

const renderCellJoinedAt = (user) => {
    if (!user?.dateJoined) {
        return <Cell />;
    }
    return <Cell>{formatDateString(user.dateJoined, "short")}</Cell>;
};
// END CELL CreatedAt

// BEGIN CELL Message count

const renderCellLiveMessageCount = (user, history) => {
    if (!user?.liveMessageCount) {
        return <Cell />;
    }

    return (
        <Cell>
            <Popover2
                content={
                    <Menu>
                        <MenuDivider title="Actions" />
                        <MenuItem
                            icon="document-open"
                            onClick={() => history.push(`/messages/?owner=${user.email}&state=live&state=review`)}
                            text="Show messages"
                        />
                    </Menu>
                }
            >
                <Button minimal text={user.liveMessageCount} />
            </Popover2>
        </Cell>
    );
};
// END CELL Message count

const StyledSubscriptionCell = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 170px;
    text-align: center;
`;
// START CELL Wallets

const renderCellWallets = (user) => {
    if (!user?.wallets) {
        return <Cell />;
    }
    return (
        <Cell wrapText>
            {user.wallets.map((w) => (
                <div key={w.id}>
                    <Tag minimal key={w.currency}>
                        {formatCurrency(w.balance, w.currency)}
                    </Tag>
                    &nbsp;
                </div>
            ))}
        </Cell>
    );
};

const renderCellWalletSubscription = (user) => {
    const walletSubscription = user?.wallets?.[0]?.subscriptions?.find((sub) => sub.canceledAt === null);
    if (!walletSubscription) {
        return <Cell />;
    }
    const amount = formatCurrency(walletSubscription.amount, user?.wallets[0]?.currency);
    return (
        <Cell wrapText>
            <Popover2
                content={
                    <PropertiesGrid
                        padding="5px"
                        values={[
                            {
                                label: "Subscription",
                                value: walletSubscription.interval,
                            },
                            {
                                label: `Amount per ${walletSubscription.interval.toLowerCase()}`,
                                value: amount,
                            },
                            {
                                label: `started at`,
                                value: walletSubscription.startedAt,
                            },
                            {
                                label: `next due at`,
                                value: walletSubscription.nextDueDate,
                            },
                        ]}
                    />
                }
            >
                <StyledSubscriptionCell>
                    <strong>{walletSubscription.interval}</strong>
                    <Divider />
                    <p>{amount}</p>
                </StyledSubscriptionCell>
            </Popover2>
        </Cell>
    );
};

// END CELL Wallets

// START CELL active

const renderCellActive = (user) => {
    return (
        <Cell wrapText style={{ justifyContent: "center" }}>
            {user.isActive ? <Icon icon="tick" intent={Intent.SUCCESS} /> : <Icon icon="none" />}
        </Cell>
    );
};

// END CELL active

// START CELL verified

const renderCellVerified = (user) => {
    return (
        <Cell wrapText style={{ justifyContent: "center" }}>
            {user.verified ? <Icon icon="tick" intent={Intent.SUCCESS} /> : <Icon icon="none" />}
        </Cell>
    );
};

// END CELL verified

// START CELL business

const renderCellBusiness = (user, showDialog) => {
    return (
        <Cell>
            <Popover2
                content={
                    <PropertiesGrid
                        padding="5px"
                        values={[
                            {
                                label: "Name",
                                value: user.business?.name,
                            },
                            {
                                label: "Short name",
                                value: user.business?.shortName,
                            },
                            {
                                label: "",
                                value: (
                                    <Button
                                        small
                                        text="Edit business"
                                        icon="edit"
                                        onClick={() => handleEditBusiness(user, showDialog)}
                                    />
                                ),
                            },
                        ]}
                    />
                }
            >
                {user.business ? <span>{user.business.name}</span> : <NotSet>Not set</NotSet>}
            </Popover2>
        </Cell>
    );
};

// END CELL business

// BEGIN CELL Facebook page
const renderCellFacebookData = (user) => {
    if (!user?.business?.facebookData?.selectedPageId) {
        return <Cell />;
    }
    return (
        <Cell>
            <Popover2
                content={
                    <PropertiesGrid
                        padding="5px"
                        values={[
                            {
                                label: "Page name",
                                value: user.business?.facebookData.selectedPageName,
                            },
                            {
                                label: "Page ID",
                                value: (
                                    <div>
                                        {user.business?.facebookData.selectedPageId}{" "}
                                        {user.business?.facebookData.selectedPageId && (
                                            <AnchorButton
                                                small
                                                intent={Intent.PRIMARY}
                                                icon="document-open"
                                                target="_blank"
                                                href={`https://facebook.com/${user.business?.facebookData.selectedPageId}`}
                                            />
                                        )}{" "}
                                    </div>
                                ),
                            },
                        ]}
                    />
                }
            >
                <Button outlined small icon="tick" intent={Intent.SUCCESS} />
            </Popover2>
        </Cell>
    );
};
// END CELL Facebook page

const StyledUsersTable = styled.div`
    width: 100%;
    padding: 10px;
    position: relative;

    .centered-table-cell,
    .bp4-table-cell {
        display: flex;
        align-items: center;
    }
`;

const UsersTable = ({ users, loading, ordering = {}, onChangeSort, rootUserAgency }) => {
    const history = useHistory();
    const { showDialog } = useContext(DialogContext);
    const withUser =
        (func, ...args) =>
        (index) =>
            func(users[index], ...args);
    const [columnWidths, setColumnWidths] = useState([
        50, 50, 200, 200, 80, 150, 100, 80, 120, 190, 80, 80, 100, 100, 120, 50,
    ]);

    return (
        <StyledUsersTable>
            <Table2
                enableGhostCells
                numRows={users.length}
                enableRowHeader={false}
                numFrozenColumns={3}
                fill
                defaultRowHeight={55}
                columnWidths={columnWidths}
                disabled={loading}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
                renderMode={RenderMode.NONE} // https://github.com/palantir/blueprint/issues/3904
            >
                <Column name="" cellRenderer={withUser(renderCellMenu, { showDialog, rootUserAgency })} />
                <Column name="" cellRenderer={withUser(renderCellAvatar)} />
                <Column
                    cellRenderer={withUser((user) => (
                        <Cell>{user.username}</Cell>
                    ))}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Username",
                        sortKey: "username",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser((user) => (
                        <Cell>{user.email}</Cell>
                    ))}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Email",
                        sortKey: "email",
                        ordering,
                        onChangeSort,
                    })}
                />

                <Column
                    cellRenderer={withUser(renderCellLang)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Lang",
                        sortKey: "lang",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser(renderCellProduct)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Product",
                        sortKey: "subscriptionProduct",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser(renderCellJoinedAt)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Joined",
                        sortKey: "date_joined",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column name="Live ads" cellRenderer={withUser(renderCellLiveMessageCount, history)} />
                <Column
                    cellRenderer={withUser(renderCellWallets)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Wallet",
                        sortKey: "walletBalance",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser(renderCellWalletSubscription)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <Icon icon="stopwatch" color="gray" />,
                        name: "WalletSubscription",
                        sortKey: "walletSubscription",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser(renderCellActive)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Active",
                        sortKey: "isActive",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser(renderCellVerified)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Verified",
                        sortKey: "verified",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser((user) => (
                        <Cell>{user.firstName}</Cell>
                    ))}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "First name",
                        sortKey: "firstName",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser((user) => (
                        <Cell>{user.lastName}</Cell>
                    ))}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Last name",
                        sortKey: "lastName",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser(renderCellBusiness, showDialog)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Business",
                        sortKey: "business_name",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withUser(renderCellFacebookData)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <FaFacebook />,
                    })}
                />
            </Table2>
        </StyledUsersTable>
    );
};

UsersTable.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
        })
    ).isRequired,
    loading: PropTypes.bool,
    ordering: PropTypes.shape({}),
    onChangeSort: PropTypes.func,
    rootUserAgency: PropTypes.shape({
        id: PropTypes.string,
    }),
};

UsersTable.defaultProps = {
    loading: false,
    ordering: {},
    onChangeSort: null,
    rootUserAgency: null,
};

export default UsersTable;
